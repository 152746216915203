/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Alert, Button, Flex, Modal } from 'antd';

const ApproveBulkVisit = ({
    open,
    onClose,
    data,
    onSubmit,
    loading,
}: {
    open: boolean;
    onClose: () => void;
    data: any;
    onSubmit: () => void;
    loading: boolean;
}) => {
    return (
        <Modal
            title="Approve Bulk Request"
            open={open}
            onCancel={onClose}
            footer={
                <Flex justify="space-between" style={{ width: '100%' }}>
                    <Button onClick={onClose} danger ghost type="primary">
                        Cancel
                    </Button>
                    <Button ghost type="primary" onClick={onSubmit} loading={loading}>
                        Approve
                    </Button>
                </Flex>
            }
        >
            <Alert type="warning" message={`You're about to approve ${data.length} normal visit requests.`} />
        </Modal>
    );
};

export default ApproveBulkVisit;
