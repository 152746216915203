/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    TRANSFER_INMATE_FAIL, 
    TRANSFER_INMATE_START, 
    TRANSFER_INMATE_SUCCESS,
    TRANSFER_INMATE_CLEAR
    } from "../../../constants/actionType/inmates";

export const transferInmateData = (data: any) =>  ({type: TRANSFER_INMATE_START, data});

export const transferInmateSuccess = (data: any) => ({
    type: TRANSFER_INMATE_SUCCESS, 
    payload: data
})

export const transferInmateFail = (error: any) => ({ type: TRANSFER_INMATE_FAIL, payload: error})

export const clearTransferInmate = () => ({
    type: TRANSFER_INMATE_CLEAR
   })
