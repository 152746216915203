/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_USER_DATA_FAIL, 
    GET_USER_DATA_START, 
    GET_USER_DATA_SUCCESS 
} from "../../constants/actionType/auth";
import { fetchData } from "../../helpers";
import { delay } from '../../constants/time';

interface ResponseGenerator{
    data: any
}

function* getUserData() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, `user/retrieve-users`);
        yield call(delay, 3000)
        yield put({type: GET_USER_DATA_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_USER_DATA_FAIL, error: e.message });
    }
}

export default function* fetchUserData() {
    yield takeEvery(GET_USER_DATA_START, getUserData)
}