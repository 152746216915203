/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Flex, Form, Input, Card, Button, Radio, notification } from 'antd';
import React, { useEffect } from 'react';
import './index.css';
import { TextArea } from 'semantic-ui-react';
import { IUserSelectorType } from 'interfaces';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { clearSendBulkSMS, sendBulkSMSData } from 'redux/actions';
import Toast from 'components/common/Alert';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SendBulkSMS = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const {
        sendBulkSMS: { data, loading, message },
    } = userSelector((user) => user);

    const handleFormSubmit = async (values: any) => {
        const payload = {
            type: values.notificationType,
            subject: values.subject,
            message: values.message,
        };
        dispatch(sendBulkSMSData(payload));
    };

    useEffect(() => {
        if (data?.success) {
            Toast(api, 'Success', message);
            dispatch(clearSendBulkSMS());
        }

        if (data?.error) {
            Toast(api, 'Error', message);
            dispatch(clearSendBulkSMS());
        }
    }, [data, message]);

    return (
        <div className="send-bulk-sms-container">
            {contextHolder}
            <Card className="send-bulk-sms-container__box">
                <Flex justify="center" align="center" className="send-bulk-sms-container__box__item-box" vertical>
                    <span className="bold-text" style={{ textAlign: 'left', fontSize: '16px' }}>
                        Send Bulk SMS
                    </span>
                    <Divider />
                    <Form form={form} layout="vertical" style={{ width: '100%' }} onFinish={handleFormSubmit}>
                        <Form.Item
                            name="notificationType"
                            label="Notification Type"
                            rules={[{ required: true, message: 'Please select a notification type' }]}
                        >
                            <Radio.Group className="radio-group-vertical">
                                <Radio value="info" style={{ display: 'block' }}>
                                    Notify Users via SMS (eHuza)
                                </Radio>
                                <Radio value="awareness" style={{ display: 'block' }}>
                                    SMS Instructions to Users
                                </Radio>
                                <Radio value="update" style={{ display: 'block' }}>
                                    Send Inmate Codes to Families
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Divider />
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[{ required: true, message: 'Please enter a subject' }]}
                        >
                            <Input size="large" placeholder="Enter subject" />
                        </Form.Item>

                        <Form.Item
                            label="Body"
                            name="message"
                            rules={[{ required: true, message: 'Please enter a message' }]}
                        >
                            <TextArea
                                style={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    minWidth: '100%',
                                    boxSizing: 'border-box',
                                }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%' }}
                                loading={loading}
                            >
                                Send SMS
                            </Button>
                        </Form.Item>
                    </Form>
                </Flex>
            </Card>
        </div>
    );
};

export default SendBulkSMS;
