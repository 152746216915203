import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REQUEST_DELETE_USER_ACCOUNT_FAIL, 
    REQUEST_DELETE_USER_ACCOUNT_START, 
    REQUEST_DELETE_USER_ACCOUNT_SUCCESS 
} from "../../constants/actionType/auth";
import { saveData } from '../../helpers/index';

interface IData {
    user_id: string | number
}

interface ResponseGenerator{
    message: string
    data:  IData
}

interface IPayload {
    type: typeof REQUEST_DELETE_USER_ACCOUNT_START;
    data: IData
}

function* deleteUserAccount(data:IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, `user/delete-user-account`, data?.data);
        yield put({type: REQUEST_DELETE_USER_ACCOUNT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_DELETE_USER_ACCOUNT_FAIL, error: e.message });
    }
}

export default function* fetchDeleteUserAccount() {
    yield takeEvery(REQUEST_DELETE_USER_ACCOUNT_START, deleteUserAccount)
}