/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
     REQUEST_USER_ACCOUNT_FAIL, 
     REQUEST_USER_ACCOUNT_START, 
     REQUEST_USER_ACCOUNT_SUCCESS 
} from "../../../constants/actionType/users";
import { fetchData } from "../../../helpers";

interface ResponseGenerator{}

function* getUserProfileData() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, `user/get-user-profile`);
        yield put({type:  REQUEST_USER_ACCOUNT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:  REQUEST_USER_ACCOUNT_FAIL, error: e.message });
    }
}

export default function* fetchUserProfile() {
    yield takeEvery( REQUEST_USER_ACCOUNT_START, getUserProfileData)
}