/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { Modal, Flex, Button, Spin, Table, Divider, Card } from 'antd';
import { FilePdfOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
    clearCloseDeliveryNoteFileStore,
    clearPurchaseOrderDetails,
    deliveryNoteData,
    getDeliveryNoteItemsData,
} from '../../../redux/actions';

import { DataType } from '../../shop/orders/PurchaseOrdersDetails';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const DeliveryNoteDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [tableData, setTableData] = useState<DataType[]>([]);
    const {
        getDeliveryNoteItems: { data, loading },
    } = userSelector((user) => user);

    const deliveryNoteId = state && state?.deliveryNoteId;

    const dataCol: DataType[] =
        data?.details?.map((item: any, index: number) => ({
            key: index,
            name: item?.item ?? '',
            quantity: item?.quantity ?? 0,
            unit_price: item?.unit_price ?? 0,
            total_price: item?.total_price ?? 0,
            order_day: item?.order_day ?? '',
            delivery_quantity: 0,
        })) || [];

    const baseColumns = [
        {
            title: 'Items',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Delivery quantity',
            dataIndex: 'delivery_quantity',
            key: 'delivery_quantity',
        },
        {
            title: 'Total amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
    ];

    const daysColumn = { title: 'Days', dataIndex: 'order_day', key: 'order_day' };
    const includeDaysColumn = data?.details?.some((item: any) => item.order_day && item.order_day.trim() !== '');

    const columns = includeDaysColumn ? [daysColumn, ...baseColumns] : baseColumns;

    useEffect(() => {
        if (data?.details) {
            const formattedData = data.details.map((item: any, index: number) => ({
                key: index,
                id: item.id,
                name: item?.item ?? '',
                unit_price: item?.unit_price ?? 0,
                total_price: item?.total_price ?? 0,
                order_day: item?.order_day ?? '',
                delivery_quantity: item?.delivery_quantity ?? 0,
                quantity: item?.requested_quantity ?? 0,
            }));
            setTableData(formattedData);
        }
    }, [data]);

    useEffect(() => {
        if (deliveryNoteId) {
            dispatch(
                getDeliveryNoteItemsData({
                    delivery_note_Id: deliveryNoteId,
                }),
            );
        }
    }, [deliveryNoteId]);

    useEffect(() => {
        if (!state) {
            navigate('/orders');
        }
    }, [state]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const fileInfo: any = data;
    const deliveryNoteInfo = [
        { title: 'Facility', description: data && fileInfo?.facility },
        { title: 'File Name', description: data && fileInfo?.delivery_file },
        {
            title: 'Total Amount on Delivery',
            description: !fileInfo?.total_amount ? '' : `${fileInfo?.total_amount} ${fileInfo?.currency}`,
        },
        { title: 'Cell', description: data && fileInfo?.cell_name },
        { title: 'Delivered By', description: data && fileInfo?.delivered_by },
    ];

    return (
        <>
            <Flex align="center" justify="space-between" style={{ padding: '10px' }}>
                <Button
                    danger
                    type="primary"
                    icon={<LeftOutlined />}
                    ghost
                    onClick={() => {
                        navigate('/orders');
                        dispatch(clearPurchaseOrderDetails());
                        dispatch(clearCloseDeliveryNoteFileStore());
                        dispatch(deliveryNoteData({ ordersFileId: '', check_status: false }));
                    }}
                >
                    Go Back
                </Button>
            </Flex>
            <Divider style={{ margin: '4px' }} />
            <Flex align="center" justify="space-between" gap="middle" style={{ width: '100%', padding: '10px' }}>
                {deliveryNoteInfo?.map((item, index: number) => (
                    <Card size="small" bordered key={index} style={{ width: '25%' }}>
                        <Flex align="left" vertical>
                            <h5 style={{ textAlign: 'left' }}>{item.title}</h5>
                            <span style={{ textAlign: 'left' }}>{item.description}</span>
                        </Flex>
                    </Card>
                ))}
            </Flex>

            {loading ? (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            ) : (
                <Flex vertical>
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={tableData}
                        pagination={tableData.length >= 30 ? undefined : false}
                    />
                </Flex>
            )}
            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>
                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>PURCHASE ORDER</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {data?.data?.facility}
                            </span>
                            <span>
                                <b>Deliver note number: </b>
                                {data?.data?.delivery_file}
                            </span>
                            <span>
                                <b>Delivered on: </b>
                                {moment(data?.data?.date).format('DD-MMM-YYYY')}
                            </span>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataCol}
                            pagination={data?.data?.length >= 30 ? undefined : false}
                        />
                        <div className="print-users">
                            <span>
                                <b>Prepared by: </b>
                                {data?.data?.delivered_by}
                            </span>
                        </div>

                        {data?.data?.reviewers?.map((item: any) => {
                            return (
                                <>
                                    {' '}
                                    <div className="print-users">
                                        {item?.role_id === 5 ? (
                                            <span>
                                                <b>Reviewed by: </b>
                                                {item?.first_name} {item?.last_name}
                                            </span>
                                        ) : null}
                                        {item?.role_id === 3 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Verified for cash availability by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                        {item?.role_id === 2 || item?.role_id == 7 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Approved by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeliveryNoteDetails;
