/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './index.css';

import {
    MdAccountBalance,
    MdArrowDropDown,
    MdArrowDropUp,
    MdCalendarToday,
    MdManageAccounts,
    MdOutlineDashboardCustomize,
    MdOutlineFormatListBulleted,
    MdVideocam,
    MdOutlineMiscellaneousServices,
    MdOutlineSavings,
    MdOutlineViewAgenda,
    MdProductionQuantityLimits,
} from 'react-icons/md';
import { Button } from 'antd';

import { IoPeopleOutline } from 'react-icons/io5';
import { GiHistogram, GiMoneyStack, GiPrisoner, GiWallet } from 'react-icons/gi';
import { BsClipboardCheck, BsShop } from 'react-icons/bs';
import { AiFillProfile } from 'react-icons/ai';
import { TbPrison, TbReport } from 'react-icons/tb';
import { Badge, Skeleton } from 'antd';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { useDispatch } from 'react-redux';
import { getNotificationData, readNotificationData } from '../../../../redux/actions';
import { BiLogOutCircle } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { HiHome } from 'react-icons/hi';
import { FaHistory } from 'react-icons/fa';
import { appName } from '../../../../constants/defaultLabel';

// const AppLogo: any = Logo;
const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SubNavBar = ({ userData, logout, loadingLogout }: any) => {
    const { pathname } = location;
    const splitLocation: any = pathname.split('/');
    const dispatch = useDispatch();
    const [openReportSubNav, setOpenReportSubNav] = useState<boolean>();
    const [openInmatesSubNav, setOpenInmatesSubNav] = useState<boolean>();
    const [openSupInmatesSubNav, setOpenSupInmatesSubNav] = useState<boolean>();
    const [openServicers, setOpenServices] = useState<boolean>();

    const {
        getNotifications: { data },
    } = userSelector((user) => user);

    const readNotificationBtn = () => {
        dispatch(
            readNotificationData({
                data: data?.data?.notificationData,
            }),
        );
    };

    useEffect(() => {
        dispatch(getNotificationData());
    }, [dispatch]);

    const usersData = userData !== undefined ? userData[0] : [{}];

    const options = () => {
        if (usersData?.role_id === 1) {
            return (
                <>
                    <Link
                        to="/general-dashboard"
                        className={splitLocation[1] === 'general-dashboard' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenSupInmatesSubNav(false);
                        }}
                    >
                        <MdOutlineDashboardCustomize size={18} />
                        <span>Dashboard</span>
                    </Link>
                    <Link
                        to="/facilities"
                        className={splitLocation[1] === 'facilities' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenSupInmatesSubNav(false);
                        }}
                    >
                        <TbPrison size={18} />
                        <span>Facility</span>
                    </Link>
                    <Link
                        to="/facility-appointments"
                        className={splitLocation[1] === 'facility-appointments' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenSupInmatesSubNav(false);
                        }}
                    >
                        <MdCalendarToday size={18} />
                        <span>Booking</span>
                    </Link>
                    <div className="main-link">
                        <Link
                            to="/facility-inmates-list"
                            onClick={() => {
                                openSupInmatesSubNav === false
                                    ? setOpenSupInmatesSubNav(true)
                                    : setOpenSupInmatesSubNav(false);
                            }}
                            className={
                                splitLocation[1] === 'facility-inmates-wallets'
                                    ? 'ActiveButton'
                                    : splitLocation[1] === 'facility-inmates-list'
                                    ? 'ActiveButton'
                                    : 'Icon-group'
                            }
                        >
                            <div className="btn-right-items">
                                <GiPrisoner size={18} />
                                <span>Inmates</span>
                            </div>
                            {openSupInmatesSubNav === true ? (
                                <MdArrowDropUp size={18} className="right-icon" />
                            ) : (
                                <MdArrowDropDown size={18} />
                            )}
                        </Link>
                        {openSupInmatesSubNav === true ? (
                            <div className="sub-nav">
                                <Link
                                    to="/facility-inmates-list"
                                    className={splitLocation[1] === 'facility-inmates-list' ? 'active' : 'Icon-group'}
                                >
                                    <MdOutlineFormatListBulleted size={18} />
                                    <span>List</span>
                                </Link>
                                <Link
                                    to="/facility-inmates-wallets"
                                    className={
                                        splitLocation[1] === 'facility-inmates-wallets' ? 'active' : 'Icon-group'
                                    }
                                >
                                    <GiWallet size={18} />
                                    <span>Wallets</span>
                                </Link>
                            </div>
                        ) : null}
                    </div>
                    <Link
                        to="/facility-transactions"
                        className={splitLocation[1] === 'facility-transactions' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenSupInmatesSubNav(false);
                        }}
                    >
                        <GiMoneyStack size={18} />
                        <span>Transactions</span>
                    </Link>
                    <Link
                        to="/manage-accounts"
                        className={splitLocation[1] === 'manage-accounts' ? 'ActiveButton' : 'Icon-group'}
                    >
                        <MdManageAccounts size={18} />
                        <span>Manage accounts</span>
                    </Link>
                    <Link
                        to="/activity-log"
                        className={splitLocation[1] === 'general-dashboard' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenSupInmatesSubNav(false);
                        }}
                    >
                        <FaHistory size={18} />
                        <span>Activity Log</span>
                    </Link>
                </>
            );
        }
        if ([2, 3, 5, 6, 7].includes(usersData?.role_id) === true) {
            return (
                <>
                    <Link
                        className={splitLocation[1] === 'dashboard' ? 'ActiveButton' : 'Icon-group'}
                        to="/dashboard"
                        onClick={() => {
                            setOpenReportSubNav(false);
                            readNotificationBtn();
                            setOpenInmatesSubNav(false);
                            setOpenServices(false);
                        }}
                    >
                        <HiHome size={18} />
                        <span>Dashboard</span>
                    </Link>
                    <Badge count={0} offset={[10, 10]}>
                        <Link
                            to="/appointment"
                            className={splitLocation[1] === 'appointment' ? 'ActiveButton' : 'Icon-group'}
                            onClick={() => {
                                setOpenReportSubNav(false);
                                setOpenInmatesSubNav(false);
                            }}
                        >
                            <MdOutlineViewAgenda size={18} />
                            <span>Appointment</span>
                        </Link>
                    </Badge>

                    <div className="main-link">
                        <Link
                            to="/inmates-list"
                            onClick={() => {
                                openInmatesSubNav === false ? setOpenInmatesSubNav(true) : setOpenInmatesSubNav(false);
                                setOpenReportSubNav(false);
                                setOpenServices(false);
                            }}
                            className={
                                splitLocation[1] === 'inmates-wallets' || splitLocation[1] === 'inmates-list'
                                    ? 'ActiveButton'
                                    : 'Icon-group'
                            }
                        >
                            <div className="btn-right-items">
                                <GiPrisoner size={18} />
                                <span>Inmates</span>
                            </div>
                            {openInmatesSubNav === true ? (
                                <MdArrowDropUp size={18} className="right-icon" />
                            ) : (
                                <MdArrowDropDown size={18} />
                            )}
                        </Link>
                        {openInmatesSubNav === true ? (
                            <div className="sub-nav">
                                <Link
                                    to="/inmates-list"
                                    className={splitLocation[1] === 'inmates-list' ? 'active' : 'Icon-group'}
                                >
                                    <IoPeopleOutline size={18} />
                                    <span>Inmates</span>
                                </Link>
                                <Link
                                    to="/inmates-wallets"
                                    className={splitLocation[1] === 'inmates-wallets' ? 'active' : 'Icon-group'}
                                >
                                    <GiWallet size={18} />
                                    <span>Wallets</span>
                                </Link>
                            </div>
                        ) : null}
                    </div>

                    <div className="main-link">
                        <Link
                            to="/out-going-money"
                            onClick={() => {
                                openServicers === false ? setOpenServices(true) : setOpenServices(false);
                                setOpenReportSubNav(false);
                                setOpenInmatesSubNav(false);
                            }}
                            className={
                                splitLocation[1] === 'out-going-money' || splitLocation[1] === 'bulk-saving'
                                    ? 'ActiveButton'
                                    : 'Icon-group'
                            }
                        >
                            <div className="btn-right-items">
                                <MdOutlineMiscellaneousServices size={18} />
                                <span>Services</span>
                            </div>
                            {openServicers === true ? (
                                <MdArrowDropUp size={18} className="right-icon" />
                            ) : (
                                <MdArrowDropDown size={18} />
                            )}
                        </Link>
                        {openServicers === true ? (
                            <div className="sub-nav">
                                <Link
                                    to="/out-going-money"
                                    className={splitLocation[1] === 'out-going-money' ? 'active' : 'Icon-group'}
                                >
                                    <FiSend size={18} />
                                    <span>Transfers</span>
                                </Link>
                                <Link
                                    to="/bulk-saving"
                                    className={splitLocation[1] === 'bulk-saving' ? 'active' : 'Icon-group'}
                                >
                                    <MdOutlineSavings size={18} />
                                    <span>10% Income</span>
                                </Link>
                            </div>
                        ) : null}
                    </div>

                    {[2, 7, 6].includes(usersData?.role_id) === true ? (
                        <div className="main-link">
                            <Link
                                to="/appointments-reports"
                                onClick={() => {
                                    openReportSubNav === false ? setOpenReportSubNav(true) : setOpenReportSubNav(false);
                                    setOpenInmatesSubNav(false);
                                    setOpenServices(false);
                                }}
                                className={
                                    splitLocation[1] === 'appointments-reports'
                                        ? 'ActiveButton'
                                        : splitLocation[1] === 'transactions-report'
                                        ? 'ActiveButton'
                                        : 'Icon-group'
                                }
                            >
                                <div className="btn-right-items">
                                    <TbReport size={18} />
                                    <span>Reports</span>
                                </div>
                                {openReportSubNav === true ? (
                                    <MdArrowDropUp size={18} className="right-icon" />
                                ) : (
                                    <MdArrowDropDown size={18} />
                                )}
                            </Link>
                            {openReportSubNav === true ? (
                                <div className="sub-nav">
                                    <Link
                                        to="/appointments-reports"
                                        className={
                                            splitLocation[1] === 'appointments-reports' ? 'active' : 'Icon-group'
                                        }
                                    >
                                        <GiHistogram size={18} />
                                        <span>Booking</span>
                                    </Link>
                                    <Link
                                        to="/transactions-report"
                                        className={splitLocation[1] === 'transactions-report' ? 'active' : 'Icon-group'}
                                    >
                                        <MdAccountBalance size={18} />
                                        <span>Transactions</span>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <Link
                        className={splitLocation[1] === 'confirm-visit' ? 'ActiveButton' : 'Icon-group'}
                        to="/confirm-visit"
                        onClick={() => {
                            setOpenReportSubNav(false);
                            readNotificationBtn();
                            setOpenInmatesSubNav(false);
                            setOpenServices(false);
                        }}
                    >
                        <BsClipboardCheck size={18} />
                        <span>Confirm visit</span>
                    </Link>
                    <Link
                        to="/shop-products"
                        className={splitLocation[1] === 'shop-products' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenReportSubNav(false);
                            setOpenInmatesSubNav(false);
                            setOpenServices(false);
                        }}
                    >
                        <MdProductionQuantityLimits size={18} />
                        <span>Products</span>
                    </Link>
                    <Badge count={data?.data?.order} offset={[10, 10]}>
                        <Link
                            className={splitLocation[1] === 'orders' ? 'ActiveButton' : 'Icon-group'}
                            to="/orders"
                            onClick={() => {
                                setOpenReportSubNav(false);
                                setOpenInmatesSubNav(false);
                                readNotificationBtn();
                                setOpenServices(false);
                            }}
                        >
                            <BsShop size={18} />
                            <span>Shop orders</span>
                        </Link>
                    </Badge>
                    <Link
                        to="/video-visits"
                        className={splitLocation[1] === 'video-visits' ? 'ActiveButton' : 'Icon-group'}
                    >
                        <MdVideocam size={18} />
                        <span>Video visits</span>
                    </Link>
                    <Link
                        to="/manage-accounts"
                        className={splitLocation[1] === 'manage-accounts' ? 'ActiveButton' : 'Icon-group'}
                        onClick={() => {
                            setOpenInmatesSubNav(false);
                            setOpenReportSubNav(false);
                            setOpenServices(false);
                        }}
                    >
                        <MdManageAccounts size={18} />
                        <span>Manage accounts</span>
                    </Link>
                </>
            );
        }

        if ([4].includes(usersData?.role_id) === true) {
            return (
                <>
                    {/* <Link
                        to="/shop-seller"
                        className={splitLocation[1] === 'shop-seller' ? 'ActiveButton' : 'Icon-group'}
                    >
                        <MdOutlinePointOfSale size={18} />
                        <span>Shop-seller</span>
                    </Link> */}
                    <Link
                        to="/shop-products"
                        className={splitLocation[1] === 'shop-products' ? 'ActiveButton' : 'Icon-group'}
                    >
                        <MdProductionQuantityLimits size={18} />
                        <span>Products</span>
                    </Link>
                    <Badge count={data?.data?.order} offset={[10, 10]}>
                        <Link
                            className={splitLocation[1] === 'orders' ? 'ActiveButton' : 'Icon-group'}
                            to="/shop-seller"
                            onClick={readNotificationBtn}
                        >
                            <BsShop size={18} />
                            <span>Shop orders</span>
                        </Link>
                    </Badge>
                </>
            );
        } else {
            return (
                <>
                    {[...Array(11)].map((_x, i) => (
                        <Skeleton.Button
                            active={true}
                            size={'default'}
                            shape={'default'}
                            block={false}
                            style={{ width: '100%', marginBottom: '25px' }}
                            key={i}
                        />
                    ))}
                </>
            );
        }
    };

    return (
        <>
            <div className="side-bar__container">
                <div className="img-content__item">
                    <span>{appName}</span>
                </div>
                <div className="side-bar__container-box-content">
                    {options()}

                    <div style={{ marginTop: '40px' }}>
                        <Link to="/user-profile" className="Icon-group" style={{ marginTop: '10px' }}>
                            <AiFillProfile size={18} />
                            <span>Profile</span>
                        </Link>
                        <Button className="logout-btn" loading={loadingLogout} onClick={() => logout()}>
                            {loadingLogout === false ? <BiLogOutCircle size={18} className="logout-icon" /> : null}
                            <span>{loadingLogout ? `Please wait...` : `Logout`}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubNavBar;
