import { 
    REQUEST_FINANCE_REPORT_START, 
    REQUEST_FINANCE_REPORT_SUCCESS, 
    REQUEST_FINANCE_REPORT_FAIL 
} from '../../../constants/actionType/finance';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const getFinanceReport = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_FINANCE_REPORT_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_FINANCE_REPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_FINANCE_REPORT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        default: {
            return state;
          }
    }
}

export default getFinanceReport;