/* eslint-disable @typescript-eslint/no-explicit-any */
import {
      VISITOR_LIMIT_CLEAR,
      VISITOR_LIMIT_FAIL, 
      VISITOR_LIMIT_START, 
      VISITOR_LIMIT_SUCCESS 
  } from "../../../constants/actionType/facility";
  
   export const visitorLimitData = (data: any) => ({type:  VISITOR_LIMIT_START, data});
   
   export const visitorLimitSuccess = (data:any) => {
    return ({
      type: VISITOR_LIMIT_SUCCESS,
      payload: data
    })
   }
   
   export const visitorLimitError = (error:any) => ({
     type: VISITOR_LIMIT_FAIL,
     payload: error
   })
  
   export const clearVisitorLimit = () => ({type: VISITOR_LIMIT_CLEAR })