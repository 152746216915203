import React from 'react';
import SendMoney from '../components/sendMoney';
import AppointmentPageContainer from '../containers/appointments';
import DashboardPageContainer from '../containers/dashboard';
import LandingPageContainer from '../containers/landingPage';
import LoginPageContainer from '../containers/loginPage/index';
import MeetingAccessPageContainer from '../containers/meetings';
import VirtualAppointmentPage from '../components/Appointment/virtualAppointment';
import UpdatePassword from '../components/updatePassword';
import VideoMeeting from '../components/meeting/videoMeeting';
import FacilityVideoMeeting from '../components/meeting/facilityVideoMeeting';
import NotFoundComponent from '../components/NotFoundComponent/index';
import SendBulkSMS from 'components/SendBulkSMS';

interface RouteElement {
    name: string;
    path: string;
    element: React.ReactElement;
    isProtected: boolean;
    key: string;
}

type Routes = Array<RouteElement>;

const routes: Routes = [
    {
        path: '/',
        isProtected: false,
        key: 'home',
        name: 'Home',
        element: <LandingPageContainer />,
    },
    {
        path: '/purchase-order-details',
        isProtected: true,
        key: 'purchase-order-details',
        name: 'Purchase-order-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/shop-delivery-note-details',
        isProtected: true,
        key: 'shop-delivery-note-details',
        name: 'Shop-delivery-note-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/delivery-note-details',
        isProtected: true,
        key: 'delivery-note-details',
        name: 'delivery-note-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/shop-purchase-order-details',
        isProtected: true,
        key: 'shop-purchase-order-details',
        name: 'shop-purchase-order-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/admin',
        isProtected: false,
        key: 'login',
        name: 'Login',
        element: <LoginPageContainer />,
    },
    {
        path: '/change-password',
        isProtected: false,
        key: 'change-password',
        name: 'Change-password',
        element: <UpdatePassword />,
    },

    {
        path: '/request-physical-appointment',
        isProtected: false,
        key: 'request-appointment',
        name: 'Request-appointment',
        element: <AppointmentPageContainer />,
    },
    {
        path: '/request-virtual-appointment',
        isProtected: false,
        key: 'request-virtual-appointment',
        name: 'request-virtual-appointment',
        element: <VirtualAppointmentPage />,
    },
    {
        path: '/send-money',
        isProtected: false,
        key: 'send-money',
        name: 'Send-money',
        element: <SendMoney />,
    },
    {
        path: '/access-meeting',
        isProtected: false,
        key: 'access-meeting',
        name: 'access-meeting',
        element: <MeetingAccessPageContainer />,
    },
    {
        path: '/start-meeting',
        isProtected: false,
        key: 'start-meeting',
        name: 'start-meeting',
        element: <VideoMeeting />,
    },
    {
        path: '/activity-log',
        isProtected: true,
        key: 'activity-log',
        name: 'activity-log',
        element: <DashboardPageContainer />,
    },
    {
        path: '/facility-video-meeting',
        isProtected: true,
        key: 'facility-video-meeting',
        name: 'facility-video-meeting',
        element: <FacilityVideoMeeting />,
    },
    {
        path: '/send-bulk-sms',
        isProtected: false,
        key: 'send-bulk-sms',
        name: 'send-bulk-sms',
        element: <SendBulkSMS />,
    },
    {
        path: '/dashboard',
        isProtected: true,
        key: 'dashboard',
        name: 'dashboard',
        element: <DashboardPageContainer />,
    },
    {
        path: '/users-accounts',
        isProtected: true,
        key: 'users-accounts',
        name: 'users-accounts',
        element: <DashboardPageContainer />,
    },
    {
        path: '/global-report',
        isProtected: true,
        key: 'global-report',
        name: 'global-report',
        element: <DashboardPageContainer />,
    },
    {
        path: '/finance-report',
        isProtected: true,
        key: 'finance-report',
        name: 'finance-report',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmates-list',
        isProtected: true,
        key: 'inmates-list',
        name: 'inmates-list',
        element: <DashboardPageContainer />,
    },
    {
        path: '/appointment',
        isProtected: true,
        key: 'appointment',
        name: 'appointment',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmate-wallet',
        isProtected: true,
        key: 'inmate-wallet',
        name: 'inmate-wallet',
        element: <DashboardPageContainer />,
    },
    {
        path: '/shop',
        isProtected: true,
        key: 'shop',
        name: 'shop',
        element: <DashboardPageContainer />,
    },
    {
        path: '/shop-products',
        isProtected: true,
        key: 'shop-products',
        name: 'shop-products',
        element: <DashboardPageContainer />,
    },
    {
        path: '/shop-finance',
        isProtected: true,
        key: 'shop-finance',
        name: 'shop-finance',
        element: <DashboardPageContainer />,
    },
    {
        path: '/shop-seller',
        isProtected: true,
        key: 'shop-seller',
        name: 'shop-seller',
        element: <DashboardPageContainer />,
    },

    {
        path: '/orders',
        isProtected: true,
        key: 'orders',
        name: 'orders',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmates-wallets',
        isProtected: true,
        key: 'inmates-wallets',
        name: 'inmates-wallets',
        element: <DashboardPageContainer />,
    },
    {
        path: '/report-details',
        isProtected: true,
        key: 'report-details',
        name: 'report-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/orders-made',
        isProtected: true,
        key: 'orders-made',
        name: 'orders-made',
        element: <DashboardPageContainer />,
    },
    {
        path: '/super-admin-dashboard',
        isProtected: true,
        key: 'super-admin-dashboard',
        name: 'super-admin-dashboard',
        element: <DashboardPageContainer />,
    },
    {
        path: '/header-officer',
        isProtected: true,
        key: 'header-officer',
        name: 'header-officer',
        element: <DashboardPageContainer />,
    },
    {
        path: '/out-going-money',
        isProtected: true,
        key: 'out-going-money',
        name: 'out-going-money',
        element: <DashboardPageContainer />,
    },
    {
        path: '/facilities',
        isProtected: true,
        key: 'facilities',
        name: 'facilities',
        element: <DashboardPageContainer />,
    },
    {
        path: '/transactions-report',
        isProtected: true,
        key: 'transactions-report',
        name: 'transactions-report',
        element: <DashboardPageContainer />,
    },
    {
        path: '/appointments-reports',
        isProtected: true,
        key: 'appointments-reports',
        name: 'appointments-reports',
        element: <DashboardPageContainer />,
    },
    {
        path: '/confirm-visit',
        isProtected: true,
        key: 'confirm-visit',
        name: 'confirm-visit',
        element: <DashboardPageContainer />,
    },
    {
        path: '/settings',
        isProtected: true,
        key: 'settings',
        name: 'settings',
        element: <DashboardPageContainer />,
    },
    {
        path: '/manage-accounts',
        isProtected: true,
        key: 'manage-accounts',
        name: 'manage-accounts',
        element: <DashboardPageContainer />,
    },
    {
        path: '/facility-inmates-list',
        isProtected: true,
        key: 'facility-inmates-list',
        name: 'facility-inmates-list',
        element: <DashboardPageContainer />,
    },
    {
        path: '/facility-inmates-wallets',
        isProtected: true,
        key: 'facility-inmates-wallets',
        name: 'facility-inmates-wallets',
        element: <DashboardPageContainer />,
    },
    {
        path: '/facility-appointments',
        isProtected: true,
        key: 'facility-appointments',
        name: 'facility-appointments',
        element: <DashboardPageContainer />,
    },
    {
        path: '/general-dashboard',
        isProtected: true,
        key: 'general-dashboard',
        name: 'general-dashboard',
        element: <DashboardPageContainer />,
    },
    {
        path: '/facility-transactions',
        isProtected: true,
        key: 'facility-transactions',
        name: 'facility-transactions',
        element: <DashboardPageContainer />,
    },
    {
        path: '/bound-phone-number',
        isProtected: true,
        key: 'bound-phone-number',
        name: 'bound-phone-number',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmate-phone-numbers',
        isProtected: true,
        key: 'inmate-phone-numbers',
        name: 'inmate-phone-numbers',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmate-calls',
        isProtected: true,
        key: 'inmate-calls',
        name: 'inmate-calls',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmate-calls-history',
        isProtected: true,
        key: 'inmate-calls-history',
        name: 'inmate-calls-history',
        element: <DashboardPageContainer />,
    },
    {
        path: '/inmates-calls-invoices',
        isProtected: true,
        key: 'inmates-calls-invoices',
        name: 'inmates-calls-invoices',
        element: <DashboardPageContainer />,
    },

    {
        path: '/record-savings',
        isProtected: true,
        key: 'record-savings',
        name: 'record-savings',
        element: <DashboardPageContainer />,
    },
    {
        path: '/bulk-saving-details',
        isProtected: true,
        key: 'bulk-saving-details',
        name: 'bulk-saving-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/bulk-saving',
        isProtected: true,
        key: 'bulk-saving',
        name: 'bulk-saving',
        element: <DashboardPageContainer />,
    },

    {
        path: '/record-transaction',
        isProtected: true,
        key: 'record-transaction',
        name: 'record-transaction',
        element: <DashboardPageContainer />,
    },
    {
        path: '/bulk-transaction-details',
        isProtected: true,
        key: 'bulk-transaction-details',
        name: 'bulk-transaction-details',
        element: <DashboardPageContainer />,
    },
    {
        path: '/pending-transaction-approval-request',
        isProtected: true,
        key: 'pending-transaction-approval-request',
        name: 'pending-transaction-approval-request',
        element: <DashboardPageContainer />,
    },
    {
        path: '/pending-approval-request',
        isProtected: true,
        key: 'pending-approval-request',
        name: 'pending-approval-request',
        element: <DashboardPageContainer />,
    },
    {
        path: '/user-profile',
        isProtected: true,
        key: 'user-profile',
        name: 'user-profile',
        element: <DashboardPageContainer />,
    },
    {
        path: '/completed-visits',
        isProtected: true,
        key: 'completed-visits',
        name: 'completed-visits',
        element: <DashboardPageContainer />,
    },
    {
        path: '/video-visits',
        isProtected: true,
        key: 'video-visits',
        name: 'video-visits',
        element: <DashboardPageContainer />,
    },

    {
        path: '*',
        element: <NotFoundComponent />,
        isProtected: false,
        key: 'Error page',
        name: 'error',
    },
];

export default routes;
