/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
     FETCH_ACTIVITY_LOG_FAIL, 
     FETCH_ACTIVITY_LOG_START, 
     FETCH_ACTIVITY_LOG_SUCCESS 
} from "../../../constants/actionType/users";
import {  saveData } from "../../../helpers";

interface ResponseGenerator{}

function* fetchGetActivityLog(data: any) {
    try {
        const formData: ResponseGenerator = yield call(saveData, 'user/activity-log',  data?.data);
        yield put({type:  FETCH_ACTIVITY_LOG_SUCCESS, data: formData});

    } catch (e: any) {
        yield put({ type:  FETCH_ACTIVITY_LOG_FAIL, error: e.message });
    }
}

export default function* getActivityLogData() {
    yield takeEvery( FETCH_ACTIVITY_LOG_START, fetchGetActivityLog)
}

