/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_ORDER_FILE_DETAILS_FAIL, 
    REQUEST_ORDER_FILE_DETAILS_START, 
    REQUEST_ORDER_FILE_DETAILS_SUCCESS 
} from "../../../constants/actionType/orders"

 export const getOrderFileDetailsData = (data: any) => ({type:  REQUEST_ORDER_FILE_DETAILS_START, data});
 
 export const getOrderFileDetailsSuccess = (data:any) => ({
   type: REQUEST_ORDER_FILE_DETAILS_SUCCESS,
   payload: data
 })
 
 export const getOrderFileDetailsError = (error:any) => ({
   type: REQUEST_ORDER_FILE_DETAILS_FAIL,
   payload: error
 })