/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_PUBLIC_ITEMS_SUCCESS,
    GET_PUBLIC_ITEMS_FAIL, 
    GET_PUBLIC_ITEMS_START } from "../../../constants/actionType/publicItems";
import { fetchData } from '../../../helpers';
import { delay } from '../../../constants/time';


export interface ResponseGenerator{}

function* getPublicItems() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'user/public-items');
        yield call(delay, 3000)
        yield put({type: GET_PUBLIC_ITEMS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_PUBLIC_ITEMS_FAIL, error: e.message });
    }
}

export default function* fetchPublicItems() {
    yield takeEvery(GET_PUBLIC_ITEMS_START, getPublicItems)
}