import React, { useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    clearRequestPhoneNumberLinkStore,
    getCallOfficerData,
    requestPhoneNumberLinkData,
} from '../../../../redux/actions';
import './style.css';
import { IoRemoveCircle } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { ILinkPhoneNumber } from '../../../../interfaces/linkPhoneNumber';
import { IUserSelectorType } from '../../../../interfaces';
import { countryCode } from '../../../../constants/telco';
import { hasOnlyDigits, isFirstTwoZeroZero, isFirstTwoZero, numberStartWithSeven } from '../../../Appointment';

interface DataType {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    relationShip: string;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const BoundPhoneNumber: React.FC = () => {
    const dispatch = useDispatch();
    const {
        state: { inmateId },
    } = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const [contacts, setContacts] = useState<DataType[]>([]);
    const [form, setForm] = useState<DataType>({
        id: inmateId,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationShip: '',
    });

    const { requestPhoneNumberLink } = userSelector((user) => user);

    const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const addFormField = () => {
        if (contacts.length < 6) {
            if (form.firstName !== '' && form.lastName !== '' && form.phoneNumber !== '' && form.relationShip !== '') {
                setContacts([
                    ...contacts,
                    {
                        id: inmateId,
                        firstName: form?.firstName,
                        lastName: form?.lastName,
                        phoneNumber: form?.phoneNumber,
                        relationShip: form?.relationShip,
                    },
                ]);
                setForm({
                    id: inmateId,
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    relationShip: '',
                });
            } else if (
                !hasOnlyDigits(form?.phoneNumber) ||
                form?.phoneNumber.length !== 9 ||
                isFirstTwoZeroZero(form?.phoneNumber) ||
                isFirstTwoZero(form?.phoneNumber) ||
                !numberStartWithSeven(form?.phoneNumber)
            ) {
                toast.error('Incorrect phone number provided');
            } else {
                toast.error('Please fill all fields.');
            }
        } else {
            toast.error('You can not add more than 6 contact at once.');
        }
    };

    const removeFormFields = (index: number) => {
        const rows = [...contacts];
        rows.splice(index, 1);
        setContacts(rows);
    };

    const onSubmit = () => {
        const newContactList: ILinkPhoneNumber[] = contacts.map((contact: DataType) => {
            return {
                inmate_id: contact.id,
                first_name: contact.firstName,
                last_name: contact.lastName,
                phone_number: `0${contact.phoneNumber}`,
                relation_ship: contact.relationShip,
            };
        });

        dispatch(
            requestPhoneNumberLinkData({
                data: newContactList,
            }),
        );
    };

    useEffect(() => {
        if (requestPhoneNumberLink?.data?.data) {
            toast.success(requestPhoneNumberLink.data.data.description);
            dispatch(clearRequestPhoneNumberLinkStore());
            navigate('/inmates-list');
        }
        if (requestPhoneNumberLink?.data?.failed) {
            toast.error('Failed to save phone number');
        }
    }, [requestPhoneNumberLink?.data]);

    useEffect(() => {
        dispatch(getCallOfficerData());
    }, []);

    return (
        <>
            <div className="user-account-tbl">
                <div className="tbl-items">
                    <div className="details-btn-group__left">
                        <Button onClick={() => navigate('/inmates-list')} className="back-btn">
                            <BsArrowLeft size={22} />
                            <span style={{ alignSelf: 'center', marginLeft: '5px' }}>Inmate list</span>
                        </Button>
                    </div>
                </div>
                <div className="row__box">
                    <div className="input-form__column">
                        <div className="input-box">
                            <span className="input-label">First name</span>
                            <Input
                                name="firstName"
                                value={form.firstName}
                                onChange={(event) => onHandleChangeInput(event)}
                                placeholder="Enter first name"
                            />
                        </div>
                        <div className="input-box">
                            <span className="input-label">Last name</span>
                            <Input
                                name="lastName"
                                value={form.lastName}
                                onChange={(event) => onHandleChangeInput(event)}
                                placeholder="Enter last name"
                            />
                        </div>
                        <div className="input-box">
                            <span className="input-label">Who is this?</span>
                            <Input
                                name="relationShip"
                                value={form.relationShip}
                                onChange={(event) => onHandleChangeInput(event)}
                                placeholder="Who is this?"
                            />
                        </div>
                        <div className="input-box">
                            <span className="input-label">Phone number</span>
                            <Input
                                name="phoneNumber"
                                addonBefore={countryCode}
                                placeholder={'78xxxxxxxxx'}
                                value={form.phoneNumber}
                                onChange={(event) => onHandleChangeInput(event)}
                            />
                        </div>

                        <div className="input-box">
                            <Button onClick={addFormField}>Add to the list</Button>
                        </div>
                    </div>
                    <div className="table-form__column">
                        <div>
                            {contacts &&
                                contacts.map((contact: DataType, index: number) => {
                                    return (
                                        <>
                                            <div className="list-title">
                                                <div className="row">
                                                    <span className="list-title__title">
                                                        {contact.firstName} {contact.lastName}
                                                    </span>

                                                    <IoRemoveCircle
                                                        color="red"
                                                        onClick={() => removeFormFields(index)}
                                                        className="icon"
                                                    />
                                                </div>
                                                <div className="list-title__subtitle">
                                                    <span>Phone: {contact.phoneNumber}</span> <span>|</span>{' '}
                                                    <span>Relation ship: {contact.relationShip}</span>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            {contacts.length === 0 ? (
                                <div className="empty-box">
                                    <MdDelete size={40} style={{ color: 'red' }} />
                                    <span className="empty">Empty</span>
                                </div>
                            ) : null}
                        </div>
                        {contacts.length !== 0 ? (
                            <div className="input-box">
                                <Button loading={requestPhoneNumberLink.loading} onClick={onSubmit}>
                                    {contacts.length === 1 ? 'Add' : 'Add all'}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BoundPhoneNumber;
