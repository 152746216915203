/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    UPDATE_USER_ACCOUNT_FAIL,
    UPDATE_USER_ACCOUNT_START,
    UPDATE_USER_ACCOUNT_SUCCESS,
    UPDATE_USER_ACCOUNT_CLEAR
} from '../../../constants/actionType/users';

export const updateUserProfileData = (data: any) => ({ type: UPDATE_USER_ACCOUNT_START, data });

export const updateUserProfileSuccess = (data: any) => ({
    type: UPDATE_USER_ACCOUNT_SUCCESS,
    payload: data,
});

export const updateUserProfileError = (error: any) => ({
    type: UPDATE_USER_ACCOUNT_FAIL,
    payload: error,
});

export const clearUpdateUserProfileStore = () => ({
    type: UPDATE_USER_ACCOUNT_CLEAR
})