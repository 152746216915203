import {
    REQUEST_INMATE_CALL_HISTORY_FAIL, 
    REQUEST_INMATE_CALL_HISTORY_START, 
    REQUEST_INMATE_CALL_HISTORY_SUCCESS,
    REQUEST_INMATE_CALL_HISTORY_CLEAR
    } from "../../../constants/actionType/linkPhoneNumberToInmate";
import {   InmateCallHistoryList } from "../../../interfaces/linkPhoneNumber";

  
export interface IInmateCallsHistoryData {
    data: InmateCallHistoryList,
    error: []
}

export const requestInmateCallHistoryData = (data: any) =>  ({type: REQUEST_INMATE_CALL_HISTORY_START, data});

export const requestInmateCallHistorySuccess = (data:  IInmateCallsHistoryData) => ({
    type: REQUEST_INMATE_CALL_HISTORY_SUCCESS, 
    payload: data
})

export const requestInmateCallHistoryFail = (error:  IInmateCallsHistoryData) => ({ type: REQUEST_INMATE_CALL_HISTORY_FAIL, payload: error})

export const clearRequestInmateCallHistoryStore = () => ({
    type: REQUEST_INMATE_CALL_HISTORY_CLEAR
   })
