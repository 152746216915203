/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    SENDER_TRANSACTION_REPORT_FAIL, 
    SENDER_TRANSACTION_REPORT_START, 
    SENDER_TRANSACTION_REPORT_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const senderTransactionReportData = (data: any) => ({type:  SENDER_TRANSACTION_REPORT_START, data});
 
 export const senderTransactionReportSuccess = (data:any) => ({
   type: SENDER_TRANSACTION_REPORT_SUCCESS,
   payload: data
 })
 
 export const senderTransactionReportError = (error:any) => ({
   type: SENDER_TRANSACTION_REPORT_FAIL,
   payload: error
 })