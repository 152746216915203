import {
    GET_INMATE_PHONE_NUMBER_FAIL, 
    GET_INMATE_PHONE_NUMBER_START, 
    GET_INMATE_PHONE_NUMBER_SUCCESS 
    } from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  ILinkPhoneNumberList } from "../../../interfaces/linkPhoneNumber";


    
interface ILinked {
    data: ILinkPhoneNumberList,
    error: []
}

export const getInmatePhoneNumberData = (data:  string) =>  ({type: GET_INMATE_PHONE_NUMBER_START, data});

export const getInmatePhoneNumberSuccess = (data: ILinked) => ({
    type: GET_INMATE_PHONE_NUMBER_SUCCESS, 
    payload: data
})

export const getInmatePhoneNumberFail = (error: ILinked) => ({ type: GET_INMATE_PHONE_NUMBER_FAIL, payload: error})
