/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     UPLOAD_BULK_AMOUNT_START 
} from "constants/actionType/bulkTransaction";
import { saveData} from 'helpers';
import { uploadBulkAmountError, uploadBulkAmountSuccess } from "redux/actions";


interface ResponseGenerator {
    data: any;
}

function* uploadBulkAmount(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'upload-bulk-amount', action.data);
        if (response?.data?.error) {
            yield put(uploadBulkAmountError(response.data || 'Unknown error'));
        } else {
            yield put(uploadBulkAmountSuccess(response));
        }
    } catch (e: any) {
        yield put(uploadBulkAmountError(e.message || 'Unknown error'));
    }
}

export default function* fetchUploadBulkAmount() {
    yield takeEvery(UPLOAD_BULK_AMOUNT_START, uploadBulkAmount);
}