/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CONFIRM_VISIT_CLEAR,
    CONFIRM_VISIT_FAIL, 
    CONFIRM_VISIT_START, 
    CONFIRM_VISIT_SUCCESS 
 } from "../../../constants/actionType/appointment";
 
  export const confirmAppointmentData = (data: any) => ({type: CONFIRM_VISIT_START, data});
  
  export const confirmAppointmentSuccess = (data:any) => ({
    type:CONFIRM_VISIT_SUCCESS,
    payload: data
  })
  
  export const confirmAppointmentError = (error:any) => ({
    type:CONFIRM_VISIT_FAIL,
    payload: error
  })

  export const clearConfirmVisitStore = () => ({
    type: CONFIRM_VISIT_CLEAR,
  });