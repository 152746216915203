/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    UPDATE_USER_ACCOUNT_SUCCESS, 
    UPDATE_USER_ACCOUNT_FAIL, 
    UPDATE_USER_ACCOUNT_START 
} from "../../../constants/actionType/users";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}


function* updateUserProfile(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'user/update-user-profile', data?.data);
        yield put({type: UPDATE_USER_ACCOUNT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: UPDATE_USER_ACCOUNT_FAIL, error: e.message });
    }
}

export default function* fetchUpdateUserProfile() {
    yield takeEvery(UPDATE_USER_ACCOUNT_START, updateUserProfile)
}