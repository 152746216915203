import { Button, DatePicker, Modal, Space, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { getAppointmentReportsData } from '../../../../redux/actions';
import moment from 'moment';
import { IUserSelectorType } from '../../../../interfaces';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined } from '@ant-design/icons';
import { ManageDates } from '../..';
import VisitorsComprehensiveReportDetails from './visitorComprehensiveReportDetails';
import { BiArrowBack } from 'react-icons/bi';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { FaRegFileExcel } from 'react-icons/fa';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IColumnTitles {
    text: string;
    value: string | number;
}

const columnTitles: IColumnTitles[] = [
    { text: 'Name', value: 'name' },
    { text: 'Contacts', value: 'contacts' },
    { text: 'Inmate visited', value: 'visitNumber' },
    { text: 'Frequency', value: 'frequency' },
];

const GenerateExportToXLSX = (data: any[], filename: string) => {
    const wsData = data;
    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

const VisitorsComprehensiveReport: React.FC<any> = () => {
    const dispatch = useDispatch();
    const getDate = new ManageDates();
    const [selectedDate, setSelectedDate]: any = useState();
    const [dates, setDates] = useState<RangeValue>(null);
    const [visitorReportDetails, setVisitorReportDetails] = useState<any>();
    const [changesPage, setChangePage] = useState<string>('default');
    const [open, setOpen] = useState(false);
    const [formatTableColumn, setFormatTableColumn] = useState<any[]>([]);

    const {
        getAppointmentReport: { data, loading },
    } = userSelector((user) => user);

    const reportData = data?.data !== undefined ? data?.data : [];

    const inmatesComprehensiveReportData: any = reportData?.details?.map((item: any) => {
        return {
            name: `${item?.visitor?.visitor_first_name} ${item?.visitor?.visitor_last_name}`,
            contacts: item?.visitor?.contacts,
            visitor: `${item?.visitor_first_name} ${item?.visitor_last_name}`,
            frequency: item?.frequency,
            visitNumber: item?.inmates_number,
            allInmates: item?.visit_frequency_number,
        };
    });

    const columnsPrint: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Contacts',
            dataIndex: 'contacts',
            key: 'contacts',
        },
        {
            title: 'Inmate visited',
            dataIndex: 'visitNumber',
            key: 'visitNumber',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },
    ];

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Contacts',
            dataIndex: 'contacts',
            key: 'contacts',
        },
        {
            title: 'Inmate visited',
            dataIndex: 'visitNumber',
            key: 'visitNumber',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },

        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            render: (data) => {
                return (
                    <Space size="middle">
                        <a
                            onClick={() => {
                                setChangePage('report-details');
                                setVisitorReportDetails(data?.allInmates);
                            }}
                        >
                            Details
                        </a>
                    </Space>
                );
            },
        },
    ];

    const dataToBeExported =
        reportData?.details &&
        reportData?.details.map((item: any) => ({
            name: `${item?.visitor?.visitor_first_name} ${item?.visitor?.visitor_last_name}`,
            contacts: item?.visitor?.contacts,
            visitor: `${item?.visitor_first_name} ${item?.visitor_last_name}`,
            frequency: item?.frequency,
            visitNumber: item?.inmates_number,
            allInmates: item?.visit_frequency_number,
        }));

    const exportXLSX = () => {
        const values = dataToBeExported
            ?.map((item: any) =>
                columnTitles?.map((option: any) => ({
                    [option.text]: item[option.value],
                })),
            )
            .filter(Boolean);

        const formattedColumns = values?.map((subArray: any) => {
            return subArray.reduce((acc: { [x: string]: any }, obj: { [x: string]: any }) => {
                for (const key in obj) {
                    acc[key] = obj[key];
                }
                return acc;
            }, {});
        });

        setFormatTableColumn(formattedColumns);
    };

    useEffect(() => {
        if (formatTableColumn.length !== 0) {
            GenerateExportToXLSX(
                formatTableColumn.map((item: any) => ({ ...item })),
                `visit-report-${Date.now()}`,
            );
        }
    }, [formatTableColumn]);

    const onSearch = () => {
        dispatch(
            getAppointmentReportsData({
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    const onSelectDate = (dateString: any) => {
        setDates(dateString);
        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    useEffect(() => {
        dispatch(
            getAppointmentReportsData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
                comprehensive_report: false,
                frequency_report: false,
                inmate_with_visit_0: false,
                visitors_comprehensive_report: true,
                lawyers_report: false,
            }),
        );
    }, [dispatch, selectedDate]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        {changesPage !== 'default' ? (
                            <Button className="back-btn" onClick={() => setChangePage('default')}>
                                <BiArrowBack size={18} />
                                <span style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px' }}>
                                    Frequency details
                                </span>
                            </Button>
                        ) : (
                            <div></div>
                        )}
                        <div className="top-right__items" style={{ width: '80%' }}>
                            <div style={{ marginLeft: '5px', marginTop: '-2px' }}>
                                <RangePicker
                                    style={{ padding: '6px' }}
                                    onChange={onSelectDate}
                                    format="YYYY-MM-DD"
                                    defaultValue={[
                                        dayjs(getDate.defaultStartedDate()),
                                        dayjs(getDate.defaultEndDate()),
                                    ]}
                                />
                            </div>
                            <Button
                                style={{ marginRight: '30px', marginLeft: '10px' }}
                                loading={loading}
                                onClick={onSearch}
                            >
                                Search
                            </Button>

                            {changesPage === 'default' ? (
                                <Button type="primary" onClick={() => setOpen(true)}>
                                    Generate report
                                </Button>
                            ) : null}
                        </div>
                    </div>

                    {changesPage === 'default' ? (
                        <>
                            {' '}
                            {reportData !== undefined ? (
                                <>
                                    {' '}
                                    <Table
                                        columns={columns}
                                        dataSource={inmatesComprehensiveReportData}
                                        pagination={data?.length >= 10 ? undefined : false}
                                    />
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <div style={{ margin: '20% auto', height: '800px' }}>
                                        <Spin
                                            style={{ background: ' #282936' }}
                                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    ) : null}

                    {changesPage === 'report-details' ? (
                        <VisitorsComprehensiveReportDetails visitorReportDetails={visitorReportDetails} />
                    ) : null}
                </div>
            </div>

            <Modal title="" open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={1000}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                        marginBottom: '55px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                        <Button size="large" ghost type="primary" style={{ marginRight: '30px' }} onClick={exportXLSX}>
                            <FaRegFileExcel color="green" style={{ marginRight: '10px' }} /> Export to XLSX
                        </Button>
                    </div>

                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>VISITORS COMPREHENSIVE REPORT BY FREQUENCY</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {reportData?.facility}
                            </span>
                            <span>
                                <b>Period: </b>
                                {reportData?.start_date} to {reportData?.start_date}
                            </span>
                        </div>
                        <Table columns={columnsPrint} dataSource={inmatesComprehensiveReportData} pagination={false} />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default VisitorsComprehensiveReport;
