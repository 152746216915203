/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_SHOP_SUMMARY_REPORT_SUCCESS,
    GET_SHOP_SUMMARY_REPORT_FAIL,
    GET_SHOP_SUMMARY_REPORT_START 
} from "../../../constants/actionType/shop";
import { fetchData} from '../../../helpers';


export interface ResponseGenerator{}

function* getShopSummaryReport() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/prison/summary-report');
        yield put({type:GET_SHOP_SUMMARY_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_SHOP_SUMMARY_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchShopSummaryReport() {
    yield takeEvery(GET_SHOP_SUMMARY_REPORT_START, getShopSummaryReport)
}