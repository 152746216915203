/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    UPLOAD_BULK_AMOUNT_CLEAR,
    UPLOAD_BULK_AMOUNT_FAIL, 
    UPLOAD_BULK_AMOUNT_START, 
    UPLOAD_BULK_AMOUNT_SUCCESS 
 } from "constants/actionType/bulkTransaction";


export const uploadBulkAmountData = (data: any) => ({
  type: UPLOAD_BULK_AMOUNT_START,
  data
});

export const uploadBulkAmountSuccess = (data: any) => ({
  type: UPLOAD_BULK_AMOUNT_SUCCESS,
  payload: data
});

export const uploadBulkAmountError = (error: string | any) => ( {
  type: UPLOAD_BULK_AMOUNT_FAIL,
  payload: error
});

export const clearUploadBulkAmountStore = () => ({
  type: UPLOAD_BULK_AMOUNT_CLEAR
});