/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REGISTER_FACILITY_SUCCESS, 
    REGISTER_FACILITY_FAIL, 
    REGISTER_FACILITY_START } from "../../../constants/actionType/facility";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}

function* registerFacility(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'user/register/facility', data?.data);
        toast(`${userData?.message}`)
        yield put({type: REGISTER_FACILITY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REGISTER_FACILITY_FAIL, error: e.message });
    }
}

export default function* getRegisterFacility() {
    yield takeEvery(REGISTER_FACILITY_START, registerFacility)
}