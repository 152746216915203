import React, { ReactElement } from 'react';

const EmptyTable = ({
    title,
    subTitle,
    Icon,
    button,
}: {
    title: string;
    subTitle: string;
    Icon: ReactElement;
    button: ReactElement | undefined;
}) => {
    return (
        <>
            <div className="empty-list">
                <>{Icon}</>
                <span className="empty-list__title">{title}</span>
                <span className="empty-list__subtitle">{`${subTitle}`}</span>
                <>{button}</>
            </div>
        </>
    );
};

export default EmptyTable;
