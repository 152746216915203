import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { Button, Select, Spin } from 'antd';
import { getPublicItemsData, getTransactionReportData } from '../../../redux/actions';
import { LoadingOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const AllTransactions = () => {
    const dispatch = useDispatch();
    const [prisonName, setPrisonName] = useState('');

    const { getTransactionsReport, getPublicItem } = userSelector((user) => user);
    const publicItems = getPublicItem?.data?.data;

    const columns: ColumnsType<any> = [
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact',
        },
        {
            title: 'RSC Case number',
            dataIndex: 'inmateCode',
            key: 'inmateCode',
        },
        {
            title: 'Received Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
    ];

    const data: any = getTransactionsReport?.data?.data?.all_transactions?.map((item: any) => {
        return {
            firstName: item?.sender_first_name,
            lastName: item?.sender_last_name,
            contact: item?.sender_reference,
            inmateCode: item?.inmate_code,
            totalAmount: `${item?.incoming_amount}`,
            coupon: item?.coupon,
        };
    });

    const onSearch = (value: string) => {
        return value;
    };
    const onHandleChangeCorrectionalService = (value: string) => setPrisonName(value);

    useEffect(() => {
        dispatch(getPublicItemsData());
    }, [dispatch]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <h2></h2>
                        <div className="details-btn-group">
                            <div className="right-btn-group">
                                <div className="top-right__items" style={{ width: '100%' }}>
                                    <Select
                                        showSearch
                                        placeholder="Select a correctional facility"
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, option) => {
                                            const val: any = option?.label ?? '';
                                            return val.toLowerCase().includes(input.toLowerCase());
                                        }}
                                        onChange={onHandleChangeCorrectionalService}
                                        options={publicItems?.facilities}
                                    />
                                    <Button
                                        style={{ marginLeft: '30px' }}
                                        loading={getTransactionsReport?.loading}
                                        onClick={() => {
                                            dispatch(
                                                getTransactionReportData({
                                                    prison_name: prisonName,
                                                }),
                                            );
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {getTransactionsReport?.data?.data?.details !== undefined ? (
                        <>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={
                                    getTransactionsReport?.data?.data?.details?.length >= 10 ? undefined : false
                                }
                            />
                        </>
                    ) : (
                        <div style={{ margin: '20% auto', height: '800px' }}>
                            <Spin
                                style={{ color: '#282936' }}
                                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AllTransactions;
