import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REQUEST_PENDING_VISITS_START, 
    REQUEST_PENDING_VISITS_SUCCESS, 
    REQUEST_PENDING_VISITS_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import { fetchData } from "../../../helpers";


export interface ResponseGenerator{
    message: string
}


function* pendingVisits() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'video-call/visit-in-pending');
        yield put({type: REQUEST_PENDING_VISITS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_PENDING_VISITS_FAIL, error: e.message });
    }
}

export default function* fetchPendingVisits() {
    yield takeEvery(REQUEST_PENDING_VISITS_START, pendingVisits)
}