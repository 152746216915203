import React, { useState } from 'react';
import AllTransactions from './allTransactions';
import SenderTransactionsHistory from './senderTransactionsHistory';
import DetailsOnMoney from './detailsOnMoney';

const SuperAdminTransactionsReport = () => {
    const [displayAllTransaction, setDisplayAllTransaction] = useState(true);
    const [senderTransactionHistory, setSenderTransactionHistory] = useState<boolean>();
    const [detailsOnMoney, setDetailsOnMoney] = useState<boolean>();
    return (
        <>
            <div className="tbl-items" style={{ padding: '0px' }}>
                <div className="tab-box">
                    <div
                        onClick={() => {
                            setDisplayAllTransaction(true);
                            setSenderTransactionHistory(false);
                            setDetailsOnMoney(false);
                        }}
                        className={displayAllTransaction ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Incoming amounts</span>
                    </div>
                    <div
                        onClick={() => {
                            setDisplayAllTransaction(false);
                            setSenderTransactionHistory(true);
                            setDetailsOnMoney(false);
                        }}
                        className={senderTransactionHistory ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Senders transactions</span>
                    </div>
                    <div
                        onClick={() => {
                            setDisplayAllTransaction(false);
                            setSenderTransactionHistory(false);
                            setDetailsOnMoney(true);
                        }}
                        className={detailsOnMoney ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Money Consumption details</span>
                    </div>
                </div>
            </div>
            {displayAllTransaction === true ? <AllTransactions /> : null}
            {senderTransactionHistory === true ? <SenderTransactionsHistory /> : null}
            {detailsOnMoney === true ? <DetailsOnMoney /> : null}
        </>
    );
};

export default SuperAdminTransactionsReport;
