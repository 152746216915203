/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    PURCHASE_ORDER_FAIL,
    PURCHASE_ORDER_START,
    PURCHASE_ORDER_SUCCESS,
    CLEAR_PURCHASE_ORDER
} from '../../../constants/actionType/orders';

export interface IPurchaseOrderData {
    data: any;
    error: any;
}

interface IPayload {
    ordersFileId: string
}

export const purchaseOrderData = (data: IPayload) => ({ type: PURCHASE_ORDER_START, data});

export const purchaseOrderSuccess = (data: any) => ({
    type: PURCHASE_ORDER_SUCCESS,
    payload: data,
});

export const purchaseOrderFail = (error:any) => ({
    type: PURCHASE_ORDER_FAIL,
    payload: error,
});

export const clearPurchaseOrderDetails= () => ({
    type: CLEAR_PURCHASE_ORDER
  });