import {
    UPLOAD_BULK_SAVING_DOCUMENT_FAIL, 
    UPLOAD_BULK_SAVING_DOCUMENT_START, 
    UPLOAD_BULK_SAVING_DOCUMENT_SUCCESS 
} from "../../../../constants/actionType/bulkSaving";

interface IDocument {
    data: string,
    error: []
}


interface IError {
    data: string,
}


 export const uploadBulkSavingFileData = (data: any) => ({type:  UPLOAD_BULK_SAVING_DOCUMENT_START, data});
 
 export const uploadBulkSavingFileSuccess = (data: IDocument) => ({
   type: UPLOAD_BULK_SAVING_DOCUMENT_SUCCESS,
   payload: data
 })
 
 export const uploadBulkSavingFileError = (error:IError) => ({
   type: UPLOAD_BULK_SAVING_DOCUMENT_FAIL,
   payload: error
 })