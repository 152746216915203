/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     REQUEST_BIND_FILE_AND_CELL_START 
} from "../../../constants/actionType/orders";
import { saveData} from '../../../helpers';
import { requestBindFileAndCellError, requestBindFileAndCellSuccess } from "../../actions";


interface ResponseGenerator {
    data: any;
}

function* requestBindFileAndCell(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/bind-file-and-cell', action.data);
        if (response?.data?.error) {
            yield put(requestBindFileAndCellError(response.data || 'Unknown error'));
        } else {
            yield put(requestBindFileAndCellSuccess(response));
        }
    } catch (e: any) {
        yield put(requestBindFileAndCellError(e.message || 'Unknown error'));
    }
}

export default function* fetchRequestBindFileAndCell() {
    yield takeEvery(REQUEST_BIND_FILE_AND_CELL_START, requestBindFileAndCell);
}