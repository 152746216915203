import React from 'react';
import NoResultFound from '../../common/noResultFound';

const Settings = () => {
    return (
        <>
            <NoResultFound />
        </>
    );
};

export default Settings;
