/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Table, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { useDispatch } from 'react-redux';
import { getFinanceReportData } from '../../../../redux/actions/finance/getFinanceReport';
import moment from 'moment';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ManageDates } from '../..';

interface DataType {
    key: string;
    telco: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    inmateCode: string;
    phoneNumber: string;
    amountSent: number;
    date: string;
    description: string;
    incomingAmount: string;
    outGoingAmount: string;
    totalAmount: string;
    senderFullName: string;
    currency: string;
    send_moneys: any;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const columns: ColumnsType<DataType> = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Telco',
        dataIndex: 'telco',
    },
    {
        title: 'Sender name',
        dataIndex: 'senderFullName',
    },
    {
        title: 'Sender phone number',
        dataIndex: 'phoneNumber',
    },
    {
        title: 'Inmate code',
        dataIndex: 'inmateCode',
    },

    {
        title: 'Amount',
        dataIndex: 'amountSent',
    },
];

const secondColumns: ColumnsType<DataType> = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Debit',
        dataIndex: 'incomingAmount',
    },
    {
        title: 'Credit',
        dataIndex: 'outGoingAmount',
    },
    {
        title: 'Balance',
        dataIndex: 'totalAmount',
    },
];

const FinanceReport = () => {
    const dispatch = useDispatch();
    const [showPage, setShowPage] = useState<boolean>(true);
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);
    const [selectedDate, setSelectedDate]: any = useState();
    const {
        getFinanceReport: {
            data: { allIncomingAmount, allOutgoingAmount },
        },
    } = userSelector((user) => user);

    const data: DataType[] = allIncomingAmount?.map((item: DataType) => {
        return {
            createdAt: moment(item?.send_moneys[0]?.created_at).format('DD-MMM-YYYY HH:mm:ss'),
            telco: item?.send_moneys[0]?.telco,
            senderFullName: `${item?.send_moneys[0]?.firstName} ${item?.send_moneys[0]?.lastName}`,
            phoneNumber: item?.send_moneys[0]?.phoneNumber,
            inmateCode: item?.inmateCode,
            amountSent: `${item?.send_moneys[0]?.amount_sent} ${item?.send_moneys[0]?.currency}`,
        };
    });
    const secondData = allOutgoingAmount?.map((item: DataType) => {
        return {
            createdAt: moment(item?.createdAt).format('DD-MMM-YYYY HH:mm:ss'),
            incomingAmount: `${item?.incomingAmount} ${item?.currency}`,
            outGoingAmount: `${item?.outGoingAmount} ${item?.currency}`,
            totalAmount: `${item?.totalAmount} ${item?.currency}`,
            description: `${item?.description}`,
        };
    });

    const onSelectDate = (dateString: any) => {
        setDates(dateString);

        setSelectedDate({
            selectedDate: {
                startDate: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
                endDate: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
            },
        });
    };

    useEffect(() => {
        if (selectedDate === undefined) {
            dispatch(
                getFinanceReportData({
                    selectedDate: {
                        startDate: getDate.defaultStartedDate(),
                        endDate: getDate.defaultEndDate(),
                    },
                }),
            );
        } else {
            dispatch(getFinanceReportData(selectedDate));
        }
    }, [dispatch, selectedDate]);

    useEffect(() => {}, [showPage]);

    return (
        <>
            <div className="details-btn-group">
                <div className="details-btn-group__left">
                    <Button className="back-btn">
                        <LeftOutlined style={{ marginRight: '10px' }} />
                        <Link to="/global-report">Go back</Link>
                    </Button>
                    <span>/ Finance details</span>
                </div>

                <div className="right-btn-group">
                    <Button
                        style={
                            showPage === true
                                ? {
                                      background: 'rgb(13, 110, 253)',
                                      fontWeight: 'bold',
                                      color: '#fff',
                                      border: '1px solid rgb(13, 110, 253)',
                                  }
                                : { fontWeight: 'bold' }
                        }
                        onClick={() => setShowPage(true)}
                    >
                        <span className="telco-amount">Incoming Amount</span>
                    </Button>
                    <Button
                        style={
                            showPage === false
                                ? {
                                      background: 'rgb(13, 110, 253)',
                                      fontWeight: 'bold',
                                      color: '#fff',
                                      border: '1px solid rgb(13, 110, 253)',
                                  }
                                : { fontWeight: 'bold' }
                        }
                        onClick={() => setShowPage(false)}
                    >
                        <span className="telco-amount">Report details</span>
                    </Button>
                    <div style={{ marginRight: '10px' }}>
                        <RangePicker
                            style={{ padding: '6px' }}
                            onChange={onSelectDate}
                            format="YYYY-MM-DD"
                            defaultValue={[dayjs(getDate.defaultStartedDate()), dayjs(getDate.defaultEndDate())]}
                        />
                    </div>
                </div>
            </div>
            <div style={{ height: '800px' }}>
                <div className="middle-items" style={{ marginTop: '30px', width: '100%' }}>
                    {showPage === false ? (
                        <div className="middle-items_left" style={{ width: '100%' }}>
                            <Table columns={secondColumns} dataSource={secondData} bordered />
                        </div>
                    ) : (
                        <div className="middle-items_left" style={{ width: '100%' }}>
                            <Table columns={columns} dataSource={data} bordered />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FinanceReport;
