import { call, put, takeEvery } from "redux-saga/effects";
import { 
    CHECK_VISIT_ID_START, 
    CHECK_VISIT_ID_SUCCESS, 
    CHECK_VISIT_ID_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import { saveData } from "../../../helpers";



export interface ResponseGenerator{
    message: string
}

interface IPayload {
    type: typeof CHECK_VISIT_ID_START,
    data: any
}

function* checkVisitId(data:  IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/check-visit-code', data?.data);
        yield put({type: CHECK_VISIT_ID_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: CHECK_VISIT_ID_FAIL, error: e.message });
    }
}

export default function* fetchCheckVisitId() {
    yield takeEvery(CHECK_VISIT_ID_START, checkVisitId)
}