import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { Button, Select, Spin } from 'antd';
import { getPublicItemsData, getTransactionReportData } from '../../../redux/actions';
import { LoadingOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const DetailsOnMoney = () => {
    const dispatch = useDispatch();
    const [prisonName, setPrisonName] = useState('');

    const { getTransactionsReport, getPublicItem } = userSelector((user) => user);
    const publicItems = getPublicItem?.data?.data;

    const columns: ColumnsType<any> = [
        {
            title: 'Sender',
            dataIndex: 'sender',
            key: 'sender',
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact',
        },
        {
            title: 'Receiver',
            dataIndex: 'receiver',
            key: 'receiver',
        },
        {
            title: 'RSC Case number',
            dataIndex: 'inmateCode',
            key: 'inmateCode',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },

        {
            title: 'Received Amount',
            dataIndex: 'incoming_amount',
            key: 'incoming_amount',
        },
        {
            title: 'Outgoing Amount',
            dataIndex: 'outgoing_amount',
            key: 'outgoing_amount',
        },
    ];

    const data: any = getTransactionsReport?.data?.data?.amount_consumption?.map((item: any) => {
        return {
            sender: `${item?.sender_first_name} ${item?.sender_last_name}`,
            receiver: `${item?.first_name} ${item?.last_name}`,
            description: item?.description,
            contact: item?.sender_reference,
            inmateCode: item?.inmate_code,
            incoming_amount: `${item?.incoming_amount}`,
            outgoing_amount: `${item?.out_going_amount}`,
        };
    });

    const onSearch = (value: string) => {
        return value;
    };
    const onHandleChangeCorrectionalService = (value: string) => setPrisonName(value);

    useEffect(() => {
        dispatch(getPublicItemsData());
    }, [dispatch]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <h2></h2>
                        <div className="details-btn-group">
                            <div className="right-btn-group">
                                <div className="top-right__items" style={{ width: '100%' }}>
                                    <Select
                                        showSearch
                                        placeholder="Select a correctional facility"
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, option) => {
                                            const val: any = option?.label ?? '';
                                            return val.toLowerCase().includes(input.toLowerCase());
                                        }}
                                        onChange={onHandleChangeCorrectionalService}
                                        options={publicItems?.facilities}
                                    />
                                    <Button
                                        style={{ marginLeft: '30px' }}
                                        loading={getTransactionsReport?.loading}
                                        onClick={() => {
                                            dispatch(
                                                getTransactionReportData({
                                                    prison_name: prisonName,
                                                }),
                                            );
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {getTransactionsReport?.data?.data?.details !== undefined ? (
                        <>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={
                                    getTransactionsReport?.data?.data?.details?.length >= 10 ? undefined : false
                                }
                            />
                        </>
                    ) : (
                        <div style={{ margin: '20% auto', height: '800px' }}>
                            <Spin
                                style={{ color: '#282936' }}
                                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DetailsOnMoney;
