import {
    SAVE_DEVISE_SIGNATURE_CLEAR,
    SAVE_DEVISE_SIGNATURE_FAIL,
    SAVE_DEVISE_SIGNATURE_START,
    SAVE_DEVISE_SIGNATURE_SUCCESS,
 } from '../../../constants/actionType/videoMeeting';
 
 interface IResponseData {
     data: any;
     error: any;
 }
 
 interface Payload {
    facility_id: string;
    browser_name: string;
    browser_signature: string;
    device_status: string;
 }
 
 export const saveDeviceSignatureData = (data: Payload) => ({ type:SAVE_DEVISE_SIGNATURE_START, data});
 
 export const saveDeviceSignatureSuccess = (data: IResponseData) => ({
     type:SAVE_DEVISE_SIGNATURE_SUCCESS,
     payload: data,
 });
 
 export const saveDeviceSignatureFail = (error: IResponseData) => ({
     type:SAVE_DEVISE_SIGNATURE_FAIL,
     payload: error,
 });
 
 
 export const clearSaveDeviceSignature = () =>  ({
     type:SAVE_DEVISE_SIGNATURE_CLEAR,
 });