import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import { LoadingOutlined } from '@ant-design/icons';

import { IAudioLinkInfo } from '../../../../interfaces/linkPhoneNumber';
import { IUserSelectorType } from '../../../../interfaces';
import { AiOutlineAudioMuted } from 'react-icons/ai';
import { inmateCallsData } from '../../../../redux/actions';
import moment from 'moment';
import { token } from '../../../../middleware/authMiddleware';
import AudioPlayer from './audioPlay';

const AUDIO_URL =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL_API_PROD : process.env.REACT_APP_URL_API_DEV;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const InmateCalls: React.FC = () => {
    const dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const {
        state: { inmateCode },
    } = useLocation();

    const {
        inmateCalls: { data },
    } = userSelector((user) => user);

    const audioRecorded: any = data?.data;

    useEffect(() => {
        dispatch(inmateCallsData(`prison/audio/inmate-vocal/${inmateCode}`));
    }, [dispatch]);

    // const audioRef = useRef(null);

    // useEffect(() => {
    //     playAudio();
    // }, []);

    // const playAudio = () => {
    //     const audioElement: any = audioRef.current;

    //     const headers: any = new Headers();
    //     headers.append('Authorization', `Bearer ${token}`);

    //     fetch(audioUrl, { headers })
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //             const audioBlob = new Blob([blob], { type: 'audio/mp3' });
    //             const audioObjectURL = URL.createObjectURL(audioBlob);

    //             audioElement.src = audioObjectURL;
    //             audioElement.play();
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching audio:', error);
    //         });
    // };

    // const handlePlayPause = () => {
    //     if (isPlaying) {
    //         audioRef.current.pause();
    //     } else {
    //         audioRef.current.play();
    //     }
    //     setIsPlaying(!isPlaying);
    // };

    // const playAudio = async () => {
    //     try {
    //         const cacheBuster = Date.now();
    //         const audioUrl =
    //             'http://localhost:5000/api/prison/audio/play/1234567/0783499352_pascalinekamigmail.com_1234567_2023-08-27_040412_rec.mp3';
    //         const response = await fetch(`${audioUrl}?t=${cacheBuster}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });

    //         if (response.ok) {
    //             const blob = await response.blob();
    //             audioRef.current.src = URL.createObjectURL(blob);
    //             audioRef.current.play();
    //             setIsPlaying(true);
    //         } else {
    //             console.error('Audio playback failed');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    return (
        <>
            <div className="user-account-tbl">
                <div className="tbl-items">
                    <div className="details-btn-group__left">
                        <Button onClick={() => navigate('/inmates-list')} className="back-btn">
                            <BsArrowLeft size={22} />
                            <span style={{ alignSelf: 'center', marginLeft: '5px' }}>Inmate list</span>
                        </Button>
                    </div>
                </div>

                {audioRecorded !== undefined ? (
                    <>
                        {audioRecorded?.vocal_data?.length === 0 ? (
                            <>
                                {
                                    <div className="empty-list">
                                        <span className="empty-list__title">No results found</span>
                                        <span className="empty-list__subtitle">{`Try reload again to find what you're looking for.`}</span>
                                    </div>
                                }
                            </>
                        ) : null}

                        {audioRecorded?.vocal_data === undefined ? (
                            <>
                                {' '}
                                <div style={{ margin: '20% auto', height: '800px' }}>
                                    <Spin
                                        style={{ color: ' #282936' }}
                                        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                    />
                                </div>
                            </>
                        ) : null}

                        {audioRecorded?.vocal_data?.length !== 0 ? (
                            <div className="table-form__column" style={{ width: '100%', background: 'transparent' }}>
                                {audioRecorded?.vocal_data?.map((audio: IAudioLinkInfo, index: number) => {
                                    return (
                                        <>
                                            <div className="list-title" key={index}>
                                                <div className="row">
                                                    <span className="list-title__title">{audio.audio_link}</span>

                                                    <div
                                                        className="icon-btn-right"
                                                        style={{ justifyContent: 'flex-end' }}
                                                    >
                                                        {/* <audio
                                                            src={`http://www.ehuza.rw/app/api/prison/audio/play/${inmateCode}/${audio.audio_link}`}
                                                            controls
                                                            style={{ background: '#ddd', backgroundColor: '#fff' }}
                                                        /> */}
                                                        <AudioPlayer
                                                            audioURL={`${AUDIO_URL}/prison/audio/play/${inmateCode}/${audio.audio_link}`}
                                                            token={token}
                                                        />
                                                        <AiOutlineAudioMuted
                                                            color="red"
                                                            className="icon"
                                                            size={30}
                                                            style={{ alignSelf: 'center' }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="list-title__subtitle">
                                                    <span>
                                                        Recorded on: {moment(audio.created_at).format('DD-MMM-YYYY')}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div style={{ margin: '20% auto', height: '800px' }}>
                        <Spin
                            style={{ color: ' #282936' }}
                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default InmateCalls;
