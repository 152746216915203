import { call, put, takeEvery } from "redux-saga/effects";
import { REGISTER_INMATES_SUCCESS, REGISTER_INMATES_FAIL, REGISTER_INMATES_START } from "../../../constants/actionType/inmates";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}

function* registerInmate(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/register/inmates', data?.data);
        yield put({type: REGISTER_INMATES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REGISTER_INMATES_FAIL, error: e.message });
    }
}

export default function* getRegisterInmates() {
    yield takeEvery(REGISTER_INMATES_START, registerInmate)
}