/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_BULK_SAVING_FILE_DETAILS_SUCCESS,
    REQUEST_BULK_SAVING_FILE_DETAILS_FAIL,
    REQUEST_BULK_SAVING_FILE_DETAILS_START 
} from "../../../../constants/actionType/bulkSaving";
import {  saveData } from '../../../../helpers';




export interface ResponseGenerator{
    message: string
}

function* retrieveBulkSavingFileDetails(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'retrieve-bulk-saving-data', data?.data);
        yield put({type:REQUEST_BULK_SAVING_FILE_DETAILS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_BULK_SAVING_FILE_DETAILS_FAIL, error: e.message });
    }
}

export default function* fetchRetrieveBulkSavingFileDetails() {
    yield takeEvery(REQUEST_BULK_SAVING_FILE_DETAILS_START, retrieveBulkSavingFileDetails)
}