import { 
    VISITOR_ALLOWED_ITEMS_START, 
    VISITOR_ALLOWED_ITEMS_SUCCESS, 
    VISITOR_ALLOWED_ITEMS_FAIL,
    VISITOR_ALLOWED_ITEMS_CLEAR
} from "../../../constants/actionType/appointment";


interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const fetchVisitorItems = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case VISITOR_ALLOWED_ITEMS_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case VISITOR_ALLOWED_ITEMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case VISITOR_ALLOWED_ITEMS_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case VISITOR_ALLOWED_ITEMS_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }

        default: {
            return state;
          }
    }
}

export default fetchVisitorItems;