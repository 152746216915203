/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    UPDATE_DELETE_INMATES_FAIL, 
    UPDATE_DELETE_INMATES_START, 
    UPDATE_DELETE_INMATES_SUCCESS,
    UPDATE_DELETE_INMATES_CLEAR
} from "../../../constants/actionType/inmates";

 export const updateDeleteInmateData = (data: any) => ({type:  UPDATE_DELETE_INMATES_START, data});
 
 export const updateDeleteInmatesSuccess = (data:any) => ({
   type: UPDATE_DELETE_INMATES_SUCCESS,
   payload: data
 })
 
 export const updateDeleteInmatesError = (error:any) => ({
   type: UPDATE_DELETE_INMATES_FAIL,
   payload: error
 })

 export const clearUpdateDeleteInmatesStore = () => ({
  type: UPDATE_DELETE_INMATES_CLEAR,

})

