import { Button, Flex, Modal, Spin, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../interfaces';
import { purchaseOrderData } from '../../redux/actions';

import { FilePdfOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IOderDetails {
    id: string;
    price: string;
    picture: string;
    article: string;
    quantity: string;
    currency: string;
}
interface DataType {
    id: string;
    name: string;
    username: number;
    address: string;
    user_profiles: string[];
    order_products: [IOderDetails];
    userRoleId: number;
    rows: any;
    firstName: string;
    lastName: string;
    status: string;
    amount: string;
    send_moneys: any;
    currency: string;
    totalQuantity: string;
    totalAmount: string;
    price: string;
    quantity: string;
    orderedProducts: [IOderDetails];
    inmateWallet: string;
    walletAfterPayment: string;
    article: string;
}

const PurchaseOrder: React.FC<any> = ({ setShowNextPage, orderFileId }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const {
        purchaseOrder: { data },
    } = userSelector((user) => user);

    const dataCol: DataType[] =
        data?.data === undefined
            ? []
            : data?.data?.details?.map((item: any) => {
                  return {
                      name: item?.item,
                      quantity: item?.quantity,
                      unit_price: item?.unit_price,
                      total_price: item?.total_price,
                  };
              });
    const columns: ColumnsType<DataType> = [
        {
            title: 'Items',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
    ];

    useEffect(() => {
        dispatch(
            purchaseOrderData({
                ordersFileId: orderFileId,
            }),
        );
    }, [dispatch, data]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <Flex align="center" style={{ padding: '10px' }}>
                <Button danger type="primary" icon={<LeftOutlined />} ghost onClick={() => setShowNextPage('details')}>
                    Back to order details
                </Button>
            </Flex>
            <Flex align="center" justify="space-between" style={{ padding: '10px', background: '#fff' }}>
                <div className="tbl-items__col">
                    <span style={{ color: '#777677' }}>Order total amount</span>
                    <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                        {!data?.data ? 0 : `${data?.data?.total_order_amount} ${data?.data?.currency}`}
                    </span>
                </div>
                <Button type="primary">Generate report</Button>
            </Flex>

            {data?.data === undefined ? (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            ) : (
                <Table
                    bordered
                    size="small"
                    columns={columns}
                    dataSource={dataCol}
                    pagination={data?.data?.length >= 30 ? undefined : false}
                />
            )}

            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>
                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>PURCHASE ORDER</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {data?.data?.facility}
                            </span>
                            <span>
                                <b>Deliver note number: </b>
                                {data?.data?.delivery_file}
                            </span>
                            <span>
                                <b>Delivered on: </b>
                                {moment(data?.data?.date).format('DD-MMM-YYYY')}
                            </span>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataCol}
                            pagination={data?.data?.length >= 30 ? undefined : false}
                        />
                        <div className="print-users">
                            <span>
                                <b>Prepared by: </b>
                                {data?.data?.delivered_by}
                            </span>
                        </div>

                        {data?.data?.reviewers?.map((item: any) => {
                            return (
                                <>
                                    {' '}
                                    <div className="print-users">
                                        {item?.role_id === 5 ? (
                                            <span>
                                                <b>Reviewed by: </b>
                                                {item?.first_name} {item?.last_name}
                                            </span>
                                        ) : null}
                                        {item?.role_id === 3 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Verified for cash availability by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                        {item?.role_id === 2 || item?.role_id == 7 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Approved by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default PurchaseOrder;
