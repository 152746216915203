import {
    GET_SUMMARY_FAIL, 
    GET_SUMMARY_START, 
    GET_SUMMARY_SUCCESS 
    } from "../../../constants/actionType/summary";
    
    
export interface ISummaryData {
    data: any,
    error: any
}

export const getSummary = (data: any) =>  ({type: GET_SUMMARY_START, data});

export const getSummarySuccess = (data: ISummaryData) => ({
    type: GET_SUMMARY_SUCCESS, 
    payload: data
})

export const getSummaryFail = (error: ISummaryData) => ({ type: GET_SUMMARY_FAIL, payload: error})