// GET ORDERS
export const GET_ORDERS_START = "GET_ORDERS_START";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL =  "GET_ORDERS_FAIL";

// GET ORDERS DONE
export const GET_ORDERS_DONE_START = "GET_ORDERS_DONE_START";
export const GET_ORDERS_DONE_SUCCESS = "GET_ORDERS_DONE_SUCCESS";
export const GET_ORDERS_DONE_FAIL =  "GET_ORDERS_DONE_FAIL";

// CONFIRM ORDER
export const CONFIRM_ORDER_START = "CONFIRM_ORDER_START";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_FAIL =  "CONFIRM_ORDER_FAIL";

// MAKE AN ORDER
export const MAKE_ORDER_START = "MAKE_ORDER_START";
export const MAKE_ORDER_SUCCESS = "MAKE_ORDER_SUCCESS";
export const MAKE_ORDER_FAIL =  "MAKE_ORDER_FAIL";
export const MAKE_ORDER_CLEAR =  "MAKE_ORDER_CLEAR";

// CREATE AN ORDER  FILE
export const CREATE_ORDER_FILE_START = "CREATE_ORDER_FILE_START";
export const CREATE_ORDER_FILE_SUCCESS = "CREATE_ORDER_FILE_SUCCESS";
export const CREATE_ORDER_FILE_FAIL =  "CREATE_ORDER_FILE_FAIL";
export const CREATE_ORDER_FILE_CLEAR =  "CREATE_ORDER_FILE_CLEAR";

export const REQUEST_ORDER_FILE_START = "REQUEST_ORDER_FILE_START";
export const REQUEST_ORDER_FILE_SUCCESS = "REQUEST_ORDER_FILE_SUCCESS";
export const REQUEST_ORDER_FILE_FAIL =  "REQUEST_ORDER_FILE_FAIL";

export const REQUEST_ORDER_FILE_DETAILS_START = "REQUEST_ORDER_FILE_DETAILS_START";
export const REQUEST_ORDER_FILE_DETAILS_SUCCESS = "REQUEST_ORDER_FILE_DETAILS_SUCCESS";
export const REQUEST_ORDER_FILE_DETAILS_FAIL =  "REQUEST_ORDER_FILE_DETAILS_FAIL";

export const REQUEST_APPROVE_ORDER_FILE_START = "REQUEST_APPROVE_ORDER_FILE_START";
export const REQUEST_APPROVE_ORDER_FILE_SUCCESS = "REQUEST_APPROVE_ORDER_FILE_SUCCESS";
export const REQUEST_APPROVE_ORDER_FILE_FAIL =  "REQUEST_APPROVE_ORDER_FILE_FAIL";


export const DELIVERY_NOTE_START = "DELIVERY_NOTE_START";
export const DELIVERY_NOTE_SUCCESS = "DELIVERY_NOTE_SUCCESS";
export const DELIVERY_NOTE_FAIL =  "DELIVERY_NOTE_FAIL";

export const PURCHASE_ORDER_START = "PURCHASE_ORDER_START";
export const PURCHASE_ORDER_SUCCESS = "PURCHASE_ORDER_SUCCESS";
export const PURCHASE_ORDER_FAIL =  "PURCHASE_ORDER_FAIL";
export const CLEAR_PURCHASE_ORDER =  "CLEAR_PURCHASE_ORDER";

// GET_COMMENT

export const GET_COMMENT_START = "GET_COMMENT_START";
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const GET_COMMENT_FAIL =  "GET_COMMENT_FAIL";

// CALCULATE ORDER AMOUNT
export const CALCULATE_ORDER_AMOUNT_START = "CALCULATE_ORDER_AMOUNT_START";
export const CALCULATE_ORDER_AMOUNT_SUCCESS = "CALCULATE_ORDER_AMOUNT_SUCCESS";
export const CALCULATE_ORDER_AMOUNT_FAIL =  "CALCULATE_ORDER_AMOUNT_FAIL";

// EXECUTE ORDER AMOUNT
export const EXECUTE_ORDER_AMOUNT_START = "EXECUTE_ORDER_AMOUNT_START";
export const EXECUTE_ORDER_AMOUNT_SUCCESS = "EXECUTE_ORDER_AMOUNT_SUCCESS";
export const EXECUTE_ORDER_AMOUNT_FAIL =  "EXECUTE_ORDER_AMOUNT_FAIL";

// DELIVERY QUANTITY
export const DELIVERY_ITEMS_START = "DELIVERY_ITEMS_START";
export const DELIVERY_ITEMS_SUCCESS = "DELIVERY_ITEMS_SUCCESS";
export const DELIVERY_ITEMS_FAIL =  "DELIVERY_ITEMS_FAIL";
export const CLEAR_DELIVERY_ITEMS =  "CLEAR_DELIVERY_ITEMS";

export const GET_ORDERS_DETAILS_START = "GET_ORDERS_DETAILS_START";
export const GET_ORDERS_DETAILS_SUCCESS = "GET_ORDERS_DETAILS_SUCCESS";
export const GET_ORDERS_DETAILS_FAIL =  "GET_ORDERS_DETAILS_FAIL";

export const CREATE_DELIVERY_NOTE_FILE_START = "CREATE_DELIVERY_NOTE_FILE_START";
export const CREATE_DELIVERY_NOTE_FILE_SUCCESS = "CREATE_DELIVERY_NOTE_FILE_SUCCESS";
export const CREATE_DELIVERY_NOTE_FILE_FAIL =  "CREATE_DELIVERY_NOTE_FILE_FAIL";

export const GET_DELIVERY_NOTE_ITEMS_START = "GET_DELIVERY_NOTE_ITEMS_START";
export const GET_DELIVERY_NOTE_ITEMS_SUCCESS = "GET_DELIVERY_NOTE_ITEMS_SUCCESS";
export const GET_DELIVERY_NOTE_ITEMS_FAIL =  "GET_DELIVERY_NOTE_ITEMS_FAIL";

export const CLOSE_DELIVERY_NOTE_FILE_START = "CLOSE_DELIVERY_NOTE_FILE_START";
export const CLOSE_DELIVERY_NOTE_FILE_SUCCESS = "CLOSE_DELIVERY_NOTE_FILE_SUCCESS";
export const CLOSE_DELIVERY_NOTE_FILE_FAIL =  "CLOSE_DELIVERY_NOTE_FILE_FAIL";
export const CLEAR_CLOSE_DELIVERY_NOTE_FILE =  "CLEAR_CLOSE_DELIVERY_NOTE_FILE";

export const UPLOAD_DOCUMENT_START = "UPLOAD_DOCUMENT_START";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAIL =  "UPLOAD_DOCUMENT_FAIL";

export const PREVIEW_UPLOADED_FILE_START =  "PREVIEW_UPLOADED_FILE_START";
export const PREVIEW_UPLOADED_FILE_SUCCESS =  "PREVIEW_UPLOADED_FILE_SUCCESS";
export const PREVIEW_UPLOADED_FILE_FAIL =   "PREVIEW_UPLOADED_FILE_FAIL";
export const PREVIEW_UPLOADED_FILE_CLEAR =   "PREVIEW_UPLOADED_FILE_CLEAR";


export const FETCH_MANAGED_ZONES_START =  "FETCH_MANAGED_ZONES_START";
export const FETCH_MANAGED_ZONES_SUCCESS =  "FETCH_MANAGED_ZONES_SUCCESS";
export const FETCH_MANAGED_ZONES_FAIL =   "FETCH_MANAGED_ZONES_FAIL";
export const FETCH_MANAGED_ZONES_CLEAR =   "FETCH_MANAGED_ZONES_CLEAR";

export const REQUEST_BIND_FILE_AND_CELL_START =  "REQUEST_BIND_FILE_AND_CELL_START";
export const REQUEST_BIND_FILE_AND_CELL_SUCCESS =  "REQUEST_BIND_FILE_AND_CELL_SUCCESS";
export const REQUEST_BIND_FILE_AND_CELL_FAIL =   "REQUEST_BIND_FILE_AND_CELL_FAIL";
export const REQUEST_BIND_FILE_AND_CELL_CLEAR =   "REQUEST_BIND_FILE_AND_CELL_CLEAR";

export const RETRIEVE_PURCHASE_ORDER_LIST_START =  "RETRIEVE_PURCHASE_ORDER_LIST_START";
export const RETRIEVE_PURCHASE_ORDER_LIST_SUCCESS =  "RETRIEVE_PURCHASE_ORDER_LIST_SUCCESS";
export const RETRIEVE_PURCHASE_ORDER_LIST_FAIL =   "RETRIEVE_PURCHASE_ORDER_LIST_FAIL";
export const RETRIEVE_PURCHASE_ORDER_LIST_CLEAR =   "RETRIEVE_PURCHASE_ORDER_LIST_CLEAR";

export const MANAGE_ZONES_START =  "MANAGE_ZONES_START";
export const MANAGE_ZONES_SUCCESS =  "MANAGE_ZONES_SUCCESS";
export const MANAGE_ZONES_FAIL =   "MANAGE_ZONES_FAIL";
export const MANAGE_ZONES_CLEAR =   "MANAGE_ZONES_CLEAR";

export const EDIT_ORDER_START =  "EDIT_ORDER_START";
export const EDIT_ORDER_SUCCESS =  "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAIL =   "EDIT_ORDER_FAIL";
export const EDIT_ORDER_CLEAR =   "EDIT_ORDER_CLEAR";