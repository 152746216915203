/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     FETCH_MANAGED_ZONES_SUCCESS,
     FETCH_MANAGED_ZONES_FAIL,
     FETCH_MANAGED_ZONES_START 
} from "../../../constants/actionType/orders";
import { saveData} from '../../../helpers';


export interface ResponseGenerator{}

function* getCells(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'facility-settings/fetch-managed-zones',  data?.data);
        yield put({type: FETCH_MANAGED_ZONES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: FETCH_MANAGED_ZONES_FAIL, error: e.message });
    }
}

export default function* fetchGetCells() {
    yield takeEvery( FETCH_MANAGED_ZONES_START, getCells)
}
