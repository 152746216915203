interface IVideoMeeting {
    checkVisitId: string
    connection: string
}

export const videoMeetingRoutes:IVideoMeeting  = {
    checkVisitId: "checkVisitId",
    connection: "connection"
}

export const VIDEO_MEETING_URL: string = `${process.env.REACT_APP_URL_VIDEO_BACKEND}` || "";