/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    VISITOR_ALLOWED_ITEMS_CLEAR,
    VISITOR_ALLOWED_ITEMS_FAIL, 
    VISITOR_ALLOWED_ITEMS_START, 
    VISITOR_ALLOWED_ITEMS_SUCCESS 
 } from "../../../constants/actionType/appointment";
 
  export const visitorItemsData = (data:any) => ({type: VISITOR_ALLOWED_ITEMS_START, data});
  
  export const visitorItemsSuccess = (data:any) => ({
    type:VISITOR_ALLOWED_ITEMS_SUCCESS,
    payload: data
  })
  
  export const visitorItemsError = (error:any) => ({
    type:VISITOR_ALLOWED_ITEMS_FAIL,
    payload: error
  })

  export const clearVisitorItemsStore = () => ({
    type: VISITOR_ALLOWED_ITEMS_CLEAR,
  });