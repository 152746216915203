import { 
    GET_INMATE_PHONE_NUMBER_START, 
    GET_INMATE_PHONE_NUMBER_SUCCESS, 
    GET_INMATE_PHONE_NUMBER_FAIL 
} from '../../../constants/actionType/linkPhoneNumberToInmate';


interface InitialState {
    data: [];
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const getInmatePhoneNumber = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case GET_INMATE_PHONE_NUMBER_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case GET_INMATE_PHONE_NUMBER_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case GET_INMATE_PHONE_NUMBER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        default: {
            return state;
          }
    }
}

export default getInmatePhoneNumber;