/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {REQUEST_APPOINTMENT_SUCCESS,REQUEST_APPOINTMENT_FAIL,REQUEST_APPOINTMENT_START, OUTGOING_APPOINTMENT } from "../../../constants/actionType/appointment";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: string
}

function* requestAppointment(data: any) {
    try {
        const userData: ResponseGenerator = data?.data?.appointmentCategory === OUTGOING_APPOINTMENT 
        ? yield call(saveData, 'v2/prison/outgoing-appointment', data?.data) 
        : yield call(saveData, 'request-appointment', data?.data);
        yield put({type:REQUEST_APPOINTMENT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_APPOINTMENT_FAIL, error: e.message });
    }
}

export default function* getRequestAppointment() {
    yield takeEvery(REQUEST_APPOINTMENT_START, requestAppointment)
}


