
// REQUEST INMATES REGISTRATION
export const REGISTER_INMATES_START = "REGISTER_INMATES_START";
export const REGISTER_INMATES_SUCCESS = "REGISTER_INMATES_SUCCESS";
export const REGISTER_INMATES_FAIL =  "REGISTER_INMATES_FAIL";
export const REGISTER_INMATES_CLEAR =  "REGISTER_INMATES_CLEAR";

// RETRIEVE INMATES
export const RETRIEVE_INMATES_START = "RETRIEVE_INMATES_START";
export const RETRIEVE_INMATES_SUCCESS = "RETRIEVE_INMATES_SUCCESS";
export const RETRIEVE_INMATES_FAIL =  "RETRIEVE_INMATES_FAIL";

// GET INMATES
export const GET_INMATES_START = "GET_INMATES_START";
export const GET_INMATES_SUCCESS = "GET_INMATES_SUCCESS";
export const GET_INMATES_FAIL =  "GET_INMATES_FAIL";

// UPDATE INMATES
export const UPDATE_DELETE_INMATES_START = "UPDATE_DELETE_INMATES_START";
export const UPDATE_DELETE_INMATES_SUCCESS = "UPDATE_DELETE_INMATES_SUCCESS";
export const UPDATE_DELETE_INMATES_FAIL =  "UPDATE_DELETE_INMATES_FAIL";
export const UPDATE_DELETE_INMATES_CLEAR = "UPDATE_DELETE_INMATES_CLEAR";

// GET INMATES WALLETS INMATES
export const GET_INMATES_WALLETS_START = "GET_INMATES_WALLETS_START";
export const GET_INMATES_WALLETS_SUCCESS = "GET_INMATES_WALLETS_SUCCESS";
export const GET_INMATES_WALLETS_FAIL =  "GET_INMATES_WALLETS_FAIL";

// GET INMATES WALLETS DETAILS
export const GET_INMATES_WALLET_DETAILS_START = "GET_INMATES_WALLET_DETAILS_START";
export const GET_INMATES_WALLET_DETAILS_SUCCESS = "GET_INMATES_WALLET_DETAILS_SUCCESS";
export const GET_INMATES_WALLET_DETAILS_FAIL =  "GET_INMATES_WALLET_DETAILS_FAIL";


// PREPARE COUPON
export const PREPARE_COUPON_START = "PREPARE_COUPON_START";
export const PREPARE_COUPON_SUCCESS = "PREPARE_COUPON_SUCCESS";
export const PREPARE_COUPON_FAIL =  "PREPARE_COUPON_FAIL";

export const GET_SUPER_ADMIN_INMATE_START =  "GET_SUPER_ADMIN_INMATE_START";
export const GET_SUPER_ADMIN_INMATE_SUCCESS =  "GET_SUPER_ADMIN_INMATE_SUCCESS";
export const GET_SUPER_ADMIN_INMATE_FAIL =   "GET_SUPER_ADMIN_INMATE_FAIL";

export const GET_SUPER_ADMIN_INMATE_WALLET_START =  "GET_SUPER_ADMIN_INMATE_WALLET_START";
export const GET_SUPER_ADMIN_INMATE_WALLET_SUCCESS =  "GET_SUPER_ADMIN_INMATE_WALLET_SUCCESS";
export const GET_SUPER_ADMIN_INMATE_WALLET_FAIL =   "GET_SUPER_ADMIN_INMATE_WALLET_FAIL";

export const GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_START =  "GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_START";
export const GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_SUCCESS =  "GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_SUCCESS";
export const GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_FAIL =   "GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_FAIL";

export const INDIVIDUAL_TOKEN_START =  "INDIVIDUAL_TOKEN_START";
export const INDIVIDUAL_TOKEN_SUCCESS =  "INDIVIDUAL_TOKEN_SUCCESS";
export const INDIVIDUAL_TOKEN_FAIL =   "INDIVIDUAL_TOKEN_FAIL";


export const GET_CALLING_SETTINGS_START =  "GET_CALLING_SETTINGS_START";
export const GET_CALLING_SETTINGS_SUCCESS =  "GET_CALLING_SETTINGS_SUCCESS";
export const GET_CALLING_SETTINGS_FAIL =   "GET_CALLING_SETTINGS_FAIL";


export const REQUEST_CALLING_SETTINGS_START =  "REQUEST_CALLING_SETTINGS_START";
export const REQUEST_CALLING_SETTINGS_SUCCESS =  "REQUEST_CALLING_SETTINGS_SUCCESS";
export const REQUEST_CALLING_SETTINGS_CLEAR =  "REQUEST_CALLING_SETTINGS_CLEAR";
export const REQUEST_CALLING_SETTINGS_FAIL =   "REQUEST_CALLING_SETTINGS_FAIL";


export const TRANSFER_INMATE_START =  "TRANSFER_INMATE_START";
export const TRANSFER_INMATE_SUCCESS =  "TRANSFER_INMATE_SUCCESS";
export const TRANSFER_INMATE_CLEAR =  "TRANSFER_INMATE_CLEAR";
export const TRANSFER_INMATE_FAIL =   "TRANSFER_INMATE_FAIL";