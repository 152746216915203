import { 
    GET_CALL_OFFICER_USER_FAIL, 
    GET_CALL_OFFICER_USER_START, 
    GET_CALL_OFFICER_USER_SUCCESS 
} from '../../../constants/actionType/users';


const INITIAL_STATE = {
    data: [],
    error: {},
    loading: false,
}

const getCallOfficer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case GET_CALL_OFFICER_USER_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case GET_CALL_OFFICER_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case GET_CALL_OFFICER_USER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        default: {
            return state;
          }
    }
}

export default getCallOfficer;