/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CLEAR_DELETE_PRODUCT,
    DELETE_PRODUCT_FAIL, 
    DELETE_PRODUCT_START, 
    DELETE_PRODUCT_SUCCESS 
} from "../../../constants/actionType/shop"

 export const deleteProductData = (data: any) => ({type:  DELETE_PRODUCT_START, data});
 
 export const deleteProductSuccess = (data:any) => ({
   type: DELETE_PRODUCT_SUCCESS,
   payload: data
 })
 
 export const deleteProductError = (error:any) => ({
   type: DELETE_PRODUCT_FAIL,
   payload: error
 })


export const clearDeleteProduct = () => ({
  type: CLEAR_DELETE_PRODUCT,
})