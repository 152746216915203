/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    INCOMING_APPOINTMENT_FAIL, 
    INCOMING_APPOINTMENT_START, 
    INCOMING_APPOINTMENT_SUCCESS 
} from "../../../constants/actionType/appointment";

 export const incomingAppointments = (data:any) => ({type:  INCOMING_APPOINTMENT_START, data});
 
 export const incomingAppointmentSuccess = (data:any) => ({
   type: INCOMING_APPOINTMENT_SUCCESS,
   payload: data
 })
 
 export const incomingAppointmentError = (error:any) => ({
   type: INCOMING_APPOINTMENT_FAIL,
   payload: error
 })