import React, { useEffect, useState } from 'react';
import { Button, Empty, Modal, Spin } from 'antd';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { retrieveBulkTransactionFileData } from '../../../../redux/actions';
import { LoadingOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { AiFillFileImage } from 'react-icons/ai';
import NoResultFound from '../../../common/noResultFound';
import { IFileData } from '../../../../interfaces/bulkSaving';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import '../sendMoney.css';
import EmptyTable from '../../../common/emptyTable';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IDocument {
    name: string;
    file: string | number;
}

const PendingApprovalSavingFile = () => {
    const readDocument = process.env.REACT_APP_API_SAVINGS_DOCUMENT_URL;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openR, setOpenR] = useState(false);

    const [document, setDocument] = useState<IDocument>({
        name: '',
        file: '',
    });

    const { getUserData } = userSelector((state) => state);

    const fileData = location.state;
    const prisonId = getUserData?.data?.data[0]?.prison_id;

    const dataCol: any = fileData?.map((item: IFileData) => {
        return {
            id: item?.id,
            file_name: item?.file_name,
            document: item?.document,
            actions_status: item?.actions_status,
            current_user: item?.current_user,
            status: item?.status,
            current_user_role: item?.current_user_role,
            created_at: moment(item.created_at).format('DD-MMM-YYYY'),
        };
    });

    useEffect(() => {
        dispatch(retrieveBulkTransactionFileData());
    }, [dispatch]);

    const columns: ColumnsType<IFileData> = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Created on',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, data) => {
                return (
                    <div className="tbl-group-btn">
                        {[2, 3, 5, 7].includes(getUserData?.data?.data[0]?.role_id) ? (
                            <a
                                onClick={() => {
                                    showModalR();
                                    setDocument({
                                        name: data?.document,
                                        file: data?.id,
                                    });
                                }}
                            >
                                <AiFillFileImage size={20} color="green" />
                            </a>
                        ) : null}

                        <a
                            onClick={() => {
                                navigate('/bulk-saving-details', {
                                    state: {
                                        fileId: data?.id,
                                        status: data?.actions_status,
                                        fileCurrentUser: data?.current_user_role,
                                    },
                                });
                            }}
                        >
                            Details
                        </a>
                    </div>
                );
            },
        },
    ];

    const showModalR = () => {
        setOpenR(true);
    };

    const handleCancelR = () => {
        setOpenR(false);
    };

    const url = `${readDocument}${prisonId}/${document.file}/${document.name}`;

    return (
        <>
            <div className="user-account-tbl">
                <div className="details-btn-group">
                    <div className="tbl-items">
                        <h2>10% income files</h2>
                        <div style={{ marginRight: '10px' }}></div>
                    </div>
                    <div>
                        {fileData?.length === 0 || fileData === undefined ? (
                            <EmptyTable
                                title={'No file generate yet'}
                                subTitle={`Generate 10% income files by`}
                                Icon={<Empty />}
                                button={undefined}
                            />
                        ) : (
                            <>
                                {fileData !== undefined ? (
                                    <>
                                        {fileData?.length !== 0 ? (
                                            <Table
                                                columns={columns}
                                                dataSource={dataCol}
                                                pagination={dataCol.length > 14 ? undefined : false}
                                            />
                                        ) : (
                                            <NoResultFound />
                                        )}
                                    </>
                                ) : (
                                    <div style={{ margin: '20% auto', height: '800px' }}>
                                        <Spin
                                            style={{ color: ' #282936' }}
                                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Modal width={800} title="Attached file" open={openR} onCancel={handleCancelR} closable={false} footer={[]}>
                <div className="custom-modal-btn-group">
                    {document && <img src={url} alt={document.name} style={{ maxWidth: '100%' }} />}
                </div>
                <div className="custom-modal-footer" style={{ justifyContent: 'space-between' }}>
                    <div></div>
                    <Button
                        key="submit"
                        onClick={() => handleCancelR()}
                        style={{ background: '#282936', color: '#ffff' }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default PendingApprovalSavingFile;
