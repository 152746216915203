/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    UPDATE_FACILITY_SUCCESS, 
    UPDATE_FACILITY_FAIL, 
    UPDATE_FACILITY_START } from "../../../constants/actionType/facility";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}

function* updateFacility(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'user/update/facility', data?.data);
        toast(`${userData?.message}`)
        yield put({type: UPDATE_FACILITY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: UPDATE_FACILITY_FAIL, error: e.message });
    }
}

export default function* getUpdateFacility() {
    yield takeEvery(UPDATE_FACILITY_START, updateFacility)
}