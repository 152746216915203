import {
    REQUEST_START_MEETING_CLEAR,
    REQUEST_START_MEETING_FAIL,
    REQUEST_START_MEETING_START,
    REQUEST_START_MEETING_SUCCESS,
} from '../../../constants/actionType/videoMeeting';
import { IResponseData } from './checkVisitId';


interface IPayload {
    browser_signature: string;
    today_date: string;
}

export const startMeetingData = (data:IPayload) => ({ type: REQUEST_START_MEETING_START, data});

export const startMeetingSuccess = (data: IResponseData) => ({
    type: REQUEST_START_MEETING_SUCCESS,
    payload: data,
});

export const startMeetingFail = (error: IResponseData) => ({
    type: REQUEST_START_MEETING_FAIL,
    payload: error,
});


export const clearStartMeeting = () =>  ({
    type: REQUEST_START_MEETING_CLEAR,
});