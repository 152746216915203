import { 
    DELIVERY_ITEMS_FAIL, 
    DELIVERY_ITEMS_START, 
    DELIVERY_ITEMS_SUCCESS ,
    CLEAR_DELIVERY_ITEMS
} from '../../../constants/actionType/orders';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const deliveryItems = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case DELIVERY_ITEMS_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case DELIVERY_ITEMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case DELIVERY_ITEMS_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case CLEAR_DELIVERY_ITEMS: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }

        default: {
            return state;
          }
    }
}

export default deliveryItems;