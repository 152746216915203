/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    CONFIRM_ORDER_SUCCESS,
    CONFIRM_ORDER_FAIL,
    CONFIRM_ORDER_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { toast } from "react-toastify";


export interface ResponseGenerator{
    message: any
}

function* getConfirmOder(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'v2/prison/order/confirm-order', data?.data);
        toast(`${userData?.message}`)
        yield put({type:CONFIRM_ORDER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:CONFIRM_ORDER_FAIL, error: e.message });
    }
}

export default function* fetchGetConfirmOder() {
    yield takeEvery(CONFIRM_ORDER_START, getConfirmOder)
}