/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_ORDERS_DONE_SUCCESS,
    GET_ORDERS_DONE_FAIL,
    GET_ORDERS_DONE_START 
} from "../../../constants/actionType/orders";
import { fetchData} from '../../../helpers';


export interface ResponseGenerator{}

function* getOrdersDone() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/prison/order/retrieve-orders-done');
        yield put({type:GET_ORDERS_DONE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_ORDERS_DONE_FAIL, error: e.message });
    }
}

export default function* fetchGetOrdersDone() {
    yield takeEvery(GET_ORDERS_DONE_START, getOrdersDone)
}