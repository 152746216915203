/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    SENDER_TRANSACTION_REPORT_DETAILS_FAIL, 
    SENDER_TRANSACTION_REPORT_DETAILS_START, 
    SENDER_TRANSACTION_REPORT_DETAILS_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const senderTransactionReportDetailsData = (data: any) => ({type:  SENDER_TRANSACTION_REPORT_DETAILS_START, data});
 
 export const senderTransactionReportDetailsSuccess = (data:any) => ({
   type: SENDER_TRANSACTION_REPORT_DETAILS_SUCCESS,
   payload: data
 })
 
 export const senderTransactionReportDetailsError = (error:any) => ({
   type: SENDER_TRANSACTION_REPORT_DETAILS_FAIL,
   payload: error
 })