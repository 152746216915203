/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     EDIT_ORDER_START 
} from "../../../constants/actionType/orders";
import { saveData} from '../../../helpers';
import {  editOrderFail, editOrderSuccess } from "../../actions";


interface ResponseGenerator {
    data: any;
}

function* editOrder(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/edit-order', data?.data);
        if (response?.data?.error) {
            yield put(editOrderFail(response.data || 'Unknown error'));
        } else {
            yield put(editOrderSuccess(response));
        }
    } catch (e: any) {
        yield put(editOrderFail(e.message || 'Unknown error'));
    }
}

export default function* fetchEditOrder() {
    yield takeEvery(EDIT_ORDER_START, editOrder);
}