import { 
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START, 
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS, 
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL, 
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_CLEAR
} from "../../../../constants/actionType/bulkTransaction";



interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: null,
    error: {},
    loading: false,
}

const requestApprovalOrRejectionTransaction = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_CLEAR: {
            return {
                ...state,
                loading: false,
                data: null,
                error: null,
            };
        }
        
        default: {
            return state;
          }
    }
}

export default requestApprovalOrRejectionTransaction;