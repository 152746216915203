import {
    GET_ALL_SUPPLIER_FAIL,
    GET_ALL_SUPPLIER_START,
    GET_ALL_SUPPLIER_SUCCESS,
} from '../../../constants/actionType/shop';

export interface IGetAllProductData {
    data: any;
    error: any;
}

export const getAllSupplierData = () => ({ type: GET_ALL_SUPPLIER_START });

export const getAllSupplierSuccess = (data: IGetAllProductData) => ({
    type: GET_ALL_SUPPLIER_SUCCESS,
    payload: data,
});

export const getAllSupplierFail = (error: IGetAllProductData) => ({
    type: GET_ALL_SUPPLIER_FAIL,
    payload: error,
});
