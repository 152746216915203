/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { FiMenu } from 'react-icons/fi';
import './index.css';
import { Button, Input } from 'antd';
import FormModal from '../modal';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { requestAppointments } from '../../../redux/actions';
import { useEffect } from 'react';
import VirtualAppointmentPage from '../../Appointment/virtualAppointment';
import Translate, { defaultLanguage } from '../../../languages/Translate';
import { appName } from '../../../constants/defaultLabel';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const NavigationBar = ({ title }: any) => {
    const languages = [
        { language: 'English', flag: 'united kingdom flag' },
        { language: 'Kinyarwanda', flag: 'rwanda flag' },
        { language: 'Français', flag: 'france flag' },
    ];
    const dispatch = useDispatch();
    const chosenLanguage = localStorage.getItem('language');
    const [changeForm, setChangeForm]: any = useState('default');
    const [openSideBar, setOpenSideBar]: any = useState('0');
    const [form, setForm]: any = useState({
        inmateCode: '',
        relativeName: '',
    });

    const { requestAppointment } = userSelector((user) => user);

    const changeLanguage = (selectedLanguage: string) => {
        localStorage.setItem('language', selectedLanguage);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const onHandleChangeInput = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const [open, setOpen] = useState(false);
    // const showModal = () => {
    //     setOpen(true);
    // };

    const hideModal = () => {
        setOpen(false);
    };

    const onSubmit = () => {
        const inmateData = {
            relative_name: form?.relativeName,
            inmate_code: form?.inmateCode,
            check: true,
        };
        dispatch(requestAppointments(inmateData));
        setChangeForm('virtual-appointment');
    };

    useEffect(() => {
        if (requestAppointment?.data?.data?.status === 200) {
            setChangeForm('virtual-appointment');
        }
    }, []);

    return (
        <>
            <div className="nav-bar__container">
                <div className="nav-bar__container-mobile" style={{ width: openSideBar }}>
                    <div className="mob-view-btn">
                        <Button onClick={() => setOpenSideBar('0')} style={{ float: 'right', fontSize: '20px' }}>
                            &times;
                        </Button>
                    </div>
                    <div className="nav-bar__container-mobile-col">
                        {title === 'Home' ? (
                            <div className="link-ground-btn">
                                <Link to="/request-physical-appointment" className="Icon-group">
                                    <span>{Translate('Appointment')}</span>
                                </Link>
                            </div>
                        ) : null}
                        <Link to="#" className="Icon-group">
                            <span>About Us</span>
                        </Link>
                        <Link to="#" className="Icon-group">
                            <span>Contact Us</span>
                        </Link>
                    </div>
                </div>

                <div className="nav-bar_container-row">
                    <div className="mob-view-btn">
                        <Button onClick={() => setOpenSideBar('70%')}>
                            <FiMenu size={22} />
                        </Button>
                    </div>
                    <div className="left-content">
                        <div className="left-content__row">
                            <Link to="/">
                                <h2>{appName}</h2>
                            </Link>
                        </div>
                        <div className="btn-group"></div>
                    </div>
                    <div className="right-content">
                        <div className="home-right-content">
                            <Popup
                                content={
                                    <div className="popup-btn">
                                        {languages.map((item) => {
                                            return (
                                                <>
                                                    <Link to="#" onClick={() => changeLanguage(item.language)}>
                                                        <i className={item.flag}></i>
                                                        <span>{item.language}</span>
                                                    </Link>
                                                </>
                                            );
                                        })}
                                    </div>
                                }
                                on="click"
                                positionFixed
                                trigger={
                                    <Link to="#" className="Icon-group-home">
                                        <Icon name="language" size="big" style={{ color: '#0d6efd' }} />
                                        <span>{`${chosenLanguage || defaultLanguage}`}</span>
                                    </Link>
                                }
                            />

                            {title === 'Appointment' ? (
                                <div className="link-ground-btn">
                                    <Link to="/" className="Icon-group-home">
                                        <span>{Translate('Home')}</span>
                                    </Link>
                                </div>
                            ) : null}

                            <Link to="#" className="Icon-group-home">
                                <span>{Translate('About Us')}</span>
                            </Link>
                            <Link to="#" className="Icon-group-home">
                                <span>{Translate('Contact Us')}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {changeForm === 'default' ? (
                <FormModal
                    title={'Request appointment'}
                    open={open}
                    hideModal={hideModal}
                    loading={requestAppointment.loading}
                    register={onSubmit}
                    btn="Proceed"
                    appointment={'appointment'}
                >
                    <>
                        <div className="form-input" style={{}}>
                            <span>{`Case number`}</span>
                            <Input
                                size="large"
                                placeholder="Enter case number"
                                value={form.inmateCode}
                                name="inmateCode"
                                onChange={onHandleChangeInput}
                            />
                        </div>
                        <div className="form-input">
                            <span>{`Parents names (Respect spaces between first name and last name)`}</span>
                            <Input
                                size="large"
                                placeholder="Enter relative name (Father or Mother)"
                                value={form.relativeName}
                                name="relativeName"
                                onChange={onHandleChangeInput}
                            />
                        </div>
                    </>
                </FormModal>
            ) : null}

            {changeForm === 'virtual-appointment' ? (
                <FormModal
                    title={'Request appointment'}
                    open={open}
                    hideModal={hideModal}
                    loading={requestAppointment.loading}
                    register={onSubmit}
                    btn="Proceed"
                    appointment={'v-appointment'}
                >
                    <VirtualAppointmentPage setChangeForm={setChangeForm} requestAppointmentData={requestAppointment} />
                </FormModal>
            ) : null}
        </>
    );
};

export default NavigationBar;
