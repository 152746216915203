/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
     GET_PRISON_FAIL, 
     GET_PRISON_START, 
     GET_PRISON_SUCCESS 
} from "../../../constants/actionType/users";
import { fetchData } from "../../../helpers";

interface ResponseGenerator{}

function* getPrisonData(data:any) {
    try {
        const prisonData: ResponseGenerator = yield call(fetchData,  data?.data);
        yield put({type:  GET_PRISON_SUCCESS, data: prisonData});

    } catch (e: any) {
        yield put({ type:  GET_PRISON_FAIL, error: e.message });
    }
}

export default function* fetchPrisonData() {
    yield takeEvery( GET_PRISON_START, getPrisonData)
}