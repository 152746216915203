/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    EXECUTE_ORDER_AMOUNT_SUCCESS,
    EXECUTE_ORDER_AMOUNT_FAIL,
    EXECUTE_ORDER_AMOUNT_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { toast } from "react-toastify";


export interface ResponseGenerator{
    message: any;
}

function* executeOrderAmount(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'orders/execute-amount', data?.data);
        toast(`${userData?.message}`)
        yield put({type:EXECUTE_ORDER_AMOUNT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:EXECUTE_ORDER_AMOUNT_FAIL, error: e.message });
    }
}

export default function* fetchExecuteOrderAmount() {
    yield takeEvery(EXECUTE_ORDER_AMOUNT_START, executeOrderAmount)
}