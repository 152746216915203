import { Button, Input, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';

import { DatePicker } from 'antd';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import moment from 'moment';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ManageDates } from '..';
import { outgoingTransactionHistoryData } from '../../../redux/actions/sendMoney/outgoingTransactionHistory';
import { ColumnsType } from 'antd/es/table';
import { FilePdfOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const TransactionHistory: React.FC<any> = ({ setShowNextPage }) => {
    const dispatch = useDispatch();
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);

    const [selectedDate, setSelectedDate]: any = useState();
    const [getRecipient, setRecipient]: any = useState('');

    const onHandleChangeRecipient = (e: any) => setRecipient(e.target.value);
    const { outgoingTransactionHistory } = userSelector((user) => user);

    const onSelectDate = (dateString: any) => {
        setDates(dateString);

        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const onSearch = () => {
        dispatch(
            outgoingTransactionHistoryData({
                recipient: getRecipient,
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            outgoingTransactionHistoryData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
                recipient: '',
            }),
        );
    }, [dispatch, selectedDate]);

    const detailsOnDataColumns: ColumnsType<any> = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
            key: 'sender',
        },
        {
            title: 'Sender RCS case',
            dataIndex: 'inmateCode',
            key: 'inmateCode',
        },

        {
            title: 'Receiver Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Receiver contact',
            dataIndex: 'contacts',
            key: 'contacts',
        },

        {
            title: 'Amount',
            dataIndex: 'out_going_amount',
            key: 'out_going_amount',
        },
        {
            title: 'Telco',
            dataIndex: 'telco',
            key: 'telco',
        },
    ];

    const outgoingTransactionAmount =
        outgoingTransactionHistory?.data?.data !== undefined ? outgoingTransactionHistory?.data?.data : [];

    const data: any = outgoingTransactionAmount?.details?.map((item: any) => {
        return {
            date: moment(`${item?.created_at}`).format('YYYY-MM-DD'),
            sender: `${item?.first_name} ${item?.last_name}`,
            inmateCode: item?.inmate_code,
            name: `${item?.sender_first_name} ${item?.sender_last_name}`,
            contacts: item?.contacts,
            out_going_amount: `${item?.out_going_amount} Rwf`,
            telco: item?.telco,
        };
    });

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => setShowNextPage('default')}>
                            <BiArrowBack size={18} style={{ color: '#333' }} />
                            <span
                                style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px', color: '#333' }}
                            >
                                Send money
                            </span>
                        </Button>
                        <div className="top-right__items" style={{ width: '80%' }}>
                            <Input
                                placeholder="Search by RCS case or Sender contact"
                                value={getRecipient}
                                onChange={onHandleChangeRecipient}
                                style={{ width: '40%' }}
                            />

                            <div style={{ marginLeft: '10px' }}>
                                <RangePicker
                                    style={{ padding: '6px' }}
                                    onChange={onSelectDate}
                                    format="YYYY-MM-DD"
                                    defaultValue={[
                                        dayjs(getDate.defaultStartedDate()),
                                        dayjs(getDate.defaultEndDate()),
                                    ]}
                                />
                            </div>

                            <Button
                                style={{ marginLeft: '30px' }}
                                loading={outgoingTransactionHistory.loading}
                                onClick={onSearch}
                            >
                                Search
                            </Button>

                            <Button onClick={handlePrint} type="primary">
                                <FilePdfOutlined />
                                <span>Export to PDF</span>
                            </Button>
                        </div>
                    </div>

                    <div className="tbl-items" style={{ justifyContent: 'flex-start' }}>
                        <div className="tbl-items__col">
                            <span style={{ color: '#777677' }}>Total transaction</span>
                            <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                                {outgoingTransactionAmount?.total_amount} {outgoingTransactionAmount?.currency}
                            </span>
                        </div>
                        <div className="separator__col"></div>
                        <div className="tbl-items__col">
                            <span style={{ color: '#777677' }}>Transaction frequency</span>
                            <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                                {outgoingTransactionAmount?.frequency}
                            </span>
                        </div>
                    </div>
                    <div ref={componentRef}>
                        <Table
                            columns={detailsOnDataColumns}
                            dataSource={data}
                            pagination={data?.length >= 10 ? undefined : false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransactionHistory;
