/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
   PREPARE_COUPON_SUCCESS, 
   PREPARE_COUPON_FAIL, 
   PREPARE_COUPON_START } from "../../../constants/actionType/inmates";
import { saveData } from '../../../helpers';
import { delay } from "../../../constants/time";



export interface ResponseGenerator{}

function* prepareCoupon(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prepare-coupon', data?.data);
        yield call(delay, 3000);
        yield put({type:PREPARE_COUPON_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:PREPARE_COUPON_FAIL, error: e.message });
    }
}

export default function* fetchPrepareCoupon() {
    yield takeEvery(PREPARE_COUPON_START, prepareCoupon)
}