import {
    REQUEST_BULK_SAVING_FILE_DETAILS_FAIL, 
    REQUEST_BULK_SAVING_FILE_DETAILS_START, 
    REQUEST_BULK_SAVING_FILE_DETAILS_SUCCESS 
} from "../../../../constants/actionType/bulkSaving";
import {  IFileDetailsData } from "../../../../interfaces/bulkSaving";


interface IData {
    data: IFileDetailsData[]
}

interface IError {
    data: string,
}

interface IParam {
  file_id: string | number
}

 export const retrieveBulkSavingFileDetailsData = (data: IParam) => ({type:  REQUEST_BULK_SAVING_FILE_DETAILS_START, data});
 
 export const retrieveBulkSavingFileDetailsSuccess = (data: IData) => ({
   type: REQUEST_BULK_SAVING_FILE_DETAILS_SUCCESS,
   payload: data
 })
 
 export const retrieveBulkSavingFileDetailsError = (error:IError) => ({
   type: REQUEST_BULK_SAVING_FILE_DETAILS_FAIL,
   payload: error
 })