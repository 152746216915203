/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_TRANSACTIONS_REPORT_SUCCESS, 
    GET_TRANSACTIONS_REPORT_FAIL, 
    GET_TRANSACTIONS_REPORT_START } 
    from "../../../constants/actionType/facility";
import {  saveData } from '../../../helpers';


export interface ResponseGenerator{}

function* getTransactionsReport(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'general/transactions-report', data?.data);
        yield put({type: GET_TRANSACTIONS_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_TRANSACTIONS_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchTransactionsReport() {
    yield takeEvery(GET_TRANSACTIONS_REPORT_START, getTransactionsReport)
}