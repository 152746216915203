import {
    CREATE_DELIVERY_NOTE_FILE_FAIL,
    CREATE_DELIVERY_NOTE_FILE_START,
    CREATE_DELIVERY_NOTE_FILE_SUCCESS,
} from '../../../constants/actionType/orders';

export interface ISCreateDeliveryNoteFileData {
    data: any;
    error: any;
}

export const createDeliveryNoteFileData = (data:any) => ({ type: CREATE_DELIVERY_NOTE_FILE_START, data});

export const createDeliveryNoteFileSuccess = (data: ISCreateDeliveryNoteFileData) => ({
    type: CREATE_DELIVERY_NOTE_FILE_SUCCESS,
    payload: data,
});

export const createDeliveryNoteFileFailed = (error: ISCreateDeliveryNoteFileData) => ({
    type: CREATE_DELIVERY_NOTE_FILE_FAIL,
    payload: error,
});
