/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS,
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL,
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_START 
} from "../../../../constants/actionType/bulkTransaction";
import {  saveData } from '../../../../helpers';
import { ITransactionFileDetailsData } from "../../../../interfaces/bulkTransaction";

interface IPayload {
    type: typeof REQUEST_BULK_TRANSACTION_FILE_DETAILS_START;
    data: ITransactionFileDetailsData
}

interface IData {
    message: string
}

export interface ResponseGenerator{
    message: string;
    data: IData;
}

function* retrieveBulkTransactionFileDetails(data: IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'retrieve-bulk-transaction-data', data?.data);
        yield put({type:REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL, error: e.message });
    }
}

export default function* fetchRetrieveBulkTransactionFileDetails() {
    yield takeEvery(REQUEST_BULK_TRANSACTION_FILE_DETAILS_START, retrieveBulkTransactionFileDetails)
}