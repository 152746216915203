/* eslint-disable @typescript-eslint/no-explicit-any */
import { call,  put, takeEvery } from "redux-saga/effects";
import {
    RECORD_SAVING_SUCCESS,
    RECORD_SAVING_FAIL,
    RECORD_SAVING_START, 
} from "../../../../constants/actionType/bulkSaving";
import { saveData } from '../../../../helpers';
import { toast } from "react-toastify";


interface IData {
    message: string
}

export interface ResponseGenerator{
    message: string
    data: IData
}

function* recordSaving(data: any) {
    try {  
        const userData: ResponseGenerator = yield call(saveData, 'record-bulk-saving', data?.data);
        toast(`${userData?.message || userData?.data?.message || ''}`)
        yield put({type:RECORD_SAVING_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:RECORD_SAVING_FAIL, error: e.message });
    }
}



export default function* fetchRecordedSaving() {
    yield takeEvery(RECORD_SAVING_START, recordSaving)

}
