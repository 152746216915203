/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Table } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import NoResultFound from '../../../common/noResultFound';

const AllProducts = ({ data, columns, loadingData }: { data: any; columns: any; loadingData: boolean }) => {
    const customLoading = {
        spinning: loadingData,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
    };
    return (
        <Flex style={{ height: '100vh' }}>
            <Table
                size="small"
                style={{ width: '100%' }}
                loading={customLoading}
                columns={columns}
                dataSource={data}
                locale={{ emptyText: NoResultFound }}
                pagination={data.length >= 30 ? undefined : false}
            />
        </Flex>
    );
};

export default AllProducts;
