/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REGISTER_FACILITY_FAIL, 
    REGISTER_FACILITY_START, 
    REGISTER_FACILITY_SUCCESS 
} from "../../../constants/actionType/facility";

 export const registerFacilityData = (data: any) => ({type:  REGISTER_FACILITY_START, data});
 
 export const registerFacilitySuccess = (data:any) => ({
   type: REGISTER_FACILITY_SUCCESS,
   payload: data
 })
 
 export const registerFacilityError = (error:any) => ({
   type: REGISTER_FACILITY_FAIL,
   payload: error
 })