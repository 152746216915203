/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    UPDATE_FACILITY_FAIL, 
    UPDATE_FACILITY_START, 
    UPDATE_FACILITY_SUCCESS 
} from "../../../constants/actionType/facility";

 export const updateFacilityData = (data: any) => ({type:  UPDATE_FACILITY_START, data});
 
 export const updateFacilitySuccess = (data:any) => ({
   type: UPDATE_FACILITY_SUCCESS,
   payload: data
 })
 
 export const updateFacilityError = (error:any) => ({
   type: UPDATE_FACILITY_FAIL,
   payload: error
 })