
import { VIDEO_MEETING_URL } from '../constants/socketRoutes';



const socket = new WebSocket(`${VIDEO_MEETING_URL}/ws`);

const connect = () => {
    console.log("Attempting Connection...");

    socket.onopen = () => {
        console.log("Successfully Connected");
    }
    
    socket.onclose = event => {
        console.log("Socket Closed Connection: ", event);
    }

    socket.onerror = error => {
        console.log("Socket Error: ", error);
      };

}


const sendMsg = (msg:any) => {
    const jsonStr = JSON.stringify(msg);
    socket.send(jsonStr);
}

export { connect, sendMsg, socket };