import {
    GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_FAIL, 
    GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_START, 
    GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_SUCCESS 
    } from "../../../constants/actionType/inmates";
    
    
export interface IInmateWithZeroWalletData {
    data: any,
    error: any
}

export const getInmateWithZeroWalletData = (data:any) =>  ({type: GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_START, data});

export const getInmateWithZeroWalletsSuccess = (data: IInmateWithZeroWalletData) => ({
    type: GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_SUCCESS, 
    payload: data
})

export const getInmateWithZeroWalletsFail = (error: IInmateWithZeroWalletData) => ({ type: GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_FAIL, payload: error})
