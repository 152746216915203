/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_SUPER_ADMIN_INMATE_SUCCESS, GET_SUPER_ADMIN_INMATE_FAIL, GET_SUPER_ADMIN_INMATE_START } from "../../../constants/actionType/inmates";
import {  saveData } from '../../../helpers';


export interface ResponseGenerator{}

function* getSuperAdminInmates(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'general/retrieve/inmates', data?.data);
        yield put({type: GET_SUPER_ADMIN_INMATE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_SUPER_ADMIN_INMATE_FAIL, error: e.message });
    }
}

export default function* fetchSuperAdminInmates() {
    yield takeEvery(GET_SUPER_ADMIN_INMATE_START, getSuperAdminInmates)
}