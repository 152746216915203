/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import DashboardPage, { ManageDates } from '../../components/dashboard';
import { useNavigate } from 'react-router-dom';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../interfaces/index';
import { getRwandaProvinceData, getUserAccountData, getUserDataInfo, logoutData } from '../../redux/actions';
import { registerInmateData } from '../../redux/actions';
import { getSummary as getAllSummary } from '../../redux/actions/summary/getSummary';
// import apiRequest from '../../helpers/apiRequest';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const DashboardPageContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getRwandaProvinces, getUserData, registerUser, getRegisteredInmate, logout } = userSelector((user) => user);
    const getDate = new ManageDates();
    const [selectedDate, setSelectedDate]: any = useState();
    const [selectedYear, setSelectedYear] = useState();

    const getSummary: any = { data: {} };

    // Register Inmates
    const [inmFirstName, setInmFirstName] = useState('');
    const [inmLastName, setInmLastName] = useState('');
    const [inmFatherNames, setInmFatherNames] = useState('');
    const [inmMotherNames, setInmMotherNames] = useState('');
    const [inmJailedReason, setInmJailedReason] = useState('');
    const [inmInmateCode, setInmInmateCode] = useState('');
    const [inmBirthDate, setInmBirthDate] = useState('');
    const [inmProvince, setInmProvince] = useState('');
    const [inmDistrict, setInmDistrict] = useState('');
    const [inmAddress, setInmAddress] = useState('');
    const [inmPrisonEntryDate, setInmPrisonEntryDate] = useState('');
    const [prisonId, setInmPrisonId] = useState();
    const [getProvince, setGetProvince] = useState('');
    const [districtsList, setDistrictList]: any = useState([]);

    // Register inmates
    const onHandleChangeInmFirstName = (e: any) => setInmFirstName(e.target.value);
    const onHandleChangeInmLastName = (e: any) => setInmLastName(e.target.value);
    const onHandleChangeInmFatherNames = (e: any) => setInmFatherNames(e.target.value);
    const onHandleChangeInmMotherNames = (e: any) => setInmMotherNames(e.target.value);
    const onHandleChangeInmJailedReason = (e: any) => setInmJailedReason(e.target.value);
    const onHandleChangeInmInmateCode = (e: any) => setInmInmateCode(e.target.value);
    const onHandleChangeInmBirthDate = (e: any) => setInmBirthDate(e.target.value);
    const onHandleChangeInmProvince = (e: any) => setInmProvince(e.target.value);
    const onHandleChangeInmDistrict = (e: any) => setInmDistrict(e.target.value);
    const onHandleChangeInmAddress = (e: any) => setInmAddress(e.target.value);
    const onHandleChangeInmPrisonEntryDate = (e: any) => setInmPrisonEntryDate(e.target.value);
    const onHandleChangeInmPrisonId = (value: any) => setInmPrisonId(value);

    // Register Inmate function
    const registerInmate = () => {
        const inmateData = {
            first_name: inmFirstName,
            last_name: inmLastName,
            father_name: inmFatherNames,
            mather_name: inmMotherNames,
            inmate_code: inmInmateCode,
            birth_date: inmBirthDate,
            province: getProvince,
            district: inmDistrict,
            address: inmAddress,
            prison_entryDate: inmPrisonEntryDate,
            userId: prisonId,
        };

        dispatch(registerInmateData(inmateData));
    };

    useEffect(() => {
        dispatch(getUserDataInfo());
        dispatch(getUserAccountData());
        dispatch(
            getAllSummary({
                selectedDate: {
                    startDate: !selectedDate?.startDate ? getDate.defaultStartedDate() : selectedDate?.startDate,
                    endDate: !selectedDate?.endDate ? getDate.defaultEndDate() : selectedDate?.endDate,
                },
            }),
        );
        dispatch(getRwandaProvinceData());
    }, [dispatch, selectedDate, selectedYear]);

    const logouts = () => {
        dispatch(logoutData());
    };

    useEffect(() => {
        if (logout?.data?.data) {
            navigate('/admin');
            localStorage.removeItem('token');
            localStorage.removeItem('currentUser');
            setTimeout(function () {
                window.location.reload();
            });
        }
    }, [logout?.data?.data]);

    const onHandleSelectProvince = (value: string) => {
        setGetProvince(value);
    };

    const onHandleSelectDistrict = (value: string) => {
        setInmDistrict(value);
    };

    useEffect(() => {
        const newList: any = [];
        getRwandaProvinces?.data?.map((item: any) => {
            if (getProvince === item?.value) {
                newList.push(item?.data);
            }
        });

        setDistrictList(...newList);
    }, [getProvince, districtsList]);

    return (
        <>
            <DashboardPage
                logout={logouts}
                loadingLogout={logout?.loading}
                getUserData={getUserData}
                loadingRegisterUser={registerUser.loading}
                registerUserData={registerUser.data}
                // Inmates section

                registerInmate={registerInmate}
                onHandleChangeInmFirstName={onHandleChangeInmFirstName}
                onHandleChangeInmLastName={onHandleChangeInmLastName}
                onHandleChangeInmFatherNames={onHandleChangeInmFatherNames}
                onHandleChangeInmMotherNames={onHandleChangeInmMotherNames}
                onHandleChangeInmJailedReason={onHandleChangeInmJailedReason}
                onHandleChangeInmInmateCode={onHandleChangeInmInmateCode}
                onHandleChangeInmBirthDate={onHandleChangeInmBirthDate}
                onHandleChangeInmProvince={onHandleChangeInmProvince}
                onHandleChangeInmDistrict={onHandleChangeInmDistrict}
                onHandleChangeInmAddress={onHandleChangeInmAddress}
                onHandleChangeInmPrisonEntryDate={onHandleChangeInmPrisonEntryDate}
                onHandleChangeInmPrisonId={onHandleChangeInmPrisonId}
                inmFirstName={inmFirstName}
                inmLastName={inmLastName}
                inmFatherNames={inmFatherNames}
                inmMotherNames={inmMotherNames}
                inmJailedReason={inmJailedReason}
                inmInmateCode={inmInmateCode}
                inmBirthDate={inmBirthDate}
                inmProvince={inmProvince}
                inmDistrict={inmDistrict}
                inmAddress={inmAddress}
                inmPrisonEntryDate={inmPrisonEntryDate}
                prisonId={prisonId}
                setInmPrisonId={setInmPrisonId}
                loadingRegisterInmate={getRegisteredInmate}
                summaryData={getSummary}
                onHandleSelectProvince={onHandleSelectProvince}
                districtsList={districtsList}
                getRwandaProvinces={getRwandaProvinces?.data}
                onHandleSelectDistrict={onHandleSelectDistrict}
                setSelectedDate={setSelectedDate}
                setSelectedYear={setSelectedYear}
                dispatch={dispatch}
                navigate={navigate}
            />
        </>
    );
};

export default DashboardPageContainer;
