/* eslint-disable @typescript-eslint/no-unused-vars */
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { getPublicItemsData } from '../../../redux/actions';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const AddFacilities: React.FC<any> = ({ setAddFacilityForm }) => {
    const dispatch = useDispatch();
    const [districtsList, setDistrictList]: any = useState([]);
    const [prisonName, setPrisonName] = useState('');
    const [district, setDistrict] = useState('');
    const [getProvince, setGetProvince] = useState('');

    const {
        getPublicItem: { data },
    } = userSelector((user: any) => user);

    const publicItems = data?.data;

    useEffect(() => {
        setAddFacilityForm({
            name: prisonName,
            province: getProvince,
            district: district,
        });
    }, [prisonName, getProvince, district]);

    const onHandleSelectProvince = (value: string) => {
        setGetProvince(value);
    };
    const onHandleChangeCorrectionalService = (value: string) => setPrisonName(value);

    const onHandleChangeDistrict = (value: string) => setDistrict(value);

    const onSearch = (value: string) => {
        return value;
    };

    useEffect(() => {
        const newList: any = [];
        publicItems?.location?.map((item: any) => {
            if (getProvince === item?.value) {
                newList.push(item?.data);
            }
        });

        setDistrictList(...newList);
    }, [getProvince, districtsList]);

    useEffect(() => {
        dispatch(getPublicItemsData());
    }, [dispatch]);

    return (
        <>
            <div className="form__input" style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                <span>{`Select correctional facility`}</span>
                <Select
                    showSearch
                    placeholder="Select a correctional facility"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) => {
                        const val: any = option?.label ?? '';
                        return val.toLowerCase().includes(input.toLowerCase());
                    }}
                    onChange={onHandleChangeCorrectionalService}
                    options={publicItems?.facilities}
                />
            </div>
            <div className="form__input" style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                <span>{`Select province`}</span>
                <Select
                    showSearch
                    placeholder="Select a province"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) => {
                        const val: any = option?.label ?? '';
                        return val.toLowerCase().includes(input.toLowerCase());
                    }}
                    onChange={onHandleSelectProvince}
                    options={publicItems?.location}
                />
            </div>{' '}
            <div className="form__input" style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                <span>{`Select district`}</span>
                <Select
                    disabled={districtsList?.length > 0 ? false : true}
                    showSearch
                    placeholder="Select a district"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) => {
                        const val: any = option?.label ?? '';
                        return val.toLowerCase().includes(input.toLowerCase());
                    }}
                    onChange={onHandleChangeDistrict}
                    options={districtsList}
                />
            </div>
        </>
    );
};

export default AddFacilities;
