import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../interfaces';
import { getGlobalSummaryData } from '../../redux/actions';
import {
    // HiOutlineArrowNarrowRight,
    HiOutlineUserGroup,
} from 'react-icons/hi';
import { Doughnut } from 'react-chartjs-2';
import { AiOutlineUngroup, AiOutlineGroup } from 'react-icons/ai';
import { SlPeople } from 'react-icons/sl';
import { GrVirtualMachine } from 'react-icons/gr';
import { MdOutlinePendingActions } from 'react-icons/md';
import { Link } from 'react-router-dom';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const {
        getGlobalSummary: { data },
    } = userSelector((state) => state);

    const globalSummaryData = data?.data;

    const chartData = {
        labels: [
            'Pending physical',
            'Completed physical',
            'Rejected physical',
            'Pending virtual',
            'Completed virtual',
            'Rejected virtual',
            'Approved virtual',
            'Approved physical',
        ],
        datasets: [
            {
                label: 'Appointment',
                data: [
                    globalSummaryData?.physical_appointment?.approved_appointments,
                    globalSummaryData?.physical_appointment?.pending_appointments,
                    globalSummaryData?.physical_appointment?.completed_appointments,
                    globalSummaryData?.physical_appointment?.rejected_appointments,
                    globalSummaryData?.virtual_appointment?.pending_appointments,
                    globalSummaryData?.virtual_appointment?.completed_appointments,
                    globalSummaryData?.virtual_appointment?.rejected_appointments,
                    globalSummaryData?.virtual_appointment?.approved_appointments,
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 2)',
                    'rgba(54, 162, 235, 2)',
                    'rgba(255, 206, 86, 2)',
                    'rgba(75, 192, 192, 2)',
                    'rgba(153, 102, 255, 2)',
                    'rgba(255, 159, 64, 2)',
                    '#65f269',
                    '#92eee1',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    '#65f269',
                    '#92eee1',
                ],
                borderWidth: 0,
            },
        ],
    };

    useEffect(() => {
        dispatch(getGlobalSummaryData());
    }, []);
    return (
        <>
            {/* {showPage === 'dashboard' ? ( */}
            <>
                <div className="btn-group">
                    <div className="btn-group__row"></div>
                </div>

                <>
                    <div className="top-items">
                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: '#abf1ff' }}>
                                            <i>
                                                <MdOutlinePendingActions size={30} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Pending appointments</span>
                                            <a></a>
                                        </div>
                                        <h2>
                                            {parseInt(
                                                `${globalSummaryData?.physical_appointment?.pending_appointments}`,
                                            ) +
                                                parseInt(
                                                    `${globalSummaryData?.virtual_appointment?.pending_appointments}`,
                                                )}
                                        </h2>
                                    </div>
                                </div>

                                <div className="card-container__box-bottom">
                                    <div className="bottom-box__item">
                                        <span className="box-title">Virtual</span>
                                        <span className="box-value">
                                            {globalSummaryData?.virtual_appointment?.pending_appointments}
                                        </span>
                                    </div>
                                    <div className="bottom-box__item">
                                        <span className="box-title">Physical</span>
                                        <span className="box-value">
                                            {globalSummaryData?.physical_appointment?.pending_appointments}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: '#99ffd3' }}>
                                            <i>
                                                <SlPeople size={30} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Physical appointments</span>
                                            <a></a>
                                        </div>

                                        <h2>{globalSummaryData?.physical_appointment?.number}</h2>
                                    </div>
                                </div>

                                <div className="card-container__box-bottom">
                                    <>
                                        <div className="bottom-box__item">
                                            <span className="box-title">Approved</span>
                                            <span className="box-value">
                                                {globalSummaryData?.physical_appointment?.approved_appointments}
                                            </span>
                                        </div>
                                        <div className="bottom-box__item">
                                            <span className="box-title">Completed</span>
                                            <span className="box-value">
                                                {globalSummaryData?.physical_appointment?.completed_appointments}
                                            </span>
                                        </div>

                                        <div className="bottom-box__item">
                                            <span className="box-title">Rejected</span>
                                            <span className="box-value">
                                                {globalSummaryData?.physical_appointment?.rejected_appointments}
                                            </span>
                                        </div>
                                        {/* 
                                        <div className="bottom-box__item">
                                            <span className="box-title">Rejected</span>
                                            <span className="box-value">
                                                {globalSummaryData?.physical_appointment.up_coming_appointment?.number}
                                            </span>
                                        </div> */}
                                    </>
                                </div>
                            </div>
                        </Link>

                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: '#ffadbe' }}>
                                            <i>
                                                <GrVirtualMachine size={30} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Virtual appointments</span>
                                            <a></a>
                                        </div>

                                        <h2>{globalSummaryData?.virtual_appointment?.number}</h2>
                                    </div>
                                </div>

                                <div className="card-container__box-bottom">
                                    <>
                                        <div className="bottom-box__item">
                                            <span className="box-title">Approved</span>
                                            <span className="box-value">
                                                {globalSummaryData?.virtual_appointment?.pending_appointments}
                                            </span>
                                        </div>
                                        <div className="bottom-box__item">
                                            <span className="box-title">Completed</span>
                                            <span className="box-value">
                                                {globalSummaryData?.virtual_appointment?.completed_appointments}
                                            </span>
                                        </div>

                                        <div className="bottom-box__item">
                                            <span className="box-title">Rejected</span>
                                            <span className="box-value">
                                                {globalSummaryData?.virtual_appointment?.rejected_appointments}
                                            </span>
                                        </div>

                                        {/* <div className="bottom-box__item">
                                            <span className="box-title">Rejected</span>
                                            <span className="box-value">
                                                {globalSummaryData?.virtual_appointment.up_coming_appointment?.number}
                                            </span>
                                        </div> */}
                                    </>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="middle-items">
                        <div className="middle-items_right">
                            <Doughnut data={chartData} style={{ marginLeft: '30px' }} />
                        </div>

                        <div className="middle-items_left-box">
                            <div className="middle-items_left">
                                <div className="middle-items_left-top">
                                    <div className="title-icon">
                                        <AiOutlineUngroup size={30} />
                                        <span>Outgoing Appointment</span>
                                    </div>
                                </div>
                                <div className="middle-items_left-bottom">
                                    <div id="items-rows">
                                        <span className="telco-names">Today</span>
                                        <span className="telco-amount">{globalSummaryData?.outgoing}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="middle-items_left">
                                <div className="middle-items_left-top">
                                    <div className="title-icon">
                                        <AiOutlineGroup size={30} />
                                        <span>Incoming Appointment</span>
                                    </div>
                                </div>
                                <div className="middle-items_left-bottom">
                                    <div id="items-rows">
                                        <span className="telco-names">Today</span>
                                        <span className="telco-amount">{globalSummaryData?.incoming}</span>
                                    </div>
                                    {/* {incomingAppointments &&
                                    incomingAppointments?.map((item: any, index: number) => {
                                        return (
                                            <>
                                                <div id="items-rows" key={index}>
                                                    <span className="telco-names">{item?.status}</span>
                                                    <span className="telco-amount">{item?.count}</span>
                                                </div>
                                            </>
                                        );
                                    })} */}
                                </div>
                            </div>
                            <div
                                className="middle-items_left"
                                // style={{
                                //     width: '20%',
                                //     height: '30%',
                                //     marginLeft: '30px',
                                // }}
                            >
                                <div className="middle-items_left-top">
                                    <div className="title-icon">
                                        <HiOutlineUserGroup size={30} />
                                        <span>Total Inmates</span>
                                    </div>
                                </div>
                                <div className="middle-items_left-bottom">
                                    <div id="items-rows">
                                        <span className="telco-names">Inmate number</span>
                                        <span className="telco-amount">{globalSummaryData?.inmate?.inmate_number}</span>
                                    </div>
                                    {/* {outgoingAppointments &&
                                    outgoingAppointments?.map((item: any) => {
                                        return (
                                            <>
                                                <div id="items-rows">
                                                    <span className="telco-names">{item?.status}</span>
                                                    <span className="telco-amount">{item?.count}</span>
                                                </div>
                                            </>
                                        );
                                    })} */}
                                </div>
                            </div>

                            <div
                                className="middle-items_left"
                                // style={{
                                //     width: '20%',
                                //     height: '30%',
                                //     marginLeft: '30px',
                                // }}
                            >
                                <div className="middle-items_left-top">
                                    <div className="title-icon">
                                        <AiOutlineUngroup size={30} />
                                        <span>Upcoming physical appointment</span>
                                    </div>
                                </div>
                                <div className="middle-items_left-bottom">
                                    <div id="items-rows">
                                        <span className="telco-names">Total</span>
                                        <span className="telco-amount">
                                            {globalSummaryData?.physical_appointment?.up_coming_appointment?.number}
                                        </span>
                                    </div>
                                </div>

                                {/* {globalSummaryData?.physical_appointment.up_coming_appointment.details.map(
                                    (_appointment) => {
                                        return (
                                            <>
                                                <div className="middle-items_left-bottom">
                                                    <div id="items-rows">
                                                        <span className="telco-names">Status</span>
                                                        <span className="telco-amount">
                                                            {_appointment.visited_status}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    },
                                )} */}
                            </div>

                            <div
                                className="middle-items_left"
                                // style={{
                                //     width: '20%',
                                //     height: '30%',
                                //     marginLeft: '30px',
                                // }}
                            >
                                <div className="middle-items_left-top">
                                    <div className="title-icon">
                                        <AiOutlineUngroup size={30} />
                                        <span>Upcoming virtual appointment</span>
                                    </div>
                                </div>
                                <div className="middle-items_left-bottom">
                                    <div id="items-rows">
                                        <span className="telco-names">Total</span>
                                        <span className="telco-amount">
                                            {globalSummaryData?.virtual_appointment?.up_coming_appointment?.number}
                                        </span>
                                    </div>
                                </div>

                                {/* {globalSummaryData?.virtual_appointment.up_coming_appointment.details.map(
                                    (_appointment) => {
                                        return (
                                            <>
                                                <div className="middle-items_left-bottom">
                                                    <div id="items-rows">
                                                        <span className="telco-names">Status</span>
                                                        <span className="telco-amount">
                                                            {_appointment.visited_status}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    },
                                )} */}
                            </div>
                        </div>
                    </div>
                </>
                {/* )} */}
            </>
            {/* ) : null}
            {showPage === 'virtualAppointment' ? (
                <VirtualAppointment setShowPage={setShowPage} data={data} />
            ) : null}
            {showPage === 'pendingAppointment' ? (
                <PendingAppointment setShowPage={setShowPage} data={data} />
            ) : null}
            {showPage === 'physicalAppointment' ? (
                <PhysicalAppointment setShowPage={setShowPage} data={data} />
            ) : null} */}
        </>
    );
};

export default Dashboard;
