/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {INCOMING_APPOINTMENT_SUCCESS,INCOMING_APPOINTMENT_FAIL,INCOMING_APPOINTMENT_START } from "../../../constants/actionType/appointment";
import { delay } from "../../../constants/time";
import { saveData } from '../../../helpers/index';


export interface ResponseGenerator{
    message: string
}

function* incomingAppointment(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/retrieve-incoming-appointment', data?.data);
        yield call(delay, 3000);
        yield put({type:INCOMING_APPOINTMENT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:INCOMING_APPOINTMENT_FAIL, error: e.message });
    }
}

export default function* getIncomingAppointment() {
    yield takeEvery(INCOMING_APPOINTMENT_START, incomingAppointment)
}