/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    PURCHASE_ORDER_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { purchaseOrderFail, purchaseOrderSuccess } from '../../actions/orders/purchaseOrder';


export interface ResponseGenerator{
    data: any
}

function* getPurchaseOrder(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/purchase-orders', data?.data);
        if (response?.data?.error) {
            yield put(purchaseOrderFail(response.data || 'Unknown error'));
        } else {
            yield put(purchaseOrderSuccess(response));
        }
    } catch (e: any) {
        yield put(purchaseOrderFail(e.message || 'Unknown error'));
    }
}

export default function* fetchPurchaseOrder() {
    yield takeEvery(PURCHASE_ORDER_START, getPurchaseOrder);
}