/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  
    PLAY_AUDIO_SUCCESS,  
    PLAY_AUDIO_FAIL,  
    PLAY_AUDIO_START } 
    from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  fetchData } from '../../../helpers';


interface ResponseGenerator{
    message: string
}

function* playAudio(data: any) {
    try {
        const userData: ResponseGenerator = yield call(fetchData,  data?.data);
        yield put({type:PLAY_AUDIO_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:PLAY_AUDIO_FAIL, error: e.message });
    }
}

export default function* fetchPlayAudio() {
    yield takeEvery(PLAY_AUDIO_START, playAudio)
}