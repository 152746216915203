/* eslint-disable @typescript-eslint/no-explicit-any */
import { 
    PURCHASE_ORDER_FAIL, 
    PURCHASE_ORDER_START, 
    PURCHASE_ORDER_SUCCESS ,
    CLEAR_PURCHASE_ORDER
} from '../../../constants/actionType/orders';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const purchaseOrder = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case PURCHASE_ORDER_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case PURCHASE_ORDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case PURCHASE_ORDER_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case CLEAR_PURCHASE_ORDER: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }

        default: {
            return state;
          }
    }
}

export default purchaseOrder;