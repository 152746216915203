/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAIL,
    GET_NOTIFICATION_START 
} from "../../../constants/actionType/notifications";
import { fetchData} from '../../../helpers';
import { delay } from "../../../constants/time";


export interface ResponseGenerator{}

function* getNotificationDone() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/prison/notifications');
        yield call(delay, 3000);
        yield put({type:GET_NOTIFICATION_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_NOTIFICATION_FAIL, error: e.message });
    }
}

export default function* fetchGetNotification() {
    yield takeEvery(GET_NOTIFICATION_START, getNotificationDone)
}