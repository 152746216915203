/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UPDATE_FACILITY_USERS_CLEAR,
    UPDATE_FACILITY_USERS_FAIL, 
    UPDATE_FACILITY_USERS_START, 
    UPDATE_FACILITY_USERS_SUCCESS 
} from "../../../constants/actionType/facility";

 export const updateFacilityUserData = (data: any) => ({type:  UPDATE_FACILITY_USERS_START, data});
 
 export const updateFacilityUserSuccess = (data:any) => ({
   type: UPDATE_FACILITY_USERS_SUCCESS,
   payload: data
 })
 
 export const updateFacilityUserError = (error:any) => ({
   type: UPDATE_FACILITY_USERS_FAIL,
   payload: error
 })

 export const clearFacilityUserStore = () => ({type: UPDATE_FACILITY_USERS_CLEAR })