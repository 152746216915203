import {
    GET_SUPER_ADMIN_INMATE_FAIL, 
    GET_SUPER_ADMIN_INMATE_START, 
    GET_SUPER_ADMIN_INMATE_SUCCESS 
    } from "../../../constants/actionType/inmates";
    
    
export interface ISuperAdminInmateData {
    data: any,
    error: any
}

export const getSuperAdminInmateData = (data:any) =>  ({type: GET_SUPER_ADMIN_INMATE_START, data});

export const getSuperAdminInmatesSuccess = (data: ISuperAdminInmateData) => ({
    type: GET_SUPER_ADMIN_INMATE_SUCCESS, 
    payload: data
})

export const getSuperAdminInmatesFail = (error: ISuperAdminInmateData) => ({ type: GET_SUPER_ADMIN_INMATE_FAIL, payload: error})
