import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordData, clearChangePasswordStore } from '../../redux/actions/auth/changePassword';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Translate from '../../languages/Translate';
import { countryCode } from '../../constants/telco';
import { hasOnlyDigits, isFirstTwoZero, isFirstTwoZeroZero, numberStartWithSeven } from '../Appointment';

const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const hasSpecialCharsAndCapitalLetters = (inputString: string) => {
    const specialCharsRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\]/;
    const capitalLettersRegex = /[A-Z]/;
    const hasSpecialChars = specialCharsRegex.test(inputString);
    const hasCapitalLetters = capitalLettersRegex.test(inputString);

    return {
        hasSpecialChars,
        hasCapitalLetters,
    };
};

const UpdatePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [step, setSteps] = useState<number | any>(1);
    const [codeVerify, setCodeVerify] = useState(false);
    const [verifyCodeSent, setVerifyCodeSent] = useState<boolean>(false);
    const [form, setForm] = useState<any>({
        new_password: '',
        old_password: '',
        repeat_password: '',
        phone_number: '',
        verification_code: '',
        email: '',
    });

    const location = useLocation();

    const receivedData = (location.state as { changePassword?: string })?.changePassword;
    const receivedEmail = (location.state as { email?: string })?.email;

    const onHandleInput = (event: any) => {
        const { name, value } = event.target;
        const list = { ...form };
        list[name] = value;
        setForm(list);
    };
    const {
        changePassword: { data, loading },
    } = useSelector((user: any) => user);

    const onSubmitChangePassword = ({ lastStep = null }: { lastStep?: string | null | number } = {}) => {
        const checkPhoneNumberMessage = 'Please provide correct phone number';
        const inputString = hasSpecialCharsAndCapitalLetters(form.new_password);

        const userData = {
            first_login: !receivedData ?? true,
            new_password: form?.new_password,
            old_password: form?.old_password,
            email: receivedEmail || '',
        };
        if (receivedData) {
            if (form.phone_number.length !== 9 && step === 2) {
                toast.error(checkPhoneNumberMessage);
            }

            if (
                !hasOnlyDigits(form.phone_number) ||
                form.phone_number.length !== 9 ||
                isFirstTwoZeroZero(form.phone_number) ||
                isFirstTwoZero(form.phone_number) ||
                !numberStartWithSeven(form?.phone_number)
            ) {
                toast.error('Incorrect phone number provided');
            }

            const sendVerificationCode = () => {
                dispatch(
                    changePasswordData({
                        ...userData,
                        change_password: receivedData || false,
                        check_phone_number: receivedData ?? true,
                        email: form?.email,
                        phone_number: `${countryCode}${form?.phone_number}`,
                        check_verification_code: false,
                    }),
                );
            };

            if (step === 1) {
                if (!validateEmail(form.email)) {
                    toast.error('Please provide a valid email');
                } else {
                    sendVerificationCode();
                }
            }

            // Resend code
            if (step === 2 && lastStep === null) {
                sendVerificationCode();
                setVerifyCodeSent(false);
            }

            if (lastStep === 3) {
                if (form?.verification_code === '') {
                    toast.error('Please provide the verification code');
                } else {
                    const formData = {
                        ...userData,
                        code_verify: codeVerify,
                        change_password: true,
                        verification_code: form?.verification_code,
                        check_verification_code: true,
                        phone_number: `${countryCode}${form?.phone_number}`,
                        check_phone_number: true,
                        email: form?.email,
                    };
                    dispatch(changePasswordData(formData));
                }
            }

            if (lastStep === 4) {
                const formData = {
                    ...userData,
                    code_verify: codeVerify,
                    change_password: true,
                    verification_code: form?.verification_code,
                    check_verification_code: false,
                    phone_number: `${countryCode}${form?.phone_number}`,
                    check_phone_number: false,
                    email: form?.email,
                };

                if (form?.new_password === '') {
                    toast.error('Please provide password');
                } else if (form.new_password.length < 8) {
                    toast.error('Password is to short');
                } else if (inputString.hasCapitalLetters === false && inputString.hasSpecialChars === false) {
                    toast.error('Password must contain spacial characters and capital letters');
                } else if (form?.new_password !== form?.repeat_password) {
                    toast.error('Password did not match');
                } else {
                    dispatch(changePasswordData(formData));
                }
            }
        } else {
            if (form?.new_password === '') {
                toast.error('Please provide password');
            } else if (inputString.hasCapitalLetters === false && inputString.hasSpecialChars === false) {
                toast.error('Password must contain spacial characters and capital letters');
            } else if (form.new_password.length <= 8) {
                toast.error('Password is to short');
            } else if (form?.new_password !== form?.repeat_password) {
                toast.error('Password did not match');
            } else {
                dispatch(changePasswordData(userData));
            }
        }
    };

    useEffect(() => {
        const isCurrentPage = localStorage.getItem('isUpdateCredentials');

        if (isCurrentPage === null || isCurrentPage === '') {
            navigate('/admin');
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (_event: any) => {
            localStorage.removeItem('isUpdateCredentials');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (data?.data?.error !== undefined) {
            toast.error(data?.data?.message);
        }

        if (data?.data?.error === 400) {
            toast.error(data?.data?.description);
        }

        if (data?.data?.success !== undefined) {
            toast.error(data?.data?.message);
        }

        if (data?.data?.responseData?.code_verified) {
            setCodeVerify(data?.data?.responseData?.code_verified);
        }

        if (data?.data?.responseData?.verified_code_sent) {
            setVerifyCodeSent(data?.data?.responseData?.verified_code_sent);
            setSteps(2);
        }

        if (data?.data?.success !== undefined) {
            toast.success(data?.message);
        }
        if (data?.data?.key === 'DONE') {
            dispatch(clearChangePasswordStore());
            window.location.href = '/admin';
        }
    }, [data?.data]);

    return (
        <div className="container">
            <div id="container-box" className="container-box">
                <div className="login-container-box__items">
                    <div className="item__contents">
                        <span className="login-title">
                            {!receivedData ? 'Update your password' : 'Change password'}
                        </span>

                        <div className="sized-box"></div>

                        {receivedData && !codeVerify && (
                            <>
                                <div className="login-form__input">
                                    <span>Provide your email</span>
                                    <div className="input-group">
                                        <Input
                                            type="email"
                                            value={form?.email}
                                            size="large"
                                            name="email"
                                            onChange={onHandleInput}
                                            placeholder="Enter email"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="form__input"
                                    style={{
                                        color: 'white',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                        marginTop: '12px',
                                    }}
                                >
                                    <span style={{ marginBottom: '10px' }}>Provide your phone number</span>
                                    <Input
                                        style={{
                                            color: 'white',
                                            background: '#fff',
                                            borderRadius: '8px',
                                            border: '1px solid #d9d9d9',
                                        }}
                                        type="text"
                                        value={form?.phone_number}
                                        size="large"
                                        name="phone_number"
                                        addonBefore={countryCode}
                                        placeholder={Translate('78xxxxxxxxx')}
                                        onChange={onHandleInput}
                                        bordered={false}
                                    />
                                </div>
                                {step === 2 && (
                                    <>
                                        <div className="login-form__input">
                                            <span style={{ color: 'lightblue' }}>
                                                {`Please enter the code sent to the phone number `}
                                                <b style={{ color: 'white' }}>{form?.phone_number}</b>{' '}
                                                {`to complete the verification process`}
                                            </span>
                                            <div className="input-group">
                                                <Input
                                                    type="text"
                                                    value={form?.verification_code}
                                                    size="large"
                                                    name="verification_code"
                                                    onChange={onHandleInput}
                                                    placeholder="Enter your verification code"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {!receivedData && (
                            <>
                                <div className="login-form__input">
                                    <span>Old password</span>
                                    <div className="input-group">
                                        <Input
                                            type="password"
                                            value={form?.old_password}
                                            size="large"
                                            name="old_password"
                                            onChange={onHandleInput}
                                            placeholder="Enter your old password"
                                        />
                                    </div>
                                </div>

                                <div className="login-form__input">
                                    <span>New password</span>
                                    <div className="input-group">
                                        <Input
                                            type="password"
                                            value={form?.new_password}
                                            size="large"
                                            name="new_password"
                                            onChange={onHandleInput}
                                            placeholder="Enter new password"
                                        />
                                    </div>
                                </div>

                                <div className="login-form__input">
                                    <span>Repeat password</span>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            value={form?.repeat_password}
                                            size="large"
                                            name="repeat_password"
                                            onChange={onHandleInput}
                                            placeholder="Repeat  password"
                                        />
                                    </div>
                                </div>
                                <div style={{ color: 'white', textAlign: 'left', paddingTop: '20px' }}>
                                    {`Password should contain number, small letter, capital letter and special characters`}
                                    <span
                                        style={{ color: 'lightblue' }}
                                    >{` ! @ # $ % ^ & * ( ) _ + - = { } [ ] | : ; " ' < > , . ? /`}</span>
                                </div>
                            </>
                        )}

                        {codeVerify && (
                            <>
                                <div className="login-form__input">
                                    <span>New password</span>
                                    <div className="input-group">
                                        <Input
                                            type="password"
                                            value={form?.new_password}
                                            size="large"
                                            name="new_password"
                                            onChange={onHandleInput}
                                            placeholder="Enter new password"
                                        />
                                    </div>
                                </div>

                                <div className="login-form__input">
                                    <span>Repeat passwords</span>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            value={form?.repeat_password}
                                            size="large"
                                            name="repeat_password"
                                            onChange={onHandleInput}
                                            placeholder="Repeat  password"
                                        />
                                    </div>
                                </div>
                                <div style={{ color: 'white', textAlign: 'left', paddingTop: '20px' }}>
                                    {`Password should contain number, small letter, capital letter and special characters`}
                                    <span
                                        style={{ color: 'lightblue' }}
                                    >{` ! @ # $ % ^ & * ( ) _ + - = { } [ ] | : ; " ' < > , . ? /`}</span>
                                </div>
                            </>
                        )}

                        <div className="login-btn">
                            {!codeVerify && (
                                <>
                                    <Button
                                        style={{ background: '#000', color: '#fff', fontWeight: '600' }}
                                        size="large"
                                        type="primary"
                                        loading={loading}
                                        onClick={() => onSubmitChangePassword()}
                                        block
                                    >
                                        {!receivedData ? 'Submit' : receivedData && step === 1 ? 'Send' : 'Resend'}
                                    </Button>
                                    <div style={{ height: '20px' }}></div>
                                    {step === 2 && verifyCodeSent && (
                                        <Button
                                            style={{ background: '#000', color: '#fff', fontWeight: '600' }}
                                            size="large"
                                            danger
                                            loading={loading && verifyCodeSent ? true : false}
                                            onClick={() => onSubmitChangePassword({ lastStep: 3 })}
                                            block
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </>
                            )}
                            {codeVerify && (
                                <>
                                    <Button
                                        style={{ background: '#000', color: '#fff', fontWeight: '600' }}
                                        size="large"
                                        type="primary"
                                        loading={loading}
                                        onClick={() => onSubmitChangePassword({ lastStep: 4 })}
                                        block
                                    >
                                        Submit
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatePassword;
