
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    SAVE_NEW_PRODUCT_FAIL, 
    SAVE_NEW_PRODUCT_START, 
    SAVE_NEW_PRODUCT_SUCCESS,
    CLEAR_SAVE_NEW_PRODUCT
} from "../../../constants/actionType/shop"

 export const saveNewProductData = (data: any) => ({type:  SAVE_NEW_PRODUCT_START, data});
 
 export const saveNewProductSuccess = (data:any) => ({
   type: SAVE_NEW_PRODUCT_SUCCESS,
   payload: data
 })
 
 export const saveNewProductError = (error:any) => ({
   type: SAVE_NEW_PRODUCT_FAIL,
   payload: error
 })

 export const clearSaveProductData = () => ({
    type: CLEAR_SAVE_NEW_PRODUCT,
 })