/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GENERATE_BULK_TRANSACTION_FILE_SUCCESS,
    GENERATE_BULK_TRANSACTION_FILE_FAIL,
    GENERATE_BULK_TRANSACTION_FILE_START 
} from "../../../../constants/actionType/bulkTransaction";
import { saveData } from '../../../../helpers';
import { toast } from "react-toastify";


interface IData {
    message: string
}

export interface ResponseGenerator{
    message: string
    data: IData
}

function* generateBulkSaving(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'create-bulk-transaction', data?.data);
        toast(`${userData?.message || userData?.data?.message || ''}`)
        yield put({type:GENERATE_BULK_TRANSACTION_FILE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GENERATE_BULK_TRANSACTION_FILE_FAIL, error: e.message });
    }
}

export default function* fetchGeneratedBulkTransaction() {
    yield takeEvery(GENERATE_BULK_TRANSACTION_FILE_START, generateBulkSaving)
}