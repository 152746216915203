import {
   REQUEST_VIDEO_VISITS_CLEAR,
   REQUEST_VIDEO_VISITS_FAIL,
   REQUEST_VIDEO_VISITS_START,
   REQUEST_VIDEO_VISITS_SUCCESS,
} from '../../../constants/actionType/videoMeeting';

interface IResponseData {
    data: any;
    error: any;
}

interface Payload {
    date: string
}

export const videoVisitsData = (data: Payload) => ({ type:REQUEST_VIDEO_VISITS_START, data});

export const videoVisitsSuccess = (data: IResponseData) => ({
    type:REQUEST_VIDEO_VISITS_SUCCESS,
    payload: data,
});

export const videoVisitsFail = (error: IResponseData) => ({
    type:REQUEST_VIDEO_VISITS_FAIL,
    payload: error,
});


export const clearVideoVisits = () =>  ({
    type:REQUEST_VIDEO_VISITS_CLEAR,
});