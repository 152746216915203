/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    APPROVE_REJECT_REQUEST_SUCCESS, 
    APPROVE_REJECT_REQUEST_FAIL, 
    APPROVE_REJECT_REQUEST_START } from "../../../constants/actionType/appointment";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}

function* approveRejectAppointment(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/reject-approve-appointment', data?.data);
        yield put({type: APPROVE_REJECT_REQUEST_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: APPROVE_REJECT_REQUEST_FAIL, error: e.message });
    }
}

export default function* getApproveRejectAppointment() {
    yield takeEvery(APPROVE_REJECT_REQUEST_START, approveRejectAppointment)
}