import { 
    REQUEST_APPOINTMENT_START, 
    REQUEST_APPOINTMENT_SUCCESS, 
    REQUEST_APPOINTMENT_FAIL ,
    REQUEST_APPOINTMENT_CLEAR
} from "../../../constants/actionType/appointment";


interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const requestAppointment = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_APPOINTMENT_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_APPOINTMENT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case REQUEST_APPOINTMENT_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }
        default: {
            return state;
          }
    }
}

export default requestAppointment;