/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    DrawerProps,
    RadioChangeEvent,
    Table,
    Input,
    Button,
    Flex,
    Form,
    Divider,
    TabsProps,
    Tabs,
    Space,
    Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { SearchOutlined, RedoOutlined, LeftOutlined } from '@ant-design/icons';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { createDeliveryNoteFileData, deliveryItemsData, getOrderFileData } from '../../../redux/actions';
import './index.css';
import noDataToShow from '../../../assets/images/no-data-show.svg';
import OderDoneList from './ordersDone';
import CustomDrawer from '../../common/drawer';
import { ColumnsType } from 'antd/es/table';
import FormModal from '../../common/modal';
import ShopPurchaseOrderList from '../PurchaseOderList';
import NewDeliveryNote from './NewDeliveryNote';
import DeliveryNoteList from './DeliveryNoteList/index';

const emptyData: unknown = noDataToShow;
const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IOderDetails {
    id: string;
    price: string;
    picture: string;
    article: string;
    quantity: string;
    currency: string;
}

interface DataType {
    id: string;
    firstName: string;
    lastName: string;
    number: number;
    order_products: [IOderDetails];
    rows: any;
    price: string;
    requestedQuantity: number;
    currency: string;
    totalPrice: number;
    article: string;
    unitPrice: number;
    productId: string;
    ordersFileId: string;
    quantity: number;
}

export const filterOrderFile = {
    file_name: 'all',
    order_file_type: 'all',
};

const OrderFileTypes = [
    {
        value: 3,
        label: 'All',
    },
    {
        value: 1,
        label: 'Shop order files',
    },
    {
        value: 2,
        label: 'Food order files',
    },
];

const OrdersList = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [displayNextPage, setDisplayNextPage] = useState<any>('all-orders');
    const { getOrderFileDetails, deliveryNote } = userSelector((user) => user);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [deliveryNoteFile, setDeliveryNoteFile]: any = useState([]);
    const [filterType, setFilterType] = useState<string>();
    const [openCreateDeliveryModal, setOpenCreateDeliveryModal] = useState(false);
    const [deliveryItems, setDeliveryItems] = useState<any>({
        product_id: '',
        item: '',
        delivery_quantity: '',
        total_price: '',
        requested_quantity: '',
        total_quantity: '',
    });
    const [placement, setPlacement]: any = useState<DrawerProps['placement']>('right');

    const createDeliveryNote = () => {
        setOpen(false);
        dispatch(createDeliveryNoteFileData({ order_file: '', close: false }));
    };

    const onHandleFilterType = (value: string) => {
        setFilterType(value);
    };

    const onHandleCloseDeliveryNode = () => setOpenCreateDeliveryModal(false);
    const onHandleOpenDeliveryNode = () => setOpenCreateDeliveryModal(true);

    useEffect(() => {
        const deliveryNoteFile = deliveryNote?.data?.data !== undefined ? deliveryNote?.data?.data?.details : [];
        setDeliveryNoteFile(deliveryNoteFile);
    }, [deliveryNote]);

    const columns: ColumnsType<DataType> =
        deliveryNoteFile?.length !== 0 && ['closed'].includes(deliveryNoteFile[0]?.status) === false
            ? [
                  {
                      title: 'Item',
                      dataIndex: 'article',
                      key: 'article',
                  },
                  {
                      title: 'Unit price',
                      dataIndex: 'unitPrice',
                      key: 'unitPrice',
                  },
                  {
                      title: 'Quantity',
                      dataIndex: 'requestedQuantity',
                      key: 'requestedQuantity',
                  },
                  {
                      title: 'Total price',
                      dataIndex: 'totalPrice',
                      key: 'totalPrice',
                  },

                  {
                      title: 'Action',
                      key: 'action',
                      render: (_, data: any) => {
                          return (
                              <a
                                  onClick={() => {
                                      setOpenModal(true);
                                      setDeliveryItems({
                                          product_id: data?.productId,
                                          item: data?.article,
                                          unit_price: data?.unitPrice,
                                          delivery_quantity: '',
                                          total_price: '',
                                          requested_quantity: data?.requestedQuantity,
                                      });
                                  }}
                              >
                                  Delivery
                              </a>
                          );
                      },
                  },
              ]
            : [
                  {
                      title: 'Item',
                      dataIndex: 'article',
                      key: 'article',
                  },
                  {
                      title: 'Unit price',
                      dataIndex: 'unitPrice',
                      key: 'unitPrice',
                  },
                  {
                      title: 'Quantity',
                      dataIndex: 'requestedQuantity',
                      key: 'requestedQuantity',
                  },
                  {
                      title: 'Total price',
                      dataIndex: 'totalPrice',
                      key: 'totalPrice',
                  },
              ];

    const orderFileDetails =
        getOrderFileDetails?.data?.data !== undefined || getOrderFileDetails?.data?.data !== undefined
            ? getOrderFileDetails?.data?.data
            : [];

    const data: DataType[] = orderFileDetails?.map((item: any) => {
        return {
            productId: item?.item_id,
            article: `${item?.item}`,
            unitPrice: `${item?.unit_price} ${item?.currency}`,
            requestedQuantity: `${item?.requested_quantity}`,
            totalPrice: item?.total_amount,
        };
    });

    useEffect(() => {
        dispatch(getOrderFileData(filterOrderFile));
    }, [dispatch, getOrderFileDetails?.data]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onChange = (e: RadioChangeEvent) => {
        setPlacement(e.target.value);
    };

    const onClose: any = () => {
        setOpen(false);
    };

    const hideModal = () => {
        setOpenModal(false);
    };

    const refreshTable = () => dispatch(getOrderFileData(filterOrderFile));

    const onChangeProductInputForm = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...deliveryItems };
        list[name] = value;
        setDeliveryItems(list);
    };

    const onDeliverItem = () => {
        const productItems = {
            delivery_note_file: deliveryNoteFile[0]?.id,
            product_id: deliveryItems?.product_id,
            item: deliveryItems?.item,
            delivery_quantity: deliveryItems?.delivery_quantity,
            requested_quantity: deliveryItems?.requested_quantity,
            total_price: parseFloat(deliveryItems?.delivery_quantity) * parseFloat(deliveryItems?.unit_price),
        };

        dispatch(deliveryItemsData(productItems));
        setOpenModal(false);
    };

    const onHandleSearch = () => {
        const formData = form.getFieldsValue();
        dispatch(dispatch(getOrderFileData(formData)));
    };

    const OperationsSlot = (
        <Flex gap="middle">
            <Form form={form} onFinish={onHandleSearch} layout="horizontal">
                <Space.Compact>
                    <Form.Item
                        name="order_file_type"
                        noStyle
                        rules={[{ required: true, message: 'Order file type is required' }]}
                    >
                        <Select
                            showSearch
                            style={{ width: '20vh', textAlign: 'left' }}
                            placeholder="Select a purchase or file type"
                            optionFilterProp="label"
                            size="middle"
                            onChange={onHandleFilterType}
                            defaultValue={filterType}
                            options={OrderFileTypes}
                        />
                    </Form.Item>
                    <Form.Item
                        name="order_file_type"
                        noStyle
                        rules={[{ required: true, message: 'Order file type is required' }]}
                    >
                        <Button ghost htmlType="submit" type="primary" icon={<SearchOutlined />} />
                    </Form.Item>
                    <Form.Item
                        name="order_file_type"
                        noStyle
                        rules={[{ required: true, message: 'Order file type is required' }]}
                    >
                        <Button ghost type="primary" onClick={refreshTable} icon={<RedoOutlined />} />
                    </Form.Item>
                </Space.Compact>
            </Form>
            <Button ghost type="primary" onClick={onHandleOpenDeliveryNode}>
                New Delivery Note
            </Button>
        </Flex>
    );

    const tabItems: TabsProps['items'] = [
        {
            label: 'Purchase Orders',
            key: '2',
            children: <ShopPurchaseOrderList />,
        },
        {
            label: 'Delivery Notes',
            key: '3',
            children: <DeliveryNoteList />,
        },
    ];

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    {displayNextPage === 'all-orders' ? (
                        <Flex style={{ width: '100%', padding: '10px' }} vertical>
                            <Tabs defaultActiveKey="1" tabBarExtraContent={OperationsSlot} items={tabItems} />
                        </Flex>
                    ) : null}
                    {displayNextPage === 'orders-done' ? (
                        <>
                            <Flex align="center" style={{ padding: '10px' }}>
                                <Button
                                    danger
                                    type="primary"
                                    icon={<LeftOutlined />}
                                    ghost
                                    onClick={() => setDisplayNextPage('all-orders')}
                                >
                                    Back To Order Files
                                </Button>
                            </Flex>
                            {data?.length === 0 ? (
                                <div className="empty-data">
                                    <img src={`${emptyData}`} />
                                    <span>No product found</span>
                                </div>
                            ) : (
                                <OderDoneList orderFile={''} />
                            )}
                        </>
                    ) : null}

                    <CustomDrawer
                        placement={placement}
                        onChange={onChange}
                        showDrawer={showDrawer}
                        onClose={onClose}
                        open={open}
                        onSubmit={undefined}
                        category={'details'}
                        title={'Purchase order'}
                        width={720}
                    >
                        <Flex align="center" justify="flex-end" style={{ width: '100%', padding: '10px' }}>
                            {deliveryNote?.data?.data?.check_status !== undefined ? (
                                <>
                                    {['closed'].includes(deliveryNote?.data?.data?.check_status?.status) === true ||
                                    deliveryNote?.data?.data?.check_status?.status === undefined ? (
                                        <Button ghost type="primary" onClick={() => createDeliveryNote()}>
                                            Open a New Delivery Note
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}
                        </Flex>
                        <Table
                            bordered
                            size="small"
                            columns={columns}
                            dataSource={data}
                            pagination={data?.length > 14 ? undefined : false}
                        />
                    </CustomDrawer>
                </div>
            </div>
            <FormModal
                title={'Deliver item'}
                open={openModal}
                hideModal={hideModal}
                loading={undefined}
                register={onDeliverItem}
                btn="Save"
            >
                <Form layout="vertical">
                    <Form.Item style={{ margin: '4px' }} label="Item">
                        <Input
                            size="large"
                            placeholder="Item"
                            value={deliveryItems?.item}
                            name="item"
                            disabled={true}
                            style={{ color: '#333' }}
                            onChange={(event) => onChangeProductInputForm(event)}
                        />
                    </Form.Item>
                    <Form.Item style={{ margin: '4px' }} label="Price">
                        <Input
                            size="large"
                            placeholder="Unit price"
                            value={deliveryItems?.unit_price}
                            name="unit_price"
                            disabled={true}
                            style={{ color: '#333' }}
                            onChange={(event) => onChangeProductInputForm(event)}
                        />
                    </Form.Item>

                    <Form.Item style={{ margin: '4px' }} label="Requested Quantity">
                        <Input
                            size="large"
                            placeholder="Requested quantity"
                            value={deliveryItems?.requested_quantity}
                            name="requested_quantity"
                            disabled={true}
                            style={{ color: '#333' }}
                            onChange={(event) => onChangeProductInputForm(event)}
                        />
                    </Form.Item>

                    <Form.Item style={{ margin: '4px' }} label="Enter Delivery Quantity">
                        <Input
                            size="large"
                            placeholder="Enter requested quantity"
                            value={deliveryItems?.delivery_quantity}
                            name="delivery_quantity"
                            style={{ color: '#333' }}
                            onChange={(event) => onChangeProductInputForm(event)}
                        />
                    </Form.Item>
                    <Divider />
                </Form>
            </FormModal>
            <NewDeliveryNote open={openCreateDeliveryModal} onCloseModal={onHandleCloseDeliveryNode} />
        </>
    );
};

export default OrdersList;
