/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_COMMENT_SUCCESS,
    GET_COMMENT_FAIL,
    GET_COMMENT_START 
} from "../../../constants/actionType/orders";
import { fetchData} from '../../../helpers';
import { delay } from '../../../constants/time';


export interface ResponseGenerator{}

function* getCommentDone() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'orders/get-comment');
        yield call(delay, 300)
        yield put({type:GET_COMMENT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_COMMENT_FAIL, error: e.message });
    }
}

export default function* fetchGetComment() {
    yield takeEvery(GET_COMMENT_START, getCommentDone)
}