import {
    REQUEST_BULK_SAVING_FILE_FAIL, 
    REQUEST_BULK_SAVING_FILE_START, 
    REQUEST_BULK_SAVING_FILE_SUCCESS 
} from "../../../../constants/actionType/bulkSaving";
import { IFileData } from "../../../../interfaces/bulkSaving";


interface IData {
    data: IFileData[]
}

interface IError {
    data: string,
}


 export const retrieveBulkSavingFileData = () => ({type:  REQUEST_BULK_SAVING_FILE_START});
 
 export const retrieveBulkSavingFileSuccess = (data: IData) => ({
   type: REQUEST_BULK_SAVING_FILE_SUCCESS,
   payload: data
 })
 
 export const retrieveBulkSavingFileError = (error:IError) => ({
   type: REQUEST_BULK_SAVING_FILE_FAIL,
   payload: error
 })