/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { LOGOUT_USER_SUCCESS, LOGOUT_USER_FAIL, LOGOUT_USER_START } from "../../constants/actionType/auth";
import { fetchData } from '../../helpers';


export interface ResponseGenerator{}

function* logoutUser() {
    try {
        const userData: ResponseGenerator = yield call( fetchData,'user/logout');
        yield put({type: LOGOUT_USER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: LOGOUT_USER_FAIL, error: e.message });
    }
}

export default function* getLogoutUser() {
    yield takeEvery(LOGOUT_USER_START, logoutUser)
}