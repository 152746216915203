/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    CREATE_DELIVERY_NOTE_FILE_SUCCESS,
    CREATE_DELIVERY_NOTE_FILE_FAIL,
    CREATE_DELIVERY_NOTE_FILE_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { toast } from "react-toastify";


export interface ResponseGenerator{
    message: any;
}

function* createDeliveryNoteFile(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'orders/open-delivery-note', data?.data);
        toast(`${userData?.message}`)
        yield put({type:CREATE_DELIVERY_NOTE_FILE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:CREATE_DELIVERY_NOTE_FILE_FAIL, error: e.message });
    }
}

export default function* fetchCreateDeliveryNoteFile() {
    yield takeEvery(CREATE_DELIVERY_NOTE_FILE_START, createDeliveryNoteFile)
}