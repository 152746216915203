import { call, put, takeEvery } from "redux-saga/effects";
import { 
    SAVE_DEVISE_SIGNATURE_START, 
    SAVE_DEVISE_SIGNATURE_SUCCESS, 
    SAVE_DEVISE_SIGNATURE_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import {  saveData } from "../../../helpers";




export interface ResponseGenerator{
    message: string
}



function* saveDeviceSignature(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/save-device-signature', data?.data);
        yield put({type: SAVE_DEVISE_SIGNATURE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: SAVE_DEVISE_SIGNATURE_FAIL, error: e.message });
    }
}

export default function* fetchSaveDeviceSignature() {
    yield takeEvery(SAVE_DEVISE_SIGNATURE_START, saveDeviceSignature)
}