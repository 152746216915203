/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_USER_ROLES_FAIL, 
    GET_USER_ROLES_START, 
    GET_USER_ROLES_SUCCESS 
} from "../../constants/actionType/roles";
import { fetchData } from "../../helpers";

interface ResponseGenerator{}

function* getUserRoles() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, `v1/role/get-roles`);
        yield put({type: GET_USER_ROLES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_USER_ROLES_FAIL, error: e.message });
    }
}

export default function* fetchUserRoles() {
    yield takeEvery(GET_USER_ROLES_START, getUserRoles)
}