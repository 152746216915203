/* eslint-disable @typescript-eslint/no-explicit-any */
import { 
    OUT_GOING_MONEY_START, 
    OUT_GOING_MONEY_SUCCESS, 
    OUT_GOING_MONEY_FAIL,
    CLEAR_OUT_GOING_MONEY
} from "../../../constants/actionType/sendMoney";


interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const outGoingMoney = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case OUT_GOING_MONEY_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case OUT_GOING_MONEY_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case OUT_GOING_MONEY_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case CLEAR_OUT_GOING_MONEY: {
            return {
                ...state,
                loading: false,
                error:null,
                data: null
            };
        }
        
        default: {
            return state;
          }
    }
}

export default outGoingMoney;