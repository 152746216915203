/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Image, List, Modal, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { token } from '../../../../middleware/authMiddleware';

const AppointmentDetailsModal = ({
    visitDetails,
    open,
    onClose,
    visitorItems,
}: {
    visitDetails: any;
    open: boolean;
    onClose: () => void;
    visitorItems: any;
}) => {
    const readDocument = process.env.REACT_APP_API_READ_ATTACHED_FILE;
    const [document, setDocument] = useState<string>('');
    const [imageSrc, setImageSrc] = useState('');
    const data = [
        {
            title: 'RSC case number',
            description: visitDetails?.inmateCode,
            isAttachedDoc: false,
        },
        {
            title: 'Visitor Name',
            description: visitDetails?.visitorFullName,
            isAttachedDoc: false,
        },
        {
            title: 'Document attached',
            description: visitDetails?.otherDocument,
            isAttachedDoc: visitDetails?.otherDocument ? true : false,
        },
    ];

    const columns = [
        {
            title: 'Item',
            dataIndex: 'items',
            key: 'items',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ];

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const formattedDocumentName = visitDetails?.otherDocument.replace(/ /g, '_');

                const response = await axios.get(
                    `${readDocument}${visitDetails.visitorPassport}/${formattedDocumentName}`,
                    {
                        responseType: 'blob',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );

                const imageUrl = URL.createObjectURL(response.data);
                setImageSrc(imageUrl);
            } catch (error) {
                console.error('Error fetching the image', error);
            }
        };

        if (document) {
            fetchImage();
        }
    }, [document]);

    return (
        <Modal
            title={visitDetails?.inmateFullName}
            open={open}
            onCancel={() => {
                onClose();
                setDocument('');
                setImageSrc('');
            }}
            footer={[]}
        >
            <List
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                            <span>{item?.title}</span>
                            <Flex align="center" gap="small">
                                <span className="bold-text">{item?.description}</span>
                                {item?.isAttachedDoc && (
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={() => setDocument(visitDetails?.otherDocument)}
                                    >
                                        View
                                    </Button>
                                )}
                            </Flex>
                        </Flex>
                    </List.Item>
                )}
            />
            <div>{imageSrc && <Image src={imageSrc} alt="Protected" className="fixed-size-image" />}</div>
            {visitorItems && visitorItems?.length ? (
                <Table columns={columns} dataSource={visitorItems} pagination={false} />
            ) : null}
        </Modal>
    );
};

export default AppointmentDetailsModal;
