/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GENERATE_BULK_SAVING_FILE_FAIL, 
    GENERATE_BULK_SAVING_FILE_START, 
    GENERATE_BULK_SAVING_FILE_SUCCESS 
} from "../../../../constants/actionType/bulkSaving";


 export const createBulkSavingData = (data: undefined) => ({type:  GENERATE_BULK_SAVING_FILE_START, data});
 
 export const createBulkSavingSuccess = (data:any) => ({
   type: GENERATE_BULK_SAVING_FILE_SUCCESS,
   payload: data
 })
 
 export const createBulkSavingError = (error:any) => ({
   type: GENERATE_BULK_SAVING_FILE_FAIL,
   payload: error
 })