import {
    GET_BOOKING_REPORT_FAIL, 
    GET_BOOKING_REPORT_START, 
    GET_BOOKING_REPORT_SUCCESS 
    } from "../../../constants/actionType/appointment";
    
    
export interface IBookingReportData {
    data: any,
    error: any
}

export const getBookingReportData = (data:any) =>  ({type: GET_BOOKING_REPORT_START, data});

export const getBookingReportsSuccess = (data: IBookingReportData) => ({
    type: GET_BOOKING_REPORT_SUCCESS, 
    payload: data
})

export const getBookingReportsFail = (error: IBookingReportData) => ({ type: GET_BOOKING_REPORT_FAIL, payload: error})
