import { Button, Divider, Flex, Form, Input, Modal, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearManageZonesStore, fetchCellsData, manageZonesData } from '../../../redux/actions';

import { IUserSelectorType } from '../../../interfaces';
import { fetchCellsPayload } from '../OrderFileUpload';

interface IPayload {
    add_zone: boolean;
    add_cell: boolean;
    add_village: boolean;
    zone_name: string;
    cell_name: string;
    zone_id: number | string;
    cell_id: number | string;
    village_name: string;
}

const inputStyle = {
    marginBottom: '4px',
};

const useTypedSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const ManageZonesModal: React.FC<{ open: boolean; onHandleClose: () => void }> = ({ open, onHandleClose }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [checkedSwitch, setCheckedSwitch] = useState<number | null>(null);

    const { manageZones, fetchCells } = useTypedSelector((state) => state);

    const zones =
        Array.isArray(fetchCells.data?.data) &&
        fetchCells.data?.data.map((item: { zone_name: string; id: number }) => ({
            label: item.zone_name,
            value: item.id,
        }));

    const handleSwitchChange = (switchIndex: number, checked: boolean) => {
        if (checked) {
            setCheckedSwitch(switchIndex);
        } else {
            setCheckedSwitch(null);
        }
    };

    const onCreateOrderFile = () => {
        const formData = form.getFieldsValue();
        const newPayload: IPayload = {
            add_zone: checkedSwitch === 0 ? true : false,
            add_cell: checkedSwitch === 1 ? true : false,
            add_village: false,
            zone_name: formData.zone_name,
            cell_name: formData.cell_name,
            zone_id: formData.zone_id,
            cell_id: '',
            village_name: '',
        };
        dispatch(manageZonesData(newPayload));
    };

    const onHandleCloseModal = () => {
        onHandleClose();
        dispatch(clearManageZonesStore());
        form.resetFields();
    };

    useEffect(() => {
        if (manageZones.data?.success) {
            toast.success(manageZones.message);
            onHandleCloseModal();
        }
        if (manageZones.error) {
            toast.error(manageZones.message);
        }
    }, [manageZones?.data, manageZones.error]);

    useEffect(() => {
        if (open) {
            dispatch(fetchCellsData({ ...fetchCellsPayload, retrieve_zones: true }));
        }
    }, [open]);

    return (
        <Modal title={'New order file'} open={open} onCancel={onHandleCloseModal} footer={[]}>
            <Flex gap="middle" vertical>
                <Divider style={{ margin: '4px' }} />
                <Flex align="center" justify="space-between">
                    <span>Add Zone</span>
                    <Switch checked={checkedSwitch === 0} onChange={(checked) => handleSwitchChange(0, checked)} />
                </Flex>
                <Divider style={{ margin: '2px' }} />
                <Flex align="center" justify="space-between">
                    <span>Add Cell</span>
                    <Switch checked={checkedSwitch === 1} onChange={(checked) => handleSwitchChange(1, checked)} />
                </Flex>
                <Divider style={{ margin: '2px' }} />
                <Form form={form} layout="vertical" onFinish={onCreateOrderFile}>
                    {checkedSwitch === 0 && (
                        <Form.Item name="zone_name" label="Zone Name" style={inputStyle}>
                            <Input placeholder="Zone Name" size="large" />
                        </Form.Item>
                    )}
                    {checkedSwitch === 1 && (
                        <>
                            <Form.Item name="zone_id" label="Select Zone" style={inputStyle}>
                                <Select
                                    loading={fetchCells.loading}
                                    placeholder="Zone"
                                    size="large"
                                    options={zones && zones}
                                />
                            </Form.Item>
                            <Form.Item name="cell_name" label="Cell Name" style={inputStyle}>
                                <Input placeholder="Cell Name" size="large" />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item style={{ marginBottom: '0px' }}>
                        <Flex gap="middle" justify="flex-end" style={{ marginTop: '20px' }}>
                            <Button htmlType="submit" loading={manageZones.loading} type="primary" ghost>
                                Save
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Flex>
        </Modal>
    );
};

export default ManageZonesModal;
