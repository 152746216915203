import React, { useState } from 'react';

import ComprehensiveReport from './reports/comprehensive';
import FrequencyReport from './reports/frequency';
import NotVisitedYetReport from './reports/notVisitedYet';
import VisitorsComprehensiveReport from './reports/visitorsComprehensive';
import LawyersVisitReport from './reports/lawyers';

const AppointmentReport: React.FC<any> = () => {
    const [comprehensiveReport, setComprehensiveReport] = useState(true);
    const [frequencyReport, setFrequencyReport] = useState(false);
    const [notVisitedYet, setNotVisitedYet] = useState(false);
    const [visitorsComprehensiveReport, setVisitorsComprehensiveReport] = useState(false);
    const [lawyersVisitReport, setLawyersVisitReport] = useState(false);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="tab-box">
                    <div
                        onClick={() => {
                            setComprehensiveReport(true);
                            setFrequencyReport(false);
                            setNotVisitedYet(false);
                            setVisitorsComprehensiveReport(false);
                            setLawyersVisitReport(false);
                        }}
                        className={comprehensiveReport ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Booking comprehensive report</span>
                    </div>
                    <div
                        onClick={() => {
                            setFrequencyReport(true);
                            setComprehensiveReport(false);
                            setNotVisitedYet(false);
                            setVisitorsComprehensiveReport(false);
                            setLawyersVisitReport(false);
                        }}
                        className={frequencyReport ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Frequency report</span>
                    </div>
                    <div
                        onClick={() => {
                            setFrequencyReport(false);
                            setComprehensiveReport(false);
                            setNotVisitedYet(true);
                            setVisitorsComprehensiveReport(false);
                            setLawyersVisitReport(false);
                        }}
                        className={notVisitedYet ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Inmates with zero visit</span>
                    </div>
                    <div
                        onClick={() => {
                            setFrequencyReport(false);
                            setComprehensiveReport(false);
                            setNotVisitedYet(false);
                            setVisitorsComprehensiveReport(true);
                            setLawyersVisitReport(false);
                        }}
                        className={visitorsComprehensiveReport ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Visitors comprehensive report</span>
                    </div>
                    <div
                        onClick={() => {
                            setFrequencyReport(false);
                            setComprehensiveReport(false);
                            setNotVisitedYet(false);
                            setVisitorsComprehensiveReport(false);
                            setLawyersVisitReport(true);
                        }}
                        className={lawyersVisitReport ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Lawyers booking report</span>
                    </div>
                </div>
                {/* </div> */}
                {comprehensiveReport === true ? <ComprehensiveReport /> : null}
                {frequencyReport === true ? <FrequencyReport /> : null}
                {notVisitedYet === true ? <NotVisitedYetReport /> : null}
                {visitorsComprehensiveReport === true ? <VisitorsComprehensiveReport /> : null}
                {lawyersVisitReport === true ? <LawyersVisitReport /> : null}
            </div>
        </>
    );
};

export default AppointmentReport;
