/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Modal, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import { IUserSelectorType } from '../../../interfaces';
import { closeDeliveryNoteFileData, deliveryNoteData } from '../../../redux/actions';
import moment from 'moment';
import { getDeliveryNoteItemsData } from '../../../redux/actions/orders/getDeliveryNoteItems';
import NoResultFound from '../../common/noResultFound';

interface IOderDetails {
    id: string;
    price: string;
    picture: string;
    article: string;
    quantity: string;
    currency: string;
    shop_product: string;
}

const today: any = new Date().toISOString().slice(0, 10);

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const OderDoneList: React.FC<any> = ({ orderFile }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { deliveryNote, getDeliveryNoteItems, closeDeliveryNoteFile }: any = userSelector((user) => user);

    useEffect(() => {
        dispatch(deliveryNoteData({ ordersFileId: orderFile, check_status: false }));
    }, [dispatch]);

    const columns: ColumnsType<IOderDetails> = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, status: any) => {
                return (
                    <Tag color={status?.status === 'opened' ? 'blue' : 'volcano'}>{`${
                        status?.status === 'opened' ? 'In progress' : 'Closed'
                    }`}</Tag>
                );
            },
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, data: any) => {
                return (
                    <Flex gap="middle">
                        <Button
                            ghost
                            type="primary"
                            onClick={() => {
                                setOpen(true);
                                dispatch(getDeliveryNoteItemsData({ delivery_note_Id: data?.id }));
                            }}
                        >
                            Preview
                        </Button>
                        <Button
                            ghost
                            danger
                            type="primary"
                            loading={closeDeliveryNoteFile.loading}
                            onClick={() => {
                                dispatch(
                                    closeDeliveryNoteFileData({
                                        file_id: data?.id,
                                    }),
                                );
                            }}
                        >
                            Close
                        </Button>
                    </Flex>
                );
            },
        },
    ];

    const columns1: ColumnsType<IOderDetails> = [
        {
            title: 'Items',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Quantities Requested',
            dataIndex: 'requested_quantity',
            key: 'requested_quantity',
        },
        {
            title: 'Quantities Delivered',
            dataIndex: 'delivery_quantity',
            key: 'delivery_quantity',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
    ];

    const deliveryNoteItemsData =
        getDeliveryNoteItems?.data?.data !== undefined ? getDeliveryNoteItems?.data?.data : [];

    const newItemsData: IOderDetails[] = deliveryNoteItemsData?.details?.map(
        (
            item: {
                item: string;
                unit_price: number;
                currency: string;
                delivery_quantity: number;
                requested_quantity: number;
                total_price: number;
            },
            index: number,
        ) => {
            return {
                key: index,
                item: item?.item,
                unit_price: `${item?.unit_price} ${item?.currency}`,
                delivery_quantity: item?.delivery_quantity,
                total_price: `${item?.total_price}  ${item?.currency}`,
                requested_quantity: item.requested_quantity,
            };
        },
    );

    const newData: IOderDetails[] = deliveryNote?.data?.data !== undefined ? deliveryNote?.data?.data?.details : [];
    const newDataCol: any = newData.map((item: any) => {
        return {
            id: item?.id,
            file_name: item?.file_name,
            status: item.status,
            created_at: moment(item.created_at).format('DD-MMM-YYYY'),
        };
    });

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {newData?.length === 0 ? (
                <NoResultFound />
            ) : (
                <>
                    {newData === undefined ? (
                        <div style={{ margin: '20% auto', height: '800px' }}>
                            <Spin
                                style={{ color: ' #282936' }}
                                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                            />
                        </div>
                    ) : (
                        <Table
                            loading={deliveryNote?.loading}
                            size="small"
                            columns={columns}
                            dataSource={newDataCol}
                            pagination={newData.length > 14 ? undefined : false}
                        />
                    )}
                </>
            )}

            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
                footer={
                    <Flex justify="flex-end" gap="middle">
                        <Button type="primary" onClick={handlePrint}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                        <Button ghost danger type="primary">
                            Close
                        </Button>
                    </Flex>
                }
            >
                <Flex vertical style={{ width: '100%' }}>
                    <Flex ref={componentRef} gap="middle" vertical>
                        <Flex justify="flex-start" vertical>
                            <span>
                                <b>GOODS DELIVERY NOTE</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {deliveryNoteItemsData?.facility}
                            </span>
                            <span>
                                <b>Deliver note number: </b>
                                {deliveryNoteItemsData?.delivery_file}
                            </span>
                            <span>
                                <b>Cell: </b>
                                {deliveryNoteItemsData.cell_name}
                            </span>
                            <span>
                                <b>Delivered on: </b>
                                {moment(deliveryNoteItemsData?.date).format('DD-MMM-YYYY')}
                            </span>
                        </Flex>
                        <Table
                            bordered
                            size="small"
                            columns={columns1}
                            dataSource={newItemsData}
                            pagination={newItemsData?.length >= 10 ? undefined : false}
                        />
                        <Flex vertical>
                            <span>
                                <b>Delivered by: </b>
                                {deliveryNoteItemsData?.delivered_by}
                            </span>
                            <span>
                                <b>Date: </b>
                                {today}
                            </span>
                        </Flex>
                    </Flex>
                </Flex>
            </Modal>
        </>
    );
};

export default OderDoneList;
