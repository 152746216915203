import {
    GET_PUBLIC_ITEMS_FAIL, 
    GET_PUBLIC_ITEMS_START, 
    GET_PUBLIC_ITEMS_SUCCESS 
    } from "../../../constants/actionType/publicItems";
    
    
export interface IPublicItems {
    data: any,
    error: any
}

export const getPublicItemsData = () =>  ({type: GET_PUBLIC_ITEMS_START});

export const getPublicItemsSuccess = (data: IPublicItems) => ({
    type: GET_PUBLIC_ITEMS_SUCCESS, 
    payload: data
})

export const getPublicItemsFail = (error: IPublicItems) => ({ type: GET_PUBLIC_ITEMS_FAIL, payload: error})