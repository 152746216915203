export const REQUEST_PHONE_NUMBER_LINK_START = "REQUEST_PHONE_NUMBER_LINK_START";
export const REQUEST_PHONE_NUMBER_LINK_SUCCESS = "REQUEST_PHONE_NUMBER_LINK_SUCCESS";
export const REQUEST_PHONE_NUMBER_LINK_FAIL =  "REQUEST_PHONE_NUMBER_LINK_FAIL";
export const REQUEST_PHONE_NUMBER_LINK_CLEAR =  "REQUEST_PHONE_NUMBER_LINK_CLEAR";

export const REQUEST_INMATE_CALL_HISTORY_START = "REQUEST_INMATE_CALL_HISTORY_START";
export const REQUEST_INMATE_CALL_HISTORY_SUCCESS = "REQUEST_INMATE_CALL_HISTORY_SUCCESS";
export const REQUEST_INMATE_CALL_HISTORY_FAIL =  "REQUEST_INMATE_CALL_HISTORY_FAIL";
export const REQUEST_INMATE_CALL_HISTORY_CLEAR =  "REQUEST_INMATE_CALL_HISTORY_CLEAR";


export const GET_INMATE_PHONE_NUMBER_START = "GET_INMATE_PHONE_NUMBER_START";
export const GET_INMATE_PHONE_NUMBER_SUCCESS = "GET_INMATE_PHONE_NUMBER_SUCCESS";
export const GET_INMATE_PHONE_NUMBER_FAIL =  "GET_INMATE_PHONE_NUMBER_FAIL";


export const LINK_OR_DELETE_PHONE_NUMBER_START = "LINK_OR_DELETE_PHONE_NUMBER_START";
export const LINK_OR_DELETE_PHONE_NUMBER_SUCCESS = "LINK_OR_DELETE_PHONE_NUMBER_SUCCESS";
export const LINK_OR_DELETE_PHONE_NUMBER_FAIL =  "LINK_OR_DELETE_PHONE_NUMBER_FAIL";

export const REQUEST_INMATE_CALLS_START = "REQUEST_INMATE_CALLS_START";
export const REQUEST_INMATE_CALLS_SUCCESS = "REQUEST_INMATE_CALLS_SUCCESS";
export const REQUEST_INMATE_CALLS_FAIL =  "REQUEST_INMATE_CALLS_FAIL";

export const PLAY_AUDIO_START = "PLAY_AUDIO_START";
export const PLAY_AUDIO_SUCCESS = "PLAY_AUDIO_SUCCESS";
export const PLAY_AUDIO_FAIL =  "PLAY_AUDIO_FAIL";


export const REQUEST_CALL_INVOICE_START = "REQUEST_CALL_INVOICE_START";
export const REQUEST_CALL_INVOICE_SUCCESS = "REQUEST_CALL_INVOICE_SUCCESS";
export const REQUEST_CALL_INVOICE_FAIL =  "REQUEST_CALL_INVOICE_FAIL";
export const REQUEST_CALL_INVOICE_CLEAR =  "REQUEST_CALL_INVOICE_CLEAR";