/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Table, Spin, Popconfirm, Typography, Form, InputNumber, Input } from 'antd';
import { TableProps } from 'antd/es/table';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { toast } from 'react-toastify';
import { clearEditOrder, editOrderData, getOrdersDetailsData } from '../../../../redux/actions';
import { getOrderFileDetailsData } from '../../../../redux/actions/orders/getOrderFileDetails';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const EditableCell: React.FC<React.PropsWithChildren<any>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const DetailsOnOrder: React.FC<{ data: any; orderFileId: string | number; loading: boolean; inmate: number }> = ({
    data,
    orderFileId,
    loading,
    inmate,
}) => {
    const dispatch = useDispatch();
    const allData = data?.all_orders ?? [];
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any>([]);
    const [editingKey, setEditingKey] = useState('');
    const { editOrder, getOrderDetails } = userSelector((user) => user);

    const handleDelete = (quantity: number, product_id: number) => {
        const payload = {
            order_product_id: product_id,
            requested_quantity: quantity,
            cancel: true,
        };
        dispatch(editOrderData(payload));
    };

    const isEditing = (record: any) => record.key === editingKey;

    const save = async (_key: React.Key, product_id: number) => {
        try {
            const formData = form.getFieldsValue();
            const payload = {
                order_product_id: product_id,
                requested_quantity: formData?.requested_quantity ?? '',
                cancel: false,
            };
            dispatch(editOrderData(payload));
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const edit = (record: Partial<any> & { key: any }) => {
        form.setFieldsValue({ requested_quantity: '', ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'requested_quantity',
            key: 'requested_quantity',
            editable: true,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return (
                    <Flex gap="middle" align="center" justify="flex-start">
                        {dataSource.length >= 1 ? (
                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => handleDelete(record?.requested_quantity, record?.order_product_id)}
                            >
                                <a>Delete</a>
                            </Popconfirm>
                        ) : null}

                        <>
                            {editable ? (
                                <span>
                                    <Typography.Link
                                        onClick={() => save(record.key, record?.order_product_id)}
                                        style={{ marginRight: 8 }}
                                    >
                                        Save
                                    </Typography.Link>
                                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                        <a>Cancel</a>
                                    </Popconfirm>
                                </span>
                            ) : (
                                <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                    Edit
                                </Typography.Link>
                            )}
                        </>
                    </Flex>
                );
            },
        },
    ];

    const mergedColumns: TableProps['columns'] = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                inputType: col.dataIndex === 'requested_quantity' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    useEffect(() => {
        if (editOrder?.data?.success) {
            toast.success(editOrder?.data.message);
            dispatch(
                getOrdersDetailsData({
                    inmate_id: `${inmate}`,
                    ordersFileId: orderFileId,
                }),
            );
            dispatch(
                getOrderFileDetailsData({
                    ordersFileId: orderFileId,
                }),
            );
            dispatch(clearEditOrder());
            setEditingKey('');
        }

        if (editOrder?.error && !editOrder?.data.success) {
            toast.success(editOrder?.data.message);
        }

        if (getOrderDetails?.data?.all_orders) {
            const allData = getOrderDetails?.data?.all_orders ?? [];
            const filteredData = allData.map((item: any, index: number) => ({
                key: index,
                ...item,
            }));
            setDataSource(filteredData);
        }
    }, [dispatch, editOrder?.data?.success, editOrder?.error, orderFileId, getOrderDetails?.data?.all_orders]);

    useEffect(() => {
        const filteredData = allData.map((item: any, index: number) => ({
            key: index,
            ...item,
        }));
        setDataSource(filteredData);
    }, [allData]);

    return (
        <Flex gap="middle" vertical>
            {!loading ? (
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        dataSource={dataSource}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={
                            dataSource.length > 8
                                ? {
                                      onChange: cancel,
                                      pageSize: 8,
                                  }
                                : false
                        }
                    />
                </Form>
            ) : (
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            )}
        </Flex>
    );
};

export default DetailsOnOrder;
