/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    RETRIEVE_PURCHASE_ORDER_LIST_CLEAR,
    RETRIEVE_PURCHASE_ORDER_LIST_FAIL, 
    RETRIEVE_PURCHASE_ORDER_LIST_START, 
    RETRIEVE_PURCHASE_ORDER_LIST_SUCCESS 
 } from "../../../constants/actionType/orders";


export const retrievePurchaseOrderListData = (data: any) => ({
  type: RETRIEVE_PURCHASE_ORDER_LIST_START,
  data
});

export const retrievePurchaseOrderListSuccess = (data: any) => ({
  type: RETRIEVE_PURCHASE_ORDER_LIST_SUCCESS,
  payload: data
});

export const retrievePurchaseOrderListError = (error: string | any) => ( {
  type: RETRIEVE_PURCHASE_ORDER_LIST_FAIL,
  payload: error
});

export const clearRetrievePurchaseOrderListStore = () => ({
  type: RETRIEVE_PURCHASE_ORDER_LIST_CLEAR
});