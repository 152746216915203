/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  
    LINK_OR_DELETE_PHONE_NUMBER_SUCCESS,  
    LINK_OR_DELETE_PHONE_NUMBER_FAIL,  
    LINK_OR_DELETE_PHONE_NUMBER_START } 
    from "../../../constants/actionType/linkPhoneNumberToInmate";
import {   saveData } from '../../../helpers';
import { toast } from "react-toastify";


export interface ResponseGenerator{
    message: string
}

function* linkOrDeletePhoneNumber(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/audio/link-delete-contact', data?.data);
        toast(`${userData.message}`)
        yield put({type:LINK_OR_DELETE_PHONE_NUMBER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:LINK_OR_DELETE_PHONE_NUMBER_FAIL, error: e.message });
    }
}

export default function* fetchLinkOrDeletePhoneNumber() {
    yield takeEvery(LINK_OR_DELETE_PHONE_NUMBER_START, linkOrDeletePhoneNumber)
}