/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_CALL_INVOICE_FAIL, 
    REQUEST_CALL_INVOICE_START, 
    REQUEST_CALL_INVOICE_SUCCESS,
    REQUEST_CALL_INVOICE_CLEAR
} from "../../../constants/actionType/linkPhoneNumberToInmate";

export const requestCallInvoiceData = () =>  ({type: REQUEST_CALL_INVOICE_START});

export const requestCallInvoiceSuccess = (data: any) => ({
    type: REQUEST_CALL_INVOICE_SUCCESS, 
    payload: data
})

export const requestCallInvoiceFail = (error: any) => ({ type: REQUEST_CALL_INVOICE_FAIL, payload: error})

export const clearRequestCallInvoiceStore = () => ({
    type: REQUEST_CALL_INVOICE_CLEAR
})
