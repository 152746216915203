import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import './assets/style/index.css';
import AuthMiddleware from './middleware/authMiddleware';
import routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';

import { ToastContainer } from 'react-toastify';

import { connect } from './socket/checkVisitId';
import NotFoundComponent from './components/NotFoundComponent';
import { ConfigProvider } from 'antd';

connect();

function App() {
    const [count, setCount] = useState(0);
    useEffect(() => {
        const onBackButtonEvent = (e: any) => {
            e.preventDefault();
            if (window.location.pathname === '/dashboard' || window.location.pathname === '/general-dashboard') {
                setCount((prev) => prev + 1);
            }
        };

        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    useEffect(() => {
        if (count > 0) {
            window.location.href = window.location.pathname;
        }
    }, [count]);

    useEffect(() => {
        const currentUserLogin = localStorage.getItem('currentUser');
        if (window.location.pathname === '/admin' && currentUserLogin) {
            window.location.href = '/dashboard';
        }
    }, []);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#282936',
                },
            }}
        >
            <div className="App">
                <BrowserRouter basename="/">
                    <Routes>
                        {routes.map((route, index) => {
                            return route.isProtected ? (
                                <Route key={index} element={<AuthMiddleware isAuthProtected={route.isProtected} />}>
                                    <Route path={route.path} key={route.name} element={route.element} />
                                </Route>
                            ) : (
                                <Route path={route.path} key={route.name} element={route.element} />
                            );
                        })}
                        <Route path={'*'} element={<NotFoundComponent />} />
                    </Routes>
                </BrowserRouter>
                <ToastContainer />
            </div>
        </ConfigProvider>
    );
}

export default App;
