/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put,  takeEvery } from "redux-saga/effects";
import { REQUEST_FINANCE_REPORT_SUCCESS, REQUEST_FINANCE_REPORT_FAIL, REQUEST_FINANCE_REPORT_START } from "../../../constants/actionType/finance";
import { saveData } from '../../../helpers/index';


export interface ResponseGenerator{}
const delay = (time:any) => new Promise(resolve => setTimeout(resolve, time));

function* getFinanceReport(data:any) {
    try {
        yield call(delay, 3000);
        const userData: ResponseGenerator = yield call( saveData, 'v2/prison/finance-report', data?.data);
        yield put({type: REQUEST_FINANCE_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_FINANCE_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchFinanceReport() {
    yield takeEvery(REQUEST_FINANCE_REPORT_START, getFinanceReport)
}