import React from 'react';

const NoResultFound = () => {
    return (
        <>
            <div className="empty-list">
                <span className="empty-list__title">No results found</span>
                <span className="empty-list__subtitle">{`Try reload again to find what you're looking for.`}</span>
            </div>
        </>
    );
};

export default NoResultFound;
