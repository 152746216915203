/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     REQUEST_CALL_INVOICE_START 
} from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  fetchData} from '../../../helpers';
import { requestCallInvoiceFail, requestCallInvoiceSuccess } from "../../actions";



interface ResponseGenerator {
    data: any;
}

function* callInvoices() {
    try {
        const response: ResponseGenerator = yield call(fetchData, 'prison/audio/retrieve-facility-invoice');
        if (response?.data?.error) {
            yield put(requestCallInvoiceFail(response.data || 'Unknown error'));
        } else {
            yield put(requestCallInvoiceSuccess(response));
        }
    } catch (e: any) {
        yield put(requestCallInvoiceFail(e.message || 'Unknown error'));
    }
}

export default function* fetchCallInvoices() {
    yield takeEvery(REQUEST_CALL_INVOICE_START, callInvoices);
}