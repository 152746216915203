import {
GET_USER_DATA_FAIL, 
GET_USER_DATA_START, 
GET_USER_DATA_SUCCESS 
} from "../../../constants/actionType/auth";


export interface IUserData {
    data: any,
    error: any
}

export const getUserDataInfo = () =>  ({type: GET_USER_DATA_START});

export const getUserDataSuccess = (data: IUserData) => ({
    type: GET_USER_DATA_SUCCESS, 
    payload: data
})

export const getUserDataFailed = (error: IUserData) => ({ type: GET_USER_DATA_FAIL, payload: error})