import {
    COMPLETED_VISIT_CLEAR,
    COMPLETED_VISIT_FAIL, 
    COMPLETED_VISIT_START, 
    COMPLETED_VISIT_SUCCESS 
 } from "../../../constants/actionType/appointment";
 
  export const completedVisitData = () => ({type: COMPLETED_VISIT_START});
  
  export const completedVisitSuccess = (data:any) => ({
    type:COMPLETED_VISIT_SUCCESS,
    payload: data
  })
  
  export const completedVisitError = (error:any) => ({
    type:COMPLETED_VISIT_FAIL,
    payload: error
  })

  export const clearCompletedVisitStore = () => ({
    type: COMPLETED_VISIT_CLEAR,
  });