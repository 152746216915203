import { 
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_START, 
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS, 
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL 
} from "../../../../constants/actionType/bulkTransaction";
import { ITransactionFileDetailsData } from "../../../../interfaces/bulkTransaction";




interface InitialState {
    data: ITransactionFileDetailsData[];
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const retrieveBulkTransactionFilesDetails = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_BULK_TRANSACTION_FILE_DETAILS_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        
        default: {
            return state;
          }
    }
}

export default retrieveBulkTransactionFilesDetails;