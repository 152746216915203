import {
    GET_PRISON_FAIL, 
    GET_PRISON_START, 
    GET_PRISON_SUCCESS 
    } from "../../../constants/actionType/users";
    
    
    export interface IPrisonData {
        data: any,
        error: any
    }
    
    export const getPrisonsData = (data: any) =>  ({type: GET_PRISON_START, data});
    
    export const getPrisonsSuccess = (data: IPrisonData) => ({
        type: GET_PRISON_SUCCESS, 
        payload: data
    })
    
    export const getPrisonsFail = (error: IPrisonData) => ({ type: GET_PRISON_FAIL, payload: error})