import React from 'react';
import AppointmentPage from '../../components/Appointment';

const AppointmentPageContainer = () => {
    return (
        <>
            <AppointmentPage />
        </>
    );
};

export default AppointmentPageContainer;
