/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Modal, Table, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { IUserSelectorType } from 'interfaces';
import { UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { clearUploadBulkAmountStore, getInmateWalletsData, uploadBulkAmountData } from 'redux/actions';
import NoResultFound from 'components/common/noResultFound';

const requiredColumns = [
    {
        title: 'Inmate rcs',
        width: 100,
        dataIndex: 'inmate_rcs',
        key: 'item',
        fixed: 'left',
    },
    {
        title: 'Description',
        width: 100,
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Amount',
        width: 100,
        dataIndex: 'amount',
        key: 'amount',
    },
];

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const requiredColumnKeys = requiredColumns.map((col: { dataIndex: any }) => col.dataIndex);

const UploadBulkAmounts = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    const { uploadBulkAmount } = userSelector((user) => user);

    const handleFileUpload = (file: File) => {
        const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXlsx) {
            toast.error('You can only upload XLSX files!');
            return Upload.LIST_IGNORE;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target?.result;
            if (binaryStr) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                if (jsonData.length) {
                    const headers: any = jsonData[0];
                    const headerKeys = headers.map((header: string) => header.toLowerCase().replace(' ', '_'));
                    const isValid = requiredColumnKeys.every((key: any) => headerKeys.includes(key));

                    if (!isValid) {
                        toast.error(
                            'The uploaded file does not contain the required columns: Inmate rcs, Amount, Description',
                        );
                        return Upload.LIST_IGNORE;
                    }

                    const tableColumns = headers.map((header: string) => ({
                        title: header,
                        dataIndex: header.toLowerCase().replace(' ', '_'),
                        key: header.toLowerCase().replace(' ', '_'),
                    }));

                    const tableData = jsonData.slice(1).map((row: any, index: number) => {
                        const rowData: { key: number; [key: string]: any } = { key: index };
                        row.forEach((cell: any, cellIndex: number) => {
                            rowData[headerKeys[cellIndex]] = cell;
                        });
                        return rowData;
                    });

                    setColumns(tableColumns);
                    setData(tableData);
                }
            }
        };
        reader.readAsBinaryString(file);
        return false;
    };

    const handleSaveUploadedFile = () => {
        const saveProductWithIssues: { item: string; supplier: string }[] = [];

        const newProductList = data.map((item) => item).filter(Boolean);

        if (saveProductWithIssues.length > 0) {
            const issuesMessage = saveProductWithIssues.map((issue) => `${issue.item}: ${issue.supplier}`).join('\n');
            toast.error(`The following uploaded inmate contains issues:\n${issuesMessage}`);
        }

        const itemData = newProductList;
        dispatch(uploadBulkAmountData({ data: itemData }));
    };

    useEffect(() => {
        if (uploadBulkAmount.data?.success) {
            toast.success(uploadBulkAmount?.message);
            onClose();
            setData([]);
            setColumns([]);
            dispatch(getInmateWalletsData());
            dispatch(clearUploadBulkAmountStore());
        }

        if (uploadBulkAmount.error) {
            toast.error(uploadBulkAmount?.message);
            dispatch(clearUploadBulkAmountStore());
        }
    }, [uploadBulkAmount.data, uploadBulkAmount?.error]);

    return (
        <Modal width={800} title={'Upload file'} open={open} onCancel={onClose} footer={[]}>
            <div className="display-row-items-space-bt" style={{ marginTop: '20px' }}>
                <Upload beforeUpload={handleFileUpload} showUploadList={false}>
                    <Button ghost type="primary" icon={<UploadOutlined />}>
                        Select File
                    </Button>
                </Upload>
                {data.length ? (
                    <Button
                        type="primary"
                        icon={<CloudUploadOutlined />}
                        onClick={handleSaveUploadedFile}
                        loading={uploadBulkAmount.loading}
                    >
                        Submit
                    </Button>
                ) : null}
            </div>

            <Divider style={{ margin: '8px' }} />
            <Table
                columns={columns}
                locale={{ emptyText: NoResultFound }}
                dataSource={data}
                size="small"
                pagination={{ pageSize: 50 }}
            />
        </Modal>
    );
};

export default UploadBulkAmounts;
