import { Button, DatePicker, Modal, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { getAppointmentReportsData } from '../../../../redux/actions';
import moment from 'moment';
import { IUserSelectorType } from '../../../../interfaces';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined } from '@ant-design/icons';
import { ManageDates } from '../..';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import { FaRegFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IColumnTitles {
    text: string;
    value: string | number;
}

const columnTitles: IColumnTitles[] = [
    { text: 'Inmate', value: 'inmateFullName' },
    { text: 'RCS case', value: 'inmateCode' },
    { text: 'Visitor', value: 'visitorFullName' },
    { text: 'Visitor contact', value: 'visitorContact' },
    { text: 'Visit date', value: 'visitDate' },
    { text: 'Visit time', value: 'visitTime' },
    { text: 'Visit type', value: 'visitType' },
    { text: 'Visit method', value: 'visitMethod' },
    { text: 'Status', value: 'status' },
];

const GenerateExportToXLSX = (data: any[], filename: string) => {
    const wsData = data;
    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

const ComprehensiveReport: React.FC<any> = () => {
    const dispatch = useDispatch();
    const getDate = new ManageDates();
    const [selectedDate, setSelectedDate]: any = useState();
    const [dates, setDates] = useState<RangeValue>(null);
    const [open, setOpen] = useState(false);
    const [formatTableColumn, setFormatTableColumn] = useState<any[]>([]);

    const {
        getAppointmentReport: { data, loading },
    } = userSelector((user) => user);

    const reportData = data?.data !== undefined ? data?.data : [];

    const inmatesComprehensiveReportData: any = reportData?.details?.map((item: any) => {
        return {
            name: `${item?.first_name} ${item?.last_name}`,
            inmate_code: item?.inmate_code,
            visitor: `${item?.visitor_first_name} ${item?.visitor_last_name}`,
            visitorContact: item?.visitor_contacts,
            dateAndTime: `${item?.visit_date} ${item?.visit_time}`,
            typeOfVisit: item?.appointment_type,
            appointmentCategory: item?.appointment_category,
            appointmentType: item?.appointment_type,
        };
    });

    const dataToBeExported =
        reportData?.details &&
        reportData?.details.map((item: any) => ({
            inmateFullName: `${item?.first_name} ${item?.last_name}`,
            inmateCode: item?.inmate_code,
            visitorFullName: `${item?.visitor_first_name} ${item?.visitor_last_name}`,
            visitDate: item?.visit_date,
            visitTime: item?.visit_time,
            visitType: item?.appointment_type,
            visitMethod: item?.appointment_category,
            status: item?.status,
            visitorContact: item?.visitor_contacts,
        }));

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Visitors',
            dataIndex: 'visitor',
            key: 'visitor',
        },
        {
            title: 'Visitors contact',
            dataIndex: 'visitorContact',
            key: 'visitorContact',
        },
        {
            title: 'Date and time',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
        },

        {
            title: 'Visit method',
            dataIndex: 'appointmentCategory',
            key: 'appointmentCategory',
        },
        {
            title: 'Type of visit',
            dataIndex: 'appointmentType',
            key: 'appointmentType',
        },
    ];

    const onSearch = () => {
        dispatch(
            getAppointmentReportsData({
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    const onSelectDate = (dateString: any) => {
        setDates(dateString);
        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const exportXLSX = () => {
        const values = dataToBeExported
            ?.map((item: any) =>
                columnTitles?.map((option: any) => ({
                    [option.text]: item[option.value],
                })),
            )
            .filter(Boolean);

        const formattedColumns = values?.map((subArray: any) => {
            return subArray.reduce((acc: { [x: string]: any }, obj: { [x: string]: any }) => {
                for (const key in obj) {
                    acc[key] = obj[key];
                }
                return acc;
            }, {});
        });

        setFormatTableColumn(formattedColumns);
    };

    useEffect(() => {
        if (formatTableColumn.length !== 0) {
            GenerateExportToXLSX(
                formatTableColumn.map((item: any) => ({ ...item })),
                `visit-report-${Date.now()}`,
            );
        }
    }, [formatTableColumn]);

    useEffect(() => {
        dispatch(
            getAppointmentReportsData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
                comprehensive_report: true,
                frequency_report: false,
                inmate_with_visit_0: false,
                visitors_comprehensive_report: false,
                lawyers_report: false,
            }),
        );
    }, [dispatch, selectedDate]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <div></div>
                        <div className="top-right__items" style={{ width: '80%' }}>
                            <div style={{ marginLeft: '5px', marginTop: '-2px' }}>
                                <RangePicker
                                    style={{ padding: '6px' }}
                                    onChange={onSelectDate}
                                    format="YYYY-MM-DD"
                                    defaultValue={[
                                        dayjs(getDate.defaultStartedDate()),
                                        dayjs(getDate.defaultEndDate()),
                                    ]}
                                    size="large"
                                />
                            </div>
                            <Button
                                style={{ marginRight: '30px', marginLeft: '10px' }}
                                loading={loading}
                                onClick={onSearch}
                            >
                                Search
                            </Button>

                            <Button type="primary" onClick={() => setOpen(true)}>
                                Generate report
                            </Button>
                        </div>
                    </div>

                    {reportData !== undefined ? (
                        <>
                            <Table
                                columns={columns}
                                dataSource={inmatesComprehensiveReportData}
                                pagination={data?.length >= 10 ? undefined : false}
                            />
                        </>
                    ) : (
                        <>
                            <div style={{ margin: '20% auto', height: '800px' }}>
                                <Spin
                                    style={{ background: ' #282936' }}
                                    indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1200}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                        marginBottom: '55px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Button onClick={handlePrint} size="large" ghost type="primary" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                        <Button onClick={exportXLSX} size="large" ghost type="primary" style={{ marginRight: '30px' }}>
                            <FaRegFileExcel color="green" style={{ marginRight: '10px' }} /> Export to XLSX
                        </Button>
                    </div>

                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>BOOKING COMPREHENSIVE REPORT</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {reportData?.facility}
                            </span>
                            <span>
                                <b>Period: </b>
                                {reportData?.start_date} to {reportData?.start_date}
                            </span>
                        </div>
                        <Table columns={columns} dataSource={inmatesComprehensiveReportData} pagination={false} />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ComprehensiveReport;
