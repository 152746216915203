export const appointmentCategory = [
    {  
        value: "physical",
        label: "Physical"
    } ,
    {
        value: "virtual",
        label: "Virtual"
    }     
]

export const appointmentTypes = [
    {  
        value: "normal",
        label: "Normal"
    } ,
    {  
        value: "special",
        label: "Special"
    } ,  
    {  
        value: "lawyer",
        label: "Lawyers"
    } ,  
]

export const genders = [
    {  
        value: "F",
        label: "Female"
    } ,
    {  
        value: "M",
        label: "Male"
    } ,  
]


export const INCOMING_APPOINTMENT  = "incoming"
export const OUTGOING_APPOINTMENT = 'outgoing'