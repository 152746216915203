import {
    GET_USER_FAIL, 
    GET_USER_START, 
    GET_USER_SUCCESS 
    } from "../../../constants/actionType/users";
    
    
    export interface IUserAccountData {
        data: any,
        error: any
    }
    
    export const getUserAccountData = () =>  ({type: GET_USER_START});
    
    export const getUserAccountsSuccess = (data: IUserAccountData) => ({
        type: GET_USER_SUCCESS, 
        payload: data
    })
    
    export const getUserAccountsFail = (error: IUserAccountData) => ({ type: GET_USER_FAIL, payload: error})