/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_RWANDA_PROVINCES_SUCCESS, GET_RWANDA_PROVINCES_FAIL, GET_RWANDA_PROVINCES_START } from "../../../constants/actionType/rwandaProvince";
import { fetchData } from '../../../helpers';


export interface ResponseGenerator{}

function* getRwandaProvinces() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/admin/rwanda-provinces-and-districts');
        yield put({type: GET_RWANDA_PROVINCES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_RWANDA_PROVINCES_FAIL, error: e.message });
    }
}

export default function* fetchRwandaProvinces() {
    yield takeEvery(GET_RWANDA_PROVINCES_START, getRwandaProvinces)
}