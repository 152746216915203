/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_ALL_SUPPLIER_SUCCESS,
    GET_ALL_SUPPLIER_FAIL,
    GET_ALL_SUPPLIER_START 
} from "../../../constants/actionType/shop";
import { fetchData} from '../../../helpers';


export interface ResponseGenerator{}

function* getAllSupplier() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'shop/retrieve-product-supplier');
       
        yield put({type:GET_ALL_SUPPLIER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_ALL_SUPPLIER_FAIL, error: e.message });
    }
}

export default function* fetchAllSupplier() {
    yield takeEvery(GET_ALL_SUPPLIER_START, getAllSupplier)
}