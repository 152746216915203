/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  REQUEST_APPOINTMENT_CLEAR,
    REQUEST_APPOINTMENT_FAIL, 
    REQUEST_APPOINTMENT_START, 
    REQUEST_APPOINTMENT_SUCCESS 
} from "../../../constants/actionType/appointment";

 export const requestAppointments = (data: any) => ({type:  REQUEST_APPOINTMENT_START, data});
 
 export const requestAppointmentSuccess = (data:any) => ({
   type: REQUEST_APPOINTMENT_SUCCESS,
   payload: data
 })
 
 export const requestAppointmentError = (error:any) => ({
   type: REQUEST_APPOINTMENT_FAIL,
   payload: error
 })

 export const clearRequestAppointmentStore = () => ({
  type: REQUEST_APPOINTMENT_CLEAR,
 })