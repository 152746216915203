

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_APPROVE_ORDER_FILE_FAIL, 
    REQUEST_APPROVE_ORDER_FILE_START, 
    REQUEST_APPROVE_ORDER_FILE_SUCCESS 
} from "../../../constants/actionType/orders"

 export const approveOrderFileData = (data: any) => ({type:  REQUEST_APPROVE_ORDER_FILE_START, data});
 
 export const approveOrderFileSuccess = (data:any) => ({
   type: REQUEST_APPROVE_ORDER_FILE_SUCCESS,
   payload: data
 })
 
 export const approveOrderFileError = (error:any) => ({
   type: REQUEST_APPROVE_ORDER_FILE_FAIL,
   payload: error
 })