import React, { useEffect, useState } from 'react';
import { Button, Empty, Input, Select, Table } from 'antd';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { clearRecordBulkTransactionStore, getInmateData, recordBulkTransactionData } from '../../../../redux/actions';
import '../sendMoney.css';
import TransactionHistory from '../transactionHistory';
import { IFileData } from '../../../../interfaces/bulkSaving';
import { ColumnsType } from 'antd/es/table';
import EmptyTable from '../../../common/emptyTable';
import { IInmateDetails } from '../../../../interfaces/inmate';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IInmate {
    value: string | number;
    label: string;
}

interface IForm {
    file_id: string | number;
    inmate_id: string | number;
    inmate_code: string;
    receiver_first_name: string;
    receiver_last_name: string;
    receiver_contact: string;
    inmate_receiver_code: string;
    amount: string | number;
    firstName: string;
    lastName: string;
}

const RecordTransactions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showNextPage, setShowNextPage] = useState('default');
    const [selectedFile, setSelectedFile] = useState<any>();
    const [itemSelected, setItemSelected] = useState<IForm[]>([]);
    const [selectedItemForm, setSelectedItemForm] = useState<any>([]);
    const [savedFiles, setSavedFiles] = useState<IInmate[]>([]);
    const [form, setForm] = useState<IForm>({
        file_id: '',
        inmate_id: '',
        inmate_code: '',
        receiver_first_name: '',
        receiver_last_name: '',
        receiver_contact: '',
        inmate_receiver_code: '',
        amount: '',
        firstName: '',
        lastName: '',
    });
    const [inmateId, setInmateId] = useState<string | number>();
    const {
        getInmates: {
            data: { data },
        },
        outGoingMoney,
        recordTransaction,
        retrieveBulkTransactionFiles,
    } = userSelector((user) => user);

    const inmateInfo: IInmate[] = data?.details?.map((item: IInmateDetails) => {
        return {
            value: item.id,
            label: item?.inmate_code,
        };
    });

    const onChangeInmateCode = (value: React.SetStateAction<string | number | undefined>) => {
        setInmateId(value);
    };

    const onChangeInputValue = (event: { target: { name: string; value: string | number } }) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const addInputField = () => {
        setItemSelected([
            ...itemSelected,
            {
                inmate_id: form.inmate_id,
                receiver_first_name: form.receiver_first_name,
                receiver_last_name: form.receiver_last_name,
                inmate_code: form.inmate_code,
                amount: parseFloat(`${form.amount}`),
                file_id: selectedFile,
                receiver_contact: form.receiver_contact,
                inmate_receiver_code: form.inmate_receiver_code,
                firstName: form.firstName,
                lastName: form.lastName,
            },
        ]);

        setSelectedItemForm([
            ...selectedItemForm,
            {
                file_id: selectedFile,
                inmate_id: form.inmate_id,
                inmate_code: form.inmate_code,
                receiver_first_name: form.receiver_first_name,
                receiver_last_name: form.receiver_last_name,
                receiver_contact: form.receiver_contact || '',
                inmate_receiver_code: form.inmate_receiver_code || '',
                amount: form.amount,
            },
        ]);
    };

    const removeInputFields = (index: number) => {
        const rows = [...itemSelected];
        rows.splice(index, 1);
        setItemSelected(rows);
    };

    useEffect(() => {
        if (inmateId !== undefined) {
            data?.details?.map((item: IInmateDetails) => {
                if (item?.id === inmateId) {
                    setForm({
                        inmate_id: item.id,
                        receiver_first_name: '',
                        receiver_last_name: '',
                        inmate_code: item.inmate_code,
                        receiver_contact: '',
                        file_id: '',
                        inmate_receiver_code: '',
                        amount: '',
                        firstName: item.first_name,
                        lastName: item.last_name,
                    });
                }
            });
        }
    }, [inmateId, data]);

    const onChangeSaveFile = (value: string | number) => {
        setSelectedFile(value);
    };

    const dataCol1: IForm[] = itemSelected.map((inmate: IForm) => {
        return {
            inmate_id: inmate.inmate_id,
            receiver_first_name: '',
            receiver_last_name: '',
            inmate_code: inmate.inmate_code,
            receiver_contact: inmate.receiver_contact,
            file_id: '',
            inmate_receiver_code: inmate.inmate_receiver_code,
            amount: inmate.amount,
            firstName: inmate.firstName,
            lastName: inmate.lastName,
            name: `${inmate.receiver_first_name} ${inmate.receiver_last_name}`,
        };
    });

    const columns1: ColumnsType<IForm> = [
        {
            title: 'Sender RCS number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'Receiver contact',
            dataIndex: 'receiver_contact',
            key: 'receiver_contact',
        },
        {
            title: 'RSC number',
            dataIndex: 'inmate_receiver_code',
            key: 'inmate_receiver_code',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, _data, index: number) => {
                return <a onClick={() => removeInputFields(index)}>Remove</a>;
            },
        },
    ];

    const onSubmit = () => {
        dispatch(
            recordBulkTransactionData({
                data: selectedItemForm,
            }),
        );
    };

    useEffect(() => {
        const newList: IInmate[] = [];
        retrieveBulkTransactionFiles.data?.data?.flatMap((item: IFileData) => {
            if (item?.actions_status === 'pending') {
                newList.push({
                    value: item.id,
                    label: item?.file_name,
                });
            }
        });
        setSavedFiles(newList);
    }, []);

    useEffect(() => {
        dispatch(
            getInmateData({
                inmateWithWallet: 'NO',
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (recordTransaction.data) {
            navigate('/out-going-money');
            dispatch(clearRecordBulkTransactionStore());
        }
    }, [dispatch, recordTransaction?.data]);

    return (
        <>
            {showNextPage === 'default' ? (
                <>
                    <div className="user-account-tbl">
                        <div className="details-btn-group">
                            <div className="tbl-items">
                                <Button className="back-btn" onClick={() => navigate('/out-going-money')}>
                                    <BiArrowBack size={18} />
                                    <span style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px' }}>
                                        Bulk transaction files
                                    </span>
                                </Button>
                                <div></div>
                                <div style={{ marginRight: '10px' }}>
                                    <Button
                                        onClick={() => {
                                            setShowNextPage('transaction-history');
                                        }}
                                    >
                                        <span>Generate bulk saving file</span>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setShowNextPage('transaction-history');
                                        }}
                                    >
                                        <span>Transactions history</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="column-box record-money-form">
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div className="send-form__left">
                                    <div className="form__input">
                                        <span>Select inmate transfers file</span>
                                        <Select
                                            showSearch
                                            style={{ width: '100%', textAlign: 'left' }}
                                            placeholder="Select inmate transfers file"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '')
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={retrieveBulkTransactionFiles.loading}
                                            options={savedFiles}
                                            onChange={onChangeSaveFile}
                                        />
                                    </div>
                                    <div className="form__input">
                                        <span>Select inmate transfers file</span>
                                        <Select
                                            showSearch
                                            style={{ width: '100%', textAlign: 'left' }}
                                            placeholder="Search inmate by code"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '')
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={inmateInfo}
                                            onChange={onChangeInmateCode}
                                        />
                                    </div>
                                    <div className="form__input">
                                        <span>First name</span>
                                        <Input
                                            size="middle"
                                            value={form.firstName}
                                            placeholder="Enter first name"
                                            name="firstName"
                                            onChange={(event) => onChangeInputValue(event)}
                                            disabled={true}
                                            style={{ color: '#333' }}
                                        />
                                    </div>
                                    <div className="form__input">
                                        <span>{`Last name`}</span>
                                        <Input
                                            size="middle"
                                            value={form.lastName}
                                            name="lastName"
                                            onChange={(event) => onChangeInputValue(event)}
                                            placeholder="Last name"
                                            disabled={true}
                                            style={{ color: '#333' }}
                                        />
                                    </div>
                                    <div className="form__input">
                                        <span>{`Inmate code`}</span>
                                        <Input
                                            size="middle"
                                            type="text"
                                            value={form.inmate_code}
                                            name="inmate_code"
                                            onChange={(event) => onChangeInputValue(event)}
                                            placeholder="Enter inmate code"
                                            disabled={true}
                                            style={{ color: '#333' }}
                                        />
                                    </div>

                                    <div className="form__input">
                                        <span>{`Receiver first name`}</span>
                                        <Input
                                            size="middle"
                                            placeholder="Enter inmate code"
                                            value={form.receiver_first_name}
                                            name="receiver_first_name"
                                            onChange={(event) => onChangeInputValue(event)}
                                        />
                                    </div>
                                    <div className="form__input">
                                        <span>{`Receiver last name`}</span>
                                        <Input
                                            size="middle"
                                            placeholder="Enter inmate name"
                                            value={form.receiver_last_name}
                                            name="receiver_last_name"
                                            onChange={(event) => onChangeInputValue(event)}
                                        />
                                    </div>

                                    <div className="form__input">
                                        <span>{`Amount`}</span>
                                        <Input
                                            size="middle"
                                            placeholder="Enter amount"
                                            value={form.amount}
                                            name="amount"
                                            onChange={(event) => onChangeInputValue(event)}
                                        />
                                    </div>

                                    <div className="form__input">
                                        <span>{`Receiver phone number`}</span>
                                        <Input
                                            size="middle"
                                            placeholder="Enter phone number"
                                            value={form.receiver_contact}
                                            disabled={form.inmate_receiver_code === '' ? false : true}
                                            name="receiver_contact"
                                            onChange={(event) => onChangeInputValue(event)}
                                        />
                                    </div>

                                    <div className="form__input">
                                        <span>{`Receiver inmate code`}</span>
                                        <Input
                                            size="middle"
                                            placeholder="Enter receiver inmate code"
                                            value={form.inmate_receiver_code}
                                            disabled={form.receiver_contact === '' ? false : true}
                                            name="inmate_receiver_code"
                                            onChange={(event) => onChangeInputValue(event)}
                                        />
                                    </div>

                                    <Button
                                        size="large"
                                        onClick={() => addInputField()}
                                        loading={outGoingMoney.loading}
                                        disabled={selectedFile === undefined || selectedFile === '' ? true : false}
                                        style={{
                                            width: '100%',
                                            marginTop: '30px',
                                            color: 'white',
                                            background: ' #282936',
                                        }}
                                    >
                                        Add
                                    </Button>
                                </div>
                                <div className="display-inmate-recorded">
                                    <div style={{ height: 600 }}>
                                        {itemSelected.length !== 0 ? (
                                            <>
                                                {' '}
                                                <Table
                                                    columns={columns1}
                                                    dataSource={dataCol1}
                                                    pagination={itemSelected.length >= 8 ? undefined : false}
                                                />
                                                <Button
                                                    loading={recordTransaction.loading}
                                                    disabled={itemSelected.length === 0 ? true : false}
                                                    className="save-btn"
                                                    onClick={() => onSubmit()}
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        ) : (
                                            <EmptyTable
                                                title={'No record found'}
                                                subTitle={`Try to add inmate with amount by selecting inmate by their case number.`}
                                                Icon={<Empty />}
                                                button={undefined}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

            {showNextPage === 'transaction-history' ? <TransactionHistory setShowNextPage={setShowNextPage} /> : null}
        </>
    );
};

export default RecordTransactions;
