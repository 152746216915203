/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    SEND_MONEY_SUCCESS,
    SEND_MONEY_FAIL,
    SEND_MONEY_START 
} from "../../../constants/actionType/sendMoney";
import { saveData } from '../../../helpers';




export interface ResponseGenerator{
    message: string
    data: any
}

function* sendMoney(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'request-payment', data?.data);
        yield put({type:SEND_MONEY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:SEND_MONEY_FAIL, error: e.message });
    }
}

export default function* getSendMoney() {
    yield takeEvery(SEND_MONEY_START, sendMoney)
}