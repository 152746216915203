/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
     GET_USER_FAIL, 
     GET_USER_START, 
     GET_USER_SUCCESS 
} from "../../../constants/actionType/users";
import { fetchData } from "../../../helpers";

interface ResponseGenerator{}

function* getUserAccountData() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, `v2/admin/retrieve-users`);
        yield put({type:  GET_USER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:  GET_USER_FAIL, error: e.message });
    }
}

export default function* fetchUserAccountData() {
    yield takeEvery( GET_USER_START, getUserAccountData)
}