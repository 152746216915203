import React, { useState } from 'react';
import UsersAccounts from '../dashboard/users/usersAccount';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../interfaces';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const ManageAccounts = () => {
    const [account, setAccount] = useState(true);
    const { getUserData } = userSelector((user) => user);
    const usersData = getUserData?.data?.data === undefined ? [] : getUserData?.data?.data[0];

    return (
        <>
            <div className="dashboard-container__box">
                <div className="tbl-items" style={{ padding: '0px' }}>
                    <div className="tab-box">
                        {[2, 7, 1].includes(parseInt(usersData?.role_id)) === true ? (
                            <div
                                onClick={() => {
                                    setAccount(true);
                                }}
                                className={account ? 'active-tab' : 'tab-btn'}
                            >
                                <span>Accounts</span>
                            </div>
                        ) : null}
                    </div>
                </div>
                {account === true ? <UsersAccounts /> : null}
            </div>
        </>
    );
};

export default ManageAccounts;
