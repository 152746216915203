import { call, put, takeEvery } from "redux-saga/effects";
import {
    CHANGE_PASSWORD_USER_SUCCESS, 
    CHANGE_PASSWORD_USER_FAIL, 
    CHANGE_PASSWORD_USER_START } from "../../constants/actionType/users";
import { saveData } from '../../helpers';


export interface ResponseGenerator{
    message: string
}

interface IData {
    first_login:  boolean;
    new_password: string;
    old_password: string;
    change_password: boolean;
}
interface IPayload {
    type: typeof CHANGE_PASSWORD_USER_START,
    data: IData
}

function* changePassword(data:  IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'user/update-password', data?.data);
        yield put({type: CHANGE_PASSWORD_USER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: CHANGE_PASSWORD_USER_FAIL, error: e.message });
    }
}

export default function* getChangePassword() {
    yield takeEvery(CHANGE_PASSWORD_USER_START, changePassword)
}