import React from 'react';
import { Result } from 'antd';

const ErrorPage: React.FC = () => (
    <>
        <div className="dashboard-container__box">
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                style={{ margin: '10% auto' }}
            />
        </div>
    </>
);

export default ErrorPage;
