import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import {
    // checkVisitIDData,
    clearCheckVisitID,
    clearLinkDeviceToVisit,
    clearPendingVisit,
    clearVideoVisits,
    getDevicesData,
    linkDeviceToVisitData,
    pendingVisitData,
    videoVisitsData,
    visitDoneData,
} from '../../../redux/actions';
// import { MdOpenInBrowser } from 'react-icons/md';
import Table, { ColumnsType } from 'antd/es/table';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Select, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import NoResultFound from '../../common/noResultFound';
import './index.css';
import { ManageDates } from '..';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const VideoVisits = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getDate = new ManageDates();
    const [open, setOpen] = useState(false);
    const [fetchAllDevices, setFetchAllDevices] = useState<any>();
    const [browserId, setBrowserId] = useState<string | number>();
    const [payload, setPayload] = useState<any>({});
    const [changePage, setChangePage] = useState<string>('default');
    const {
        videoVisits: { data, loading },
        checkVisitId,
        pendingVisits,
        visitDone,
        getDevice,
        linkDeviceToVisit,
    } = userSelector((user) => user);

    const appointments = !data?.data ? [] : data?.data?.appointments;
    const pendingAppointments = !pendingVisits.data?.data ? [] : pendingVisits.data?.data.appointments;
    const appointmentDone = !visitDone.data?.data ? [] : visitDone.data?.data.appointments;
    const allDevices = !getDevice?.data?.data ? [] : getDevice?.data?.data.devices;

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'RSC case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Visit date',
            dataIndex: 'visit_date',
            key: 'visit_date',
        },
        {
            title: 'Visit time',
            dataIndex: 'visit_time',
            key: 'visit_time',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, data) => {
                return (
                    <div className="tbl-group-btn">
                        <Checkbox
                            disabled={browserId === undefined ? true : false}
                            value={`${data.visit_id}`}
                            onChange={(value) => onChange(value, data.appointment_id)}
                        >
                            <span>Link to device</span>
                        </Checkbox>
                        {/* <a
                            onClick={() => {
                                dispatch(checkVisitIDData({ visit_id: data.visit_id, guest: 'inmate' }));
                            }}
                        >
                            {checkVisitId.loading ? 'Please wait...' : <MdOpenInBrowser size={18} color="orange" />}
                        </a> */}
                    </div>
                );
            },
        },
    ];

    const columnStatus: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'RSC case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Visit date',
            dataIndex: 'visit_date',
            key: 'visit_date',
        },
        {
            title: 'Visit time',
            dataIndex: 'visit_time',
            key: 'visit_time',
        },
    ];

    const newDataCol: any = appointments?.map((item: any) => {
        return {
            id: item?.id,
            name: `${item?.first_name} ${item?.last_name}`,
            inmate_code: item.inmate_code,
            visit_date: item?.visit_date,
            visit_time: item?.visit_time,
            visit_id: item?.inmate_visit_id,
            appointment_id: item?.id,
        };
    });

    const pendingAppointmentsCol: any = pendingAppointments?.map((appointment: any) => {
        return {
            id: appointment?.id,
            name: `${appointment?.first_name} ${appointment?.last_name}`,
            inmate_code: appointment.inmate_code,
            visit_date: appointment?.visit_date,
            visit_time: appointment?.visit_time,
            visit_id: appointment?.inmate_visit_id,
        };
    });

    const appointmentsDoneCol: any = appointmentDone?.map((appointment: any) => {
        return {
            id: appointment?.id,
            name: `${appointment?.first_name} ${appointment?.last_name}`,
            inmate_code: appointment.inmate_code,
            visit_date: appointment?.visit_date,
            visit_time: appointment?.visit_time,
            visit_id: appointment?.inmate_visit_id,
        };
    });

    useEffect(() => {
        if (checkVisitId?.data?.data?.success) {
            navigate('/start-meeting');
        }

        if (checkVisitId?.data?.data?.error) {
            message.error(checkVisitId.data.data.message);
            dispatch(clearCheckVisitID());
        }
    }, [checkVisitId?.data]);

    useEffect(() => {
        if (changePage === 'default') {
            dispatch(videoVisitsData({ date: getDate.defaultEndDate() }));
        }
    }, []);

    useEffect(() => {
        dispatch(getDevicesData({ date: getDate.defaultEndDate() }));
    }, []);

    useEffect(() => {
        const newList: any = [];
        if (allDevices) {
            allDevices.map((item: any) => {
                newList.push({
                    value: item.id,
                    label: item.browser_name,
                });
            });
            setFetchAllDevices([...newList]);
        }
    }, [allDevices]);

    const handleChange = (value: any) => {
        setBrowserId(value);
    };

    const onChange = (_value: any, visit_id: string | number) => {
        const newList: any = { ...payload };
        newList['browser_id'] = browserId;
        newList['visit_id'] = visit_id;
        setPayload(newList);
    };

    useEffect(() => {
        if (payload) {
            dispatch(linkDeviceToVisitData(payload));
        }
    }, [payload]);

    useEffect(() => {
        if (linkDeviceToVisit?.data?.data?.success) {
            message.success(linkDeviceToVisit?.data?.message);
            dispatch(clearLinkDeviceToVisit());
        }
        if (linkDeviceToVisit?.data?.data?.error) {
            message.error(linkDeviceToVisit?.data?.message);
            dispatch(clearLinkDeviceToVisit());
        }
    }, [linkDeviceToVisit?.data?.data?.success, linkDeviceToVisit?.data?.data?.error]);

    return (
        <div className="user-account-tbl">
            <div className="tbl-items">
                <h2></h2>
                <div>
                    <Button
                        id="transparent-btn"
                        onClick={() => {
                            navigate('/facility-video-meeting');
                        }}
                    >
                        <span>Browse to auto start visit</span>
                    </Button>
                    <Button
                        id="transparent-btn"
                        onClick={() => {
                            setChangePage('default');
                            dispatch(videoVisitsData({ date: getDate.defaultEndDate() }));
                            dispatch(clearPendingVisit());
                        }}
                    >
                        <span style={changePage === 'default' ? { fontWeight: 'bold' } : {}}>Today visits</span>
                    </Button>
                    <Button
                        id="transparent-btn"
                        onClick={() => {
                            setChangePage('visit-done');
                            dispatch(visitDoneData());
                            dispatch(clearPendingVisit());
                            dispatch(clearVideoVisits());
                        }}
                    >
                        <span style={changePage === 'visit-done' ? { fontWeight: 'bold' } : {}}>Visit done</span>
                    </Button>
                    <Button
                        id="transparent-btn"
                        onClick={() => {
                            setChangePage('still-pending');
                            dispatch(pendingVisitData());
                            dispatch(clearVideoVisits());
                        }}
                    >
                        <span style={changePage === 'still-pending' ? { fontWeight: 'bold' } : {}}>
                            Still in pending
                        </span>
                    </Button>
                </div>
            </div>

            <div className="tbl-items" style={{ justifyContent: 'flex-start' }}>
                <div className="tbl-items__col">
                    <span style={{ color: '#777677' }}>Device connected</span>
                    <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                        {!data?.data?.device_number ? '0' : data?.data?.device_number}
                    </span>
                </div>
                <div className="separator__col"></div>
                <div className="tbl-items__col">
                    <span style={{ color: '#777677' }}>Appointments</span>
                    <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                        {!data?.data?.appointment_number ? '0' : data?.data?.appointment_number}
                    </span>
                </div>

                <div className="right-btn-group" style={{ width: '60%' }}>
                    <div className="top-right__items">
                        <Select
                            allowClear
                            style={{ width: '20%', marginRight: '5px' }}
                            placeholder="Select and link device"
                            onChange={handleChange}
                            options={fetchAllDevices}
                            size="large"
                        />
                    </div>
                </div>
            </div>

            {changePage === 'default' && (
                <>
                    {loading && (
                        <div style={{ margin: '20% auto', height: '800px' }}>
                            <Spin
                                style={{ color: ' #282936' }}
                                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                            />
                        </div>
                    )}
                    {appointments !== undefined && (
                        <>
                            {appointments?.length !== 0 ? (
                                <Table
                                    columns={columns}
                                    dataSource={newDataCol}
                                    pagination={newDataCol.length > 14 ? undefined : false}
                                />
                            ) : (
                                <NoResultFound />
                            )}
                        </>
                    )}
                    {!appointments && <NoResultFound />}
                </>
            )}

            {changePage === 'visit-done' && (
                <>
                    {' '}
                    {visitDone.loading && (
                        <div style={{ margin: '20% auto', height: '800px' }}>
                            <Spin
                                style={{ color: ' #282936' }}
                                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                            />
                        </div>
                    )}
                    {appointmentsDoneCol !== undefined && (
                        <>
                            {appointmentsDoneCol?.length !== 0 ? (
                                <Table
                                    columns={columnStatus}
                                    dataSource={appointmentsDoneCol}
                                    pagination={appointmentsDoneCol.length > 14 ? undefined : false}
                                />
                            ) : (
                                <NoResultFound />
                            )}
                        </>
                    )}
                </>
            )}

            {changePage === 'still-pending' && (
                <>
                    {pendingVisits.loading && (
                        <div style={{ margin: '20% auto', height: '800px' }}>
                            <Spin
                                style={{ color: ' #282936' }}
                                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                            />
                        </div>
                    )}
                    {pendingAppointments !== undefined && (
                        <>
                            {pendingAppointments?.length !== 0 ? (
                                <Table
                                    columns={columnStatus}
                                    dataSource={pendingAppointmentsCol}
                                    pagination={pendingAppointmentsCol.length > 14 ? undefined : false}
                                />
                            ) : (
                                <NoResultFound />
                            )}
                        </>
                    )}
                </>
            )}

            <Modal
                title="Link appointment to device"
                centered
                open={open}
                onOk={() => {
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
                width={500}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                        marginBottom: '55px',
                    }}
                >
                    <span>Do you want to leave this session?</span>
                </div>
            </Modal>
        </div>
    );
};

export default VideoVisits;
