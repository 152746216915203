/* eslint-disable @typescript-eslint/no-explicit-any */
import { Space, Input, Button, Form, Modal, Flex, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';

const AddItemAllowedTBring = ({
    open,
    onClose,
    setItemSelected,
}: {
    open: boolean;
    onClose: () => void;
    setItemSelected: any;
}) => {
    const onFinish = (values: any) => {
        setItemSelected(values);
        onClose();
    };

    return (
        <Modal title="Add items to bring with you when you visit" open={open} onCancel={undefined} footer={[]}>
            <Form name="dynamic_form_nest_item" onFinish={onFinish} style={{ maxWidth: 600 }} autoComplete="off">
                <Divider />
                <Form.List name="items_allowed_to_bring">
                    {(fields, { add, remove }) => (
                        <Flex style={{ width: '100%' }} vertical>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'items']}
                                        rules={[{ required: true, message: 'items' }]}
                                    >
                                        <Input placeholder="Items" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'quantity']}
                                        rules={[{ required: true, message: 'quantity' }]}
                                    >
                                        <Input placeholder="Quantity" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Flex justify="flex-end">
                                    <Button type="primary" ghost onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add item
                                    </Button>
                                </Flex>
                            </Form.Item>
                        </Flex>
                    )}
                </Form.List>
                <Divider />
                <Form.Item>
                    <Flex style={{ width: '100%' }} justify="space-between">
                        <Button danger ghost onClick={onClose}>
                            Cancel
                        </Button>
                        <Button ghost type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddItemAllowedTBring;
