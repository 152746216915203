import { call, put, takeEvery } from "redux-saga/effects";
import { 
    LINK_DEVICE_TO_VISIT_START, 
    LINK_DEVICE_TO_VISIT_SUCCESS, 
    LINK_DEVICE_TO_VISIT_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import {  saveData } from "../../../helpers";




export interface ResponseGenerator{
    message: string
}



function* linkDeviceToVisit(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/link-devices', data?.data);
        yield put({type: LINK_DEVICE_TO_VISIT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: LINK_DEVICE_TO_VISIT_FAIL, error: e.message });
    }
}

export default function* fetchLinkDeviceToVisit() {
    yield takeEvery(LINK_DEVICE_TO_VISIT_START, linkDeviceToVisit)
}