/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_CALLING_SETTINGS_CLEAR,
      REQUEST_CALLING_SETTINGS_FAIL, 
      REQUEST_CALLING_SETTINGS_START, 
      REQUEST_CALLING_SETTINGS_SUCCESS 
  } from "../../../constants/actionType/inmates";
  
   export const requestCallingSettingsData = (data: any) => ({type:  REQUEST_CALLING_SETTINGS_START, data});
   
   export const requestCallingSettingsSuccess = (data:any) => ({
     type: REQUEST_CALLING_SETTINGS_SUCCESS,
     payload: data
   })
   
   export const requestCallingSettingsError = (error:any) => ({
     type: REQUEST_CALLING_SETTINGS_FAIL,
     payload: error
   })
  
   export const clearRequestCallingSettingStore = () => ({
    type: REQUEST_CALLING_SETTINGS_CLEAR
   })