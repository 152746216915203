/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    LOGOUT_USER_FAIL, 
    LOGOUT_USER_START, 
    LOGOUT_USER_SUCCESS, 
    RESET_ALL_STATE
} from "../../../constants/actionType/auth";

 export const logoutData = () => ({type:  LOGOUT_USER_START});
 
 export const logoutSuccess = (data:any) => ({
   type: LOGOUT_USER_SUCCESS,
   payload: data
 })
 
 export const logoutError = (error:any) => ({
   type: LOGOUT_USER_FAIL,
   payload: error
 })

 export const resetAllState = () => ({
  type: RESET_ALL_STATE
 })