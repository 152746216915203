import React, { MouseEventHandler, ReactNode } from 'react';
import { Space, Drawer, Button, RadioChangeEvent, DrawerProps } from 'antd';

interface ICustomDrawer {
    placement?: DrawerProps['placement'] | undefined;
    onChange: ((e: RadioChangeEvent) => void) | undefined;
    showDrawer: MouseEventHandler<HTMLElement> | undefined;
    onClose?: () => void | undefined;
    open: boolean;
    children: ReactNode;
    onSubmit: any;
    title: string;
    category: string;
    width: number;
}

const CustomDrawer: React.FC<ICustomDrawer> = ({
    placement,
    onClose,
    open,
    onSubmit,
    title,
    category,
    children,
    width = null,
}) => {
    return (
        <>
            <Drawer
                title={title}
                placement={placement}
                width={width || 500}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        {category !== 'details' ? (
                            <>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        onClose;
                                        onSubmit();
                                    }}
                                >
                                    Submit
                                </Button>
                            </>
                        ) : null}
                    </Space>
                }
            >
                {children}
            </Drawer>
        </>
    );
};

export default CustomDrawer;
