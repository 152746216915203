import {
    GET_SUPER_ADMIN_INMATE_WALLET_FAIL, 
    GET_SUPER_ADMIN_INMATE_WALLET_START, 
    GET_SUPER_ADMIN_INMATE_WALLET_SUCCESS 
    } from "../../../constants/actionType/inmates";
    

export interface IInmateWalletListData {
    data: any,
    error: any
}

export const getInmateWalletsListData = (data:any) =>  ({type: GET_SUPER_ADMIN_INMATE_WALLET_START, data});

export const getInmatesWalletsListSuccess = (data: IInmateWalletListData) => ({
    type: GET_SUPER_ADMIN_INMATE_WALLET_SUCCESS, 
    payload: data
})

export const getInmatesWalletsListFail = (error: IInmateWalletListData) => ({ type: GET_SUPER_ADMIN_INMATE_WALLET_FAIL, payload: error})