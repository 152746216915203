/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CLEAR_CLOSE_DELIVERY_NOTE_FILE,
    CLOSE_DELIVERY_NOTE_FILE_FAIL,
    CLOSE_DELIVERY_NOTE_FILE_START,
    CLOSE_DELIVERY_NOTE_FILE_SUCCESS,
} from '../../../constants/actionType/orders';

export interface IScloseDeliveryNoteFileData {
    data: any;
    error: any;
}

export const closeDeliveryNoteFileData = (data:any) => ({ type: CLOSE_DELIVERY_NOTE_FILE_START, data});

export const closeDeliveryNoteFileSuccess = (data:any) => ({
    type: CLOSE_DELIVERY_NOTE_FILE_SUCCESS,
    payload: data,
});

export const closeDeliveryNoteFileFailed = (error:any) => ({
    type: CLOSE_DELIVERY_NOTE_FILE_FAIL,
    payload: error,
});

export const clearCloseDeliveryNoteFileStore = () => ({
    type: CLEAR_CLOSE_DELIVERY_NOTE_FILE
});