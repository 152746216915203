/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    DELIVERY_ITEMS_FAIL,
    DELIVERY_ITEMS_START,
    DELIVERY_ITEMS_SUCCESS,
    CLEAR_DELIVERY_ITEMS
} from '../../../constants/actionType/orders';

export interface IDeliveryItemData {
    data: any;
    error: any;
}

export const deliveryItemsData = (data:any) => ({ type: DELIVERY_ITEMS_START, data});

export const deliveryItemsSuccess = (data: any) => ({
    type: DELIVERY_ITEMS_SUCCESS,
    payload: data,
});

export const deliveryItemsFail = (error:any) => ({
    type: DELIVERY_ITEMS_FAIL,
    payload: error,
});

export const clearDeliveryItemsStore = () => ({
    type: CLEAR_DELIVERY_ITEMS
  });