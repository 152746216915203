import {
    GET_CORRECTIONAL_FACILITY_FAIL, 
    GET_CORRECTIONAL_FACILITY_START, 
    GET_CORRECTIONAL_FACILITY_SUCCESS 
} from "../../../constants/actionType/users";
    
    
export interface ICorrectionalServiceData {
    data: any,
    error: any
}

export const getCorrectionalServiceData = () =>  ({type: GET_CORRECTIONAL_FACILITY_START});

export const getCorrectionalServiceSuccess = (data: ICorrectionalServiceData) => ({
    type: GET_CORRECTIONAL_FACILITY_SUCCESS, 
    payload: data
})

export const getCorrectionalServiceFail = (error: ICorrectionalServiceData) => ({ type: GET_CORRECTIONAL_FACILITY_FAIL, payload: error})