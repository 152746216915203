/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_OUT_GOING_MONEY_SUCCESS,
    GET_OUT_GOING_MONEY_FAIL,
    GET_OUT_GOING_MONEY_START 
} from "../../../constants/actionType/sendMoney";
import { saveData } from '../../../helpers';
import { delay } from "../../../constants/time";




export interface ResponseGenerator{
    message: string
}

function* getOutGoingMoney(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'v2/prison/get-amount-sent', data?.data);
        yield call(delay, 3000)
        yield put({type:GET_OUT_GOING_MONEY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_OUT_GOING_MONEY_FAIL, error: e.message });
    }
}

export default function* fetchGetOutGoingMoney() {
    yield takeEvery(GET_OUT_GOING_MONEY_START, getOutGoingMoney)
}