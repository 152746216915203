/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    OUTGOING_APPOINTMENT_SUCCESS,
    OUTGOING_APPOINTMENT_FAIL,
    OUTGOING_APPOINTMENT_START 
} from "../../../constants/actionType/appointment";
import { fetchData} from '../../../helpers';
import { delay } from "../../../constants/time";


export interface ResponseGenerator{
    message: string
}

function* outgoingAppointment() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/prison/outgoing-appointment');
        yield call(delay, 3000);
        yield put({type:OUTGOING_APPOINTMENT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:OUTGOING_APPOINTMENT_FAIL, error: e.message });
    }
}

export default function* getOutgoingAppointment() {
    yield takeEvery(OUTGOING_APPOINTMENT_START, outgoingAppointment)
}