import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Flex, Modal, Space, Spin, Table } from 'antd';
import { LoadingOutlined, LeftOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { IUserSelectorType } from '../../interfaces';
import { deliveryNoteData, getDeliveryNoteItemsData } from '../../redux/actions';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IOderDetails {
    id: string;
    item: string;
    unit_price: string;
    delivery_quantity: number;
    total_price: string;
    currency?: string;
    shop_product?: string;
    file_name?: string;
    status?: string;
    created_at?: string;
}

const today: string = new Date().toISOString().slice(0, 10);

const DeliveryNote: React.FC<{ setShowNextPage: (page: string) => void; orderFileId: string }> = ({
    setShowNextPage,
    orderFileId,
}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { deliveryNote, getDeliveryNoteItems } = userSelector((state) => state);

    useEffect(() => {
        dispatch(deliveryNoteData({ ordersFileId: orderFileId, check_status: false }));
    }, [dispatch, orderFileId]);

    const columns: ColumnsType<IOderDetails> = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, data: IOderDetails) => (
                <Space>
                    <a
                        onClick={() => {
                            setOpen(true);
                            dispatch(getDeliveryNoteItemsData({ delivery_note_Id: data.id }));
                        }}
                    >
                        Preview
                    </a>
                </Space>
            ),
        },
    ];

    const columns1: ColumnsType<IOderDetails> = [
        {
            title: 'Items',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Quantities Requested',
            dataIndex: 'requested_quantity',
            key: 'requested_quantity',
        },
        {
            title: 'Quantities Delivered',
            dataIndex: 'delivery_quantity',
            key: 'delivery_quantity',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
    ];

    const deliveryNoteItemsData = getDeliveryNoteItems?.data?.data ?? [];

    const newItemsData: IOderDetails[] = useMemo(
        () =>
            deliveryNoteItemsData.details?.map(
                (
                    item: {
                        item: string;
                        unit_price: number;
                        currency: string;
                        delivery_quantity: number;
                        requested_quantity: number;
                        total_price: number;
                    },
                    index: number,
                ) => ({
                    key: index,
                    item: item.item,
                    unit_price: `${item.unit_price} ${item.currency}`,
                    delivery_quantity: item.delivery_quantity,
                    requested_quantity: item.requested_quantity,
                    total_price: `${item.total_price} ${item.currency}`,
                }),
            ) ?? [],
        [deliveryNoteItemsData],
    );

    const newData: IOderDetails[] = deliveryNote?.data?.data?.details ?? [];
    const newDataCol: IOderDetails[] | any = useMemo(
        () =>
            newData.map((item, index: number) => ({
                key: index,
                id: item?.id,
                file_name: item?.file_name,
                status: item?.status,
                created_at: moment(item.created_at).format('DD-MMM-YYYY'),
            })),
        [newData],
    );

    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <Flex align="center" style={{ padding: '10px' }}>
                <Button danger type="primary" icon={<LeftOutlined />} ghost onClick={() => setShowNextPage('details')}>
                    Back to Order files
                </Button>
            </Flex>
            {!newData ? (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: '#282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            ) : (
                <Table
                    bordered
                    size="small"
                    columns={columns}
                    dataSource={newDataCol}
                    pagination={newData.length > 14 ? undefined : false}
                />
            )}

            <Modal
                title=""
                centered
                open={open}
                onOk={undefined}
                onCancel={() => setOpen(false)}
                width={1000}
                footer={
                    <Flex justify="flex-end" gap="middle">
                        <Button icon={<FilePdfOutlined />} type="primary" onClick={handlePrint}>
                            Export to PDF
                        </Button>
                        <Button ghost danger type="primary">
                            Close
                        </Button>
                    </Flex>
                }
            >
                <Flex vertical style={{ width: '100%' }}>
                    <Flex ref={componentRef} gap="middle" vertical>
                        <Flex justify="flex-start" vertical>
                            <span>
                                <b>GOODS DELIVERY NOTE</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {deliveryNoteItemsData.facility}
                            </span>
                            <span>
                                <b>Purchase note number: </b>
                                {deliveryNoteItemsData.delivery_file}
                            </span>
                            <span>
                                <b>Cell: </b>
                                {deliveryNoteItemsData.cell_name}
                            </span>
                            <span>
                                <b>Delivered on: </b>
                                {moment(deliveryNoteItemsData.date).format('DD-MMM-YYYY')}
                            </span>
                        </Flex>
                        <Table
                            bordered
                            columns={columns1}
                            size="small"
                            dataSource={newItemsData}
                            pagination={newItemsData.length >= 10 ? undefined : false}
                        />
                        <Flex vertical>
                            <span>
                                <b>Delivered by: </b>
                                {deliveryNoteItemsData.delivered_by}
                            </span>
                            <span>
                                <b>Date: </b>
                                {today}
                            </span>
                        </Flex>
                    </Flex>
                </Flex>
            </Modal>
        </>
    );
};

export default DeliveryNote;
