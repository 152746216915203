/* eslint-disable @typescript-eslint/no-explicit-any */

import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_ORDER_FILE_START 
} from "../../../constants/actionType/orders";
import {  saveData} from '../../../helpers';
import { getOrderFileFail, getOrderFileSuccess } from "../../actions";


export interface ResponseGenerator{
    data: any
}


function* getOrderFile(data:any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/retrieve-order-file', data?.data);
        if (response?.data?.error) {
            yield put(getOrderFileFail(response.data || 'Unknown error'));
        } else {
            yield put(getOrderFileSuccess(response));
        }
    } catch (e: any) {
        yield put(getOrderFileFail(e.message || 'Unknown error'));
    }
}
export default function* fetchGetOrderFile() {
    yield takeEvery(REQUEST_ORDER_FILE_START, getOrderFile)
}