import { 
    REQUEST_BULK_SAVING_FILE_START, 
    REQUEST_BULK_SAVING_FILE_SUCCESS, 
    REQUEST_BULK_SAVING_FILE_FAIL 
} from "../../../../constants/actionType/bulkSaving";


interface IFileData {
    actions: string;
    actions_status: string;
    active: boolean;
    created_at: string;
    current_user: number | string;
    current_user_role: number | string;
    description: string;
    document: string;
    file_name: string;
    id: number;
    number: number;
    status: string;
    steps: number;
    updated_at: string;
}


interface InitialState {
    data: IFileData[];
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const retrieveBulkSavingFiles = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_BULK_SAVING_FILE_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_BULK_SAVING_FILE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_BULK_SAVING_FILE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        
        default: {
            return state;
          }
    }
}

export default retrieveBulkSavingFiles;