/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    CLOSE_DELIVERY_NOTE_FILE_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { closeDeliveryNoteFileFailed, closeDeliveryNoteFileSuccess } from "../../actions";


export interface ResponseGenerator{
    message: string;
    data:any
}

function* closeDeliveryNoteFile(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/close-order-file', data?.data);
        if (response?.data?.error) {
            yield put(closeDeliveryNoteFileFailed(response.data || 'Unknown error'));
        } else {
            yield put(closeDeliveryNoteFileSuccess(response));
        }
    } catch (e: any) {
        yield put(closeDeliveryNoteFileFailed(e.message || 'Unknown error'));
    }
}

export default function* fetchCloseDeliveryNoteFile() {
    yield takeEvery(CLOSE_DELIVERY_NOTE_FILE_START, closeDeliveryNoteFile)
}