/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_DELETE_USER_ACCOUNT_START,
    REQUEST_DELETE_USER_ACCOUNT_SUCCESS,
    REQUEST_DELETE_USER_ACCOUNT_FAIL,
    REQUEST_DELETE_USER_ACCOUNT_CLEAR
} from "../../../constants/actionType/auth";

 export const deleteUserAccount = (data: any) => ({type:  REQUEST_DELETE_USER_ACCOUNT_START, data});
 
 export const deleteUserAccountSuccess = (data:any) => ({
   type: REQUEST_DELETE_USER_ACCOUNT_SUCCESS,
   payload: data
 })
 
 export const deleteUserAccountError = (error:any) => ({
   type: REQUEST_DELETE_USER_ACCOUNT_FAIL,
   payload: error
 })

 export const clearDeleteUserAccountStore = () => ({
  type: REQUEST_DELETE_USER_ACCOUNT_CLEAR
})
