import React from 'react'; // useState
import {
    //  Modal,
    Popconfirm,
    Space,
    Table,
    Tag,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BiArrowBack } from 'react-icons/bi';
import { getFacilityUserData } from '../../../redux/actions/facility/getFacilityUsers';
import {
    clearDeleteUserAccountStore,
    clearFacilityUserStore,
    // deleteUserAccount as deleteUserAccountData,
    updateFacilityUserData,
} from '../../../redux/actions';
import { roles } from '../../../constants/roles';
import NoResultFound from '../../common/noResultFound';
import { toast } from 'react-toastify';

interface DataType {
    id: string;
    name: string;
    username: string;
    email: string;
    address: string;
    userRoleId: number;
    district: string;
    province: string;
    status: string | boolean;
}

interface IFileData {
    id: string | number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    birth_date: string;
    role_id: string | number;
    username: string;
    language: string;
    status: string | boolean;
}

const FacilityUsers: React.FC<any> = ({ facilityId, setChangePage }) => {
    const dispatch = useDispatch();
    // const [open, setOpen] = useState<boolean>(false);
    // const [userToDelete, setUserToDelete] = useState<string | number>();
    const { deleteUserAccount, getFacilityUser, updateFacilityUser } = useSelector((user: any) => user);

    const disableFacility = (id: string | number) => {
        const userData = {
            user_id: id,
            blocked: true,
            unblocked: false,
        };
        dispatch(updateFacilityUserData(userData));
    };

    const activateFacility = (id: string | number) => {
        const userData = {
            user_id: id,
            blocked: false,
            unblocked: true,
        };
        dispatch(updateFacilityUserData(userData));
    };

    // const showModal = () => {
    //     setOpen(true);
    // };

    // const handleCancel = () => {
    //     setOpen(false);
    // };

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Role',
            dataIndex: 'role_id',
            key: 'role_id',
            render: (_, data: any) => {
                return roles.map((item: any) => {
                    if (data?.roleId === item.rolesKey) {
                        return (
                            <>
                                <a color={'grey'}>{item?.name}</a>
                            </>
                        );
                    }
                });
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, status: any) => {
                return (
                    <>
                        {status?.status === true ? (
                            <Tag color={'green'}>{'Activated'}</Tag>
                        ) : (
                            <Tag color={'red'}>{'Disabled'}</Tag>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, data) => (
                <Space size="middle" className="custom-spacer">
                    {data?.status === true ? (
                        <Popconfirm
                            title="Are you sure you want to block this user?"
                            onConfirm={() => disableFacility(data.id)}
                            okText="Yes"
                            cancelText="Cancel"
                        >
                            <a style={{ color: 'red' }}>Disable</a>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Are you sure you want to unblock this user?"
                            onConfirm={() => activateFacility(data.id)}
                            okText="Yes"
                            cancelText="Cancel"
                        >
                            <a style={{ color: 'green' }}>Activate</a>
                        </Popconfirm>
                    )}
                    {/* <a
                        style={{ color: 'red' }}
                        onClick={() => {
                            showModal();
                            setUserToDelete(data.id);
                        }}
                    >
                        {'Delete account'}
                    </a> */}
                </Space>
            ),
        },
    ];

    const facility_users = getFacilityUser?.data?.data === undefined ? [] : getFacilityUser?.data?.data;
    const data: DataType[] = facility_users?.map((item: IFileData) => {
        return {
            id: item?.id,
            name: `${item?.first_name} ${item?.last_name}`,
            email: item?.email,
            phone: item?.phone,
            birthDate: item?.birth_date,
            roleId: item?.role_id,
            username: item?.username,
            language: item?.language,
            status: item?.status,
        };
    });

    useEffect(() => {
        dispatch(
            getFacilityUserData({
                facility_id: facilityId,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (updateFacilityUser?.data?.data?.error) {
            toast(updateFacilityUser?.data?.data?.error?.description);
            dispatch(clearDeleteUserAccountStore());
        }
    }, [updateFacilityUser?.data?.data?.error]);

    useEffect(() => {
        if (deleteUserAccount?.data?.data?.error) {
            toast(deleteUserAccount?.data?.data?.error?.description);
            dispatch(clearDeleteUserAccountStore());
        }
    }, [deleteUserAccount?.data?.data?.error]);

    useEffect(() => {
        if (updateFacilityUser?.data?.data?.description) {
            toast(updateFacilityUser?.data?.message);
            dispatch(clearFacilityUserStore());
            // setOpen(false);
            dispatch(
                getFacilityUserData({
                    facility_id: facilityId,
                }),
            );
        }
    }, [updateFacilityUser?.data]);

    useEffect(() => {
        if (deleteUserAccount?.data?.data?.description) {
            toast(deleteUserAccount?.data?.message);
            dispatch(clearDeleteUserAccountStore());
            // setOpen(false);
            dispatch(
                getFacilityUserData({
                    facility_id: facilityId,
                }),
            );
        }
    }, [deleteUserAccount?.data]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => setChangePage('default')}>
                            <BiArrowBack size={22} />
                            <span>All facilities</span>
                        </Button>
                        <h2></h2>
                    </div>

                    {data.length <= 0 ? <NoResultFound /> : null}
                    {data.length !== 0 ? (
                        <>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={data?.length >= 16 ? undefined : false}
                            />
                        </>
                    ) : null}
                </div>
            </div>
            {/* <Modal title="Delete user account" open={open} onCancel={handleCancel} closable={false} footer={[]}>
                <div className="custom-modal-btn-group">Are you sure you want to delete this account?</div>
                <div className="custom-modal-footer" style={{ justifyContent: 'space-between' }}>
                    <Button size="small" key="submit" onClick={handleCancel} className="modal-cancel-button">
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        key="submit"
                        className="modal-save-button"
                        loading={deleteUserAccount.loading}
                        onClick={() => {
                            dispatch(deleteUserAccountData({ user_id: userToDelete }));
                        }}
                    >
                        Yes
                    </Button>
                </div>
            </Modal> */}
        </>
    );
};

export default FacilityUsers;
