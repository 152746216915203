/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import {
    clearRegisterUserStore,
    getCorrectionalServiceData,
    getFacilityUserData,
    getPublicItemsData,
    getUserDataInfo,
    registerUsers,
} from '../../../redux/actions';
import Input from 'antd/es/input/Input';
import { roles } from '../../../constants/roles';
import { allGenders } from '../../../constants/gender';
import { countryCode } from '../../../constants/telco';
import { toast } from 'react-toastify';
import {
    hasOnlyDigits,
    isFirstTwoZero,
    isFirstTwoZeroZero,
    nameHasSpecialChar,
    numberStartWithSeven,
} from '../../Appointment';
import { appName } from '../../../constants/defaultLabel';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const CreateAccount: React.FC<any> = ({ facilityId, setChangePage }) => {
    const dispatch = useDispatch();
    const [userRole, setUserRole] = useState<any>();
    const [getUserRole, setGetUserRole] = useState<any>();
    const [userGender, setUserGender] = useState('');
    const [form, setForm] = useState({
        email: '',
        username: '',
        phone_number: '',
        first_name: '',
        last_name: '',
        birth_date: '',
        role_id: '',
        is_admin: false,
        is_user: true,
        is_facility: false,
        prison_id: '',
        password: '',
    });

    const { registerUser, getUserData } = userSelector((user) => user);

    const onSubmit = () => {
        const userData = {
            email: form.email,
            username: form.username,
            phone_number: `${countryCode}${form.phone_number}`,
            first_name: form.first_name,
            last_name: form.last_name,
            gender: userGender,
            birth_date: '19',
            role_id: getUserRole,
            is_admin: false,
            is_user: true,
            is_facility: false,
            prison_id: facilityId,
            password: '',
            is_dfc: false,
            is_school: false,
            is_simpo: false,
        };
        if (form.email === '') {
            toast.error('Please provide email');
        } else if (userGender === '') {
            toast.error('Please select user gender.');
        } else if (!getUserRole) {
            toast.error('Please select user role.');
        } else if (nameHasSpecialChar(form.first_name)) {
            toast.error('The provided first name has some special characters.');
        } else if (nameHasSpecialChar(form.last_name)) {
            toast.error('The provided last name has some special characters.');
        } else if (!validateEmail(form.email) && form.email !== '') {
            toast.error('Please provide a valid email');
        } else if (form.phone_number === '') {
            toast.error('Please provide phone number');
        } else if (
            !hasOnlyDigits(form.phone_number) ||
            form.phone_number.length < 9 ||
            form.phone_number.length > 9 ||
            isFirstTwoZeroZero(form.phone_number) ||
            isFirstTwoZero(form.phone_number) ||
            !numberStartWithSeven(form?.phone_number)
        ) {
            toast.error('Please provide a valid phone number');
        } else {
            dispatch(registerUsers(userData));
        }
    };

    const onHandleChangeUserInfo = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const onSearch = (value: string) => {
        return value;
    };

    const onHandleChangeUserRole = (value: string) => setGetUserRole(value);

    useEffect(() => {
        const newList: any = [];
        roles?.map((item: any) => {
            if (['DDCF', 'DCF'].includes(item.name) === true && [1].includes(getUserData?.data?.data[0]?.role_id)) {
                newList.push({
                    label: item?.name,
                    value: item?.rolesKey,
                });
            } else if (
                ['Super Admin', 'Prison Admin', 'DCF'].includes(item.name) !== true &&
                [1].includes(getUserData?.data?.data[0]?.role_id) === false
            ) {
                newList.push({
                    label: item?.name,
                    value: item?.rolesKey,
                });
            }
        });
        setUserRole(newList);
    }, []);

    const onGenderChanges = (value: React.SetStateAction<string>) => {
        setUserGender(value);
    };

    useEffect(() => {
        if (registerUser.data?.data?.error) {
            toast.error(registerUser.data?.data?.error?.description);
            dispatch(clearRegisterUserStore());
        }
    }, [registerUser.data?.data?.error]);

    useEffect(() => {
        if (registerUser.data?.data?.description) {
            toast.success(registerUser.data?.data?.description);
            dispatch(clearRegisterUserStore());
            setChangePage('default');
            dispatch(getUserDataInfo());
            dispatch(getCorrectionalServiceData());
            dispatch(getFacilityUserData({ facility_id: '' }));
        }
    }, [registerUser?.data]);

    useEffect(() => {
        dispatch(getPublicItemsData());
    }, [dispatch]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => setChangePage('default')}>
                            <BiArrowBack size={22} />
                            <span style={{ alignSelf: 'center', marginLeft: '5px' }}>Users accounts list</span>
                        </Button>
                        <h2></h2>
                    </div>
                    <div className="column-box">
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>First name</span>
                            <Input
                                size="large"
                                value={form.first_name}
                                placeholder="Enter first name"
                                name="first_name"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Last name</span>
                            <Input
                                size="large"
                                value={form.last_name}
                                placeholder="Enter last name"
                                name="last_name"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Username</span>
                            <Input
                                size="large"
                                value={form.username}
                                placeholder="Enter username"
                                name="username"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>

                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>{`Select gender`}</span>
                            <Select
                                showSearch
                                placeholder="Select gender"
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={(input, option) => {
                                    const val: any = option?.label ?? '';
                                    return val.toLowerCase().includes(input.toLowerCase());
                                }}
                                onChange={onGenderChanges}
                                options={allGenders}
                            />
                        </div>

                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Email</span>
                            <Input
                                type="email"
                                size="large"
                                value={form.email}
                                placeholder={`text@${appName}.com`}
                                name="email"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Phone number</span>
                            <Input
                                size="large"
                                addonBefore={countryCode}
                                value={form.phone_number}
                                placeholder="78xxxxxxx"
                                name="phone_number"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>

                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>{`Select user's role`}</span>
                            <Select
                                showSearch
                                placeholder="Select user's role"
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={(input, option) => {
                                    const val: any = option?.label ?? '';
                                    return val.toLowerCase().includes(input.toLowerCase());
                                }}
                                onChange={onHandleChangeUserRole}
                                options={userRole}
                            />
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <Button
                                onClick={() => onSubmit()}
                                loading={registerUser?.loading}
                                style={{ background: '#262d30', color: '#fff', float: 'left', width: '350px' }}
                                size="large"
                            >
                                Add user
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateAccount;
