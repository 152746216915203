import {
    FETCH_ACTIVITY_LOG_FAIL, 
    FETCH_ACTIVITY_LOG_START, 
    FETCH_ACTIVITY_LOG_SUCCESS,
    FETCH_ACTIVITY_LOG_CLEAR
} from "../../../constants/actionType/users";
    
    
export interface IFetchActivityLog {
    fetchActivityLog: any,
    fetchActivityLogError: any,
    loadGetActivityLog: boolean
}

export interface IActivityLogPayload {
    filter_action: boolean;
    activity_key: any;
}


export const fetchActivityLogData = (data: any) =>   {
    return { type:  FETCH_ACTIVITY_LOG_START, data }
};

export const fetchActivityLogSuccess = (data: IFetchActivityLog) => ({
    type: FETCH_ACTIVITY_LOG_SUCCESS, 
    payload: data
})

export const fetchActivityLogFail = (error: IFetchActivityLog) => ({ type: FETCH_ACTIVITY_LOG_FAIL, payload: error})

export const clearFetchActivityLog = () => ({ type: FETCH_ACTIVITY_LOG_CLEAR, })