import React from 'react'; // useState
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../common/footer';
import NavigationBar from '../common/navBar';
import './index.css';
import { Button } from 'antd';
import Translate from '../../languages/Translate';
import { appName } from '../../constants/defaultLabel';

const LandingPage = () => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="container-tiny_top-bar">
                <Link to="/request-physical-appointment">
                    <span>{Translate('About Us')}</span>
                </Link>
                <span>|</span>
                <Link to="#">
                    <span>{Translate('Contact Us')}: +250 788 304 663</span>
                </Link>
                <span>|</span>
                <Link to="#">
                    <span>{Translate('Follow Us on')}: Twitter</span>
                </Link>
            </div>
            <NavigationBar title={'Home'} />
            <div className="container-box">
                <div className="container-box__items">
                    <div className="container-box__left" style={{ marginTop: '0px' }}>
                        <div className="container-box__left-item">
                            <div className="title-msg">
                                <span className="home-message-title">{`${appName}`}</span>
                                <span className="home-message-subtitle">{Translate(`Connecting families`)}</span>
                            </div>
                            <div className="btn-groups" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button onClick={() => navigate('/request-physical-appointment')} size="large">
                                    {Translate('Physical visit')}
                                </Button>

                                <Button onClick={() => navigate('/request-virtual-appointment')} size="large">
                                    {Translate('Video visit')}
                                </Button>

                                <Button onClick={() => navigate('/send-money')} size="large">
                                    {Translate('Send money')}
                                </Button>

                                <Button onClick={() => navigate('/access-meeting')} size="large">
                                    {Translate('Join video meeting')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="container-box__right">
                        <div className="widget-options"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LandingPage;
