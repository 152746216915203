/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    AVAILABLE_TIME_CLEAR,
    AVAILABLE_TIME_FAIL, 
    AVAILABLE_TIME_START, 
    AVAILABLE_TIME_SUCCESS 
 } from "../../../constants/actionType/appointment";
import { IGetAvailableTimePayload } from "../../../interfaces/appointment";

 
export const getAvailableTimeData = (data: IGetAvailableTimePayload) => ({type:  AVAILABLE_TIME_START, data});
  
export const getAvailableTimeSuccess = (data: any) => ({
    type: AVAILABLE_TIME_SUCCESS,
    payload: data
  })
  
export const getAvailableTimeError = (error: any) => ({
    type: AVAILABLE_TIME_FAIL,
    payload: error
  })

export const clearGetAvailableTimeStore = () => ({
    type:  AVAILABLE_TIME_CLEAR,
  });