/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_INMATES_WALLET_DETAILS_SUCCESS, 
    GET_INMATES_WALLET_DETAILS_FAIL, 
    GET_INMATES_WALLET_DETAILS_START } from "../../../constants/actionType/inmates";
import { saveData } from '../../../helpers';
import { delay } from "../../../constants/time";



export interface ResponseGenerator{}

function* getInmatesWalletDetails(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/inmate-wallet-report', data?.data);
        yield call(delay, 3000);
        yield put({type: GET_INMATES_WALLET_DETAILS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_INMATES_WALLET_DETAILS_FAIL, error: e.message });
    }
}

export default function* fetchInmatesWalletDetails() {
    yield takeEvery(GET_INMATES_WALLET_DETAILS_START, getInmatesWalletDetails)
}