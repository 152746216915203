import { Button, Select, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { IUserSelectorType } from '../../../../interfaces';
import { getInmateWithZeroWalletData } from '../../../../redux/actions';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SuperAdminInmateWithZeroWallet: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [prisonName, setPrisonName] = useState('');

    const {
        getInmatesWithZeroWallet: { data, loading },
        getPublicItem,
    } = userSelector((user) => user);
    const publicItems = getPublicItem?.data?.data;

    const reportData = data?.data !== undefined ? data?.data : [];

    const inmatesReportData: any = reportData?.inmates?.map((item: any) => {
        return {
            name: `${item?.first_name} ${item?.last_name}`,
            inmate_code: item?.inmate_code,
            gender: item?.gender,
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
    ];

    const onSearch = (value: string) => {
        return value;
    };

    const onHandleChangeCorrectionalService = (value: string) => setPrisonName(value);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <h2>{data?.data?.facility}</h2>
                        <div className="details-btn-group">
                            <div className="right-btn-group">
                                <div className="top-right__items" style={{ width: '100%' }}>
                                    <Select
                                        showSearch
                                        placeholder="Select a correctional facility"
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, option) => {
                                            const val: any = option?.label ?? '';
                                            return val.toLowerCase().includes(input.toLowerCase());
                                        }}
                                        onChange={onHandleChangeCorrectionalService}
                                        options={publicItems?.facilities}
                                    />
                                    <Button
                                        style={{ marginLeft: '30px' }}
                                        loading={loading}
                                        onClick={() => {
                                            dispatch(
                                                getInmateWithZeroWalletData({
                                                    prison_name: prisonName,
                                                }),
                                            );
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {reportData?.length !== 0 ? (
                        <>
                            {' '}
                            {reportData !== undefined ? (
                                <>
                                    <Table
                                        columns={columns}
                                        dataSource={inmatesReportData}
                                        pagination={data?.length >= 10 ? undefined : false}
                                    />
                                </>
                            ) : (
                                <>
                                    <div style={{ margin: '20% auto', height: '800px' }}>
                                        <Spin
                                            style={{ color: ' #282936' }}
                                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="empty-list">
                            <span className="empty-list__title">No results found</span>
                            <span className="empty-list__subtitle">{`Try adjusting your search or filter to find what you're looking for.`}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SuperAdminInmateWithZeroWallet;
