import {
    READ_NOTIFICATION_FAIL,
    READ_NOTIFICATION_START,
    READ_NOTIFICATION_SUCCESS,
} from '../../../constants/actionType/notifications';

export interface IReadNotificationData {
    data: any;
    error: any;
}

export const readNotificationData = (data: any) => ({ type: READ_NOTIFICATION_START, data});

export const readNotificationSuccess = (data: IReadNotificationData) => ({
    type: READ_NOTIFICATION_SUCCESS,
    payload: data,
});

export const readNotificationFail = (error: IReadNotificationData) => ({
    type: READ_NOTIFICATION_FAIL,
    payload: error,
});
