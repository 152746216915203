/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_INMATES_WITH_ZERO_WALLET_SUCCESS,
    GET_INMATES_WITH_ZERO_WALLET_FAIL,
    GET_INMATES_WITH_ZERO_WALLET_START 
} from "../../../constants/actionType/sendMoney";
import { saveData } from '../../../helpers';





export interface ResponseGenerator{
    message: string
}

function* inmatesWithZeroWallet(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'inmates-with-zero-balance', data?.data);
        yield put({type:GET_INMATES_WITH_ZERO_WALLET_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_INMATES_WITH_ZERO_WALLET_FAIL, error: e.message });
    }
}

export default function* fetchInmatesWithZeroWallet() {
    yield takeEvery(GET_INMATES_WITH_ZERO_WALLET_START, inmatesWithZeroWallet)
}