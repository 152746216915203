/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_APPOINTMENT_REPORT_SUCCESS,
    GET_APPOINTMENT_REPORT_FAIL,
    GET_APPOINTMENT_REPORT_START 
} from "../../../constants/actionType/appointment";
import { saveData} from '../../../helpers';


export interface ResponseGenerator{
    message: string
}

function* getAppointmentReport(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'appointments-report', data?.data);
        yield put({type:GET_APPOINTMENT_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_APPOINTMENT_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchGetAppointmentReport() {
    yield takeEvery(GET_APPOINTMENT_REPORT_START, getAppointmentReport)
}