/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    VISITOR_LIMIT_START 
} from "../../../constants/actionType/facility";
import { saveData} from '../../../helpers';
import { visitorLimitError, visitorLimitSuccess } from "../../actions";


export interface ResponseGenerator {
    data: any;
}

function* visitorLimit(data:any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'facility-settings/set-visitor-limit', data?.data);
        
        if (response.data?.error) {
            yield put(visitorLimitError(response.data || 'Unknown error'));
        } else {
            yield put(visitorLimitSuccess(response));
        }
    } catch (e: any) {
        yield put(visitorLimitError(e.message || 'Unknown error'));
    }
}
export default function* fetchSetVisitorLimit() {
    yield takeEvery(VISITOR_LIMIT_START, visitorLimit)
}