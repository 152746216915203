import React from 'react';

const AudioPlayer = ({ audioURL, token }: { audioURL: string; token: string }) => {
    const audioRef: any = React.createRef();

    const playAudio = async () => {
        try {
            const cacheBuster = Date.now();
            const response = await fetch(`${audioURL}?t=${cacheBuster}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                audioRef.current.src = URL.createObjectURL(blob);
                audioRef.current.play();
            } else {
                console.error('Audio playback failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <button onClick={playAudio}>Play with Authorization</button>
            <audio ref={audioRef} controls style={{ marginLeft: '10px' }}>
                <source src={audioURL} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>
    );
};

export default AudioPlayer;
