/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    SENDER_TRANSACTION_REPORT_DETAILS_SUCCESS,
    SENDER_TRANSACTION_REPORT_DETAILS_FAIL,
    SENDER_TRANSACTION_REPORT_DETAILS_START 
} from "../../../constants/actionType/sendMoney";
import { saveData } from '../../../helpers';
import { delay } from "../../../constants/time";




export interface ResponseGenerator{
    message: string
}

function* senderTransactionReportDetails(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'sender-transactions-details-report', data?.data);
        yield call(delay, 3000)
        yield put({type:SENDER_TRANSACTION_REPORT_DETAILS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:SENDER_TRANSACTION_REPORT_DETAILS_FAIL, error: e.message });
    }
}

export default function* fetchSenderTransactionReportDetails() {
    yield takeEvery(SENDER_TRANSACTION_REPORT_DETAILS_START, senderTransactionReportDetails)
}