import { Button, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { senderTransactionReportDetailsData } from '../../../redux/actions';
import moment from 'moment';
import Table, { ColumnsType } from 'antd/es/table';
import { ManageDates } from '..';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { FilePdfOutlined } from '@ant-design/icons';
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
const { RangePicker } = DatePicker;

const SenderTransactionReport: React.FC<any> = ({
    setChangePage,
    senderPhoneNumber,
    dispatch,
    componentRef,
    handlePrint,
}) => {
    const [selectedDate, setSelectedDate]: any = useState();
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);

    const {
        senderTransactionReportDetails: { data, loading },
    } = userSelector((user) => user);

    const reportData = data?.data !== undefined ? data?.data : [];

    const senderReportData: any = reportData?.details?.map((item: any) => {
        return {
            name: `${item?.first_name} ${item?.last_name}`,
            phone_number: item?.phone_number,
            amount: item?.incoming_amount,
            inmate_number: item?.inmate_number,
            frequency: item?.frequency,
            inmateCode: item?.inmate_code,
            date: moment(`${item?.created_at}`).format('YYYY-MM-DD'),
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'RSC case number',
            dataIndex: 'inmateCode',
            key: 'inmateCode',
            width: 150,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 150,
        },
    ];

    const onSelectDate = (dateString: any) => {
        setDates(dateString);

        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const onSearch = () => {
        dispatch(
            senderTransactionReportDetailsData({
                sender_phone_number: senderPhoneNumber,
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            senderTransactionReportDetailsData({
                start_date: '',
                end_date: '',
                sender_phone_number: senderPhoneNumber,
            }),
        );
    }, [dispatch, selectedDate]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => setChangePage('default')}>
                            <BiArrowBack size={18} style={{ color: '#333' }} />
                            <span
                                style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px', color: '#333' }}
                            >
                                All senders
                            </span>
                        </Button>

                        <div className="right-btn-group">
                            <div className="top-right__items" style={{ width: '100%' }}>
                                <div style={{ marginLeft: '5px' }}>
                                    <RangePicker
                                        style={{ padding: '6px' }}
                                        onChange={onSelectDate}
                                        format="YYYY-MM-DD"
                                        defaultValue={[
                                            dayjs(getDate.defaultStartedDate()),
                                            dayjs(getDate.defaultEndDate()),
                                        ]}
                                    />
                                </div>
                                <Button
                                    style={{ marginRight: '30px', marginLeft: '10px' }}
                                    loading={loading}
                                    onClick={onSearch}
                                >
                                    Search
                                </Button>

                                <Button
                                    onClick={() => {
                                        handlePrint();
                                    }}
                                    type="primary"
                                    style={{ marginRight: '30px' }}
                                >
                                    <FilePdfOutlined /> Export to PDF
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="tbl-items">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '200px',
                                marginLeft: '20px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginBottom: '5px',
                                }}
                            >
                                <span>
                                    <b>Name</b>{' '}
                                </span>
                                <span>
                                    {reportData?.sender?.sender_first_name} {reportData?.sender?.sender_last_name}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginBottom: '5px',
                                }}
                            >
                                <span>
                                    <b>Contact</b>{' '}
                                </span>
                                <span>{reportData?.sender?.sender_phone_number}</span>
                            </div>
                        </div>
                    </div>
                    <div ref={componentRef}>
                        <Table
                            columns={columns}
                            dataSource={senderReportData}
                            pagination={data?.length > 14 ? undefined : false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SenderTransactionReport;
