/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Table, Upload, Button, Divider } from 'antd';
import { UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import { toast } from 'react-toastify';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { clearRequestPhoneNumberLinkStore, requestPhoneNumberLinkData } from '../../../../redux/actions';

const requiredColumns = [
    {
        title: 'First name',
        width: 100,
        dataIndex: 'first_name',
        key: 'first_name',
        fixed: 'left',
    },
    {
        title: 'Last name',
        width: 100,
        dataIndex: 'last_name',
        key: 'last_name',
    },
];

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const requiredColumnKeys = requiredColumns.map((col) => col.dataIndex);

const UploadInmatesPhoneNumber: React.FC<{ open: boolean; handleClose: () => void }> = ({ open, handleClose }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    const { requestPhoneNumberLink } = userSelector((user) => user);

    const handleFileUpload = (file: File) => {
        const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXlsx) {
            toast.error('You can only upload XLSX files!');
            return Upload.LIST_IGNORE;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target?.result;
            if (binaryStr) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                if (jsonData.length) {
                    const headers: any = jsonData[0];
                    const headerKeys = headers.map((header: string) => header.toLowerCase().replace(' ', '_'));
                    const isValid = requiredColumnKeys.every((key: any) => headerKeys.includes(key));

                    if (!isValid) {
                        toast.error('The uploaded file does not contain the required columns: First name, Last name.');
                        return Upload.LIST_IGNORE;
                    }

                    const tableColumns = headers.map((header: string) => ({
                        title: header,
                        dataIndex: header.toLowerCase().replace(' ', '_'),
                        key: header.toLowerCase().replace(' ', '_'),
                    }));

                    const tableData = jsonData.slice(1).map((row: any, index: number) => {
                        const rowData: { key: number; [key: string]: any } = { key: index };
                        row.forEach((cell: any, cellIndex: number) => {
                            rowData[headerKeys[cellIndex]] = cell;
                        });
                        return rowData;
                    });

                    setColumns(tableColumns);
                    setData(tableData);
                }
            }
        };
        reader.readAsBinaryString(file);
        return false;
    };

    const handleSaveUploadedFile = () => {
        const saveInmateWithIssues: { inmate_rcs_number: string; iphone_number: string }[] = [];

        const newInmateList = data.map((item) => item).filter(Boolean);

        if (saveInmateWithIssues.length > 0) {
            const issuesMessage = saveInmateWithIssues
                .map((issue) => `${issue.inmate_rcs_number}: ${issue.iphone_number}`)
                .join('\n');
            toast.error(`The following uploaded inmate information contains issues:\n${issuesMessage}`);
        }

        const inmateListData = newInmateList;
        dispatch(requestPhoneNumberLinkData({ data: inmateListData }));
    };

    useEffect(() => {
        if (requestPhoneNumberLink.data?.data?.success) {
            toast.success(requestPhoneNumberLink.data?.message);
            dispatch(clearRequestPhoneNumberLinkStore());
            setData([]);
            setColumns([]);
            handleClose();
        }

        if ([500, 400].includes(requestPhoneNumberLink.data?.status)) {
            toast.error(`${requestPhoneNumberLink.data?.data.message}`);
            dispatch(clearRequestPhoneNumberLinkStore());
        }
    }, [requestPhoneNumberLink.data]);

    return (
        <Modal
            open={open}
            onCancel={() => {
                handleClose();
                setData([]);
                setColumns([]);
            }}
            footer={null}
            width={1000}
        >
            <div className="display-row-items-space-bt" style={{ marginTop: '20px' }}>
                <Upload beforeUpload={handleFileUpload} showUploadList={false}>
                    <Button ghost type="primary" icon={<UploadOutlined />}>
                        Select File
                    </Button>
                </Upload>
                {data.length ? (
                    <Button
                        type="primary"
                        icon={<CloudUploadOutlined />}
                        onClick={handleSaveUploadedFile}
                        style={{ marginRight: '20px' }}
                        loading={requestPhoneNumberLink.loading}
                    >
                        Submit
                    </Button>
                ) : null}
            </div>

            <Divider style={{ margin: '8px' }} />
            <div>
                <Table columns={columns} dataSource={data} size="small" pagination={{ pageSize: 50 }} />
            </div>
        </Modal>
    );
};

export default UploadInmatesPhoneNumber;
