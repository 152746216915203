import {
    GET_INMATES_FAIL, 
    GET_INMATES_START, 
    GET_INMATES_SUCCESS 
    } from "../../../constants/actionType/inmates";
    
    
export interface IInmateData {
    data: any,
    error: any
}

export const getInmateData = (data:any) =>  ({type: GET_INMATES_START, data});

export const getInmatesSuccess = (data: IInmateData) => ({
    type: GET_INMATES_SUCCESS, 
    payload: data
})

export const getInmatesFail = (error: IInmateData) => ({ type: GET_INMATES_FAIL, payload: error})
