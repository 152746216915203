import {
    GET_SHOP_SUMMARY_REPORT_FAIL,
    GET_SHOP_SUMMARY_REPORT_START,
    GET_SHOP_SUMMARY_REPORT_SUCCESS,
} from '../../../constants/actionType/shop';

export interface IShopSummaryReportData {
    data: any;
    error: any;
}

export const getShopSummaryReportData = () => ({ type: GET_SHOP_SUMMARY_REPORT_START });

export const getShopSummaryReportSuccess = (data: IShopSummaryReportData) => ({
    type: GET_SHOP_SUMMARY_REPORT_SUCCESS,
    payload: data,
});

export const getShopSummaryReportFail = (error: IShopSummaryReportData) => ({
    type: GET_SHOP_SUMMARY_REPORT_FAIL,
    payload: error,
});
