/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_ORDER_FILE_FAIL,
    REQUEST_ORDER_FILE_START,
    REQUEST_ORDER_FILE_SUCCESS,
} from '../../../constants/actionType/orders';

export interface ISGetOrderFileData {
    data: any;
    error: any;
}

interface IPayload {
    file_name: string;
    order_file_type: string | number;
}

export const getOrderFileData = (data: IPayload) => ({ type: REQUEST_ORDER_FILE_START, data});

export const getOrderFileSuccess = (data:any) => ({
    type: REQUEST_ORDER_FILE_SUCCESS,
    payload: data,
});

export const getOrderFileFail = (error: ISGetOrderFileData) => ({
    type: REQUEST_ORDER_FILE_FAIL,
    payload: error,
});
