// REQUEST LOGIN
export const REQUEST_SIGN_IN_START = "REQUEST_SIGN_IN_START";
export const REQUEST_SIGN_IN_SUCCESS = "REQUEST_SIGN_IN_SUCCESS";
export const REQUEST_SIGN_IN_FAIL =  "REQUEST_SIGN_IN_FAIL";

// GET USER DATA
export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAIL =  "GET_USER_DATA_FAIL";

// REGISTER USER 
export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL =  "REGISTER_USER_FAIL";
export const REGISTER_USER_CLEAR = "REGISTER_USER_CLEAR";

// LOGOUT USER
export const LOGOUT_USER_START = "LOGOUT_USER_START";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL =  "LOGOUT_USER_FAIL";
export const RESET_ALL_STATE =  "RESET_ALL_STATE";

// DELETE USER ACCOUNT
export const REQUEST_DELETE_USER_ACCOUNT_START = "REQUEST_DELETE_USER_ACCOUNT_START";
export const REQUEST_DELETE_USER_ACCOUNT_SUCCESS = "REQUEST_DELETE_USER_ACCOUNT_SUCCESS";
export const REQUEST_DELETE_USER_ACCOUNT_FAIL =  "REQUEST_DELETE_USER_ACCOUNT_FAIL";
export const REQUEST_DELETE_USER_ACCOUNT_CLEAR =  "REQUEST_DELETE_USER_ACCOUNT_CLEAR";
