/* eslint-disable @typescript-eslint/no-explicit-any */
import {

    SEND_BULK_SMS_CLEAR,
    SEND_BULK_SMS_FAIL, 
    SEND_BULK_SMS_START, 
    SEND_BULK_SMS_SUCCESS 
 } from 'constants/actionType/appointment';


export const sendBulkSMSData = (data: any) => ({
  type: SEND_BULK_SMS_START,
  data
});

export const sendBulkSMSSuccess = (data: any) => ({
  type: SEND_BULK_SMS_SUCCESS,
  payload: data
});

export const sendBulkSMSError = (error: string | any) => ( {
  type: SEND_BULK_SMS_FAIL,
  payload: error
});

export const clearSendBulkSMS = () => ({
  type: SEND_BULK_SMS_CLEAR
});