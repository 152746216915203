/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Flex, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from 'interfaces';
import { clearSaveNewSupplier, getAllSupplierData, saveNewSupplierData } from 'redux/actions';
import { toast } from 'react-toastify';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
const NewSupplier = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState<any>({
        name: '',
        address: '',
    });
    const { createNewSupplier } = userSelector((user) => user);

    const onHandleChange = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const onHandleSubmit = () => {
        const data = {
            name: form?.name,
            address: form?.address,
        };
        dispatch(saveNewSupplierData(data));
    };

    useEffect(() => {
        if (createNewSupplier.data?.data?.success) {
            toast.success(createNewSupplier.data?.message);
            onClose();
            dispatch(getAllSupplierData());
            dispatch(clearSaveNewSupplier());
        }

        if ([500, 400, 422].includes(createNewSupplier.data?.status)) {
            toast.error(createNewSupplier.data?.data.message);
            dispatch(clearSaveNewSupplier());
        }
    }, [createNewSupplier.data]);
    return (
        <Modal
            title="New Supplier"
            open={open}
            onCancel={onClose}
            footer={
                <Flex justify="space-between">
                    <Button onClick={onClose} type="primary" ghost danger>
                        Cancel
                    </Button>
                    <Button
                        disabled={form?.name === '' || form?.address === '' ? true : false}
                        onClick={onHandleSubmit}
                        loading={createNewSupplier?.loading}
                        type="primary"
                        ghost
                    >
                        Save
                    </Button>
                </Flex>
            }
        >
            <Form layout="vertical">
                <div className="form__input">
                    <span>Supplier name</span>
                    <Input
                        type="text"
                        placeholder="Enter supplier name"
                        name="name"
                        value={form?.name}
                        onChange={onHandleChange}
                    />
                </div>
                <div className="form__input">
                    <span>Address</span>
                    <Input
                        type="text"
                        placeholder="Enter address"
                        name="address"
                        value={form?.address}
                        onChange={onHandleChange}
                    />
                </div>
                <Divider />
            </Form>
        </Modal>
    );
};

export default NewSupplier;
