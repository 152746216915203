import {
   CHECK_VISIT_ID_CLEAR,
   CHECK_VISIT_ID_FAIL,
   CHECK_VISIT_ID_START,
   CHECK_VISIT_ID_SUCCESS,
} from '../../../constants/actionType/videoMeeting';

export interface IResponseData {
    data: any;
    error: any;
}

interface IPayload {
    visit_id: string
    guest: string
}

export const checkVisitIDData = (data:IPayload) => ({ type:CHECK_VISIT_ID_START, data});

export const checkVisitIDSuccess = (data: IResponseData) => ({
    type:CHECK_VISIT_ID_SUCCESS,
    payload: data,
});

export const checkVisitIDFail = (error: IResponseData) => ({
    type:CHECK_VISIT_ID_FAIL,
    payload: error,
});


export const clearCheckVisitID = () =>  ({
    type:CHECK_VISIT_ID_CLEAR,
});