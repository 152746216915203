/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_SUPPLIER_SUCCESS, CREATE_SUPPLIER_FAIL, CREATE_SUPPLIER_START } from "../../../constants/actionType/shop";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}

function* createNewSupplier(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'shop/record-suppliers', data?.data);
        yield put({type: CREATE_SUPPLIER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: CREATE_SUPPLIER_FAIL, error: e.message });
    }
}

export default function* getCreateNewSupplier() {
    yield takeEvery(CREATE_SUPPLIER_START, createNewSupplier)
}