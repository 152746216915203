/* eslint-disable @typescript-eslint/no-explicit-any */
import { call,  put, takeEvery } from "redux-saga/effects";
import {
    RECORD_TRANSACTION_SUCCESS,
    RECORD_TRANSACTION_FAIL,
    RECORD_TRANSACTION_START, 
} from "../../../../constants/actionType/bulkTransaction";
import { saveData } from '../../../../helpers';
import { toast } from "react-toastify";


interface IData {
    message: string
}

export interface ResponseGenerator{
    message: string
    data: IData
}

function* recordTransaction(data: any) {
    try {  
        const userData: ResponseGenerator = yield call(saveData, 'record-bulk-transaction', data?.data);
        toast(`${userData?.message || userData?.data?.message || ''}`)
        yield put({type:RECORD_TRANSACTION_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:RECORD_TRANSACTION_FAIL, error: e.message });
    }
}



export default function* fetchRecordedTransaction() {
    yield takeEvery(RECORD_TRANSACTION_START, recordTransaction)

}
