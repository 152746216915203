import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS,
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL,
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START 
} from "../../../../constants/actionType/bulkTransaction";
import { saveData } from '../../../../helpers';
import { ITransactionRequestApprovalOrRejectionPayload } from "../../../../interfaces/bulkTransaction";
import { toast } from "react-toastify";

interface IPayload {
    type: typeof REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START;
    data: ITransactionRequestApprovalOrRejectionPayload
}

interface IData {
    message: string
}

export interface ResponseGenerator{
    message: string;
    data: IData;
}

function* generateBulkTransaction(data: IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'approval-reject-bulk-transaction', data?.data);
        toast(`${userData?.message || userData?.data?.message || ''}`)
        yield put({type:REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS, data: userData});

    } catch (e: unknown) {
        if (e instanceof Error) yield put({ type:REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL, error: e.message });
       
    }
}

export default function* fetchRequestedApprovalOrRejectionTransaction() {
    yield takeEvery(REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START, generateBulkTransaction)
}

