import { 
    REQUEST_PENDING_VISITS_START, 
    REQUEST_PENDING_VISITS_SUCCESS, 
    REQUEST_PENDING_VISITS_FAIL, 
    REQUEST_PENDING_VISITS_CLEAR
} from '../../../constants/actionType/videoMeeting';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const pendingVisits = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_PENDING_VISITS_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_PENDING_VISITS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_PENDING_VISITS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case REQUEST_PENDING_VISITS_CLEAR: {
            return {
                ...state,
                loading: false,
                data: null,
                error: null,
            };
        }

        default: {
            return state;
          }
    }
}

export default pendingVisits;