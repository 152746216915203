/* eslint-disable @typescript-eslint/no-explicit-any */
import {
   GET_CALLING_SETTINGS_FAIL, 
   GET_CALLING_SETTINGS_START, 
   GET_CALLING_SETTINGS_SUCCESS 
    } from "../../../constants/actionType/inmates";
    
    
export interface IGetCallingSettingsData {
    data: any,
    error: any
}

export const getGetCallingSettingsData = () =>  ({type:GET_CALLING_SETTINGS_START});

export const getGetCallingSettingsSuccess = (data: IGetCallingSettingsData) => ({
    type:GET_CALLING_SETTINGS_SUCCESS, 
    payload: data
})

export const getGetCallingSettingsFail = (error: IGetCallingSettingsData) => ({ type:GET_CALLING_SETTINGS_FAIL, payload: error})
