/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { DataType } from 'components/orderOfficer/OrderDetails';
import { IUserSelectorType } from 'interfaces';
import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { requestCallInvoiceData } from 'redux/actions';
import { LeftOutlined } from '@ant-design/icons';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import moment from 'moment';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const InvoiceOnPhoneCalls = () => {
    const dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const { callInvoices } = userSelector((user) => user);
    const columns: ColumnsType<DataType> = [
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Generated On',
            dataIndex: 'generated_on',
            key: 'generated_on',
        },
        {
            title: 'Total const',
            dataIndex: 'total_cost',
            key: 'total_cost',
        },
        {
            title: 'Status',
            dataIndex: 'is_paid',
            key: 'is_paid',
            render: (_, data: any) => {
                return <>{data?.is_paid ? <Tag color={'green'}>{'Paid'}</Tag> : <Tag color={'red'}>{'Unpaid'}</Tag>}</>;
            },
        },
    ];

    const data: any = callInvoices?.data
        ? callInvoices?.data?.map(
              (item: {
                  id: number;
                  generated_on: string;
                  total_cost: number | number;
                  is_paid: boolean;
                  currency: string;
              }) => {
                  return {
                      number: item?.id,
                      generated_on: moment(item?.generated_on).format('YYYY-MM-DD'),
                      total_cost: `${item?.total_cost} ${item?.currency}`,
                      is_paid: item?.is_paid,
                  };
              },
          )
        : [];

    useEffect(() => {
        dispatch(requestCallInvoiceData());
    }, []);

    return (
        <>
            <Flex align="center" style={{ padding: '10px' }}>
                <Button danger type="primary" icon={<LeftOutlined />} ghost onClick={() => navigate('/inmates-list')}>
                    Back to Inmate list
                </Button>
            </Flex>
            <Table
                loading={callInvoices.loading}
                columns={columns}
                dataSource={data}
                pagination={data?.length >= 10 ? undefined : false}
            />
        </>
    );
};

export default InvoiceOnPhoneCalls;
