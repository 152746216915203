/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_CALL_OFFICER_USER_FAIL, 
    GET_CALL_OFFICER_USER_START, 
    GET_CALL_OFFICER_USER_SUCCESS 
} from "../../../constants/actionType/users";
import { fetchData } from "../../../helpers";
import { getCallOfficer } from "../../../types";


interface ResponseGenerator{
    data: getCallOfficer;
}

function* getCallOfficers() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, `user/retrieve-call-officers`);
        yield put({type: GET_CALL_OFFICER_USER_SUCCESS, data: userData});

    } catch (e ) {
        if (e instanceof Error) {
            yield put({ type: GET_CALL_OFFICER_USER_FAIL, error: e.message });
        }
        yield put({ type: GET_CALL_OFFICER_USER_FAIL, error: e });
    }
}

export default function* fetchCallOfficers() {
    yield takeEvery(GET_CALL_OFFICER_USER_START, getCallOfficers)
}