import {
    PREPARE_COUPON_FAIL, 
    PREPARE_COUPON_START, 
    PREPARE_COUPON_SUCCESS 
    } from "../../../constants/actionType/inmates";
    
    
export interface IPrepareCouponData {
    data: any,
    error: any
}

export const prepareCouponData = (data:any) =>  ({type: PREPARE_COUPON_START, data});

export const prepareCouponSuccess = (data: IPrepareCouponData) => ({
    type: PREPARE_COUPON_SUCCESS, 
    payload: data
})

export const prepareCouponFail = (error: IPrepareCouponData) => ({ type: PREPARE_COUPON_FAIL, payload: error})
