// REGISTER USER 
export const REGISTER_FACILITY_START = "REGISTER_FACILITY_START";
export const REGISTER_FACILITY_SUCCESS = "REGISTER_FACILITY_SUCCESS";
export const REGISTER_FACILITY_FAIL =  "REGISTER_FACILITY_FAIL";

export const UPDATE_FACILITY_START = "UPDATE_FACILITY_START";
export const UPDATE_FACILITY_SUCCESS = "UPDATE_FACILITY_SUCCESS";
export const UPDATE_FACILITY_FAIL =  "UPDATE_FACILITY_FAIL";

export const GET_FACILITY_USERS_START = "GET_FACILITY_USERS_START";
export const GET_FACILITY_USERS_SUCCESS = "GET_FACILITY_USERS_SUCCESS";
export const GET_FACILITY_USERS_FAIL =  "GET_FACILITY_USERS_FAIL";

export const UPDATE_FACILITY_USERS_START = "UPDATE_FACILITY_USERS_START";
export const UPDATE_FACILITY_USERS_SUCCESS = "UPDATE_FACILITY_USERS_SUCCESS";
export const UPDATE_FACILITY_USERS_FAIL =  "UPDATE_FACILITY_USERS_FAIL";
export const UPDATE_FACILITY_USERS_CLEAR = "UPDATE_FACILITY_USERS_CLEAR";

export const GET_SUMMARY_REPORT_START = "GET_SUMMARY_REPORT_START";
export const GET_SUMMARY_REPORT_SUCCESS = "GET_SUMMARY_REPORT_SUCCESS";
export const GET_SUMMARY_REPORT_FAIL =  "GET_SUMMARY_REPORT_FAIL";

export const GET_TRANSACTIONS_REPORT_START = "GET_TRANSACTIONS_REPORT_START";
export const GET_TRANSACTIONS_REPORT_SUCCESS = "GET_TRANSACTIONS_REPORT_SUCCESS";
export const GET_TRANSACTIONS_REPORT_FAIL =  "GET_TRANSACTIONS_REPORT_FAIL";

// SET VISIT LIMIT
export const VISITOR_LIMIT_START = "VISITOR_LIMIT_START";
export const VISITOR_LIMIT_SUCCESS = "VISITOR_LIMIT_SUCCESS";
export const VISITOR_LIMIT_FAIL =  "VISITOR_LIMIT_FAIL";
export const VISITOR_LIMIT_CLEAR =  "VISITOR_LIMIT_CLEAR";

// FET VISITOR LIMIT
export const FETCH_VISITOR_LIMIT_START = "FETCH_VISITOR_LIMIT_START";
export const FETCH_VISITOR_LIMIT_SUCCESS = "FETCH_VISITOR_LIMIT_SUCCESS";
export const FETCH_VISITOR_LIMIT_FAIL =  "FETCH_VISITOR_LIMIT_FAIL";
export const FETCH_VISITOR_LIMIT_CLEAR =  "FETCH_VISITOR_LIMIT_CLEAR";