/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    // DrawerProps,
    InputRef,
    Input,
    // Popconfirm,
    // RadioChangeEvent,
    Space,
    Table,
    Tag,
    Select,
    DatePicker,
    // Popconfirm,
    // Popover,
    Modal,
} from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { IUserSelectorType } from '../../../interfaces';
import { ManageDates } from '../../dashboard';
import { getBookingReportData, getPublicItemsData } from '../../../redux/actions';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const { Column } = Table;

const SuperAdminBookingReport: React.FC = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [getInmate, setGetInmates]: any = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedDate, setSelectedDate]: any = useState();
    // const [reason, setReason] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const [open, setOpen] = useState(false);
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);
    const [prisonName, setPrisonName] = useState('');

    const {
        getBookingReport: { data, loading },
        getPublicItem,
    } = userSelector((user) => user);

    const publicItems = getPublicItem?.data?.data;

    const getIncomingAppointment = data?.data !== undefined ? data?.data?.appointment_details : [];

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps: any = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const methoods = [
        {
            label: 'All',
            value: 'all',
        },
        {
            label: 'Physical',
            value: 'physical',
        },
        {
            label: 'Virtual',
            value: 'virtual',
        },
    ];

    const options = [
        {
            label: 'Normal',
            value: 'normal',
        },
        {
            label: 'Critical',
            value: 'critical',
        },
        {
            label: 'Lawyer',
            value: 'lawyer',
        },
        {
            label: 'Rejected',
            value: 'rejected',
        },
        {
            label: 'Approved',
            value: 'approved',
        },
    ];

    const [selectedOptions, setSelectedOptions] = useState<any>('');
    const [selectedMethods, setSelectedMethods] = useState<any>('');

    const handleChangeMethod = (value: string[]) => {
        setSelectedMethods(value);
    };

    const handleChange = (value: string[]) => {
        setSelectedOptions(value);
    };

    const onSearch = () => {
        dispatch(
            getBookingReportData({
                selected_type: 'all',
                selected_status: selectedOptions || 'all',
                recipient: getInmate || 'all',
                selected_method: selectedMethods || 'all',
                prison_name: prisonName,
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            getBookingReportData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
                recipient: 'all',
                selected_type: 'all',
                selected_status: 'all',
                selected_method: 'all',
                prison_name: prisonName,
            }),
        );
        dispatch(getPublicItemsData());
    }, [dispatch]);

    const onSelectDate = (dateString: any) => {
        setDates(dateString);

        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const onHandleChangeInmate = (e: any) => setGetInmates(e.target.value);

    const columnData = getIncomingAppointment?.map((item: any) => {
        return {
            inmateFullName: `${item.first_name} ${item.last_name}`,
            inmateCode: item.inmate_code,
            visitorFullName: `${item.visitor_first_name} ${item.visitor_last_name}`,
            visitDate: item.visit_date,
            visitTime: item.visit_time,
            visitType: item.appointment_type,
            visitMethod: item.appointment_category,
            status: item.status,
            visitorContact: item.visitor_contacts,
            id: item?.id,
        };
    });

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onHandleChangeCorrectionalService = (value: string) => setPrisonName(value);

    return (
        <>
            <div className="user-account-tbl">
                <div className="tbl-items">
                    <div className="details-btn-group">
                        <div className="right-btn-group">
                            <div className="top-right__items" style={{ width: '100%' }}>
                                <Select
                                    showSearch
                                    placeholder="Select a correctional facility"
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    filterOption={(input, option) => {
                                        const val: any = option?.label ?? '';
                                        return val.toLowerCase().includes(input.toLowerCase());
                                    }}
                                    onChange={onHandleChangeCorrectionalService}
                                    options={publicItems?.facilities}
                                />
                                <Select
                                    allowClear
                                    style={{ width: '10%', marginRight: '5px', marginTop: '-2px' }}
                                    placeholder="Select method"
                                    onChange={handleChangeMethod}
                                    options={methoods}
                                />
                                <Select
                                    allowClear
                                    style={{ width: '10%', marginRight: '5px', marginTop: '-2px' }}
                                    placeholder="Please select"
                                    onChange={handleChange}
                                    options={options}
                                />
                                <Input
                                    placeholder="Search by RCS case"
                                    value={getInmate}
                                    onChange={onHandleChangeInmate}
                                    style={{ width: '20%', marginTop: '-2px' }}
                                />

                                <div style={{ marginLeft: '5px', marginTop: '-2px' }}>
                                    <RangePicker
                                        style={{ padding: '6px' }}
                                        onChange={onSelectDate}
                                        format="YYYY-MM-DD"
                                        defaultValue={[
                                            dayjs(getDate.defaultStartedDate()),
                                            dayjs(getDate.defaultEndDate()),
                                        ]}
                                    />
                                </div>
                                <Button style={{ marginLeft: '20px' }} loading={loading} onClick={onSearch}>
                                    Search
                                </Button>

                                <Button onClick={() => setOpen(true)}>Generate report</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tbl-items" style={{ justifyContent: 'flex-start' }}>
                    <div className="tbl-items__col">
                        <span style={{ color: '#777677' }}>All visits</span>
                        <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                            {data?.data?.total_appointment}
                        </span>
                    </div>
                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                        <span style={{ color: '#777677' }}>Virtual visits</span>
                        <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                            {data?.data?.virtual_appointment}
                        </span>
                    </div>
                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                        <span style={{ color: '#777677' }}>Physical visits</span>
                        <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                            {data?.data?.physical_appointment}
                        </span>
                    </div>
                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                        <span style={{ color: '#777677' }}>Critical</span>
                        <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                            {data?.data?.critical}
                        </span>
                    </div>
                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                        <span style={{ color: '#777677' }}>Individuals</span>
                        <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                            {data?.data?.individual}
                        </span>
                    </div>

                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                        <span style={{ color: '#777677' }}>Specials</span>
                        <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                            {data?.data?.special}
                        </span>
                    </div>
                </div>

                <Table dataSource={columnData} pagination={columnData?.length > 14 ? undefined : false}>
                    <Column title="RCS case" dataIndex="inmateCode" fixed="left" key="inmateCode" />
                    <Column
                        title="Inmate name"
                        dataIndex="inmateFullName"
                        key="inmateFullName"
                        filterSearch={getColumnSearchProps('inmateFullName')}
                    />
                    <Column title="Visitor name" dataIndex="visitorFullName" key="visitorFullName" />

                    <Column title="Visitor contact" dataIndex="visitorContact" key="visitorContact" />
                    <Column
                        title="Visit type"
                        dataIndex="visitType"
                        key="visitType"
                        render={(tag: any) => {
                            return (
                                <>
                                    {tag === 'critical' ? <Tag color={'red'}>{tag}</Tag> : null}
                                    {tag === 'normal' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                    {tag === 'special' ? <Tag color={'green'}>{tag}</Tag> : null}
                                    {tag === 'lawyer' ? <Tag color={'orange'}>{tag}</Tag> : null}
                                </>
                            );
                        }}
                    />
                    <Column title="Visit Method" dataIndex="visitMethod" key="visitMethod" />

                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        render={(tag: any) => {
                            return (
                                <>
                                    {tag === 'pending' ? <Tag color={'green'}>{tag}</Tag> : null}
                                    {tag === 'approved' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                    {tag === 'rejected' ? <Tag color={'red'}>{tag}</Tag> : null}
                                </>
                            );
                        }}
                    />
                </Table>
            </div>
            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>
                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>APPOINTMENT REPORT</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {data?.data?.facility}
                            </span>
                            <span>
                                <b>Period: </b>
                                From {moment(data?.data?.start_date).format('DD-MMM-YYYY')} to{' '}
                                {moment(data?.data?.start_date).format('DD-MMM-YYYY')}
                            </span>
                        </div>
                        <Table
                            dataSource={columnData}
                            scroll={{ x: 1300 }}
                            pagination={columnData?.length > 14 ? undefined : false}
                        >
                            <Column
                                title="Inmate name"
                                dataIndex="inmateFullName"
                                key="inmateFullName"
                                filterSearch={getColumnSearchProps('inmateFullName')}
                            />
                            <Column title="RCS case" dataIndex="inmateCode" key="inmateCode" />
                            <Column title="Visitor name" dataIndex="visitorFullName" key="visitorFullName" />

                            <Column title="Visitor " dataIndex="inmateCode" key="inmateCode" />
                            <Column title="Visit date" dataIndex="visitDate" key="visitDate" />
                            <Column title="Time" dataIndex="visitTime" key="visitTime" />
                            <Column
                                title="Visit type"
                                dataIndex="visitType"
                                key="visitType"
                                render={(tag: any) => {
                                    return (
                                        <>
                                            {tag === 'critical' ? <Tag color={'red'}>{tag}</Tag> : null}
                                            {tag === 'normal' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                            {tag === 'special' ? <Tag color={'green'}>{tag}</Tag> : null}
                                            {tag === 'lawyer' ? <Tag color={'organ'}>{tag}</Tag> : null}
                                        </>
                                    );
                                }}
                            />
                            <Column title="Visit Method" dataIndex="visitMethod" key="visitMethod" />

                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(tag: any) => {
                                    return (
                                        <>
                                            {tag === 'pending' ? <Tag color={'green'}>{tag}</Tag> : null}
                                            {tag === 'approved' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                            {tag === 'rejected' ? <Tag color={'red'}>{tag}</Tag> : null}
                                        </>
                                    );
                                }}
                            />
                        </Table>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                                marginTop: '30px',
                            }}
                        ></div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SuperAdminBookingReport;
