import {
    UPLOAD_BULK_TRANSACTION_DOCUMENT_FAIL, 
    UPLOAD_BULK_TRANSACTION_DOCUMENT_START, 
    UPLOAD_BULK_TRANSACTION_DOCUMENT_SUCCESS 
} from "../../../../constants/actionType/bulkTransaction";

interface IDocument {
    data: string,
    error: []
}

interface IError {
    data: string,
}

 export const uploadBulkTransactionFileData = (data: any) => ({type:  UPLOAD_BULK_TRANSACTION_DOCUMENT_START, data});
 
 export const uploadBulkTransactionFileSuccess = (data: IDocument) => ({
   type: UPLOAD_BULK_TRANSACTION_DOCUMENT_SUCCESS,
   payload: data
 })
 
 export const uploadBulkTransactionFileError = (error:IError) => ({
   type: UPLOAD_BULK_TRANSACTION_DOCUMENT_FAIL,
   payload: error
 })