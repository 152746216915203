import React, { useEffect, useState } from 'react';
import { Badge, Button, Empty, Flex, Input, Modal, Spin } from 'antd';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import {
    createBulkTransactionData,
    retrieveBulkTransactionFileData,
    uploadBulkTransactionFileData,
} from '../../../../redux/actions';
import { LoadingOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { AiFillFileImage } from 'react-icons/ai';
import { FaFileUpload } from 'react-icons/fa';
import NoResultFound from '../../../common/noResultFound';
import { IFileData } from '../../../../interfaces/bulkSaving';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import '../sendMoney.css';
import EmptyTable from '../../../common/emptyTable';
import SendOutMoneyModal from '../SendOutMoney';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IDocument {
    name: string;
    file: string | number;
}

const BulkSendingMoney = () => {
    const readDocument = process.env.REACT_APP_API_SAVINGS_DOCUMENT_T_URL;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openR, setOpenR] = useState(false);
    const [file, setFile] = useState<string | null | undefined | ArrayBuffer>();
    const [fileData, setFileData] = useState<IFileData[]>([]);
    const [pendingApprovalRequest, setPendingApprovalRequest] = useState<IFileData[]>([]);
    const [saveDocument, setSaveDocument] = useState<any>();
    const [openSendMoneyModal, setOpenSendMoneyModal] = useState<boolean>(false);
    const [document, setDocument] = useState<IDocument>({
        name: '',
        file: '',
    });
    const [bulkTransactionFileId, setBulkTransactionFileId] = useState<string | number>();
    const {
        createBulkTransaction,
        retrieveBulkTransactionFiles: { data },
        getUserData,
        uploadBulkTransactionDocument,
    } = userSelector((state) => state);

    const prisonId = getUserData?.data?.data[0]?.prison_id;

    const dataCol: any = fileData?.map((item: IFileData) => {
        return {
            id: item?.id,
            file_name: item?.file_name,
            document: item?.document,
            actions_status: item?.actions_status,
            current_user: item?.current_user,
            status: item?.status,
            current_user_role: item?.current_user_role,
            created_at: moment(item.created_at).format('DD-MMM-YYYY'),
        };
    });

    useEffect(() => {
        const newList: IFileData[] = [];
        fileData?.map((file) => {
            if (
                file?.actions_status === 'approved' &&
                file?.current_user_role === getUserData?.data?.data[0]?.role_id
            ) {
                newList.push(file);
            }
        });
        setPendingApprovalRequest(newList);
    }, [fileData]);

    useEffect(() => {
        if (data?.data) {
            setFileData(data?.data);
        }
    }, [data?.data]);

    useEffect(() => {
        if (createBulkTransaction?.data) {
            dispatch(retrieveBulkTransactionFileData());
        }
    }, [dispatch, createBulkTransaction?.data]);

    useEffect(() => {
        dispatch(retrieveBulkTransactionFileData());
    }, [dispatch]);

    const columns: ColumnsType<IFileData> = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Created on',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, data) => {
                return (
                    <div className="tbl-group-btn">
                        {[2, 3, 5, 7].includes(getUserData?.data?.data[0]?.role_id) ? (
                            <a
                                onClick={() => {
                                    showModalR();
                                    setDocument({
                                        name: data?.document,
                                        file: data?.id,
                                    });
                                }}
                            >
                                <AiFillFileImage size={20} color="green" />
                            </a>
                        ) : null}

                        {getUserData?.data?.data[0]?.role_id === 5 ? (
                            <>
                                {' '}
                                <a
                                    onClick={() => {
                                        showModal();
                                        setBulkTransactionFileId(data?.id);
                                    }}
                                >
                                    <FaFileUpload size={18} color="orange" />
                                </a>
                            </>
                        ) : null}

                        <a
                            onClick={() => {
                                navigate('/bulk-transaction-details', {
                                    state: {
                                        fileId: data?.id,
                                        status: data?.actions_status,
                                        fileCurrentUser: data?.current_user_role,
                                    },
                                });
                            }}
                        >
                            Details
                        </a>
                    </div>
                );
            },
        },
    ];

    const onCreateBulkSavingFile = () => {
        dispatch(createBulkTransactionData(undefined));
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setSaveDocument(null);
        setFile(null);
    };

    const showModalR = () => {
        setOpenR(true);
    };

    const handleCancelR = () => {
        setOpenR(false);
    };

    const handleFileChange = (event: any) => {
        const targetFile = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setFile(reader.result);
        };

        reader.readAsDataURL(targetFile);

        const formData = new FormData();
        formData.append('file', targetFile, targetFile.name);
        setSaveDocument(formData);
    };

    const onSaveDocument = () => {
        dispatch(uploadBulkTransactionFileData({ file: saveDocument, transactionFileId: bulkTransactionFileId }));
    };

    const onHandleOpenSendMoney = () => setOpenSendMoneyModal(true);
    const onHandleCloseSendMoney = () => setOpenSendMoneyModal(false);

    const url = `${readDocument}${prisonId}/${document.file}/${document.name}`;

    return (
        <>
            <div className="user-account-tbl">
                <Flex vertical>
                    <Flex justify="flex-end" gap="small" style={{ padding: '10px', width: '100%' }}>
                        {[2, 3, 7].includes(getUserData?.data?.data[0]?.role_id) ? (
                            <>
                                <Badge count={pendingApprovalRequest?.length} offset={[-40, 10]} showZero>
                                    <Button
                                        id="transparent-btn"
                                        onClick={() =>
                                            navigate('/pending-approval-request', {
                                                state: pendingApprovalRequest,
                                            })
                                        }
                                    >
                                        <span className="notification-content">Approval file requests</span>
                                    </Button>
                                </Badge>
                            </>
                        ) : null}
                        {getUserData?.data?.data[0]?.role_id === 5 ? (
                            <Flex gap="small">
                                <Button
                                    ghost
                                    type="primary"
                                    loading={createBulkTransaction.loading}
                                    onClick={() => onCreateBulkSavingFile()}
                                >
                                    Generate Inmate Transaction File
                                </Button>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        navigate('/record-transaction');
                                    }}
                                >
                                    Record Bulk Transaction
                                </Button>
                                <Button type="primary" ghost onClick={onHandleOpenSendMoney}>
                                    Send Money
                                </Button>
                            </Flex>
                        ) : null}
                    </Flex>

                    <div>
                        {data?.length === 0 || data === undefined ? (
                            <EmptyTable
                                title={'No file generate yet'}
                                subTitle={`Generate Bulk transaction files by`}
                                Icon={<Empty />}
                                button={
                                    <Button
                                        loading={createBulkTransaction.loading}
                                        onClick={() => onCreateBulkSavingFile()}
                                        className="goto"
                                    >
                                        clicking here
                                    </Button>
                                }
                            />
                        ) : (
                            <>
                                {fileData !== undefined ? (
                                    <>
                                        {fileData?.length !== 0 ? (
                                            <Table
                                                columns={columns}
                                                dataSource={dataCol}
                                                pagination={dataCol.length > 14 ? undefined : false}
                                            />
                                        ) : (
                                            <NoResultFound />
                                        )}
                                    </>
                                ) : (
                                    <div style={{ margin: '20% auto', height: '800px' }}>
                                        <Spin
                                            style={{ color: ' #282936' }}
                                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Flex>
            </div>

            <Modal width={800} title="Upload file" open={open} onCancel={handleCancel} closable={false} footer={[]}>
                <div className="custom-modal-btn-group">
                    <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                        <Input
                            type="file"
                            onChange={handleFileChange}
                            style={{ background: '#282936', color: '#ffff' }}
                        />
                    </div>
                    {file && <img src={`${file}`} alt="Preview" style={{ maxWidth: '100%' }} />}
                </div>
                <div className="custom-modal-footer" style={{ justifyContent: 'space-between' }}>
                    <Button key="submit" onClick={handleCancel} style={{ background: '#cf1322', color: '#ffff' }}>
                        Cancel
                    </Button>
                    <Button
                        key="submit"
                        style={{ background: '#282936', color: '#ffff' }}
                        onClick={() => onSaveDocument()}
                        loading={uploadBulkTransactionDocument?.loading}
                    >
                        Save
                    </Button>
                </div>
            </Modal>

            <Modal width={800} title="Attached file" open={openR} onCancel={handleCancelR} closable={false} footer={[]}>
                <div className="custom-modal-btn-group">{document && <img src={url} alt={document.name} />}</div>
                <div className="custom-modal-footer" style={{ justifyContent: 'space-between' }}>
                    <div></div>
                    <Button
                        key="submit"
                        onClick={() => handleCancelR()}
                        style={{ background: '#282936', color: '#ffff' }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <SendOutMoneyModal open={openSendMoneyModal} onClose={onHandleCloseSendMoney} />
        </>
    );
};

export default BulkSendingMoney;
