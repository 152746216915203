import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import ContainerLayout from '../../common/containerLayout';
import { clearConfirmVisitStore, confirmAppointmentData } from '../../../redux/actions';
import { IUserSelectorType } from '../../../interfaces';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IForm {
    visit_code: string;
    confirm_visit: boolean;
    get_visitor: boolean;
}

const ConfirmVisit: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form, setForm] = useState<IForm>({
        visit_code: '',
        confirm_visit: false,
        get_visitor: true,
    });

    const { confirmAppointment } = userSelector((state) => state);

    const onHandleChangeInput = (event: any) => {
        const { name, value } = event.target;
        const data: any = { ...form };
        data[name] = value;
        setForm(data);
    };

    const onGetVisitor = () => {
        dispatch(
            confirmAppointmentData({
                visit_code: form.visit_code,
                confirm_visit: false,
                get_visitor: true,
            }),
        );
    };

    const onConfirmVisit = () => {
        dispatch(
            confirmAppointmentData({
                visit_code: form.visit_code,
                confirm_visit: true,
                get_visitor: false,
            }),
        );
    };

    const userFound = confirmAppointment?.data?.data;

    useEffect(() => {
        if (confirmAppointment?.data?.data?.error) {
            toast(confirmAppointment?.data?.data?.message);
            dispatch(clearConfirmVisitStore());
        }
    }, [confirmAppointment?.data?.data?.error]);

    useEffect(() => {
        if (confirmAppointment?.data?.data?.success) {
            toast(confirmAppointment?.data?.data?.description);
            dispatch(clearConfirmVisitStore());
        }
    }, [confirmAppointment?.data?.data?.success]);

    return (
        <>
            <ContainerLayout
                title={'Confirm visit'}
                leftTopItem={<Button onClick={() => navigate('/completed-visits')}>Completed visits</Button>}
            >
                <div className="align-middle__box">
                    {!confirmAppointment?.data?.data ? (
                        <div className="align-middle__box-form box-size">
                            <div className="form__input">
                                <span>Enter Visitor National ID No. or Passport number or visit code</span>
                                <Input
                                    value={form.visit_code}
                                    name={'visit_code'}
                                    onChange={onHandleChangeInput}
                                    placeholder="Enter Visitor National ID No. or Passport number or visit code"
                                />
                            </div>

                            <Button
                                className="apply-change-button"
                                size="large"
                                loading={confirmAppointment.loading}
                                disabled={form.visit_code === '' ? true : false}
                                onClick={() => onGetVisitor()}
                            >
                                Search
                            </Button>
                        </div>
                    ) : null}

                    {confirmAppointment?.data?.data ? (
                        <div className="align-middle__box-form">
                            <span className="align-middle__box--title">Visitor Information</span>
                            <div className="align-middle__box-row">
                                <span className="left-label">Name</span>
                                <span className="right-label">
                                    {userFound?.visitor_first_name} {userFound?.visitor_last_name}
                                </span>
                            </div>
                            <div className="align-middle__box-row">
                                <span className="left-label">Gender</span>
                                <span className="right-label">
                                    {userFound?.visitor_gender === 'F' ? 'Female' : 'Male'}
                                </span>
                            </div>
                            <div className="align-middle__box-row">
                                <span className="left-label">Age</span>
                                <span className="right-label">{userFound?.visitor_age}</span>
                            </div>
                            <div className="align-middle__box-row">
                                <span className="left-label">National ID No.</span>
                                <span className="right-label">{userFound?.visitor_identification}</span>
                            </div>
                            <div className="align-middle__box-row">
                                <span className="left-label">Passport No.</span>
                                <span className="right-label">{userFound?.visitor_passport}</span>
                            </div>
                            <div className="align-middle__box-row">
                                <span className="left-label">Phone number</span>
                                <span className="right-label">+{userFound?.visitor_contacts}</span>
                            </div>
                            <div className="align-middle__box-row">
                                <span className="left-label">Phone number</span>
                                <span className="right-label">+{userFound?.phone}</span>
                            </div>

                            <div className="align-middle__box-row">
                                <span className="left-label">Visit date</span>
                                <span className="right-label">{userFound?.visit_date}</span>
                            </div>

                            <div className="align-middle__box-row">
                                <span className="left-label">Visit time</span>
                                <span className="right-label">{userFound?.visit_time}</span>
                            </div>

                            <div className="align-middle__box-row">
                                <span className="left-label">Type of visit</span>
                                <span className="right-label">{userFound?.appointment_type}</span>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Button
                                    className="apply-change-button"
                                    size="large"
                                    onClick={() => {
                                        dispatch(clearConfirmVisitStore());
                                    }}
                                    style={{ width: '100px', color: '#333', background: 'transparent' }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="apply-change-button"
                                    size="large"
                                    loading={confirmAppointment.loading}
                                    onClick={() => onConfirmVisit()}
                                    style={{ width: '100px' }}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </ContainerLayout>
        </>
    );
};

export default ConfirmVisit;
