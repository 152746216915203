/* eslint-disable @typescript-eslint/no-explicit-any */
import { 
    AVAILABLE_TIME_START, 
    AVAILABLE_TIME_SUCCESS, 
    AVAILABLE_TIME_FAIL,
    AVAILABLE_TIME_CLEAR
} from "../../../constants/actionType/appointment";



interface InitialState {
    data:  any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: null,
    error: null,
    loading: false,
}

const getAvailableTime = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case AVAILABLE_TIME_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case AVAILABLE_TIME_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case AVAILABLE_TIME_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        }

        case AVAILABLE_TIME_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }

        default: {
            return state;
          }
    }
}

export default getAvailableTime;