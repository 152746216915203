/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    MANAGE_ZONES_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { manageZonesError, manageZonesSuccess } from "../../actions";


export interface ResponseGenerator{
   data: any
}

function* manageZones(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'facility-settings/manage-zones', data?.data);
        if (response?.data?.error) {
            yield put(manageZonesError(response.data || 'Unknown error'));
        } else {
            yield put(manageZonesSuccess(response));
        }
        } catch (e: any) {
        yield put(manageZonesError(e.message || 'Unknown error'));
        }
}



export default function* fetchManageZones() {
    yield takeEvery(MANAGE_ZONES_START, manageZones)
}