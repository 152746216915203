/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Button, Spin, Table, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { FilePdfOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { clearPurchaseOrderDetails, purchaseOrderData } from '../../../redux/actions';
import { IUserSelectorType } from '../../../interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import exportToXLSX from 'utils/ExportToXLSXFile';

interface IOderDetails {
    id: string;
    price: string;
    picture: string;
    article: string;
    quantity: string;
    currency: string;
}
interface DataType {
    id: string;
    name: string;
    username: number;
    address: string;
    user_profiles: string[];
    order_products: [IOderDetails];
    userRoleId: number;
    rows: any;
    firstName: string;
    lastName: string;
    status: string;
    amount: string;
    send_moneys: any;
    currency: string;
    totalQuantity: string;
    totalAmount: string;
    price: string;
    quantity: string;
    orderedProducts: [IOderDetails];
    inmateWallet: string;
    walletAfterPayment: string;
    article: string;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const PurchaseOrdersDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const {
        purchaseOrder: { data, loading },
    } = userSelector((user) => user);

    const orderFileId = state && state?.orderFileId;

    const dataCol: DataType[] =
        data?.details?.map((item: any, index: number) => ({
            key: index,
            name: item?.item ?? '',
            quantity: item?.quantity ?? 0,
            unit_price: item?.unit_price ?? 0,
            total_price: item?.total_price ?? 0,
            order_day: item?.order_day ?? '',
        })) || [];

    const baseColumns: ColumnsType<DataType> = [
        {
            title: 'Items',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
    ];

    const daysColumn = { title: 'Days', dataIndex: 'order_day', key: 'order_day' };
    const includeDaysColumn = data?.details?.some((item: any) => item.order_day && item.order_day.trim() !== '');

    const columns = includeDaysColumn ? [daysColumn, ...baseColumns] : baseColumns;

    useEffect(() => {
        if (orderFileId) {
            dispatch(
                purchaseOrderData({
                    ordersFileId: orderFileId,
                }),
            );
        }
    }, [orderFileId]);

    useEffect(() => {
        if (!state) {
            navigate('/orders');
        }
    }, [state]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleExport = () => {
        const headerMapping: { [key: string]: string } = {
            name: 'Name',
            quantity: 'Quantity',
            unit_price: 'Unit price',
            total_price: 'Total price',
            order_day: 'Order day',
        };

        const dataToExport = data?.details?.map((item: any) => ({
            name: item?.item ?? '',
            quantity: item?.quantity ?? 0,
            unit_price: item?.unit_price ?? 0,
            total_price: item?.total_price ?? 0,
            order_day: item?.order_day ?? '',
        }));

        exportToXLSX(dataToExport, 'ExportedData.xlsx', headerMapping);
    };

    return (
        <Flex style={{ width: '100%' }} vertical>
            <Flex align="center" style={{ padding: '10px' }}>
                <Button
                    danger
                    type="primary"
                    icon={<LeftOutlined />}
                    ghost
                    onClick={() => {
                        navigate('/orders');
                        dispatch(clearPurchaseOrderDetails());
                    }}
                >
                    Back to purchaser orders
                </Button>
            </Flex>
            <Flex
                align="center"
                justify="space-between"
                className="white-background"
                style={{ padding: '10px', width: '100%' }}
            >
                <div className="tbl-items__col">
                    <span className="default-grey-color">Order total amount</span>
                    <span className="bold-text">{!data ? 0 : `${data?.total_order_amount} ${data?.currency}`}</span>
                </div>
                <Button ghost type="primary" onClick={handleExport}>
                    Export to XLSX
                </Button>
            </Flex>

            {loading === undefined ? (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            ) : (
                <Flex vertical>
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={dataCol}
                        pagination={data?.length >= 30 ? undefined : false}
                    />
                </Flex>
            )}

            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>
                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>PURCHASE ORDER</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {data?.data?.facility}
                            </span>
                            <span>
                                <b>Deliver note number: </b>
                                {data?.data?.delivery_file}
                            </span>
                            <span>
                                <b>Delivered on: </b>
                                {moment(data?.data?.date).format('DD-MMM-YYYY')}
                            </span>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataCol}
                            pagination={data?.data?.length >= 30 ? undefined : false}
                        />
                        <div className="print-users">
                            <span>
                                <b>Prepared by: </b>
                                {data?.data?.delivered_by}
                            </span>
                        </div>

                        {data?.data?.reviewers?.map((item: any) => {
                            return (
                                <>
                                    {' '}
                                    <div className="print-users">
                                        {item?.role_id === 5 ? (
                                            <span>
                                                <b>Reviewed by: </b>
                                                {item?.first_name} {item?.last_name}
                                            </span>
                                        ) : null}
                                        {item?.role_id === 3 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Verified for cash availability by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                        {item?.role_id === 2 || item?.role_id == 7 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Approved by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </Flex>
    );
};

export default PurchaseOrdersDetails;
