/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    SEND_MONEY_FAIL, 
    SEND_MONEY_START, 
    SEND_MONEY_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const sendMoneys = (data: any) => ({type:  SEND_MONEY_START, data});
 
 export const sendMoneySuccess = (data:any) => ({
   type: SEND_MONEY_SUCCESS,
   payload: data
 })
 
 export const sendMoneyError = (error:any) => ({
   type: SEND_MONEY_FAIL,
   payload: error
 })