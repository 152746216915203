/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REGISTER_USER_FAIL, 
    REGISTER_USER_START, 
    REGISTER_USER_SUCCESS ,
    REGISTER_USER_CLEAR
} from "../../../constants/actionType/auth";

 export const registerUsers = (data: any) => ({type:  REGISTER_USER_START, data});
 
 export const registerUserSuccess = (data:any) => ({
   type: REGISTER_USER_SUCCESS,
   payload: data
 })
 
 export const registerUserError = (error:any) => ({
   type: REGISTER_USER_FAIL,
   payload: error
 })

 export const clearRegisterUserStore = () => ({
  type: REGISTER_USER_CLEAR
})
