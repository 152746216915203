/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    MANAGE_ZONES_FAIL, 
    MANAGE_ZONES_START, 
    MANAGE_ZONES_SUCCESS ,
    MANAGE_ZONES_CLEAR
} from "../../../constants/actionType/orders"

interface IPayload {
  add_zone: boolean;
  add_cell: boolean;
  add_village: boolean;
  zone_name: string;
  cell_name: string;
  zone_id: number | string;
  cell_id: number | string;
  village_name: string;
}

 export const manageZonesData = (data: IPayload) => ({type:  MANAGE_ZONES_START, data});
 
 export const manageZonesSuccess = (data:any) => ({
   type: MANAGE_ZONES_SUCCESS,
   payload: data
 })
 
 export const manageZonesError = (error:any) => ({
   type: MANAGE_ZONES_FAIL,
   payload: error
 })

 export const clearManageZonesStore = () => ({
  type: MANAGE_ZONES_CLEAR
});