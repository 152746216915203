/* eslint-disable @typescript-eslint/no-explicit-any */

import { call, put, takeEvery } from "redux-saga/effects";
import { UPDATE_PRODUCT_START } from "../../../constants/actionType/shop";
import { saveData } from '../../../helpers';
import { updateProductError, updateProductSuccess } from "redux/actions";



export interface ResponseGenerator{
    message: any
    data: any;
}

function* updateProduct(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'shop/update-product-item', data?.data);
        if (response?.data?.error) {
            yield put(updateProductError(response.data || 'Unknown error'));
        } else {
            yield put(updateProductSuccess(response));
        }
    } catch (e: any) {
        yield put(updateProductError(e.message || 'Unknown error'));
    }
}

export default function* getUpdateProduct() {
    yield takeEvery(UPDATE_PRODUCT_START, updateProduct)
}