/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_INMATES_SUCCESS, GET_INMATES_FAIL, GET_INMATES_START } from "../../../constants/actionType/inmates";
import {  saveData } from '../../../helpers';
import { delay } from '../../../constants/time';


export interface ResponseGenerator{}

function* getInmates(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/retrieve/inmates', data?.data);
        yield call(delay, 3000)
        yield put({type: GET_INMATES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_INMATES_FAIL, error: e.message });
    }
}

export default function* fetchInmates() {
    yield takeEvery(GET_INMATES_START, getInmates)
}