import {
  REQUEST_APPROVEMENT_OR_REJECTION_CLEAR,
    REQUEST_APPROVEMENT_OR_REJECTION_FAIL, 
    REQUEST_APPROVEMENT_OR_REJECTION_START, 
    REQUEST_APPROVEMENT_OR_REJECTION_SUCCESS 
} from "../../../../constants/actionType/bulkSaving";
import { IError, IRequestApprovalOrRejectionPayload, IResponse } from "../../../../interfaces/bulkSaving";


 export const requestApprovalOrRejectionData = (data: IRequestApprovalOrRejectionPayload) => 
 ({type:  REQUEST_APPROVEMENT_OR_REJECTION_START, data});
 
 export const requestApprovalOrRejectionSuccess = (data: IResponse) => ({
   type: REQUEST_APPROVEMENT_OR_REJECTION_SUCCESS,
   payload: data
 })
 
 export const requestApprovalOrRejectionError = (error:IError ) => ({
   type: REQUEST_APPROVEMENT_OR_REJECTION_FAIL,
   payload: error
 })

 export const clearRequestApprovalOrRejectionStore = () => ({type: REQUEST_APPROVEMENT_OR_REJECTION_CLEAR})