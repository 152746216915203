import {
    REQUEST_PENDING_VISITS_CLEAR,
    REQUEST_PENDING_VISITS_FAIL,
    REQUEST_PENDING_VISITS_START,
    REQUEST_PENDING_VISITS_SUCCESS,
 } from '../../../constants/actionType/videoMeeting';
 
 interface IResponseData {
     data: any;
     error: any;
 }
 
 export const pendingVisitData = () => ({ type:REQUEST_PENDING_VISITS_START});
 
 export const pendingVisitSuccess = (data: IResponseData) => ({
     type:REQUEST_PENDING_VISITS_SUCCESS,
     payload: data,
 });
 
 export const pendingVisitFail = (error: IResponseData) => ({
     type:REQUEST_PENDING_VISITS_FAIL,
     payload: error,
 });
 
 
 export const clearPendingVisit = () =>  ({
     type:REQUEST_PENDING_VISITS_CLEAR,
 });