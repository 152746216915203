/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginPage from '../../components/loginPage';
import {
    getUserDataInfo,
    // logoutData,
    signIn,
} from '../../redux/actions';
import { ILogin } from '../../interfaces/auth';
import { toast } from 'react-toastify';
import { generateRandomCode } from '../../utils/randomCode';

interface User {
    id: string;
    name: string;
}

const isNumeric = (value: any) => {
    return /^\d+$/.test(value);
};

const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const defaultData = {
    email: '',
    password: '',
    otp: '',
};

const LoginPageContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [showOtp, setShowOtp] = useState(false);
    const [isEmail, setIsEmail] = useState<any>({
        isEmail: true,
        isPhoneNumber: true,
    });
    const [alertMessage, setAlertMessage] = useState<any>();
    const [form, setForm] = useState<any>(defaultData);

    const splitLocation: any = pathname.split('/');

    const onHandleInputChanges = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        const formData: any = { ...form };
        formData[name] = value;
        setForm(formData);
    };

    const userLoggedIn = [];

    const handleLogin = (user: User) => {
        if (data?.access_fresh_token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            userLoggedIn.push(data?.userData?.userRoleId);
            if (data?.access_fresh_token) {
                localStorage.setItem('token', data?.access_fresh_token);
            }

            if (data?.access_fresh_token && data?.userData?.userRoleId === 8) {
                setShowOtp(false);
                setForm(defaultData);
                localStorage.setItem('token', data?.access_fresh_token);
            }

            if (data?.userData?.login_status === false) {
                const dataToSend = { email: form.email };
                localStorage.setItem('isUpdateCredentials', generateRandomCode(10));
                navigate('/change-password', { state: dataToSend });
            }

            if (data?.userData?.userRoleId === 1 && data?.userData?.login_status) {
                navigate('/general-dashboard');
                setShowOtp(false);
                setForm(defaultData);
                setTimeout(function () {
                    window.location.reload();
                });
            }

            if (
                (data?.userData?.userRoleId === 2 && data?.userData?.login_status) ||
                (data?.userData?.userRoleId === 7 && data?.userData?.login_status)
            ) {
                navigate('/dashboard');
                setShowOtp(false);
                setForm(defaultData);
                setTimeout(function () {
                    window.location.reload();
                });
            }

            if (data?.userData?.userRoleId === 3 && data?.userData?.login_status) {
                navigate('/dashboard');
                setShowOtp(false);
                setForm(defaultData);
                setTimeout(function () {
                    window.location.reload();
                });
            }

            if (data?.userData?.userRoleId === 4 && data?.userData?.login_status) {
                navigate('/shop-products');
                setShowOtp(false);
                setForm(defaultData);
                setTimeout(function () {
                    window.location.reload();
                });
            }

            if (data?.userData?.userRoleId === 5 && data?.userData?.login_status) {
                navigate('/dashboard');
                setTimeout(function () {
                    window.location.reload();
                });
                setShowOtp(false);
                setForm(defaultData);
            }

            if (data?.userData?.userRoleId === 6 && data?.userData?.login_status) {
                navigate('/dashboard');
                setShowOtp(false);
                setForm(defaultData);
                setTimeout(function () {
                    window.location.reload();
                });
            }

            if (data?.userData?.userRoleId === 8 && data?.userData?.login_status) {
                toast.info('No specific page has been allocated for this role');
                setShowOtp(false);
                setForm(defaultData);
            }
        }
    };

    const {
        signInUser: { data, loading },
    } = useSelector((user: any) => user);

    const onSubmit = () => {
        const userData: ILogin = {
            email: isEmail,
            password: form.password || '',
            otp: form.otp || '',
        };

        if (form.email === '' || form.password === '') {
            toast.error('You have to fill all fields.');
        } else if (!isNumeric(form.email) && !validateEmail(form.email)) {
            toast.error('Please provide a valid email or phone number');
        } else if (isNumeric(form.email) === true && form.email.length < 8) {
            toast.error('Please provide a valid phone number');
        } else {
            //! Check if there an existing user login
            setAlertMessage('');
            dispatch(signIn(userData));
        }
    };

    useEffect(() => {
        if (validateEmail(form.email) === true) {
            setIsEmail(form.email);
        }

        if (isNumeric(form.email) == true) {
            setIsEmail(form.email);
        }
    }, [isEmail, form.email]);

    useEffect(() => {
        const routeName = splitLocation[1];
        if (routeName !== 'change-password') {
            localStorage.removeItem('isUpdateCredentials');
        }
    }, []);

    useEffect(() => {
        if (data?.access_fresh_token) {
            handleLogin({ id: data?.userData, name: data?.access_fresh_token });
        }
    }, [data?.access_fresh_token]);

    useEffect(() => {
        if (data?.data?.failed) {
            toast.error(data.data?.message);
        }
        if (data?.data?.error !== undefined) {
            setAlertMessage(data?.data?.error?.description);
            toast.error(data?.data?.error?.description);
        }
        if (data?.data?.provideOtp) {
            toast.success(data?.data?.description);
            setShowOtp(true);
        }
        if (data?.data?.value) {
            setShowOtp(false);
            setForm(defaultData);
        } else {
            setAlertMessage('');
        }
    }, [data?.data]);

    useEffect(() => {
        if (alertMessage === 'data and hash arguments required') {
            setAlertMessage('Invalid email or password');
        }
    }, [alertMessage]);

    useEffect(() => {
        dispatch(getUserDataInfo());
    }, [dispatch]);

    return (
        <>
            <LoginPage
                loading={loading}
                onSubmit={onSubmit}
                form={form}
                onHandleInputChanges={onHandleInputChanges}
                showOtp={showOtp}
            />
        </>
    );
};

export default LoginPageContainer;
