/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_BIND_FILE_AND_CELL_CLEAR,
    REQUEST_BIND_FILE_AND_CELL_FAIL, 
    REQUEST_BIND_FILE_AND_CELL_START, 
    REQUEST_BIND_FILE_AND_CELL_SUCCESS 
 } from "../../../constants/actionType/orders";


export const requestBindFileAndCellData = (data: any) => ({
  type: REQUEST_BIND_FILE_AND_CELL_START,
  data
});

export const requestBindFileAndCellSuccess = (data: any) => ({
  type: REQUEST_BIND_FILE_AND_CELL_SUCCESS,
  payload: data
});

export const requestBindFileAndCellError = (error: string | any) => ( {
  type: REQUEST_BIND_FILE_AND_CELL_FAIL,
  payload: error
});

export const clearRequestBindFileAndCellStore = () => ({
  type: REQUEST_BIND_FILE_AND_CELL_CLEAR
});