/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     RETRIEVE_PURCHASE_ORDER_LIST_START 
} from "../../../constants/actionType/orders";
import { saveData} from '../../../helpers';
import { retrievePurchaseOrderListError, retrievePurchaseOrderListSuccess } from "../../actions";


interface ResponseGenerator {
    data: any;
}

function* retrievePurchaseOrderList(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/retrieve-purchase-orders', action.data);
        if (response?.data?.error) {
            yield put(retrievePurchaseOrderListError(response.data || 'Unknown error'));
        } else {
            yield put(retrievePurchaseOrderListSuccess(response));
        }
    } catch (e: any) {
        yield put(retrievePurchaseOrderListError(e.message || 'Unknown error'));
    }
}

export default function* fetchPurchaseOrderList() {
    yield takeEvery(RETRIEVE_PURCHASE_ORDER_LIST_START, retrievePurchaseOrderList);
}