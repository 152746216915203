/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     TRANSFER_INMATE_START 
} from "../../../constants/actionType/inmates";
import { saveData} from '../../../helpers';
import { transferInmateFail, transferInmateSuccess } from "../../actions";



interface ResponseGenerator {
    data: any;
}

function* transferInmate(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'prison/transfer-inmate', action.data);
        if (response?.data?.error) {
            yield put(transferInmateFail(response.data || 'Unknown error'));
        } else {
            yield put(transferInmateSuccess(response));
        }
    } catch (e: any) {
        yield put(transferInmateFail(e.message || 'Unknown error'));
    }
}

export default function* fetchTransferInmate() {
    yield takeEvery(TRANSFER_INMATE_START, transferInmate);
}