import React from 'react';
import { Button, Modal } from 'antd';

const FormModal = ({ title, open, hideModal, children, register, loading, btn, calculate, appointment }: any) => {
    return (
        <>
            {calculate === undefined ? (
                <Modal
                    title={title === '' ? 'Register user' : title}
                    open={open}
                    closable={false}
                    footer={[
                        <Button ghost key="submit" type="primary" danger onClick={hideModal}>
                            Cancel
                        </Button>,
                        <Button ghost key="submit" type="primary" loading={loading} onClick={register}>
                            {btn === undefined ? 'Register' : btn}
                        </Button>,
                    ]}
                >
                    {children}
                </Modal>
            ) : (
                <Modal
                    title={title}
                    open={open}
                    closable={false}
                    footer={[
                        <Button ghost danger key="submit" type="primary" onClick={hideModal}>
                            Close
                        </Button>,
                    ]}
                >
                    {children}
                </Modal>
            )}

            {appointment !== undefined && appointment === 'appointment' ? (
                <Modal title={title} open={open} closable={false} footer={[]}>
                    {children}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                        }}
                    >
                        <Button
                            key="submit"
                            type="primary"
                            onClick={hideModal}
                            style={{ width: '40%', background: '#05386B' }}
                        >
                            Cancel
                        </Button>

                        <Button
                            key="submit"
                            loading={loading}
                            onClick={register}
                            style={{ color: '#fff', width: '40%', background: '#05386B' }}
                        >
                            {btn}
                        </Button>
                    </div>
                </Modal>
            ) : null}

            {appointment !== undefined && appointment === 'v-appointment' ? (
                <Modal title={title} open={open} closable={false} footer={[]}>
                    {children}
                    {/* <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                        }}
                    >
                        <Button
                            key="submit"
                            type="primary"
                            onClick={hideModal}
                            style={{ width: '40%', background: '#05386B' }}
                        >
                            Cancel
                        </Button>

                        <Button
                            key="submit"
                            loading={loading}
                            onClick={register}
                            style={{ color: '#fff', width: '40%', background: '#05386B' }}
                        >
                            {btn}
                        </Button>
                    </div> */}
                </Modal>
            ) : null}
        </>
    );
};

export default FormModal;
