/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    FETCH_VISITOR_LIMIT_CLEAR,
    FETCH_VISITOR_LIMIT_FAIL, 
    FETCH_VISITOR_LIMIT_START, 
    FETCH_VISITOR_LIMIT_SUCCESS 
} from "../../../constants/actionType/facility";

 export const fetchVisitorLimitData = () => ({type:  FETCH_VISITOR_LIMIT_START});
 
 export const fetchVisitorLimitSuccess = (data:any) => ({
   type: FETCH_VISITOR_LIMIT_SUCCESS,
   payload: data
 })
 
 export const fetchVisitorLimitError = (error:any) => ({
   type: FETCH_VISITOR_LIMIT_FAIL,
   payload: error
 })

 export const clearFetchVisitorLimit = () => ({type: FETCH_VISITOR_LIMIT_CLEAR })