/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GET_DELIVERY_NOTE_ITEMS_FAIL, 
    GET_DELIVERY_NOTE_ITEMS_START, 
    GET_DELIVERY_NOTE_ITEMS_SUCCESS 
} from "../../../constants/actionType/orders"

 export const getDeliveryNoteItemsData = (data: any) => ({type:  GET_DELIVERY_NOTE_ITEMS_START, data});
 
 export const getDeliveryNoteItemsSuccess = (data:any) => ({
   type: GET_DELIVERY_NOTE_ITEMS_SUCCESS,
   payload: data
 })
 
 export const getDeliveryNoteItemsError = (error:any) => ({
   type: GET_DELIVERY_NOTE_ITEMS_FAIL,
   payload: error
 })