import { 
    SAVE_NEW_PRODUCT_START, 
    SAVE_NEW_PRODUCT_SUCCESS, 
    SAVE_NEW_PRODUCT_FAIL,
    CLEAR_SAVE_NEW_PRODUCT
} from '../../../constants/actionType/shop';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const saveNewProduct = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SAVE_NEW_PRODUCT_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case SAVE_NEW_PRODUCT_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case SAVE_NEW_PRODUCT_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }
        case CLEAR_SAVE_NEW_PRODUCT: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }
        default: {
            return state;
          }
    }
}

export default saveNewProduct;