/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_VISITOR_LIMIT_START 
} from "constants/actionType/facility";
import { fetchData} from 'helpers';
import { fetchVisitorLimitError, fetchVisitorLimitSuccess } from "../../actions";


export interface ResponseGenerator{
    data: any;
}

function* fetchVisitorsLimit() {
    try {
        const response: ResponseGenerator = yield call(fetchData, 'facility-settings/fetch-visitor-limit');
        if (response?.data.error) {
            yield put(fetchVisitorLimitError(response.data || 'Unknown error'));
        } else {
            yield put(fetchVisitorLimitSuccess(response));
        }
    } catch (e: any) {
        yield put(fetchVisitorLimitError(e.message || 'Unknown error'));
    }
}

export default function* fetchVisitorLimit() {
    yield takeEvery(FETCH_VISITOR_LIMIT_START, fetchVisitorsLimit)
}