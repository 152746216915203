// REQUEST APPOINTMENT 
export const REQUEST_APPOINTMENT_START = "REQUEST_APPOINTMENT_START";
export const REQUEST_APPOINTMENT_SUCCESS = "REQUEST_APPOINTMENT_SUCCESS";
export const REQUEST_APPOINTMENT_FAIL =  "REQUEST_APPOINTMENT_FAIL";
export const REQUEST_APPOINTMENT_CLEAR = "REQUEST_APPOINTMENT_CLEAR";

//  INCOMING REQUEST
export const INCOMING_APPOINTMENT_START =  "INCOMING_APPOINTMENT_START";
export const INCOMING_APPOINTMENT_SUCCESS =  "INCOMING_APPOINTMENT_SUCCESS";
export const INCOMING_APPOINTMENT_FAIL =   "INCOMING_APPOINTMENT_FAIL";

//  OUTGOING  REQUEST
export const OUTGOING_APPOINTMENT_START =  "OUTGOING_APPOINTMENT_START";
export const OUTGOING_APPOINTMENT_SUCCESS =  "OUTGOING_APPOINTMENT_SUCCESS";
export const OUTGOING_APPOINTMENT_FAIL =   "OUTGOING_APPOINTMENT_FAIL";

//  APPROVE REJECT  REQUEST
export const APPROVE_REJECT_REQUEST_START =  "APPROVE_REJECT_REQUEST_START";
export const APPROVE_REJECT_REQUEST_SUCCESS =  "APPROVE_REJECT_REQUEST_SUCCESS";
export const APPROVE_REJECT_REQUEST_FAIL =   "APPROVE_REJECT_REQUEST_FAIL";
export const CLEAR_APPROVE_REJECT_REQUEST_STORE =   "CLEAR_APPROVE_REJECT_REQUEST_STORE";

export const OUTGOING_APPOINTMENT = '0';
export const INCOMING_APPOINTMENT = '1';

export const APPROVE_REJECT_APPOINTMENT_REQUEST_START =  "APPROVE_REJECT_APPOINTMENT_REQUEST_START";
export const APPROVE_REJECT_APPOINTMENT_REQUEST_SUCCESS =  "APPROVE_REJECT_APPOINTMENT_REQUEST_SUCCESS";
export const APPROVE_REJECT_APPOINTMENT_REQUEST_FAIL =   "APPROVE_REJECT_APPOINTMENT_REQUEST_FAIL";

// GET APPOINTMENT REPORT
export const GET_APPOINTMENT_REPORT_START =  "GET_APPOINTMENT_REPORT_START";
export const GET_APPOINTMENT_REPORT_SUCCESS =  "GET_APPOINTMENT_REPORT_SUCCESS";
export const GET_APPOINTMENT_REPORT_FAIL =   "GET_APPOINTMENT_REPORT_FAIL";

export const GET_BOOKING_REPORT_START =  "GET_BOOKING_REPORT_START";
export const GET_BOOKING_REPORT_SUCCESS =  "GET_BOOKING_REPORT_SUCCESS";
export const GET_BOOKING_REPORT_FAIL =   "GET_APPOINTMENT_REPORT_FAIL";

export const CONFIRM_VISIT_START =  "CONFIRM_VISIT_START";
export const CONFIRM_VISIT_SUCCESS =  "CONFIRM_VISIT_SUCCESS";
export const CONFIRM_VISIT_FAIL =   "CONFIRM_VISIT_FAIL";
export const CONFIRM_VISIT_CLEAR =   "CONFIRM_VISIT_CLEAR";

export const COMPLETED_VISIT_START =  "COMPLETED_VISIT_START";
export const COMPLETED_VISIT_SUCCESS =  "COMPLETED_VISIT_SUCCESS";
export const COMPLETED_VISIT_FAIL =   "COMPLETED_VISIT_FAIL";
export const COMPLETED_VISIT_CLEAR =   "COMPLETED_VISIT_CLEAR";

export const AVAILABLE_TIME_START =  "AVAILABLE_TIME_START";
export const AVAILABLE_TIME_SUCCESS =  "AVAILABLE_TIME_SUCCESS";
export const AVAILABLE_TIME_FAIL =   "AVAILABLE_TIME_FAIL";
export const AVAILABLE_TIME_CLEAR =   "AVAILABLE_TIME_CLEAR";


export const VISITOR_ALLOWED_ITEMS_START =  "VISITOR_ALLOWED_ITEMS_START";
export const VISITOR_ALLOWED_ITEMS_SUCCESS =  "VISITOR_ALLOWED_ITEMS_SUCCESS";
export const VISITOR_ALLOWED_ITEMS_FAIL =   "VISITOR_ALLOWED_ITEMS_FAIL";
export const VISITOR_ALLOWED_ITEMS_CLEAR =   "VISITOR_ALLOWED_ITEMS_CLEAR";


export const SEND_BULK_SMS_START =  "SEND_BULK_SMS_START";
export const SEND_BULK_SMS_SUCCESS =  "SEND_BULK_SMS_SUCCESS";
export const SEND_BULK_SMS_FAIL =   "SEND_BULK_SMS_FAIL";
export const SEND_BULK_SMS_CLEAR =   "SEND_BULK_SMS_CLEAR";