import { call, put, takeEvery } from "redux-saga/effects";
import { 
     GET_CORRECTIONAL_FACILITY_FAIL, 
     GET_CORRECTIONAL_FACILITY_START, 
     GET_CORRECTIONAL_FACILITY_SUCCESS 
} from "../../../constants/actionType/users";
import { fetchData } from "../../../helpers";
import {  IFacilityInfoData } from "../../../interfaces/facilities";

interface ResponseGenerator{
   data: IFacilityInfoData
}

function* getCorrectionalFacilityData() {
    try {
        const prisonData: ResponseGenerator = yield call(fetchData, "user/retrieve-facility");
        yield put({type:  GET_CORRECTIONAL_FACILITY_SUCCESS, data: prisonData?.data});

    } catch (e) {
        let message
        if (e instanceof Error ) message == e.message;
        else message = String(e)
        yield put({ type:  GET_CORRECTIONAL_FACILITY_FAIL, error: message });
    }
}

export default function* fetchCorrectionalFacilityData() {
    yield takeEvery( GET_CORRECTIONAL_FACILITY_START, getCorrectionalFacilityData)
}