/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    InputRef,
    Input,
    Space,
    Table,
    Tag,
    Select,
    DatePicker,
    Modal,
    Empty,
    Flex,
    List,
    Divider,
} from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined, SettingOutlined, PieChartOutlined, LoadingOutlined, TeamOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { IUserSelectorType } from '../../../interfaces';
import { ManageDates } from '../../dashboard';
import {
    approveRejectAppointmentData,
    clearApproveRejectAppointment,
    incomingAppointments,
    visitorItemsData,
} from '../../../redux/actions';

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import EmptyTable from '../../common/emptyTable';
import { FaRegFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import AppointmentDetailsModal from './AppointmentDetails';
import SetVisitorLimitModal from '../SetVisitorLimit';
import ApproveBulkVisit from '../ApproveBulkVisit';

const { RangePicker } = DatePicker;
const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const { Column } = Table;

interface IMethod {
    label: string;
    value: string;
}

interface IColumnTitles {
    text: string;
    value: string | number;
}

const columnTitles: IColumnTitles[] = [
    { text: 'Inmate', value: 'inmateFullName' },
    { text: 'RCS case', value: 'inmateCode' },
    { text: 'Visitor', value: 'visitorFullName' },
    { text: 'Visitor contact', value: 'visitorContact' },
    { text: 'Visit date', value: 'visitDate' },
    { text: 'Visit time', value: 'visitTime' },
    { text: 'Visit type', value: 'visitType' },
    { text: 'Visit method', value: 'visitMethod' },
    { text: 'Status', value: 'status' },
    { text: 'Attached document', value: 'otherDocument' },
];

const GenerateExportToXLSX = (data: any[], filename: string) => {
    const wsData = data;
    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

const AppointmentList: React.FC = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [getInmate, setGetInmates]: any = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedDate, setSelectedDate]: any = useState();
    const [reason, setReason] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const [open, setOpen] = useState(false);
    const [openVisitDetail, setOpenVisitDetail] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [openApproveAppointment, setOpenApproveAppointment] = useState<boolean>(false);
    const [openRejectAppointment, setOpenRejectAppointment] = useState<boolean>(false);
    const [selectedAppointment, setSelectedAppointment] = useState<any>();
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);
    const [formatTableColumn, setFormatTableColumn] = useState<any[]>([]);
    const [openVisitorLimitModal, setOpenVisitorLimitModal] = useState<boolean>(false);
    const [openBulkApproval, setOpenBulkApproval] = useState<boolean>(false);
    const [normalRequest, setNormalRequest] = useState<any>([]);

    const default_option_value = 'all';

    const {
        getUserData,
        incomingAppointment: { data, loading },
        approveRejectAppointment,
        fetchVisitorItems,
    } = userSelector((user) => user);

    const getIncomingAppointment = data?.data !== undefined ? data?.data?.appointment_details : [];

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps: any = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const method: IMethod[] = [
        {
            label: 'All',
            value: 'all',
        },
        {
            label: 'Physical',
            value: 'physical',
        },
        {
            label: 'Virtual',
            value: 'virtual',
        },
    ];

    const options: IMethod[] = [
        {
            label: 'Normal',
            value: 'normal',
        },
        {
            label: 'Special',
            value: 'special',
        },
        {
            label: 'Lawyer',
            value: 'lawyer',
        },
        {
            label: 'Rejected',
            value: 'rejected',
        },
        {
            label: 'Approved',
            value: 'approved',
        },
    ];

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectedMethods, setSelectedMethods] = useState<string[]>([]);

    const handleChangeMethod = (value: string[]) => {
        setSelectedMethods(value);
    };

    const handleChange = (value: string[]) => {
        setSelectedOptions(value);
    };

    const onSearch = () => {
        dispatch(
            incomingAppointments({
                selected_options: selectedOptions || default_option_value,
                recipient: getInmate || '',
                selectedMethods: selectedMethods,
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    useEffect(() => {
        if (selectedDate) {
            dispatch(
                incomingAppointments({
                    start_date: getDate.defaultStartedDate(),
                    end_date: getDate.defaultEndDate(),
                    recipient: '',
                    selected_options: selectedOptions || default_option_value,
                    selectedMethods: selectedMethods,
                }),
            );
        } else {
            dispatch(
                incomingAppointments({
                    start_date: getDate.defaultStartedDate(),
                    end_date: getDate.defaultEndDate(),
                    recipient: '',
                    selected_options: selectedOptions || default_option_value,
                    selectedMethods: selectedMethods,
                }),
            );
        }
    }, [dispatch, selectedDate]);

    const onSelectDate = (dateString: any) => {
        setDates(dateString);
        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const onHandleChangeReason = (e: { target: { value: React.SetStateAction<string> } }) => setReason(e.target.value);
    const onHandleFetchVisitorItems = (appointment_id: string | number) => {
        dispatch(visitorItemsData({ appointment_id: appointment_id }));
    };

    const approveAppointment = (id: number, visitMethod: string) => {
        dispatch(
            approveRejectAppointmentData({
                data: [
                    {
                        id: id,
                        rejected: false,
                        approved: true,
                        reason: '',
                        visit_type: visitMethod,
                    },
                ],
            }),
        );
    };

    const onHandleApproveBulkRequest = () => {
        dispatch(
            approveRejectAppointmentData({
                data: normalRequest,
            }),
        );
    };

    const rejectAppointment = (id: number) => {
        dispatch(
            approveRejectAppointmentData({
                data: [
                    {
                        id: id,
                        rejected: true,
                        approved: false,
                        reason: reason,
                    },
                ],
            }),
        );
        setReason('');
    };

    const onHandleChangeInmate = (e: { target: { value: any } }) => setGetInmates(e.target.value);

    const onCloseVisitorDetail = () => {
        setOpenVisitDetail(false);
        setSelectedVisit(null);
    };

    const columnData = getIncomingAppointment?.map((item: any, index: number) => {
        return {
            key: index,
            inmateFullName: `${item.first_name} ${item.last_name}`,
            inmateCode: item.inmate_code,
            visitorFullName: `${item.visitor_first_name} ${item.visitor_last_name}`,
            visitDate: item.visit_date,
            visitTime: item.visit_time,
            visitType: item.appointment_type,
            visitMethod: item.appointment_category,
            status: item.status,
            inmateStatus: item?.inmate_status,
            visitorContact: item.visitor_contacts,
            otherDocument: item?.other_document,
            visitorPassport: item?.visitor_passport,
            id: item?.id,
        };
    });

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const dataToBeExported =
        getIncomingAppointment &&
        getIncomingAppointment.map((item: any) => ({
            inmateFullName: `${item?.first_name} ${item?.last_name}`,
            inmateCode: item?.inmate_code,
            visitorFullName: `${item?.visitor_first_name} ${item?.visitor_last_name}`,
            visitDate: item?.visit_date,
            visitTime: item?.visit_time,
            visitType: item?.appointment_type,
            inmateStatus: item?.inmate_status,
            visitMethod: item?.appointment_category,
            status: item?.status,
            visitorContact: item?.visitor_contacts,
            otherDocument: item?.other_document,
            visitorPassport: item?.visitor_passport,
        }));

    const onHandleOpenBulkRequest = () => {
        const filteredNormalVisit = columnData?.filter(
            (item: any) => item.visitType === 'normal' && item?.status === 'pending',
        );
        const dataToApprove = filteredNormalVisit.map((item: any) => ({
            id: item.id,
            rejected: false,
            approved: true,
            reason: '',
            visit_type: item.visitMethod,
        }));
        setOpenBulkApproval(true);
        setNormalRequest(dataToApprove);
    };
    const onHandleCloseBulkRequest = () => setOpenBulkApproval(false);

    const exportXLSX = () => {
        const values = dataToBeExported
            ?.map((item: any) =>
                columnTitles?.map((option: any) => ({
                    [option.text]: item[option.value],
                })),
            )
            .filter(Boolean);

        const formattedColumns = values?.map((subArray: any) => {
            return subArray.reduce((acc: { [x: string]: any }, obj: { [x: string]: any }) => {
                for (const key in obj) {
                    acc[key] = obj[key];
                }
                return acc;
            }, {});
        });

        setFormatTableColumn(formattedColumns);
    };

    useEffect(() => {
        if (formatTableColumn.length !== 0) {
            GenerateExportToXLSX(
                formatTableColumn.map((item: any) => ({ ...item })),
                `visit-report-${Date.now()}`,
            );
        }
    }, [formatTableColumn]);

    useEffect(() => {
        if (approveRejectAppointment.data?.data?.success) {
            toast.success(approveRejectAppointment.data?.message);
            dispatch(
                incomingAppointments({
                    start_date: getDate.defaultStartedDate(),
                    end_date: getDate.defaultEndDate(),
                    recipient: '',
                    selected_options: selectedOptions || default_option_value,
                    selectedMethods: selectedMethods,
                }),
            );
            dispatch(clearApproveRejectAppointment());
            setOpenApproveAppointment(false);
            setOpenRejectAppointment(false);
            setOpenBulkApproval(false);
        }

        if (approveRejectAppointment.data?.data?.error) {
            toast.error(approveRejectAppointment.data?.message);
            dispatch(clearApproveRejectAppointment());
        }

        if (fetchVisitorItems.error) {
            toast.error(fetchVisitorItems.message);
        }
    }, [approveRejectAppointment.data?.data, fetchVisitorItems]);

    const selectedAppointmentData = [
        { label: 'Inmate names', value: selectedAppointment?.inmateFullName },
        { label: 'Inmate case number', value: selectedAppointment?.inmateCode },
        { label: 'Visitor name', value: selectedAppointment?.visitorFullName },
        { label: 'Visitor contact', value: selectedAppointment?.visitorContact },
        { label: 'Visit date', value: selectedAppointment?.visitDate },
        { label: 'Visit time', value: selectedAppointment?.visitTime },
        { label: 'Visit method', value: selectedAppointment?.visitMethod },
        { label: 'Visit type', value: selectedAppointment?.visitType },
    ];

    const customLoading = {
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
    };

    return (
        <>
            <Flex style={{ width: '100%' }} vertical>
                <Flex gap="small" align="center" justify="flex-end" style={{ padding: '10px' }}>
                    <Select
                        allowClear
                        placeholder="Select method"
                        onChange={handleChangeMethod}
                        options={method}
                        style={{ width: '20%' }}
                    />
                    <Select
                        allowClear
                        placeholder="Select an option"
                        onChange={handleChange}
                        options={options}
                        style={{ width: '20%' }}
                    />
                    <Input
                        placeholder="Search by RCS case"
                        value={getInmate}
                        onChange={onHandleChangeInmate}
                        style={{ width: '20%' }}
                    />
                    <RangePicker
                        onChange={onSelectDate}
                        format="YYYY-MM-DD"
                        defaultValue={[dayjs(getDate.defaultStartedDate()), dayjs(getDate.defaultEndDate())]}
                    />

                    <Button icon={<SearchOutlined />} loading={loading} onClick={onSearch} type="primary" ghost />
                    <Button icon={<PieChartOutlined />} type="primary" ghost onClick={() => setOpen(true)} />
                    <Button icon={<TeamOutlined />} type="primary" ghost onClick={onHandleOpenBulkRequest} />
                    <Button
                        icon={<SettingOutlined />}
                        onClick={() => setOpenVisitorLimitModal(true)}
                        type="primary"
                        ghost
                    />
                </Flex>

                <Flex justify="flex-start" className="white-background">
                    <Flex gap="small" justify="center" vertical>
                        <span className="default-grey-colo">All visits</span>
                        <span className="bold-text">{data?.data?.total_appointment}</span>
                    </Flex>
                    <div className="separator__col"></div>
                    <Flex gap="small" justify="center" vertical>
                        <span className="default-grey-colo">Virtual visits</span>
                        <span className="bold-text">{data?.data?.virtual_appointment}</span>
                    </Flex>
                    <div className="separator__col"></div>
                    <Flex gap="small" justify="center" vertical>
                        <span className="default-grey-colo">Physical visits</span>
                        <span className="bold-text">{data?.data?.physical_appointment}</span>
                    </Flex>
                    <div className="separator__col"></div>
                    <Flex gap="small" justify="center" vertical>
                        <span className="default-grey-colo">Critical</span>
                        <span className="bold-text">{data?.data?.critical}</span>
                    </Flex>
                    <div className="separator__col"></div>
                    <Flex gap="small" justify="center" vertical>
                        <span className="default-grey-colo">Individuals</span>
                        <span className="bold-text">{data?.data?.individual}</span>
                    </Flex>

                    <div className="separator__col"></div>
                    <Flex gap="small" justify="center" vertical>
                        <span className="default-grey-colo">Specials</span>
                        <span className="bold-text">{data?.data?.special}</span>
                    </Flex>
                </Flex>

                <div style={{ width: '168vh', height: '80vh' }}>
                    <Table
                        size="small"
                        dataSource={columnData}
                        scroll={{ x: 1400 }}
                        loading={customLoading}
                        locale={{
                            emptyText: (
                                <EmptyTable
                                    title={'No appointment yet'}
                                    subTitle={'There is no appointment yet.'}
                                    Icon={<Empty />}
                                    button={undefined}
                                />
                            ),
                        }}
                        pagination={columnData?.length > 12 ? undefined : false}
                        rowClassName={(record, _index) => {
                            if (record.inmateStatus === 'critical') {
                                return 'approved-row';
                            }
                            return '';
                        }}
                    >
                        <Column
                            title="Inmate name"
                            dataIndex="inmateFullName"
                            key="inmateFullName"
                            width={180}
                            filterSearch={getColumnSearchProps('inmateFullName')}
                        />
                        <Column width={150} title="RCS case" dataIndex="inmateCode" key="inmateCode" />
                        <Column title="Visitor name" width="120px" dataIndex="visitorFullName" key="visitorFullName" />

                        <Column width={120} title="Visitor contact" dataIndex="visitorContact" key="visitorContact" />
                        <Column width={100} title="Visit date" dataIndex="visitDate" key="visitDate" />
                        <Column width={80} title="Time" dataIndex="visitTime" key="visitTime" />
                        <Column
                            width={90}
                            title="Visit type"
                            dataIndex="visitType"
                            key="visitType"
                            render={(tag: any) => {
                                return (
                                    <>
                                        {tag === 'normal' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                        {tag === 'special' ? <Tag color={'green'}>{tag}</Tag> : null}
                                        {tag === 'lawyer' ? <Tag color={'orange'}>{tag}</Tag> : null}
                                    </>
                                );
                            }}
                        />
                        <Column title="Visit Method" dataIndex="visitMethod" key="visitMethod" width={110} />
                        <Column
                            width={100}
                            title="Status"
                            dataIndex="status"
                            key="status"
                            render={(tag: any) => {
                                return (
                                    <>
                                        {tag === 'pending' ? <Tag color={'green'}>{tag}</Tag> : null}
                                        {tag === 'approved' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                        {tag === 'rejected' ? <Tag color={'red'}>{tag}</Tag> : null}
                                    </>
                                );
                            }}
                        />

                        <Column
                            title="Action"
                            key="action"
                            fixed="right"
                            width="240px"
                            align="center"
                            render={(_, data: any) => {
                                return (
                                    <Flex align="center" justify="center">
                                        {[2, 7].includes(getUserData?.data?.data[0]?.role_id) &&
                                            ['special', 'normal', 'lawyer'].includes(data?.visitType) &&
                                            ['critical'].includes(data?.inmateStatus) && (
                                                <>
                                                    {data?.status === 'pending' ? (
                                                        <Flex>
                                                            <Button
                                                                type="link"
                                                                onClick={() => {
                                                                    setOpenApproveAppointment(true);
                                                                    setSelectedAppointment(data);
                                                                }}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                type="link"
                                                                onClick={() => {
                                                                    setOpenRejectAppointment(true);
                                                                    setSelectedAppointment(data);
                                                                }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </Flex>
                                                    ) : (
                                                        <Flex>
                                                            {data?.status === 'rejected' ? (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        setOpenApproveAppointment(true);
                                                                        setSelectedAppointment(data);
                                                                    }}
                                                                >
                                                                    Approve
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        setOpenRejectAppointment(true);
                                                                        setSelectedAppointment(data);
                                                                    }}
                                                                >
                                                                    Reject
                                                                </Button>
                                                            )}
                                                        </Flex>
                                                    )}
                                                </>
                                            )}

                                        {[2, 7].includes(getUserData?.data?.data[0]?.role_id) &&
                                            ['special'].includes(data?.visitType) &&
                                            !['critical'].includes(data?.inmateStatus) && (
                                                <>
                                                    {data?.status === 'pending' ? (
                                                        <Flex>
                                                            <Button
                                                                type="text"
                                                                onClick={() => {
                                                                    setOpenApproveAppointment(true);
                                                                    setSelectedAppointment(data);
                                                                }}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    setOpenRejectAppointment(true);
                                                                    setSelectedAppointment(data);
                                                                }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </Flex>
                                                    ) : (
                                                        <Flex>
                                                            {data?.status === 'rejected' ? (
                                                                <Button
                                                                    onClick={() => {
                                                                        setOpenApproveAppointment(true);
                                                                        setSelectedAppointment(data);
                                                                    }}
                                                                >
                                                                    Approve
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    onClick={() => {
                                                                        setOpenRejectAppointment(true);
                                                                        setSelectedAppointment(data);
                                                                    }}
                                                                >
                                                                    Reject
                                                                </Button>
                                                            )}
                                                        </Flex>
                                                    )}
                                                </>
                                            )}

                                        {[2, 5, 6, 7].includes(getUserData?.data?.data[0]?.role_id) &&
                                            ['individual', 'normal', 'lawyer'].includes(data?.visitType) &&
                                            !['critical'].includes(data?.inmateStatus) && (
                                                <>
                                                    {data?.status === 'pending' ? (
                                                        <Flex>
                                                            <Button
                                                                type="text"
                                                                onClick={() => {
                                                                    setOpenApproveAppointment(true);
                                                                    setSelectedAppointment(data);
                                                                }}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                type="text"
                                                                onClick={() => {
                                                                    setOpenRejectAppointment(true);
                                                                    setSelectedAppointment(data);
                                                                }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </Flex>
                                                    ) : (
                                                        <Flex>
                                                            {data?.status === 'rejected' ? (
                                                                <Button
                                                                    type="text"
                                                                    onClick={() => {
                                                                        setOpenApproveAppointment(true);
                                                                        setSelectedAppointment(data);
                                                                    }}
                                                                >
                                                                    Approve
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    type="text"
                                                                    onClick={() => {
                                                                        setOpenRejectAppointment(true);
                                                                        setSelectedAppointment(data);
                                                                    }}
                                                                >
                                                                    Reject
                                                                </Button>
                                                            )}
                                                        </Flex>
                                                    )}
                                                </>
                                            )}
                                        <Button
                                            onClick={() => {
                                                setOpenVisitDetail(true);
                                                setSelectedVisit(data);
                                                onHandleFetchVisitorItems(data?.id);
                                            }}
                                            type="text"
                                        >
                                            Details
                                        </Button>
                                    </Flex>
                                );
                            }}
                        />
                    </Table>
                </div>
            </Flex>
            <Modal title="" open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={1000}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                    }}
                >
                    <Flex justify="flex-start">
                        <Button onClick={handlePrint} size="large" ghost type="primary" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                        <Button size="large" ghost type="primary" style={{ marginRight: '30px' }} onClick={exportXLSX}>
                            <FaRegFileExcel color="green" style={{ marginRight: '10px' }} /> Export to XLSX
                        </Button>
                    </Flex>
                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>APPOINTMENT REPORT</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {data?.data?.facility}
                            </span>
                            <span>
                                <b>Period: </b>
                                From {moment(data?.data?.start_date).format('DD-MMM-YYYY')} to{' '}
                                {moment(data?.data?.start_date).format('DD-MMM-YYYY')}
                            </span>
                        </div>
                        <Table
                            dataSource={columnData}
                            scroll={{ x: 1300 }}
                            pagination={columnData?.length > 14 ? undefined : false}
                        >
                            <Column
                                title="Inmate name"
                                dataIndex="inmateFullName"
                                key="inmateFullName"
                                width="150px"
                                filterSearch={getColumnSearchProps('inmateFullName')}
                            />
                            <Column title="RCS case" dataIndex="inmateCode" key="inmateCode" />
                            <Column
                                title="Visitor name"
                                width="150px"
                                dataIndex="visitorFullName"
                                key="visitorFullName"
                            />

                            <Column title="Visitor " dataIndex="inmateCode" key="inmateCode" />
                            <Column title="Visit date" dataIndex="visitDate" key="visitDate" />
                            <Column title="Time" dataIndex="visitTime" key="visitTime" />
                            <Column
                                title="Visit type"
                                dataIndex="visitType"
                                key="visitType"
                                render={(tag: any) => {
                                    return (
                                        <>
                                            {tag === 'critical' ? <Tag color={'red'}>{tag}</Tag> : null}
                                            {tag === 'normal' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                            {tag === 'special' ? <Tag color={'green'}>{tag}</Tag> : null}
                                            {tag === 'lawyer' ? <Tag color={'organ'}>{tag}</Tag> : null}
                                        </>
                                    );
                                }}
                            />
                            <Column title="Visit Method" dataIndex="visitMethod" key="visitMethod" />

                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(tag: any) => {
                                    return (
                                        <>
                                            {tag === 'pending' ? <Tag color={'green'}>{tag}</Tag> : null}
                                            {tag === 'approved' ? <Tag color={'blue'}>{tag}</Tag> : null}
                                            {tag === 'rejected' ? <Tag color={'red'}>{tag}</Tag> : null}
                                        </>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </div>
            </Modal>

            <Modal title="Reject visit" open={openRejectAppointment} closable={false} footer={[]}>
                <div className="custom-modal-btn-group">
                    <Input
                        className="margin-top-regular"
                        placeholder="Leave a comment!"
                        value={reason}
                        onChange={onHandleChangeReason}
                    />
                    {selectedAppointment?.status === 'pending' ? (
                        <span className="bold-text margin-top-regular padding-small red-text-color">
                            Are you sure you want to reject this appointment visit request?
                        </span>
                    ) : null}
                </div>
                <div className="custom-modal-footer-space-bt">
                    <Button key="submit" type="primary" ghost danger onClick={() => setOpenRejectAppointment(false)}>
                        Cancel
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        ghost
                        loading={approveRejectAppointment.loading}
                        onClick={() => rejectAppointment(selectedAppointment?.id)}
                    >
                        Yes
                    </Button>
                </div>
            </Modal>

            <Modal
                title={
                    <Flex justify="space-between">
                        <span>Approve visit</span>
                        <>
                            {selectedAppointment?.status === 'pending' && (
                                <Tag color="green">{selectedAppointment?.status}</Tag>
                            )}
                            {selectedAppointment?.status === 'approved' && (
                                <Tag color="blue">{selectedAppointment?.status}</Tag>
                            )}
                            {selectedAppointment?.status === 'rejected' && (
                                <Tag color="red">{selectedAppointment?.status}</Tag>
                            )}
                        </>
                    </Flex>
                }
                open={openApproveAppointment}
                closable={false}
                footer={[]}
            >
                <div className="custom-modal-btn-group">
                    <List
                        itemLayout="horizontal"
                        dataSource={selectedAppointmentData}
                        renderItem={(item: any, index: number) => (
                            <List.Item key={index} style={{ width: '100%' }}>
                                <Flex style={{ width: '100%' }} justify="space-between">
                                    <span className="bold-text">{item.label}</span>
                                    <span>{item.value}</span>
                                </Flex>
                            </List.Item>
                        )}
                    />

                    {selectedAppointment?.status === 'pending' && (
                        <Tag color="volcano">Are you sure you want to approve this appointment visit?</Tag>
                    )}
                    <Divider />
                </div>
                <Flex style={{ width: '100%' }} justify="space-between">
                    <Button ghost danger key="submit" type="primary" onClick={() => setOpenApproveAppointment(false)}>
                        Cancel
                    </Button>
                    <Button
                        ghost
                        key="submit"
                        type="primary"
                        loading={approveRejectAppointment.loading}
                        onClick={() => approveAppointment(selectedAppointment?.id, selectedAppointment?.visitMethod)}
                    >
                        Yes
                    </Button>
                </Flex>
            </Modal>
            <AppointmentDetailsModal
                visitDetails={selectedVisit}
                open={openVisitDetail}
                onClose={onCloseVisitorDetail}
                visitorItems={fetchVisitorItems?.data}
            />
            <ApproveBulkVisit
                open={openBulkApproval}
                onClose={onHandleCloseBulkRequest}
                data={normalRequest}
                onSubmit={onHandleApproveBulkRequest}
                loading={approveRejectAppointment.loading}
            />

            <SetVisitorLimitModal open={openVisitorLimitModal} onClose={() => setOpenVisitorLimitModal(false)} />
        </>
    );
};

export default AppointmentList;
