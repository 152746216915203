/* eslint-disable @typescript-eslint/no-explicit-any */
import {
     REQUEST_SIGN_IN_FAIL, 
     REQUEST_SIGN_IN_START, 
     REQUEST_SIGN_IN_SUCCESS 
} from "../../../constants/actionType/auth";
import { ILogin } from "../../../interfaces/auth";

  export const signIn = (data: ILogin) => ({type:  REQUEST_SIGN_IN_START, data});
  
  export const signInSuccess = (data:any) => ({
    type: REQUEST_SIGN_IN_SUCCESS,
    payload: data
  })
  
  export const signInError = (error:any) => ({
    type: REQUEST_SIGN_IN_FAIL,
    payload: error
  })