/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    RECORD_SAVING_CLEAR,
    RECORD_SAVING_FAIL, 
    RECORD_SAVING_START, 
    RECORD_SAVING_SUCCESS 
} from "../../../../constants/actionType/bulkSaving";

interface IForm {
  amount: string | number;
  inmate_code: string;
  first_name: string;
  last_name: string;
  inmate_id: number | string;
}
interface IData {
  data: IForm[]
}


 export const recordSavingData = (data: IData) => ({type: RECORD_SAVING_START, data});
 
 export const recordSavingSuccess = (data:any) => ({type: RECORD_SAVING_SUCCESS, data})
 
 export const recordSavingError = (error:any) => ({type: RECORD_SAVING_FAIL, error})

 export const clearRecordSavingStore = () => ({type: RECORD_SAVING_CLEAR})