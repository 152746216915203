/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, Spin, Button, Tag } from 'antd';
import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DAILY_ORDER_FILE } from '../../../constants/general';
import { TableDataType } from '../../orderOfficer/OrderFiles';
import { ColumnsType } from 'antd/es/table';
import { deliveryNoteData } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';

const useTypedSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const FDeliveryNoteList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { deliveryNote } = useTypedSelector((state) => state);

    const deliveryNoteList = Array.isArray(deliveryNote.data?.details) && deliveryNote.data?.details;

    const columns: ColumnsType<any> = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'File type',
            dataIndex: 'order_file_type',
            key: 'order_file_type',
            render: (type: string) => (
                <Tag color={parseInt(type) === DAILY_ORDER_FILE ? 'cyan' : 'blue'}>
                    {parseInt(type) === DAILY_ORDER_FILE ? 'Food Order file' : 'Shop Order file'}
                </Tag>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (type: string) => (
                <Tag color={type === 'closed' ? 'green' : 'orange'}>{type === 'closed' ? 'Available' : 'Pending'}</Tag>
            ),
        },
        {
            title: 'Created on',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (createdAt: string) => moment(createdAt).format('DD-MMM-YYYY'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, data, index) => {
                return (
                    <Button key={index} type="primary" ghost onClick={() => displayDeliveryNoteDetails(data?.id)}>
                        Details
                    </Button>
                );
            },
        },
    ];

    const newDataCol =
        deliveryNoteList &&
        deliveryNoteList?.map((item: TableDataType, index: number) => ({
            key: index,
            id: item.id,
            file_name: item.file_name,
            actions_status: item.actions_status,
            created_at: item.created_at,
            current_user_role: item.current_user_role,
            status: item.status,
            order_file_type: item.order_file_type,
            product_number: item.product_number,
        }));

    const displayDeliveryNoteDetails = (deliveryNoteId: number) => {
        navigate('/delivery-note-details', {
            state: { deliveryNoteId: deliveryNoteId },
        });
    };

    useEffect(() => {
        dispatch(deliveryNoteData({ ordersFileId: '', check_status: false }));
    }, []);

    return (
        <>
            {!deliveryNote.loading ? (
                <Table
                    columns={columns}
                    dataSource={newDataCol}
                    size="small"
                    pagination={newDataCol?.length > 14 ? undefined : false}
                />
            ) : (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            )}
        </>
    );
};

export default FDeliveryNoteList;
