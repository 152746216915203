export const GENERATE_BULK_TRANSACTION_FILE_START = "GENERATE_BULK_TRANSACTION_FILE_START";
export const GENERATE_BULK_TRANSACTION_FILE_SUCCESS = "GENERATE_BULK_TRANSACTION_FILE_SUCCESS";
export const GENERATE_BULK_TRANSACTION_FILE_FAIL =  "GENERATE_BULK_TRANSACTION_FILE_FAIL";


export const REQUEST_BULK_TRANSACTION_FILE_START = "REQUEST_BULK_TRANSACTION_FILE_START";
export const REQUEST_BULK_TRANSACTION_FILE_SUCCESS = "REQUEST_BULK_TRANSACTION_FILE_SUCCESS";
export const REQUEST_BULK_TRANSACTION_FILE_FAIL =  "REQUEST_BULK_TRANSACTION_FILE_FAIL";

export const REQUEST_BULK_TRANSACTION_FILE_DETAILS_START = "REQUEST_BULK_TRANSACTION_FILE_DETAILS_START";
export const REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS = "REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS";
export const REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL =  "REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL";


export const UPLOAD_BULK_TRANSACTION_DOCUMENT_START = "UPLOAD_BULK_TRANSACTION_DOCUMENT_START";
export const UPLOAD_BULK_TRANSACTION_DOCUMENT_SUCCESS = "UPLOAD_BULK_TRANSACTION_DOCUMENT_SUCCESS";
export const UPLOAD_BULK_TRANSACTION_DOCUMENT_FAIL =  "UPLOAD_BULK_TRANSACTION_DOCUMENT_FAIL";

export const RECORD_TRANSACTION_START = "RECORD_TRANSACTION_START";
export const RECORD_TRANSACTION_SUCCESS = "RECORD_TRANSACTION_SUCCESS";
export const RECORD_TRANSACTION_FAIL =  "RECORD_TRANSACTION_FAIL";
export const RECORD_TRANSACTION_CLEAR =  "RECORD_TRANSACTION_CLEAR";

export const UPLOAD_BULK_AMOUNT_START = "UPLOAD_BULK_AMOUNT_START";
export const UPLOAD_BULK_AMOUNT_SUCCESS = "UPLOAD_BULK_AMOUNT_SUCCESS";
export const UPLOAD_BULK_AMOUNT_FAIL =  "UPLOAD_BULK_AMOUNT_FAIL";
export const UPLOAD_BULK_AMOUNT_CLEAR =  "UPLOAD_BULK_AMOUNT_CLEAR";

export const REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START = "REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START";
export const REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS = "REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS";
export const REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL =  "REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL";
export const REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_CLEAR =  "REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_CLEAR";