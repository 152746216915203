// REQUEST APPOINTMENT 
export const SEND_MONEY_START = "SEND_MONEY_START";
export const SEND_MONEY_SUCCESS = "SEND_MONEY_SUCCESS";
export const SEND_MONEY_FAIL =  "SEND_MONEY_FAIL";

export const OUT_GOING_MONEY_START = "OUT_GOING_MONEY_START";
export const OUT_GOING_MONEY_SUCCESS = "OUT_GOING_MONEY_SUCCESS";
export const OUT_GOING_MONEY_FAIL =  "OUT_GOING_MONEY_FAIL";
export const CLEAR_OUT_GOING_MONEY =  "CLEAR_OUT_GOING_MONEY";

export const GET_OUT_GOING_MONEY_START = "GET_OUT_GOING_MONEY_START";
export const GET_OUT_GOING_MONEY_SUCCESS = "GET_OUT_GOING_MONEY_SUCCESS";
export const GET_OUT_GOING_MONEY_FAIL =  "GET_OUT_GOING_MONEY_FAIL";

export const OUT_GOING_TRANSACTION_HISTORY_START = "OUT_GOING_TRANSACTION_HISTORY_START";
export const OUT_GOING_TRANSACTION_HISTORY_SUCCESS = "OUT_GOING_TRANSACTION_HISTORY_SUCCESS";
export const OUT_GOING_TRANSACTION_HISTORY_FAIL =  "OUT_GOING_TRANSACTION_HISTORY_FAIL";

export const SENDER_TRANSACTION_REPORT_START = "SENDER_TRANSACTION_REPORT_START";
export const SENDER_TRANSACTION_REPORT_SUCCESS = "SENDER_TRANSACTION_REPORT_SUCCESS";
export const SENDER_TRANSACTION_REPORT_FAIL =  "SENDER_TRANSACTION_REPORT_FAIL";

export const SENDER_TRANSACTION_REPORT_DETAILS_START = "SENDER_TRANSACTION_REPORT_DETAILS_START";
export const SENDER_TRANSACTION_REPORT_DETAILS_SUCCESS = "SENDER_TRANSACTION_REPORT_DETAILS_SUCCESS";
export const SENDER_TRANSACTION_REPORT_DETAILS_FAIL =  "SENDER_TRANSACTION_REPORT_DETAILS_FAIL";

export const INMATES_TRANSACTION_REPORT_START = "INMATES_TRANSACTION_REPORT_START";
export const INMATES_TRANSACTION_REPORT_SUCCESS = "INMATES_TRANSACTION_REPORT_SUCCESS";
export const INMATES_TRANSACTION_REPORT_FAIL =  "INMATES_TRANSACTION_REPORT_FAIL";

export const INMATES_TRANSACTION_REPORT_DETAILS_START = "INMATES_TRANSACTION_REPORT_DETAILS_START";
export const INMATES_TRANSACTION_REPORT_DETAILS_SUCCESS = "INMATES_TRANSACTION_REPORT_DETAILS_SUCCESS";
export const INMATES_TRANSACTION_REPORT_DETAILS_FAIL =  "INMATES_TRANSACTION_REPORT_DETAILS_FAIL";

export const GET_INMATES_WITH_ZERO_WALLET_START = "GET_INMATES_WITH_ZERO_WALLET_START";
export const GET_INMATES_WITH_ZERO_WALLET_SUCCESS = "GET_INMATES_WITH_ZERO_WALLET_SUCCESS";
export const GET_INMATES_WITH_ZERO_WALLET_FAIL =  "GET_INMATES_WITH_ZERO_WALLET_FAIL";