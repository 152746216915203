/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     SEND_BULK_SMS_START 
} from 'constants/actionType/appointment';
import { saveData} from '../../../helpers';
import { sendBulkSMSError, sendBulkSMSSuccess } from "../../actions";


interface ResponseGenerator {
    data: any;
}

function* sendBulkSMS(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'notification/send-message', action.data);
        if (response?.data?.error) {
            yield put(sendBulkSMSError(response.data || 'Unknown error'));
        } else {
            yield put(sendBulkSMSSuccess(response));
        }
    } catch (e: any) {
        yield put(sendBulkSMSError(e.message || 'Unknown error'));
    }
}

export default function* fetchSendBulkSMS() {
    yield takeEvery(SEND_BULK_SMS_START, sendBulkSMS);
}