import { Button, DatePicker, Modal, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { FilePdfOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { useReactToPrint } from 'react-to-print';
import { ManageDates } from '..';
import { IUserSelectorType } from '../../../interfaces';
import { inmatesWithZeroWalletData } from '../../../redux/actions/sendMoney/inmateWithZeroBalance';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const InmateWithZeroWallet: React.FC<any> = () => {
    const dispatch = useDispatch();
    const getDate = new ManageDates();
    const [selectedDate, setSelectedDate]: any = useState();
    const [dates, setDates] = useState<RangeValue>(null);
    const [open, setOpen] = useState(false);

    const {
        inmatesWithZeroWallet: { data, loading },
    } = userSelector((user) => user);

    const reportData = data?.data !== undefined ? data?.data : [];

    const inmatesReportData: any = reportData?.inmates?.map((item: any) => {
        return {
            name: `${item?.first_name} ${item?.last_name}`,
            inmate_code: item?.inmate_code,
            gender: item?.gender,
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
    ];

    const onSearch = () => {
        dispatch(
            inmatesWithZeroWalletData({
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    const onSelectDate = (dateString: any) => {
        setDates(dateString);
        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    useEffect(() => {
        dispatch(
            inmatesWithZeroWalletData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
            }),
        );
    }, [dispatch, selectedDate]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <div></div>
                        <div className="top-right__items" style={{ width: '80%' }}>
                            <div style={{ marginLeft: '5px', marginTop: '-2px' }}>
                                <RangePicker
                                    style={{ padding: '6px' }}
                                    onChange={onSelectDate}
                                    format="YYYY-MM-DD"
                                    defaultValue={[
                                        dayjs(getDate.defaultStartedDate()),
                                        dayjs(getDate.defaultEndDate()),
                                    ]}
                                />
                            </div>
                            <Button
                                style={{ marginRight: '30px', marginLeft: '10px' }}
                                loading={loading}
                                onClick={onSearch}
                            >
                                Search
                            </Button>

                            <Button type="primary" onClick={() => setOpen(true)}>
                                Generate report
                            </Button>
                        </div>
                    </div>

                    {reportData !== undefined ? (
                        <>
                            {' '}
                            <Table
                                columns={columns}
                                dataSource={inmatesReportData}
                                pagination={data?.length >= 10 ? undefined : false}
                            />
                        </>
                    ) : (
                        <>
                            {' '}
                            <div style={{ margin: '20% auto', height: '800px' }}>
                                <Spin
                                    style={{ background: ' #282936' }}
                                    indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                        marginBottom: '55px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>

                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>INMATES WITH ZERO BALANCE</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {reportData?.facility}
                            </span>
                            <span>
                                <b>Period: </b>
                                {reportData?.start_date} to {reportData?.start_date}
                            </span>
                        </div>
                        <Table columns={columns} dataSource={inmatesReportData} pagination={false} />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default InmateWithZeroWallet;
