import {
    REQUEST_BULK_TRANSACTION_FILE_FAIL, 
    REQUEST_BULK_TRANSACTION_FILE_START, 
    REQUEST_BULK_TRANSACTION_FILE_SUCCESS 
} from "../../../../constants/actionType/bulkTransaction";
import { ITransactionFileData } from "../../../../interfaces/bulkTransaction";


interface IData {
    data: ITransactionFileData[]
}

interface IError {
    data: string,
}


 export const retrieveBulkTransactionFileData = () => ({type:  REQUEST_BULK_TRANSACTION_FILE_START});
 
 export const retrieveBulkTransactionFileSuccess = (data: IData) => ({
   type: REQUEST_BULK_TRANSACTION_FILE_SUCCESS,
   payload: data
 })
 
 export const retrieveBulkTransactionFileError = (error:IError) => ({
   type: REQUEST_BULK_TRANSACTION_FILE_FAIL,
   payload: error
 })