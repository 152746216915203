import React, { useEffect, useState } from 'react';
import ContainerLayout from '../../common/containerLayout';
import './index.css';
import { Avatar, Button, DatePicker, Input, Modal, Select } from 'antd';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import {
    clearUpdateUserProfileStore,
    getUserProfileData,
    logoutData,
    updateUserProfileData,
} from '../../../redux/actions';
import { roles } from '../../../constants/roles';
import { changePasswordData, clearChangePasswordStore } from '../../../redux/actions/auth/changePassword';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { allGenders } from '../../../constants/gender';
import dayjs from 'dayjs';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IFormType {
    newPassword: '';
    oldPassword: '';
}

interface ISecondFormType {
    username: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    gender: string;
    country: string;
    city: string;
    state: string;
    email: string;
}

const dateFormat = 'YYYY-MM-DD';

const UserProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState<string>();
    const [userGender, setUserGender] = useState('');
    const [activeTab, setActiveTab] = useState('default');
    const [selectedDate, setSelectedDate] = useState<string | string[]>('');
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState<ISecondFormType>({
        username: '',
        phone_number: '',
        first_name: '',
        last_name: '',
        gender: '',
        country: '',
        city: '',
        state: '',
        email: '',
    });
    const [changePasswordForm, setChangePasswordForm] = useState<IFormType>({
        newPassword: '',
        oldPassword: '',
    });

    const { getUserProfile, changePassword, updateUserProfile } = userSelector((state) => state);
    const userProfile = getUserProfile.data?.data;

    const firstLetter: string = userProfile?.first_name.charAt(0).toUpperCase();
    const secondLetter: string = userProfile?.last_name.charAt(0).toUpperCase();

    const onHandleChangePasswordForm = (event: any) => {
        const { name, value } = event.target;
        const data: any = { ...changePasswordForm };
        data[name] = value;
        setChangePasswordForm(data);
    };

    const onChangePassword = () => {
        dispatch(
            changePasswordData({
                first_login: false,
                new_password: changePasswordForm.newPassword,
                old_password: changePasswordForm.oldPassword,
                change_password: true,
            }),
        );
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getUserProfileData());
    }, []);

    useEffect(() => {
        if (getUserProfile.data?.data) {
            setForm({
                username: userProfile.username,
                phone_number: userProfile.phone,
                first_name: userProfile.first_name,
                last_name: userProfile.last_name,
                gender: userProfile.gender,
                country: '',
                city: '',
                state: '',
                email: userProfile.email,
            });
        }
    }, [getUserProfile.data?.data]);

    useEffect(() => {
        const role = roles.filter((role) => role.rolesKey === parseInt(userProfile?.role_id))[0];
        setUserRole(role?.name);
    }, []);

    useEffect(() => {
        if (changePassword?.data?.data?.error) {
            toast(changePassword?.data?.message);
            dispatch(clearChangePasswordStore());
        }
    }, [changePassword?.data?.data?.error]);

    useEffect(() => {
        if (changePassword?.data?.data?.success) {
            toast(changePassword?.data?.message);
            dispatch(clearChangePasswordStore());

            setTimeout(function () {
                dispatch(logoutData());
                navigate('/admin');
            }, 3000);

            setTimeout(function () {
                window.location.reload();
            }, 5000);
        }
    }, [changePassword?.data]);

    useEffect(() => {
        if (updateUserProfile?.data?.data?.error) {
            toast.error(updateUserProfile?.data?.message);
            dispatch(clearChangePasswordStore());
        }
    }, [updateUserProfile?.data?.data?.error]);

    useEffect(() => {
        if (updateUserProfile?.data?.data?.success) {
            toast.success(updateUserProfile?.data?.message);
            dispatch(clearChangePasswordStore());
            dispatch(getUserProfileData());
            dispatch(clearUpdateUserProfileStore());
            setOpen(false);
        }
    }, [updateUserProfile?.data]);

    const onSearch = (value: string) => {
        return value;
    };

    const onGenderChanges = (value: React.SetStateAction<string>) => {
        setUserGender(value);
    };

    const onHandleChangeVisitDate = ({ dateString }: { dateString: string | string[] }) => {
        setSelectedDate(dateString);
    };

    const onHandleChangeUserInfo = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const onSubmitUpdateUserInformation = () => {
        dispatch(
            updateUserProfileData({
                username: form.username,
                phone_number: form.phone_number,
                first_name: form.first_name,
                last_name: form.last_name,
                gender: userGender,
                birth_date: selectedDate,
                country: '',
                city: '',
                state: '',
                email: form.email,
            }),
        );
    };

    return (
        <>
            <ContainerLayout title={'User Information'} leftTopItem={undefined}>
                <div className="profile-container row">
                    <div className="align-left">
                        <div className="align-left__box">
                            <Avatar size={100}>
                                <span className="letters">
                                    {firstLetter}
                                    {secondLetter}
                                </span>
                            </Avatar>
                            <div className="over-shape-label">
                                <span>{userProfile?.username}</span>
                                <span>{userProfile?.email}</span>
                            </div>
                        </div>
                    </div>
                    <div className="align-right">
                        <div className="align-right-tab">
                            <Button
                                className="transparent-btn"
                                style={activeTab === 'default' ? { fontWeight: '700' } : {}}
                                onClick={() => setActiveTab('default')}
                            >
                                Personal Information
                            </Button>
                            <Button
                                className="transparent-btn"
                                style={activeTab === 'security-tab' ? { fontWeight: '700' } : {}}
                                onClick={() => setActiveTab('security-tab')}
                            >
                                Security
                            </Button>
                        </div>
                        {activeTab === 'default' ? (
                            <>
                                <div className="align-right__box">
                                    <span className="align-right__box--title">Personal Information</span>
                                    <div className="align-right__box-row">
                                        <span className="left-label">Name</span>
                                        <span className="right-label">
                                            {userProfile?.first_name} {userProfile?.last_name}
                                        </span>
                                    </div>
                                    <div className="align-right__box-row">
                                        <span className="left-label">Gender</span>
                                        <span className="right-label">{userProfile?.gender}</span>
                                    </div>
                                    <div className="align-right__box-row">
                                        <span className="left-label">Birth date</span>
                                        <span className="right-label">{userProfile?.birth_date}</span>
                                    </div>
                                    <div className="align-right__box-row">
                                        <span className="left-label">Language</span>
                                        <span className="right-label">{userProfile?.language}</span>
                                    </div>
                                    <div className="align-right__box-row">
                                        <span className="left-label">Phone number</span>
                                        <span className="right-label">+{userProfile?.phone}</span>
                                    </div>
                                    <div className="align-right__box-row">
                                        <span className="left-label">Email</span>
                                        <span className="right-label">{userProfile?.email}</span>
                                    </div>

                                    <div className="align-right__box-row">
                                        <span className="left-label">User role</span>
                                        <span className="right-label">{userRole}</span>
                                    </div>
                                </div>
                                <Button size="large" className="update-button" onClick={() => setOpen(true)}>
                                    Update information
                                </Button>
                            </>
                        ) : null}
                        {activeTab == 'security-tab' ? (
                            <div className="align-right__box">
                                <span className="align-right__box--title">Change password</span>
                                <span className="align-right__box--message">
                                    After changing your password you will be logout.
                                </span>
                                <div className="align-right__box-form">
                                    <div className="form__input">
                                        <span>Old password</span>
                                        <Input
                                            type="password"
                                            value={changePasswordForm.oldPassword}
                                            placeholder="Enter old password"
                                            name="oldPassword"
                                            onChange={onHandleChangePasswordForm}
                                        />
                                    </div>
                                    <div className="form__input">
                                        <span>New password</span>
                                        <Input
                                            value={changePasswordForm.newPassword}
                                            placeholder="Enter new password"
                                            name="newPassword"
                                            onChange={onHandleChangePasswordForm}
                                        />
                                    </div>

                                    <Button
                                        disabled={
                                            changePasswordForm.newPassword === '' ||
                                            changePasswordForm.oldPassword === ''
                                                ? true
                                                : false
                                        }
                                        onClick={() => onChangePassword()}
                                        loading={changePassword?.loading}
                                        className="apply-change-button"
                                        size="large"
                                    >
                                        Apply change
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <Modal title="Update user information" open={open} onCancel={handleCancel} closable={false} footer={[]}>
                    <div className="custom-modal-btn-group">
                        <div className="form__input">
                            <span>First name</span>
                            <Input
                                size="large"
                                value={form.first_name}
                                placeholder="Enter first name"
                                name="first_name"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div className="form__input">
                            <span>Last name</span>
                            <Input
                                size="large"
                                value={form.last_name}
                                placeholder="Enter last name"
                                name="last_name"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div className="form__input">
                            <span>Username</span>
                            <Input
                                size="large"
                                value={form.username}
                                placeholder="Enter username"
                                name="username"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>

                        <div className="form__input">
                            <span>{`Select gender`}</span>
                            <Select
                                showSearch
                                size="large"
                                placeholder="Select gender"
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={(input, option) => {
                                    const val: any = option?.label ?? '';
                                    return val.toLowerCase().includes(input.toLowerCase());
                                }}
                                onChange={onGenderChanges}
                                options={allGenders}
                                defaultValue={userProfile?.gender || ''}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form__input">
                            <span>Birth date</span>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={dayjs(userProfile?.birth_date, dateFormat)}
                                onChange={(_date, dateString) => onHandleChangeVisitDate({ dateString: dateString })}
                            />
                        </div>

                        <div className="form__input">
                            <span>Phone number</span>
                            <Input
                                size="large"
                                value={form.phone_number}
                                placeholder="Enter phone number"
                                name="phone_number"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div className="form__input">
                            <span>Email</span>
                            <Input
                                size="large"
                                value={form.email}
                                placeholder="Enter email"
                                name="email"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                    </div>
                    <div className="custom-modal-footer" style={{ justifyContent: 'space-between' }}>
                        <Button key="submit" size="large" onClick={handleCancel} className="modal-cancel-button">
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            size="large"
                            loading={updateUserProfile.loading}
                            onClick={() => onSubmitUpdateUserInformation()}
                            className="modal-save-button"
                        >
                            Save
                        </Button>
                    </div>
                </Modal>
            </ContainerLayout>
        </>
    );
};

export default UserProfile;
