import {
    LINK_OR_DELETE_PHONE_NUMBER_FAIL, 
    LINK_OR_DELETE_PHONE_NUMBER_START, 
    LINK_OR_DELETE_PHONE_NUMBER_SUCCESS 
    } from "../../../constants/actionType/linkPhoneNumberToInmate";
import { ILinkOrDeletePhoneNumber, ILinkPhoneNumberList } from "../../../interfaces/linkPhoneNumber";


    
interface ILinked {
    data: ILinkPhoneNumberList,
    error: []
}

export const linkOrDeletePhoneNumberData = (data: ILinkOrDeletePhoneNumber) =>  ({type: LINK_OR_DELETE_PHONE_NUMBER_START, data});

export const linkOrDeletePhoneNumberSuccess = (data: ILinked) => ({
    type: LINK_OR_DELETE_PHONE_NUMBER_SUCCESS, 
    payload: data
})

export const linkOrDeletePhoneNumberFail = (error: ILinked) => ({ type: LINK_OR_DELETE_PHONE_NUMBER_FAIL, payload: error})
