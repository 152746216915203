/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_SUCCESS, GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_FAIL, GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_START } from "../../../constants/actionType/inmates";
import { saveData } from '../../../helpers/index';


export interface ResponseGenerator{}

function* getInmatesWithZeroWalletsList(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'general/inmates-with-zero-balance', data?.data);
        yield put({type: GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_FAIL, error: e.message });
    }
}

export default function* fetchInmatesWIthZeroWalletsList() {
    yield takeEvery(GET_SUPER_ADMIN_INMATE_WITH_ZERO_WALLET_START, getInmatesWithZeroWalletsList)
}