/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GET_INMATES_WITH_ZERO_WALLET_FAIL, 
    GET_INMATES_WITH_ZERO_WALLET_START, 
    GET_INMATES_WITH_ZERO_WALLET_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const inmatesWithZeroWalletData = (data: any) => ({type:  GET_INMATES_WITH_ZERO_WALLET_START, data});
 
 export const inmatesWithZeroWalletSuccess = (data:any) => ({
   type: GET_INMATES_WITH_ZERO_WALLET_SUCCESS,
   payload: data
 })
 
 export const inmatesWithZeroWalletError = (error:any) => ({
   type: GET_INMATES_WITH_ZERO_WALLET_FAIL,
   payload: error
 })