import {
    GET_COMMENT_FAIL,
    GET_COMMENT_START,
    GET_COMMENT_SUCCESS,
} from '../../../constants/actionType/orders';

export interface ISGetCommentData {
    data: any;
    error: any;
}

export const getCommentData = () => ({ type: GET_COMMENT_START });

export const getCommentSuccess = (data: ISGetCommentData) => ({
    type: GET_COMMENT_SUCCESS,
    payload: data,
});

export const getCommentFail = (error: ISGetCommentData) => ({
    type: GET_COMMENT_FAIL,
    payload: error,
});
