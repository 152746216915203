/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    PREVIEW_UPLOADED_FILE_CLEAR,
    PREVIEW_UPLOADED_FILE_FAIL, 
    PREVIEW_UPLOADED_FILE_START, 
    PREVIEW_UPLOADED_FILE_SUCCESS 
 } from "../../../constants/actionType/orders";


export const previewOrderFileData = (data: any) => ({
  type: PREVIEW_UPLOADED_FILE_START,
  data
});

export const previewOrderFileSuccess = (data: any) => ({
  type: PREVIEW_UPLOADED_FILE_SUCCESS,
  payload: data
});

export const previewOrderFileError = (error: string | any) => ( {
  type: PREVIEW_UPLOADED_FILE_FAIL,
  payload: error
});

export const clearPreviewOrderFileStore = () => ({
  type: PREVIEW_UPLOADED_FILE_CLEAR
});