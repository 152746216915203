/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  SAVE_NEW_PRODUCT_START } from "../../../constants/actionType/shop";
import { saveData } from '../../../helpers';
import { saveNewProductError, saveNewProductSuccess } from "redux/actions";



export interface ResponseGenerator{
    message: any;
    data: any;
}

function* saveNewProduct(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'shop/record-product-item', data?.data);
        if (response?.data?.error) {
            yield put(saveNewProductError(response.data || 'Unknown error'));
        } else {
            yield put(saveNewProductSuccess(response));
        }
    } catch (e: any) {
        yield put(saveNewProductError(e.message || 'Unknown error'));
    }
}

export default function* getSaveNewProduct() {
    yield takeEvery(SAVE_NEW_PRODUCT_START, saveNewProduct)
}