import { Flex, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { IUserSelectorType } from '../../../../interfaces';

import { getAllSupplierData } from '../../../../redux/actions';
import NoResultFound from '../../../common/noResultFound';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
interface DataType {
    id: string | number;
    name: string;
    address: string;
    status: string;
}

const SuppliersList = () => {
    const dispatch = useDispatch();
    const [productData, setProductData] = useState<any>();
    const { getAllSupplier } = userSelector((user) => user);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Supplier ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '200px',
            render: (_, status) => (
                <Tag color={`${status.status === 'active' ? 'green' : 'volcano'}`}>{`${
                    status.status === 'active' ? 'Active' : 'Removed'
                }`}</Tag>
            ),
        },
    ];

    const data: DataType[] = productData?.data?.map((item: DataType) => {
        return {
            id: item?.id,
            name: item?.name,
            address: item?.address,
            status: item?.status,
        };
    });

    useEffect(() => {
        dispatch(getAllSupplierData());
    }, [dispatch]);

    useEffect(() => {
        setProductData(getAllSupplier?.data);
    }, [getAllSupplier?.data]);

    const customLoading = {
        spinning: getAllSupplier?.loading,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
    };

    return (
        <Flex style={{ height: '100vh' }}>
            <Table
                columns={columns}
                dataSource={data}
                loading={customLoading}
                size="small"
                style={{ width: '100%' }}
                locale={{ emptyText: NoResultFound }}
                pagination={data?.length >= 10 ? undefined : false}
            />
        </Flex>
    );
};

export default SuppliersList;
