/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  
    UPLOAD_DOCUMENT_SUCCESS,  
    UPLOAD_DOCUMENT_FAIL,  
    UPLOAD_DOCUMENT_START } 
    from "../../../constants/actionType/orders";
import {   saveData } from '../../../helpers';
import { toast } from "react-toastify";


interface ResponseGenerator{
    message: string
}

function* uploadDocument(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, `orders/upload-order-file/${data?.data?.orderFileId}`,   data?.data?.file);
        toast(`${userData?.message}`)
        yield put({type:UPLOAD_DOCUMENT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:UPLOAD_DOCUMENT_FAIL, error: e.message });
    }
}

export default function* fetchUploadDocument() {
    yield takeEvery(UPLOAD_DOCUMENT_START, uploadDocument)
}