import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { requestInmateCallHistoryData } from '../../../../redux/actions';
import { IUserSelectorType } from '../../../../interfaces';
import { Button, Table, Flex } from 'antd';
import moment from 'moment';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

interface InmateCallHistoryItem {
    phone_number_called: string;
    call_duration: string;
    created_at: string;
    amount_costed: string;
    currency: string;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const CallHistory = () => {
    const dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const { requestInmateCallHistory } = userSelector((state) => state);
    const { state } = useLocation();

    const inmateCode = state?.inmateCode;

    const columns = [
        {
            title: 'Phone Number Called',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },

        {
            title: 'Call duration',
            dataIndex: 'callDuration',
            key: 'callDuration',
        },
        {
            title: 'Amount Charged',
            dataIndex: 'amountCharged',
            key: 'amountCharged',
        },
        {
            title: 'Calling Date',
            dataIndex: 'date',
            key: 'date',
        },
    ];

    const tableData = Array.isArray(requestInmateCallHistory.data?.data?.details)
        ? requestInmateCallHistory?.data?.data?.details
        : [];

    const expenseOnCal = requestInmateCallHistory?.data?.data;

    const data = tableData.map((item: InmateCallHistoryItem, index: number) => ({
        phoneNumber: item.phone_number_called,
        callDuration: item.call_duration,
        amountCharged: item.amount_costed ? `${item.amount_costed} ${item?.currency}` : ' ',
        date: moment(item.created_at).format('YYYY-MM-DD HH:mm'),
        key: index,
    }));

    useEffect(() => {
        if (!inmateCode) {
            navigate('/inmates-list');
        }
        dispatch(
            requestInmateCallHistoryData({
                inmate_code: inmateCode,
                all: false,
            }),
        );
    }, [inmateCode, navigate, dispatch]);

    return (
        <>
            <Flex align="center" style={{ padding: '10px' }}>
                <Button danger type="primary" icon={<LeftOutlined />} ghost onClick={() => navigate('/inmates-list')}>
                    Back to Inmate list
                </Button>
            </Flex>

            <Flex justify="flex-start" className="white-background">
                <Flex gap="small" justify="center" vertical>
                    <span className="default-grey-colo">Amount spent daily</span>
                    <span className="bold-text ">{expenseOnCal && expenseOnCal?.daily_expense}</span>
                </Flex>
                <div className="separator__col"></div>
                <Flex gap="small" justify="center" vertical>
                    <span className="default-grey-colo">Amount spent weekly</span>
                    <span className="bold-text">{expenseOnCal && expenseOnCal?.weekly_expense}</span>
                </Flex>
            </Flex>
            <Table loading={requestInmateCallHistory.loading} columns={columns} dataSource={data} />
        </>
    );
};

export default CallHistory;
