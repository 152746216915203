/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    OUT_GOING_TRANSACTION_HISTORY_SUCCESS,
    OUT_GOING_TRANSACTION_HISTORY_FAIL,
    OUT_GOING_TRANSACTION_HISTORY_START 
} from "../../../constants/actionType/sendMoney";
import { saveData } from '../../../helpers';
import { delay } from "../../../constants/time";




export interface ResponseGenerator{
    message: string;
}

function* outgoingTransactionHistory(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'outgoing-transactions-history', data?.data);
        yield call(delay, 3000)
        yield put({type:OUT_GOING_TRANSACTION_HISTORY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:OUT_GOING_TRANSACTION_HISTORY_FAIL, error: e.message });
    }
}

export default function* fetchOutgoingTransactionHistory() {
    yield takeEvery(OUT_GOING_TRANSACTION_HISTORY_START, outgoingTransactionHistory)
}