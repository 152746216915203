import { Button, Divider, Flex, Modal, notification, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { clearCreateOrderFileStore, createOrderFileData, getOrderFileData } from '../../../redux/actions';
import { DAILY_ORDER_FILE, STATIC_ORDER_FILE } from '../../../constants/general';
import { IUserSelectorType } from '../../../interfaces';
import Toast from '../../common/Alert';
import { toast } from 'react-toastify';

interface IPayload {
    order_file_type: string | number;
}

interface CreateOrderFileProps {
    open: boolean;
    close: () => void;
}

const filterOrderFile = {
    file_name: 'all',
    order_file_type: 'all',
};

const useTypedSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const CreateOrderFileModal: React.FC<CreateOrderFileProps> = ({ open, close }) => {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [payload, setPayload] = useState<IPayload>({
        order_file_type: '',
    });
    const [checkedSwitch, setCheckedSwitch] = useState<number | null>(null);

    const { createOrderFile } = useTypedSelector((state) => state);

    const handleSwitchChange = (switchIndex: number, checked: boolean) => {
        if (checked) {
            setCheckedSwitch(switchIndex);
            setPayload((prev: IPayload) => ({ ...prev, order_file_type: switchIndex }));
        } else {
            setCheckedSwitch(null);
        }
    };

    const onCreateOrderFile = () => {
        dispatch(createOrderFileData(payload));
    };

    const onHandleClose = () => {
        close();
        dispatch(clearCreateOrderFileStore());
        dispatch(getOrderFileData(filterOrderFile));
    };

    useEffect(() => {
        if (createOrderFile.data?.success) {
            toast.success(createOrderFile.message);
            onHandleClose();
        }
        if (createOrderFile.error) {
            Toast(api, 'Error', createOrderFile.message);
        }
    }, [createOrderFile?.data]);

    return (
        <Modal
            title={'New order file'}
            open={open}
            onCancel={onHandleClose}
            footer={
                <Flex justify="flex-end">
                    <Button
                        disabled={payload.order_file_type === '' ? true : false}
                        ghost
                        type="primary"
                        onClick={onCreateOrderFile}
                        loading={createOrderFile.loading}
                    >
                        Create
                    </Button>
                </Flex>
            }
        >
            {contextHolder}
            <Flex gap="middle" vertical>
                <Divider style={{ margin: '4px' }} />
                <Flex align="center" justify="space-between">
                    <span>New Shop Order File</span>
                    <Switch
                        checked={checkedSwitch === STATIC_ORDER_FILE}
                        onChange={(checked) => handleSwitchChange(STATIC_ORDER_FILE, checked)}
                    />
                </Flex>

                <Flex align="center" justify="space-between">
                    <span>New Food Order File</span>
                    <Switch
                        checked={checkedSwitch === DAILY_ORDER_FILE}
                        onChange={(checked) => handleSwitchChange(DAILY_ORDER_FILE, checked)}
                    />
                </Flex>
                <Divider />
            </Flex>
        </Modal>
    );
};

export default CreateOrderFileModal;
