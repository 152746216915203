/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { ClearOutlined, LeftOutlined, PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IUserSelectorType } from '../../interfaces';
import { getInmateData, getInmateWalletsData, getOrdersData, getProductData } from '../../redux/actions';
import { Alert, Button, Card, Descriptions, DescriptionsProps, Divider, Flex, Form, Input, Select } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { clearMakeOrderStore, makeOrderData } from '../../redux/actions/orders/makeOrder';
import { toast } from 'react-toastify';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
const MakeOrder: React.FC<any> = ({ setChangePage, orderFileId }) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState<any>({});
    const [productForm, setProductForm] = useState<any>({});
    const [totalAmount, setTotalAmount] = useState<any>();
    const [itemSelected, setItemSelected]: any = useState([]);
    const [inmateId, setInmateId]: any = useState();
    const [product, setProduct]: any = useState();

    const addInputField = () => {
        if (productForm.requested_quantity) {
            const filterList = itemSelected?.find((item: any) => item.id === productForm.id);
            if (filterList) {
                toast.error('Item already exist in the list');
            } else {
                setItemSelected([
                    ...itemSelected,
                    {
                        id: productForm.id,
                        item: productForm.item,
                        quantity: productForm.requested_quantity,
                        unit_price: productForm.unit_price,
                        total_price: `${productForm.requested_quantity * productForm.unit_price}`,
                    },
                ]);
            }
        } else {
            toast.error('Please enter Requested quantity');
        }
    };

    const removeInputFields = (index: number, _e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const rows = [...itemSelected];
        rows.splice(index, 1);
        setItemSelected(rows);
    };

    const {
        getInmatesWallets,
        getInmates: {
            data: { data },
        },
        outGoingMoney,
        getProduct,
        makeOder,
    } = userSelector((user) => user);

    const inmateWallets =
        getInmatesWallets?.data?.data !== undefined
            ? getInmatesWallets?.data?.data
            : [
                  {
                      amount: '',
                      currency: '',
                      first_name: '',
                      id: '',
                      inmate_code: '',
                      last_name: '',
                  },
              ];
    const inmateInfo: any[] = inmateWallets?.details?.map((item: any) => {
        return {
            value: parseInt(item.id),
            label: `${item?.inmate_code}`,
        };
    });

    const products = getProduct?.data?.data !== undefined ? getProduct?.data?.data : [];
    const productInfo: any[] = products?.map((item: any) => {
        return {
            value: item.id,
            label: item?.item,
        };
    });

    const onChangeInmateCode = (value: any) => {
        setInmateId(value);
    };

    const refresh = () => {
        setInmateId();
    };

    const onChangeProduct = (value: any) => {
        setProduct(value);
    };

    const onChangeInputValue = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const onChangeProductInputForm = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...productForm };
        list[name] = value;
        setProductForm(list);
    };

    useEffect(() => {
        if (inmateId !== undefined) {
            inmateWallets?.details?.map((item: any) => {
                if (parseInt(item?.id) === inmateId) {
                    setForm({
                        inmateId: item?.id,
                        firstName: item?.first_name,
                        lastName: item?.last_name,
                        inmate_code: item?.inmate_code,
                        receiverFirstName: '',
                        receiverLastName: '',
                        phoneNumber: '',
                        tariffAmount: '',
                        wallet: item?.amount,
                    });
                }
            });
        }
    }, [inmateId, data, inmateWallets?.details]);

    const columns1: ColumnsType<any> = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total price',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, _data, index: number) => {
                return (
                    <Button key={index} type="link" onClick={(e) => removeInputFields(index, e)}>
                        <DeleteOutlined />
                    </Button>
                );
            },
        },
    ];

    const dataCol1: any = itemSelected.map((item: any) => {
        return {
            id: item?.id,
            quantity: item?.quantity,
            unit_price: item?.unit_price,
            item: item?.item,
            total_price: parseFloat(item?.total_price),
        };
    });

    useEffect(() => {
        const totalAmount = itemSelected
            ?.map((item: any) => parseFloat(item.total_price))
            .reduce((a: any, b: any) => a + b, 0);
        setTotalAmount(totalAmount);
    }, [itemSelected]);

    useEffect(() => {
        if (getProduct !== undefined) {
            getProduct?.data?.data?.map((item: any) => {
                if (item?.id === product) {
                    setProductForm({
                        id: item.id,
                        item: item.item,
                        quantity: item.quantity,
                        unit_price: item.unit_price,
                    });
                }
            });
        }
    }, [product]);

    useEffect(() => {
        if (makeOder?.error) {
            toast.error(makeOder?.message);
        }
        if (makeOder?.data && !makeOder?.error) {
            setChangePage('order-file-details');
        }
    }, [dispatch, makeOder?.error, makeOder?.data]);

    useEffect(() => {
        dispatch(getProductData());
        dispatch(getOrdersData());
        dispatch(getInmateWalletsData());
    }, []);

    useEffect(() => {
        dispatch(getProductData());
        dispatch(
            getInmateData({
                inmateWithWallet: 'YES',
            }),
        );
        dispatch(getProductData());
    }, []);

    const onSubmit = () => {
        const newList: any = [];
        if (itemSelected?.length) {
            itemSelected?.map((item: { id: number; quantity: string }) => {
                newList.push({
                    inmate_id: form.inmateId,
                    order_file: orderFileId,
                    product_id: item?.id,
                    description: '',
                    days: '',
                    requested_quantity: parseFloat(item?.quantity),
                });
            });
            dispatch(makeOrderData({ data_to_format: [], order_file: orderFileId, data: newList }));
            setItemSelected([]);
        } else {
            toast.error('No item added in the list....');
        }
    };

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Total Items',
            children: itemSelected.length,
        },
        {
            key: '2',
            label: 'Total Amount',
            children: totalAmount ?? 0,
        },
        {
            key: '3',
            label: `Inmate's account balance`,
            children: form.wallet ?? 0,
        },
        {
            key: '4',
            children: (
                <Flex justify="flex-end" style={{ width: '100%' }}>
                    <Button
                        loading={makeOder.loading}
                        disabled={form?.wallet < totalAmount || !itemSelected?.length ? true : false}
                        onClick={() => onSubmit()}
                        type="primary"
                        icon={<SaveOutlined />}
                    >
                        Save
                    </Button>
                </Flex>
            ),
        },
    ];

    return (
        <>
            <Flex justify="space-between" align="center" style={{ padding: '10px' }}>
                <Button
                    danger
                    type="primary"
                    icon={<LeftOutlined />}
                    ghost
                    onClick={() => {
                        setChangePage('order-file-details');
                        dispatch(clearMakeOrderStore());
                    }}
                >
                    Back to All orders
                </Button>
                <Flex gap="small" align="center">
                    <Select
                        showSearch
                        style={{ width: 200, textAlign: 'left' }}
                        placeholder="Search inmate by code"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={inmateInfo}
                        onChange={onChangeInmateCode}
                        disabled={inmateId === undefined || inmateId === '' ? false : true}
                    />

                    <Select
                        showSearch
                        style={{ width: 200, textAlign: 'left' }}
                        placeholder="Search product items"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={productInfo}
                        onChange={onChangeProduct}
                        disabled={form.firstName !== undefined ? false : true}
                    />
                    <Button icon={<ClearOutlined />} onClick={() => refresh()} type="primary">
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Flex gap="middle" align="start" justify="space-between" style={{ background: '#fff', padding: '10px' }}>
                <Card size="small" style={{ width: '35%' }}>
                    <Form layout="vertical">
                        <Form.Item label="First name" style={{ margin: '4px' }}>
                            <Input
                                size="large"
                                value={form.firstName}
                                placeholder="Enter first name"
                                name="firstName"
                                onChange={(event) => onChangeInputValue(event)}
                                disabled={true}
                                style={{ color: '#333' }}
                            />
                        </Form.Item>
                        <Form.Item label="Last name" style={{ margin: '4px' }}>
                            <Input
                                size="large"
                                value={form.lastName}
                                name="lastName"
                                onChange={(event) => onChangeInputValue(event)}
                                placeholder="Last name"
                                disabled={true}
                                style={{ color: '#333' }}
                            />
                        </Form.Item>
                        <Form.Item label="Inmate code" style={{ margin: '4px' }}>
                            <Input
                                size="large"
                                type="text"
                                value={form.inmate_code}
                                name="inmate_code"
                                onChange={(event) => onChangeInputValue(event)}
                                placeholder="Enter inmate code"
                                disabled={true}
                                style={{ color: '#333' }}
                            />
                        </Form.Item>

                        <Form.Item label="Selected unit price" style={{ margin: '4px' }}>
                            <Input
                                size="large"
                                placeholder="Selected price"
                                value={productForm.unit_price}
                                name="quantity"
                                disabled={true}
                                style={{ color: '#333' }}
                                onChange={(event) => onChangeProductInputForm(event)}
                            />
                        </Form.Item>

                        <Form.Item label="Stock quantity" style={{ margin: '4px' }}>
                            <Input
                                size="large"
                                placeholder="Selected quantity"
                                value={productForm.quantity}
                                name="quantity"
                                disabled={true}
                                style={{ color: '#333' }}
                                onChange={(event) => onChangeProductInputForm(event)}
                            />
                        </Form.Item>

                        <Form.Item label="Enter requested quantity" style={{ margin: '4px' }}>
                            <Input
                                size="large"
                                placeholder="Enter requested quantity"
                                value={productForm.requested_quantity}
                                name="requested_quantity"
                                style={{ color: '#333' }}
                                onChange={(event) => onChangeProductInputForm(event)}
                                disabled={productForm.quantity > 0 ? false : true}
                            />
                        </Form.Item>
                        <Divider style={{ margin: '14px' }} />
                        <Flex justify="flex-end">
                            <Form.Item>
                                <Button
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    disabled={parseFloat(totalAmount) > parseFloat(form.wallet) ? true : false}
                                    onClick={addInputField}
                                    loading={outGoingMoney.loading}
                                >
                                    Add to the list
                                </Button>
                            </Form.Item>
                        </Flex>
                    </Form>
                </Card>

                <Card size="small" style={{ width: '65%' }}>
                    <Flex gap="small" vertical>
                        <Flex gap="small" vertical>
                            {form?.wallet < totalAmount && (
                                <Alert
                                    type="warning"
                                    message={`You're not allowed to order for this inmate. Insufficient wallet funds.`}
                                />
                            )}
                            <Table
                                size="small"
                                bordered
                                columns={columns1}
                                dataSource={dataCol1}
                                pagination={dataCol1.length > 5 ? { pageSize: 5 } : false}
                                style={{ height: 380 }}
                            />
                        </Flex>
                        <Descriptions
                            title={
                                <Flex justify="flex-start">
                                    <span>Summary</span>
                                </Flex>
                            }
                            size="default"
                            items={items}
                        />
                    </Flex>
                </Card>
            </Flex>
        </>
    );
};

export default MakeOrder;
