/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     UPLOAD_BULK_SAVING_DOCUMENT_SUCCESS,
     UPLOAD_BULK_SAVING_DOCUMENT_FAIL,
     UPLOAD_BULK_SAVING_DOCUMENT_START 
} from "../../../../constants/actionType/bulkSaving";
import { saveData } from '../../../../helpers';
import { toast } from "react-toastify";




export interface ResponseGenerator{
    message: string
}

function* uploadBulkSaving(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, `upload-bulk-saving-file/${data?.data?.savingFileId}`,  data?.data?.file);
        toast(`${userData?.message}`)
        yield put({type: UPLOAD_BULK_SAVING_DOCUMENT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: UPLOAD_BULK_SAVING_DOCUMENT_FAIL, error: e.message });
    }
}

export default function* fetchUploadBulkSaving() {
    yield takeEvery( UPLOAD_BULK_SAVING_DOCUMENT_START, uploadBulkSaving)
}