import {
    REQUEST_INMATE_CALLS_FAIL, 
    REQUEST_INMATE_CALLS_START, 
    REQUEST_INMATE_CALLS_SUCCESS 
    } from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  IInmateCalls } from "../../../interfaces/linkPhoneNumber";

 
interface ILinked {
    data: IInmateCalls,
    error: []
}

export const inmateCallsData = (data: string) =>  ({type: REQUEST_INMATE_CALLS_START, data});

export const inmateCallsSuccess = (data: ILinked) => ({
    type: REQUEST_INMATE_CALLS_SUCCESS, 
    payload: data
})

export const inmateCallsFail = (error: ILinked) => ({ type: REQUEST_INMATE_CALLS_FAIL, payload: error})
