import React from 'react';
import MeetingAccessPage from '../../components/meeting';

const MeetingAccessPageContainer = () => {
    return (
        <>
            <MeetingAccessPage />
        </>
    );
};

export default MeetingAccessPageContainer;
