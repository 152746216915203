/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    PRODUCT_SUMMARY_REPORT_SUCCESS,
    PRODUCT_SUMMARY_REPORT_FAIL,
    PRODUCT_SUMMARY_REPORT_START 
} from "../../../constants/actionType/shop";
import { fetchData} from '../../../helpers';


export interface ResponseGenerator{}

function* getProductSummaryReport() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/prison/shop/product-summary');
        yield put({type:PRODUCT_SUMMARY_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:PRODUCT_SUMMARY_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchGetProductSummaryReport() {
    yield takeEvery(PRODUCT_SUMMARY_REPORT_START, getProductSummaryReport)
}