import { call, put, takeEvery } from "redux-saga/effects";
import {
    COMPLETED_VISIT_SUCCESS,
    COMPLETED_VISIT_FAIL,
    COMPLETED_VISIT_START 
} from "../../../constants/actionType/appointment";
import { fetchData} from '../../../helpers';
import {  IAppointmentData } from "../../../interfaces/appointment";

export interface ResponseGenerator{
    message: string
    completedVisitData: IAppointmentData
}

function* completedVisit() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'prison/get-completed-visit');
        yield put({type:COMPLETED_VISIT_SUCCESS, completedVisitData: userData});

    } catch (e: any) {
        yield put({ type:COMPLETED_VISIT_FAIL, error: e.message });
    }
}

export default function* fetchCompletedVisit() {
    yield takeEvery(COMPLETED_VISIT_START, completedVisit)
}