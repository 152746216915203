import {
    GET,
    POST,
    DELETE,
  } from "../constants/requestMethods";
  import apiRequest, { videoAPIRequest } from "./apiRequest";

  
  export const saveData = async (url: string, data: any) => apiRequest({requestMethod: POST, url: url, data: data});
  export const fetchData = async (url: string) => apiRequest({requestMethod: GET, url: url});
  export const deleteData = async ( url:string, data:any ) => apiRequest({requestMethod: DELETE, url: url, data: data});


  export const saveDataV = async (url: string, data: any) => videoAPIRequest({requestMethod: POST, url: url, data: data});
  export const fetchDataV = async (url: string) => videoAPIRequest({requestMethod: GET, url: url});
  