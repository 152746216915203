import { 
    SEND_MONEY_START, 
    SEND_MONEY_SUCCESS, 
    SEND_MONEY_FAIL 
} from "../../../constants/actionType/sendMoney";


interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const sendMoney = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SEND_MONEY_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case SEND_MONEY_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case SEND_MONEY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        
        default: {
            return state;
          }
    }
}

export default sendMoney;