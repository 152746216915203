/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  
    REQUEST_INMATE_CALLS_SUCCESS,  
    REQUEST_INMATE_CALLS_FAIL,  
    REQUEST_INMATE_CALLS_START } 
    from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  fetchData } from '../../../helpers';


interface ResponseGenerator{
    message: string
}

function* inmateCalls(data: any) {
    try {
        const userData: ResponseGenerator = yield call(fetchData,  data?.data);
        yield put({type:REQUEST_INMATE_CALLS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_INMATE_CALLS_FAIL, error: e.message });
    }
}

export default function* fetchInmateCalls() {
    yield takeEvery(REQUEST_INMATE_CALLS_START, inmateCalls)
}