import {
     GET_DEVICES_CLEAR,
     GET_DEVICES_FAIL,
     GET_DEVICES_START,
     GET_DEVICES_SUCCESS,
 } from '../../../constants/actionType/videoMeeting';
 import { IResponseData } from './checkVisitId';
 
 
 interface IPayload {
    date: string;
 }
 
 export const getDevicesData = (data:IPayload) => ({ type: GET_DEVICES_START, data});
 
 export const getDevicesSuccess = (data: IResponseData) => ({
     type: GET_DEVICES_SUCCESS,
     payload: data,
 });
 
 export const getDevicesFail = (error: IResponseData) => ({
     type: GET_DEVICES_FAIL,
     payload: error,
 });
 
 
 export const clearGetDevices = () =>  ({
     type: GET_DEVICES_CLEAR,
 });