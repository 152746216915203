/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GENERATE_BULK_TRANSACTION_FILE_FAIL, 
    GENERATE_BULK_TRANSACTION_FILE_START, 
    GENERATE_BULK_TRANSACTION_FILE_SUCCESS 
} from "../../../../constants/actionType/bulkTransaction";


 export const createBulkTransactionData = (data: undefined) => ({type:  GENERATE_BULK_TRANSACTION_FILE_START, data});
 
 export const createBulkTransactionSuccess = (data:any) => ({
   type: GENERATE_BULK_TRANSACTION_FILE_SUCCESS,
   payload: data
 })
 
 export const createBulkTransactionError = (error:any) => ({
   type: GENERATE_BULK_TRANSACTION_FILE_FAIL,
   payload: error
 })