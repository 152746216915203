import { 
    COMPLETED_VISIT_START, 
    COMPLETED_VISIT_SUCCESS, 
    COMPLETED_VISIT_FAIL,
    COMPLETED_VISIT_CLEAR
} from "../../../constants/actionType/appointment";



interface InitialState {
    completedVisitData: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    completedVisitData: [],
    error: {},
    loading: false,
}

const completedVisit = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case COMPLETED_VISIT_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case COMPLETED_VISIT_SUCCESS: {
            return {
                ...state,
                loading: false,
                completedVisitData: action.completedVisitData,
            };
        }

        case COMPLETED_VISIT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case COMPLETED_VISIT_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                completedVisitData: null
            };
        }

        default: {
            return state;
          }
    }
}

export default completedVisit;