import {
    LINK_DEVICE_TO_VISIT_CLEAR,
    LINK_DEVICE_TO_VISIT_FAIL,
    LINK_DEVICE_TO_VISIT_START,
    LINK_DEVICE_TO_VISIT_SUCCESS,
} from '../../../constants/actionType/videoMeeting';
import { IResponseData } from './checkVisitId';


interface IPayload {
   browser_id: string | number;
   visit_id: string | number;
}

export const linkDeviceToVisitData = (data:IPayload) => ({ type: LINK_DEVICE_TO_VISIT_START, data});

export const linkDeviceToVisitSuccess = (data: IResponseData) => ({
    type: LINK_DEVICE_TO_VISIT_SUCCESS,
    payload: data,
});

export const linkDeviceToVisitFail = (error: IResponseData) => ({
    type: LINK_DEVICE_TO_VISIT_FAIL,
    payload: error,
});


export const clearLinkDeviceToVisit = () =>  ({
    type: LINK_DEVICE_TO_VISIT_CLEAR,
});