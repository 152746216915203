import React, { useState } from 'react';
import SuperAdminInmateWalletList from './wallets/inmatesWallets';
import SuperAdminInmateWithZeroWallet from './wallets/inmateWithZeroBalance';

const SuperAdminInmateWalletInformation = () => {
    const [inmateWalletList, setInmateWalletList] = useState(true);
    const [inmateWithZeroBalance, setInmateWithZeroBalance] = useState<boolean>();

    return (
        <>
            <div className="tbl-items" style={{ padding: '0px' }}>
                <div className="tab-box">
                    <div
                        onClick={() => {
                            setInmateWalletList(true);
                            setInmateWithZeroBalance(false);
                        }}
                        className={inmateWalletList ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Inmates wallets</span>
                    </div>
                    <div
                        onClick={() => {
                            setInmateWithZeroBalance(true);
                            setInmateWalletList(false);
                        }}
                        className={inmateWithZeroBalance ? 'active-tab' : 'tab-btn'}
                    >
                        <span>Inmates Per Amount </span>
                    </div>
                </div>
            </div>
            {inmateWalletList === true ? <SuperAdminInmateWalletList /> : null}
            {inmateWithZeroBalance === true ? <SuperAdminInmateWithZeroWallet /> : null}
        </>
    );
};

export default SuperAdminInmateWalletInformation;
