/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     VISITOR_ALLOWED_ITEMS_START 
} from "../../../constants/actionType/appointment";
import { saveData} from '../../../helpers';
import { visitorItemsError, visitorItemsSuccess } from "../../actions";

interface ResponseGenerator {
    data: any;
}

function* visitorItems(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'prison/fetch-visitor-items', action.data);
        if (response?.data?.error) {
            yield put(visitorItemsError(response.data || 'Unknown error'));
        } else {
            yield put(visitorItemsSuccess(response));
        }
    } catch (e: any) {
        yield put(visitorItemsError(e.message || 'Unknown error'));
    }
}

export default function* fetchVisitorItems() {
    yield takeEvery(VISITOR_ALLOWED_ITEMS_START, visitorItems);
}