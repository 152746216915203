/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    UPDATE_DELETE_USER_SUCCESS, 
    UPDATE_DELETE_USER_FAIL, 
    UPDATE_DELETE_USER_START 
} from "../../../constants/actionType/users";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}


function* updateDeleteUser(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'v2/admin/update-delete-user', data?.data);
        toast(`${userData?.message}`)
        yield put({type: UPDATE_DELETE_USER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: UPDATE_DELETE_USER_FAIL, error: e.message });
    }
}

export default function* getUpdateDeleteUserData() {
    yield takeEvery(UPDATE_DELETE_USER_START, updateDeleteUser)
}