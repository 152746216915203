export const CHECK_VISIT_ID_START = "CHECK_VISIT_ID_START";
export const CHECK_VISIT_ID_SUCCESS = "CHECK_VISIT_ID_SUCCESS";
export const CHECK_VISIT_ID_FAIL =  "CHECK_VISIT_ID_FAIL";
export const CHECK_VISIT_ID_CLEAR =  "CHECK_VISIT_ID_CLEAR";


export const CHECK_WHO_JOINED_START = "CHECK_WHO_JOINED_START";
export const CHECK_WHO_JOINED_SUCCESS = "CHECK_WHO_JOINED_SUCCESS";
export const CHECK_WHO_JOINED_FAIL =  "CHECK_WHO_JOINED_FAIL";
export const CHECK_WHO_JOINED_CLEAR =  "CHECK_WHO_JOINED_CLEAR";

export const REQUEST_VIDEO_VISITS_START = "REQUEST_VIDEO_VISITS_START";
export const REQUEST_VIDEO_VISITS_SUCCESS = "REQUEST_VIDEO_VISITS_SUCCESS";
export const REQUEST_VIDEO_VISITS_FAIL =  "REQUEST_VIDEO_VISITS_FAIL";
export const REQUEST_VIDEO_VISITS_CLEAR =  "REQUEST_VIDEO_VISITS_CLEAR";


export const REQUEST_PENDING_VISITS_START = "REQUEST_PENDING_VISITS_START";
export const REQUEST_PENDING_VISITS_SUCCESS = "REQUEST_PENDING_VISITS_SUCCESS";
export const REQUEST_PENDING_VISITS_FAIL =  "REQUEST_PENDING_VISITS_FAIL";
export const REQUEST_PENDING_VISITS_CLEAR =  "REQUEST_PENDING_VISITS_CLEAR";

export const REQUEST_VISITS_DONE_START = "REQUEST_VISITS_DONE_START";
export const REQUEST_VISITS_DONE_SUCCESS = "REQUEST_VISITS_DONE_SUCCESS";
export const REQUEST_VISITS_DONE_FAIL =  "REQUEST_VISITS_DONE_FAIL";
export const REQUEST_VISITS_DONE_CLEAR =  "REQUEST_VISITS_DONE_CLEAR";

export const SAVE_DEVISE_SIGNATURE_START = "SAVE_DEVISE_SIGNATURE_START";
export const SAVE_DEVISE_SIGNATURE_SUCCESS = "SAVE_DEVISE_SIGNATURE_SUCCESS";
export const SAVE_DEVISE_SIGNATURE_FAIL =  "SAVE_DEVISE_SIGNATURE_FAIL";
export const SAVE_DEVISE_SIGNATURE_CLEAR =  "SAVE_DEVISE_SIGNATURE_CLEAR";

export const GET_DEVICES_START = "GET_DEVICES_START";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_FAIL =  "GET_DEVICES_FAIL";
export const GET_DEVICES_CLEAR =  "GET_DEVICES_CLEAR";

export const LINK_DEVICE_TO_VISIT_START = "LINK_DEVICE_TO_VISIT_START";
export const LINK_DEVICE_TO_VISIT_SUCCESS = "LINK_DEVICE_TO_VISIT_SUCCESS";
export const LINK_DEVICE_TO_VISIT_FAIL =  "LINK_DEVICE_TO_VISIT_FAIL";
export const LINK_DEVICE_TO_VISIT_CLEAR =  "LINK_DEVICE_TO_VISIT_CLEAR";

export const AUTO_START_SESSION_START = "AUTO_START_SESSION_START";
export const AUTO_START_SESSION_SUCCESS = "AUTO_START_SESSION_SUCCESS";
export const AUTO_START_SESSION_FAIL =  "AUTO_START_SESSION_FAIL";
export const AUTO_START_SESSION_CLEAR =  "AUTO_START_SESSION_CLEAR";

export const REQUEST_START_MEETING_START = "REQUEST_START_MEETING_START";
export const REQUEST_START_MEETING_SUCCESS = "REQUEST_START_MEETING_SUCCESS";
export const REQUEST_START_MEETING_FAIL =  "REQUEST_START_MEETING_FAIL";
export const REQUEST_START_MEETING_CLEAR =  "REQUEST_START_MEETING_CLEAR";