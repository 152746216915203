/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CHANGE_PASSWORD_USER_FAIL, 
    CHANGE_PASSWORD_USER_START, 
    CHANGE_PASSWORD_USER_SUCCESS,
    CHANGE_PASSWORD_USER_CLEAR
} from "../../../constants/actionType/users";

 export const changePasswordData = (data: any) => ({type:  CHANGE_PASSWORD_USER_START, data});
 
 export const changePasswordSuccess = (data:any) => ({
   type: CHANGE_PASSWORD_USER_SUCCESS,
   payload: data
 })
 
 export const changePasswordError = (error:any) => ({
   type: CHANGE_PASSWORD_USER_FAIL,
   payload: error
 })

 export const clearChangePasswordStore = () => ({
  type: CHANGE_PASSWORD_USER_CLEAR,
})