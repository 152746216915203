import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import Translate from '../../../languages/Translate';

const Footer = () => {
    return (
        <>
            <div className="footer__items">
                <div className="footer__items-top">
                    <div className="footer-card__box">
                        <div className="footer-card">
                            <div className="footer-card__title">
                                <span>{Translate('About Us')}</span>
                            </div>
                            <div className="footer-card__btn-group">
                                <Link to="#">{Translate('How it works')}</Link>
                                <Link to="#">{Translate('Terms && conditions')}</Link>
                            </div>
                        </div>
                        <div className="footer-card">
                            <div className="footer-card__title">
                                <span>{Translate('Contact Us')}</span>
                            </div>
                            <div className="footer-card__btn-group">
                                <Link to="#">+250 788 304 663</Link>
                                <Link to="#">{Translate('Support')}</Link>
                            </div>
                        </div>
                        <div className="footer-card">
                            <div className="footer-card__title">
                                <span>{Translate('Social Media')}</span>
                            </div>
                            <div className="footer-card__btn-group">
                                <Link to="#">WhatsApp</Link>
                                <Link to="#">Twitter</Link>
                                <Link to="#">Youtube</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <span>Copyright © 2022. All right reserved.</span>
            </div>
        </>
    );
};

export default Footer;
