/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_DELIVERY_NOTE_ITEMS_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers/index';
import { getDeliveryNoteItemsError, getDeliveryNoteItemsSuccess } from "../../actions";


export interface ResponseGenerator {
    data: any
}

function* getDeliverNoteItems(data:any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/retrieve-delivery-note', data?.data);
        if (response?.data?.error) {
            yield put(getDeliveryNoteItemsError(response.data || 'Unknown error'));
        } else {
            yield put(getDeliveryNoteItemsSuccess(response));
        }
    } catch (e: any) {
        yield put(getDeliveryNoteItemsError(e.message || 'Unknown error'));
    }
}

export default function* fetchGetDeliverNoteItems() {
    yield takeEvery(GET_DELIVERY_NOTE_ITEMS_START, getDeliverNoteItems)
}