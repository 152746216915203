/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    OUT_GOING_TRANSACTION_HISTORY_FAIL, 
    OUT_GOING_TRANSACTION_HISTORY_START, 
    OUT_GOING_TRANSACTION_HISTORY_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const outgoingTransactionHistoryData = (data: any) => ({type:  OUT_GOING_TRANSACTION_HISTORY_START, data});
 
 export const outgoingTransactionHistorySuccess = (data:any) => ({
   type: OUT_GOING_TRANSACTION_HISTORY_SUCCESS,
   payload: data
 })
 
 export const outgoingTransactionHistoryError = (error:any) => ({
   type: OUT_GOING_TRANSACTION_HISTORY_FAIL,
   payload: error
 })