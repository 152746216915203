import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REQUEST_VISITS_DONE_START, 
    REQUEST_VISITS_DONE_SUCCESS, 
    REQUEST_VISITS_DONE_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import { fetchData } from "../../../helpers";


export interface ResponseGenerator{
    message: string
}


function* visitDone() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'video-call/visit-done');
        yield put({type: REQUEST_VISITS_DONE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_VISITS_DONE_FAIL, error: e.message });
    }
}

export default function* fetchVisitDone() {
    yield takeEvery(REQUEST_VISITS_DONE_START, visitDone)
}