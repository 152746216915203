/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  REGISTER_INMATES_CLEAR,
    REGISTER_INMATES_FAIL, 
    REGISTER_INMATES_START, 
    REGISTER_INMATES_SUCCESS 
} from "../../../constants/actionType/inmates";

 export const registerInmateData = (data: any) => ({type:  REGISTER_INMATES_START, data});
 
 export const registerInmatesSuccess = (data:any) => ({
   type: REGISTER_INMATES_SUCCESS,
   payload: data
 })
 
 export const registerInmatesError = (error:any) => ({
   type: REGISTER_INMATES_FAIL,
   payload: error
 })

 export const clearRegisterInmateStore = () => ({
  type: REGISTER_INMATES_CLEAR
 })