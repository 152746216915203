/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GET_APPOINTMENT_REPORT_FAIL, 
    GET_APPOINTMENT_REPORT_START, 
    GET_APPOINTMENT_REPORT_SUCCESS 
} from "../../../constants/actionType/appointment";

 export const getAppointmentReportsData = (data:any) => ({type:  GET_APPOINTMENT_REPORT_START, data});
 
 export const getAppointmentReportSuccess = (data:any) => ({
   type: GET_APPOINTMENT_REPORT_SUCCESS,
   payload: data
 })
 
 export const getAppointmentReportError = (error:any) => ({
   type: GET_APPOINTMENT_REPORT_FAIL,
   payload: error
 })