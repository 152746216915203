import React, { useState } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import useAgora from './agoraConfig';
import { AgoraVideoPlayer } from 'agora-rtc-react';
import './index.css';
import { clearCheckVisitID, clearCheckWhoJoined } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

interface IVideoPlayer {
    tracks: any;
    users: any;
    guestData: any;
    guest: string;
}

const VideoPlayer: React.FC<IVideoPlayer> = ({ tracks, users, guestData, guest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [useClient] = useAgora();
    const client: any = useClient();

    const [trackState, setTrackState] = useState({ video: true, audio: true });

    const endMeetingBtn = async () => {
        await client.leave();
        client.removeAllListeners();
        tracks[0].close();
        tracks[1].close();
        navigate('/');
        dispatch(clearCheckWhoJoined());
        dispatch(clearCheckVisitID());
    };

    const mute = async (type: string) => {
        if (type === 'audio') {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === 'video') {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    };

    const lastIndex = users.length - 1;

    return (
        <div className="video-screen">
            {tracks && trackState.video && (
                <div className="stream-video">
                    <AgoraVideoPlayer videoTrack={tracks[1]} className="main-camera" />
                </div>
            )}

            {guest === 'inmate' && guestData && tracks && trackState.video === false && (
                <div className="stream-video">
                    <div className="main-camera-blanc">
                        <div
                            style={{
                                margin: '35% auto',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <span style={{ color: 'white' }}>{guestData?.first_name}</span>
                            <span style={{ color: 'white', marginLeft: '10px' }}>{guestData?.last_name}</span>
                        </div>
                    </div>
                </div>
            )}

            {guest === 'visitor' && guestData && tracks && trackState.video === false && (
                <div className="stream-video">
                    <div className="main-camera-blanc">
                        <div
                            style={{
                                margin: '35% auto',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <span style={{ color: 'white' }}>{guestData?.visitor_first_name}</span>
                            <span style={{ color: 'white', marginLeft: '10px' }}>{guestData?.visitor_last_name}</span>
                        </div>
                    </div>
                </div>
            )}

            <div className="options">
                <Button onClick={() => endMeetingBtn()} className="end-meeting-btn">
                    <span>End meeting</span>
                </Button>

                <Button onClick={() => mute('video')}>{trackState.video ? 'Close camera' : 'Open camera'}</Button>

                <Button onClick={() => mute('audio')}> {trackState.audio ? 'Mute Audio' : 'Unmute Audio'}</Button>
            </div>

            <div className="call-accepted">
                {users.length > 1 && (
                    <>
                        {users[lastIndex]?.videoTrack && (
                            <AgoraVideoPlayer
                                videoTrack={users[lastIndex].videoTrack}
                                key={users[lastIndex].uid}
                                className="incoming-video"
                            />
                        )}
                    </>
                )}
            </div>

            {guest === 'inmate' && guestData && users.length <= 1 && (
                <div className="blanc-screen">
                    <div
                        style={{
                            margin: '35% auto',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <span style={{ color: 'white' }}>We are waiting for </span>
                        <span style={{ color: 'white', marginLeft: '10px', fontWeight: 'bold' }}>
                            {guestData?.visitor_first_name}
                        </span>
                        <span style={{ color: 'white', marginLeft: '10px', fontWeight: 'bold' }}>
                            {guestData?.visitor_last_name}
                        </span>
                        <span style={{ color: 'white', marginLeft: '10px' }}>to join </span>
                    </div>
                </div>
            )}

            {guest === 'visitor' && guestData && users.length <= 1 && (
                <div className="blanc-screen">
                    <div
                        style={{
                            margin: '35% auto',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <span style={{ color: 'white' }}>We are waiting for </span>
                        <span style={{ color: 'white', marginLeft: '10px', fontWeight: 'bold' }}>
                            {guestData?.first_name}
                        </span>
                        <span style={{ color: 'white', marginLeft: '10px', fontWeight: 'bold' }}>
                            {guestData?.last_name}
                        </span>
                        <span style={{ color: 'white', marginLeft: '10px' }}>to join </span>
                    </div>
                </div>
            )}

            <div className="video-footer"></div>
        </div>
    );
};

export default VideoPlayer;
