import { 

    SEND_BULK_SMS_START, 
    SEND_BULK_SMS_SUCCESS, 
    SEND_BULK_SMS_FAIL, 
    SEND_BULK_SMS_CLEAR
} from "constants/actionType/appointment";

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const  sendBulkSMS = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SEND_BULK_SMS_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case SEND_BULK_SMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case SEND_BULK_SMS_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case SEND_BULK_SMS_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }
        default: {
            return state;
          }
    }
}

export default sendBulkSMS;