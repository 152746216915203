import {
    CALCULATE_ORDER_AMOUNT_FAIL,
    CALCULATE_ORDER_AMOUNT_START,
    CALCULATE_ORDER_AMOUNT_SUCCESS,
} from '../../../constants/actionType/orders';

export interface ISCalculateOrderAmountData {
    data: any;
    error: any;
}

export const calculateOrderAmountData = (data:any) => ({ type: CALCULATE_ORDER_AMOUNT_START, data});

export const calculateOrderAmountSuccess = (data: ISCalculateOrderAmountData) => ({
    type: CALCULATE_ORDER_AMOUNT_SUCCESS,
    payload: data,
});

export const calculateOrderAmountFail = (error: ISCalculateOrderAmountData) => ({
    type: CALCULATE_ORDER_AMOUNT_FAIL,
    payload: error,
});
