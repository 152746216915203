// GET SHOP SUMMARY REPORT
export const GET_SHOP_SUMMARY_REPORT_START = "GET_SHOP_SUMMARY_REPORT_START";
export const GET_SHOP_SUMMARY_REPORT_SUCCESS = "GET_SHOP_SUMMARY_REPORT_SUCCESS";
export const GET_SHOP_SUMMARY_REPORT_FAIL =  "GET_SHOP_SUMMARY_REPORT_FAIL";

// GET PRODUCT 
export const GET_PRODUCT_START = "GET_PRODUCT_START";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL =  "GET_PRODUCT_FAIL";

// SAVE NEW PRODUCT
export const SAVE_NEW_PRODUCT_START = "SAVE_NEW_PRODUCT_START";
export const SAVE_NEW_PRODUCT_SUCCESS = "SAVE_NEW_PRODUCT_SUCCESS";
export const SAVE_NEW_PRODUCT_FAIL =  "SAVE_NEW_PRODUCT_FAIL";
export const CLEAR_SAVE_NEW_PRODUCT =  "CLEAR_SAVE_NEW_PRODUCT";

// UPDATE PRODUCT
export const UPDATE_PRODUCT_START = "UPDATE_PRODUCT_START";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL =  "UPDATE_PRODUCT_FAIL";
export const CLEAR_UPDATE_PRODUCT =  "CLEAR_UPDATE_PRODUCT";

// DELETE PRODUCT
export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL =  "DELETE_PRODUCT_FAIL";
export const CLEAR_DELETE_PRODUCT =  "CLEAR_DELETE_PRODUCT";

// PRODUCT SOLD LIST
export const PRODUCT_SOLD_LIST_START = "PRODUCT_SOLD_LIST_START";
export const PRODUCT_SOLD_LIST_SUCCESS = "PRODUCT_SOLD_LIST_SUCCESS";
export const PRODUCT_SOLD_LIST_FAIL =  "PRODUCT_SOLD_LIST_FAIL";


// PRODUCT SUMMARY REPORT
export const PRODUCT_SUMMARY_REPORT_START = "PRODUCT_SUMMARY_REPORT_START";
export const PRODUCT_SUMMARY_REPORT_SUCCESS = "PRODUCT_SUMMARY_REPORT_SUCCESS";
export const PRODUCT_SUMMARY_REPORT_FAIL =  "PRODUCT_SUMMARY_REPORT_FAIL";


// CREATE NEW SUPPLIER
export const CREATE_SUPPLIER_START = "CREATE_SUPPLIER_START";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAIL =  "CREATE_SUPPLIER_FAIL";
export const CLEAR_CREATE_SUPPLIER =  "CLEAR_CREATE_SUPPLIER";

// GET ALL SUPPLIER 
export const GET_ALL_SUPPLIER_START = "GET_ALL_SUPPLIER_START";
export const GET_ALL_SUPPLIER_SUCCESS = "GET_ALL_SUPPLIER_SUCCESS";
export const GET_ALL_SUPPLIER_FAIL =  "GET_ALL_SUPPLIER_FAIL";

