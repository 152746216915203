/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  
    REQUEST_INMATE_CALL_HISTORY_SUCCESS,  
    REQUEST_INMATE_CALL_HISTORY_FAIL,  
    REQUEST_INMATE_CALL_HISTORY_START } 
    from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  saveData } from '../../../helpers';




export interface ResponseGenerator{
    message: string
}

function* requestInmateCallHistory(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/audio/inmate-call-history', data?.data);
        yield put({type:REQUEST_INMATE_CALL_HISTORY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_INMATE_CALL_HISTORY_FAIL, error: e.message });
    }
}

export default function* fetchRequestInmateCallHistory() {
    yield takeEvery(REQUEST_INMATE_CALL_HISTORY_START, requestInmateCallHistory)
}