/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { BsPeople } from 'react-icons/bs';
import { GiFoodTruck, GiTakeMyMoney } from 'react-icons/gi';
import { GrMoney } from 'react-icons/gr';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdOutlineFastfood } from 'react-icons/md';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUserSelectorType } from '../../../interfaces';
import { getShopSummaryReportData } from '../../../redux/actions/shop/summaryReport';
// import { outgoingAppointments, incomingAppointments } from '../../../redux/actions';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const ShopDashboard: React.FC = () => {
    const dispatch = useDispatch();
    const [shopSummaryData, setShopSummaryData] = useState<any>();
    const { getShopSummaryReport } = userSelector((user) => user);

    const data = {
        labels: ['Product', 'Amount', 'inmates'],
        datasets: [
            {
                label: '# ',
                data: [
                    getShopSummaryReport?.data?.product?.number === null
                        ? 0
                        : getShopSummaryReport?.data?.product?.number,
                    getShopSummaryReport?.data?.amount,
                    getShopSummaryReport?.data?.inmates,
                ],
                backgroundColor: ['rgb(94, 114, 228)', 'rgb(45, 206, 137)', 'rgba(54, 162, 235, 1)'],
                borderColor: ['rgb(94, 114, 228)', 'rgb(45, 206, 137)', 'rgba(54, 162, 235, 1)'],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        dispatch(getShopSummaryReportData());
    }, [dispatch]);

    useEffect(() => {
        setShopSummaryData(getShopSummaryReport);
    }, [getShopSummaryReport]);

    return (
        <>
            <div className="btn-group">
                <div className="details-btn-group__left"></div>
            </div>
            <div className="top-items">
                <Link to="#">
                    <div className="card-container">
                        <div className="card-container__box">
                            <div className="card-container_right-item">
                                <div className="icons" style={{ background: '#abf1ff' }}>
                                    <i>
                                        <GiFoodTruck size={30} />
                                    </i>
                                </div>
                            </div>
                            <div className="card-container_left-item">
                                <div className="title-box">
                                    <span>Products</span>
                                </div>
                                <h2>
                                    {getShopSummaryReport?.data?.product?.number === null
                                        ? 0
                                        : getShopSummaryReport?.data?.product?.number}
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="#">
                    <div className="card-container">
                        <div className="card-container__box">
                            <div className="card-container_right-item">
                                <div className="icons" style={{ background: '#99ffd3' }}>
                                    <i>
                                        <GrMoney size={30} />
                                    </i>
                                </div>
                            </div>
                            <div className="card-container_left-item">
                                <div className="title-box">
                                    <span>Total Amount</span>
                                </div>

                                <h2>
                                    {shopSummaryData?.data?.amount?.amountSold === undefined ||
                                    shopSummaryData?.data?.amount?.amountSold === null
                                        ? shopSummaryData?.data?.amount
                                        : shopSummaryData?.data?.amount?.amountSold}{' '}
                                    {shopSummaryData?.data?.amount?.currency === null
                                        ? 'Rwf'
                                        : shopSummaryData?.data?.amount?.currency}
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="#">
                    <div className="card-container">
                        <div className="card-container__box">
                            <div className="card-container_right-item">
                                <div className="icons" style={{ background: '#ffadbe' }}>
                                    <i>
                                        <BsPeople size={30} />
                                    </i>
                                </div>
                            </div>
                            <div className="card-container_left-item">
                                <div className="title-box">
                                    <span>Inmates</span>
                                </div>

                                <h2>{getShopSummaryReport?.data?.inmates}</h2>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="middle-items">
                <div className="middle-items_right">
                    <Doughnut data={data} style={{ marginLeft: '30px' }} />
                </div>

                <div className="middle-items_left" style={{ width: '20%', height: '30%', marginLeft: '30px' }}>
                    <div className="middle-items_left-top">
                        <div className="title-icon">
                            <MdOutlineFastfood size={30} />
                            <span>Items sold</span>
                        </div>
                    </div>
                    <div className="middle-items_left-bottom">
                        <div id="items-rows">
                            <span className="telco-names">Today</span>
                            <span className="telco-amount">
                                {getShopSummaryReport?.data?.product?.number === null
                                    ? 0
                                    : getShopSummaryReport?.data?.product?.number}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="middle-items_left" style={{ width: '20%', height: '30%', marginLeft: '30px' }}>
                    <div className="middle-items_left-top">
                        <div className="title-icon">
                            <GiTakeMyMoney size={30} />
                            <span>Sold</span>
                        </div>
                    </div>
                    <div className="middle-items_left-bottom">
                        <div id="items-rows">
                            <span className="telco-names">Today</span>
                            <span className="telco-amount">
                                {shopSummaryData?.data?.amount?.amountSold === null
                                    ? shopSummaryData?.data?.amount
                                    : shopSummaryData?.data?.amount?.amountSold}{' '}
                                {shopSummaryData?.data?.amount?.currency === null
                                    ? 'Rwf'
                                    : shopSummaryData?.data?.amount?.currency}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="middle-items_left" style={{ width: '20%', height: '30%', marginLeft: '30px' }}>
                    <div className="middle-items_left-top">
                        <div className="title-icon">
                            <HiOutlineUserGroup size={30} />
                            <span>Served Inmates</span>
                        </div>
                    </div>
                    <div className="middle-items_left-bottom">
                        <div id="items-rows">
                            <span className="telco-names">Today</span>
                            <span className="telco-amount">{shopSummaryData?.data?.inmateServed}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopDashboard;
