/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    READ_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION_FAIL,
    READ_NOTIFICATION_START 
} from "../../../constants/actionType/notifications";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{}

function* readNotification(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'v2/prison/notifications', data?.data);
        yield put({type:READ_NOTIFICATION_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:READ_NOTIFICATION_FAIL, error: e.message });
    }
}

export default function* fetchReadNotification() {
    yield takeEvery(READ_NOTIFICATION_START, readNotification)
}