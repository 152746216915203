export const roles = [
    {
      name: "Super Admin",
      rolesKey: 1
    },
    {
      "name": "DDCF",
      rolesKey: 2
    },
    {
    name: "Accountant",
      rolesKey: 3
    },
    {
    name: "Shop agent",
      rolesKey: 4
    },
    {
      name: "Social officer",
      rolesKey: 5
    },
    {
      name: "Orders officer (social)",
      rolesKey: 6
    },
    {
      name: "DCF",
      rolesKey: 7
    },
    {
      "name": "Call officer",
      "rolesKey": 8
    },

  ];


export const userActivityLogKey  = [
  {
    label: "All",
    value: "0", 
  },
    {
      label: "Login",
      value: "1", 
    },
    {
      label: "Logout",
      value: "2"
    },
    {
     label: "Reject visit request",
     value : "3"
    }
    ,{
      label: "Approve visit request", 
      value: "4"
    }
   ,{
    label: "Add inmate phone number",
    value: "5"
   },
    {
      label:  "Register user",
      value: "6"
    }
  ]