import {
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL, 
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_START, 
    REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS 
} from "../../../../constants/actionType/bulkTransaction";
import {  ITransactionFileDetailsData } from "../../../../interfaces/bulkTransaction";


interface IData {
    data: ITransactionFileDetailsData[]
}

interface IError {
    data: string,
}

interface IParam {
  file_id: string | number
}

 export const retrieveBulkTransactionFileDetailsData = (data: IParam) => ({type:  REQUEST_BULK_TRANSACTION_FILE_DETAILS_START, data});
 
 export const retrieveBulkTransactionFileDetailsSuccess = (data: IData) => ({
   type: REQUEST_BULK_TRANSACTION_FILE_DETAILS_SUCCESS,
   payload: data
 })
 
 export const retrieveBulkTransactionFileDetailsError = (error:IError) => ({
   type: REQUEST_BULK_TRANSACTION_FILE_DETAILS_FAIL,
   payload: error
 })