/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    MAKE_ORDER_FAIL, 
    MAKE_ORDER_START, 
    MAKE_ORDER_SUCCESS ,
    MAKE_ORDER_CLEAR
} from "../../../constants/actionType/orders"

 export const makeOrderData = (data: any) => ({type:  MAKE_ORDER_START, data});
 
 export const makeOrderSuccess = (data:any) => ({
   type: MAKE_ORDER_SUCCESS,
   payload: data
 })
 
 export const makeOrderError = (error:any) => ({
   type: MAKE_ORDER_FAIL,
   payload: error
 })

 export const clearMakeOrderStore = () => ({
  type: MAKE_ORDER_CLEAR
});