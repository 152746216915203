import {
    GET_TRANSACTIONS_REPORT_FAIL, 
    GET_TRANSACTIONS_REPORT_START, 
    GET_TRANSACTIONS_REPORT_SUCCESS 
    } from "../../../constants/actionType/facility";
    
    
export interface ITransactionsReportData {
    data: any,
    error: any
}

export const getTransactionReportData = (data:any) =>  ({type: GET_TRANSACTIONS_REPORT_START, data});

export const getTransactionReportsSuccess = (data: ITransactionsReportData) => ({
    type: GET_TRANSACTIONS_REPORT_SUCCESS, 
    payload: data
})

export const getTransactionReportsFail = (error: ITransactionsReportData) => ({ type: GET_TRANSACTIONS_REPORT_FAIL, payload: error})
