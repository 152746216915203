import {
    GET_CALL_OFFICER_USER_FAIL, 
    GET_CALL_OFFICER_USER_START, 
    GET_CALL_OFFICER_USER_SUCCESS 
    } from "../../../constants/actionType/users";
import { getCallOfficer } from "../../../types";
    
    
    export interface ICallOfficerData {
        data: getCallOfficer,
        error: string
    }
    
    export const getCallOfficerData = () =>  ({type: GET_CALL_OFFICER_USER_START});
    
    export const getCallOfficersSuccess = (data: ICallOfficerData) => ({
        type: GET_CALL_OFFICER_USER_SUCCESS, 
        payload: data
    })
    
    export const getCallOfficersFail = (error: ICallOfficerData) => ({ type: GET_CALL_OFFICER_USER_FAIL, payload: error})