import {
    REQUEST_VISITS_DONE_CLEAR,
    REQUEST_VISITS_DONE_FAIL,
    REQUEST_VISITS_DONE_START,
    REQUEST_VISITS_DONE_SUCCESS,
 } from '../../../constants/actionType/videoMeeting';
 
 interface IResponseData {
     data: any;
     error: any;
 }
 
 export const visitDoneData = () => ({ type:REQUEST_VISITS_DONE_START});
 
 export const visitDoneSuccess = (data: IResponseData) => ({
     type:REQUEST_VISITS_DONE_SUCCESS,
     payload: data,
 });
 
 export const visitDoneFail = (error: IResponseData) => ({
     type:REQUEST_VISITS_DONE_FAIL,
     payload: error,
 });
 
 
 export const clearVisitDone = () =>  ({
     type:REQUEST_VISITS_DONE_CLEAR,
 });