/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    UPDATE_PRODUCT_FAIL, 
    UPDATE_PRODUCT_START, 
    UPDATE_PRODUCT_SUCCESS ,
    CLEAR_UPDATE_PRODUCT
} from "../../../constants/actionType/shop"

 export const updateProductData = (data: any) => ({type:  UPDATE_PRODUCT_START, data});
 
 export const updateProductSuccess = (data:any) => ({
   type: UPDATE_PRODUCT_SUCCESS,
   payload: data
 })
 
 export const updateProductError = (error:any) => ({
   type: UPDATE_PRODUCT_FAIL,
   payload: error
 })

 export const clearUpdateProduct = () => ({
  type: CLEAR_UPDATE_PRODUCT,
})