/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { DELETE_PRODUCT_START } from "../../../constants/actionType/shop";
import { saveData } from '../../../helpers';
import { deleteProductError, deleteProductSuccess } from "redux/actions";


export interface ResponseGenerator{
    message: any
    data: any
}

function* deleteProduct(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'v2/prison/shop/delete-product', data?.data);
        if (response?.data?.error) {
            yield put(deleteProductError(response.data || 'Unknown error'));
        } else {
            yield put(deleteProductSuccess(response));
        }
    } catch (e: any) {
        yield put(deleteProductError(e.message || 'Unknown error'));
    }
}


export default function* getDeleteProduct() {
    yield takeEvery(DELETE_PRODUCT_START, deleteProduct)
}