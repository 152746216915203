/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    OUT_GOING_MONEY_START 
} from "../../../constants/actionType/sendMoney";
import { saveData } from '../../../helpers';
import { outGoingMoneyError, outGoingMoneySuccess } from "redux/actions";



export interface ResponseGenerator{
    message: string,
    data: any
}

function* outGoingMoney(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'send-out-money', data?.data);
        if (response?.data?.error) {
            yield put(outGoingMoneyError(response.data || 'Unknown error'));
        } else {
            yield put(outGoingMoneySuccess(response));
        }
    } catch (e: any) {
        yield put(outGoingMoneyError(e.message || 'Unknown error'));
    }
}
export default function* fetchOutGoingMoney() {
    yield takeEvery(OUT_GOING_MONEY_START, outGoingMoney)
}