/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    DELIVERY_ITEMS_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { deliveryItemsFail, deliveryItemsSuccess } from "../../actions";


export interface ResponseGenerator{
    data: any;
}

function* deliveryItems(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/delivery-items', data?.data);
        if (response?.data?.error) {
            yield put(deliveryItemsFail(response.data || 'Unknown error'));
        } else {
            yield put(deliveryItemsSuccess(response));
        }
    } catch (e: any) {
        yield put(deliveryItemsFail(e.message || 'Unknown error'));
    }
}

export default function* fetchDeliveryItems() {
    yield takeEvery(DELIVERY_ITEMS_START, deliveryItems)
}