// REGISTER USER 
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL =  "GET_USER_FAIL";


// GET PRISON
export const GET_PRISON_START = "GET_PRISON_START";
export const GET_PRISON_SUCCESS = "GET_PRISON_SUCCESS";
export const GET_PRISON_FAIL =  "GET_PRISON_FAIL";


// GET CORRECTION SERVICES
export const GET_CORRECTIONAL_FACILITY_START = "GET_CORRECTIONAL_FACILITY_START";
export const GET_CORRECTIONAL_FACILITY_SUCCESS = "GET_CORRECTIONAL_FACILITY_SUCCESS";
export const GET_CORRECTIONAL_FACILITY_FAIL =  "GET_CORRECTIONAL_FACILITY_FAIL";


// UPDATE OR DELETE USER
export const UPDATE_DELETE_USER_START = "UPDATE_DELETE_USER_START";
export const UPDATE_DELETE_USER_SUCCESS = "UPDATE_DELETE_USER_SUCCESS";
export const UPDATE_DELETE_USER_FAIL =  "UPDATE_DELETE_USER_FAIL";


// UPDATE PASSWORD
export const CHANGE_PASSWORD_USER_START = "CHANGE_PASSWORD_USER_START";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";
export const CHANGE_PASSWORD_USER_FAIL =  "CHANGE_PASSWORD_USER_FAIL";
export const CHANGE_PASSWORD_USER_CLEAR =  "CHANGE_PASSWORD_USER_CLEAR";

// GET CALL OFFICER
export const GET_CALL_OFFICER_USER_START = "GET_CALL_OFFICER_USER_START";
export const GET_CALL_OFFICER_USER_SUCCESS = "GET_CALL_OFFICER_USER_SUCCESS";
export const GET_CALL_OFFICER_USER_FAIL =  "GET_CALL_OFFICER_USER_FAIL";

// UPDATE USER ACCOUNT
export const UPDATE_USER_ACCOUNT_START = "UPDATE_USER_ACCOUNT_START";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_FAIL =  "UPDATE_USER_ACCOUNT_FAIL";
export const UPDATE_USER_ACCOUNT_CLEAR =  "UPDATE_USER_ACCOUNT_CLEAR";

// RETRIEVE USER PROFILE
export const REQUEST_USER_ACCOUNT_START = "REQUEST_USER_ACCOUNT_START";
export const REQUEST_USER_ACCOUNT_SUCCESS = "REQUEST_USER_ACCOUNT_SUCCESS";
export const REQUEST_USER_ACCOUNT_FAIL =  "REQUEST_USER_ACCOUNT_FAIL";


// RETRIEVE USER PROFILE
export const FETCH_ACTIVITY_LOG_START = "FETCH_ACTIVITY_LOG_START";
export const FETCH_ACTIVITY_LOG_SUCCESS = "FETCH_ACTIVITY_LOG_SUCCESS";
export const FETCH_ACTIVITY_LOG_FAIL =  "FETCH_ACTIVITY_LOG_FAIL";
export const FETCH_ACTIVITY_LOG_CLEAR =  "FETCH_ACTIVITY_LOG_CLEAR";