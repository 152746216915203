/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put,  takeEvery } from "redux-saga/effects";
import { RECORD_CASH_AMOUNT_SUCCESS, RECORD_CASH_AMOUNT_FAIL, RECORD_CASH_AMOUNT_START } from "../../../constants/actionType/finance";
import { saveData } from '../../../helpers/index';


export interface ResponseGenerator{}
const delay = (time:any) => new Promise(resolve => setTimeout(resolve, time));

function* recordCashAmount(data:any) {
    try {
        yield call(delay, 3000);
        const userData: ResponseGenerator = yield call( saveData, 'v2/prison/deposit-visitor-amount', data?.data);
        yield put({type: RECORD_CASH_AMOUNT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: RECORD_CASH_AMOUNT_FAIL, error: e.message });
    }
}

export default function* fetchRecordCashAmount() {
    yield takeEvery(RECORD_CASH_AMOUNT_START, recordCashAmount)
}