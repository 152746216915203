import { Button, Divider, Form, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { createDeliveryNoteFileData, getOrderFileData } from '../../../../redux/actions';
import { filterOrderFile } from '..';
import { TableDataType } from '../../../orderOfficer/OrderFiles';

const useTypedSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const NewDeliveryNote: React.FC<{ open: boolean; onCloseModal: () => void }> = ({ open, onCloseModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { getOrderFile } = useTypedSelector((state) => state);

    const newDataCol =
        getOrderFile?.data && Array.isArray(getOrderFile?.data)
            ? getOrderFile?.data?.map((item: TableDataType, index: number) => ({
                  key: index,
                  id: item.id,
                  file_name: item.file_name,
              }))
            : [];

    const filterOrderFileData = newDataCol?.map((item: { file_name: string; id: number }, index: number) => ({
        key: index,
        label: item.file_name,
        value: item.id,
    }));

    const onHandleCreateSave = () => {
        const formData = form.getFieldsValue();
        dispatch(
            createDeliveryNoteFileData({
                order_file: formData?.order_file,
            }),
        );
    };

    useEffect(() => {
        dispatch(getOrderFileData(filterOrderFile));
    }, []);

    return (
        <Modal title="Create New Delivery Note" footer={[]} open={open} onCancel={onCloseModal}>
            <Divider style={{ margin: '6px' }} />
            <Form form={form} onFinish={onHandleCreateSave} layout="vertical">
                <Form.Item name="order_file" label="Select an order file">
                    <Select size="large" placeholder="Select an order file" options={filterOrderFileData} />
                </Form.Item>

                <Form.Item>
                    <Button ghost htmlType="submit" type="primary">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewDeliveryNote;
