import {
    GET_INMATES_WALLET_DETAILS_FAIL, 
    GET_INMATES_WALLET_DETAILS_START, 
    GET_INMATES_WALLET_DETAILS_SUCCESS 
    } from "../../../constants/actionType/inmates";
    
    
export interface IInmateWalletDetailsData {
    data: any,
    error: any
}

export const getInmateWalletDetailsData = (data:any) =>  ({type: GET_INMATES_WALLET_DETAILS_START, data});

export const getInmateWalletDetailsSuccess = (data: IInmateWalletDetailsData) => ({
    type: GET_INMATES_WALLET_DETAILS_SUCCESS, 
    payload: data
})

export const getInmateWalletDetailsFail = (error: IInmateWalletDetailsData) => ({ type: GET_INMATES_WALLET_DETAILS_FAIL, payload: error})
