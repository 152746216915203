/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CREATE_ORDER_FILE_FAIL,
    CREATE_ORDER_FILE_START,
    CREATE_ORDER_FILE_SUCCESS,
    CREATE_ORDER_FILE_CLEAR
} from '../../../constants/actionType/orders';

export interface ISCreateOrderFileData {
    data: any;
    error: any;
}

interface IPayload {
    order_file_type: string | number;
}

export const createOrderFileData = (data: IPayload) => ({ type: CREATE_ORDER_FILE_START, data});

export const createOrderFileSuccess = (data: any) => ({
    type: CREATE_ORDER_FILE_SUCCESS,
    payload: data,
});

export const createOrderFileFail = (error: any) => ({
    type: CREATE_ORDER_FILE_FAIL,
    payload: error,
});

export const clearCreateOrderFileStore = () => ({
    type: CREATE_ORDER_FILE_CLEAR
  });