export const GENERATE_BULK_SAVING_FILE_START = "GENERATE_BULK_SAVING_FILE_START";
export const GENERATE_BULK_SAVING_FILE_SUCCESS = "GENERATE_BULK_SAVING_FILE_SUCCESS";
export const GENERATE_BULK_SAVING_FILE_FAIL =  "GENERATE_BULK_SAVING_FILE_FAIL";

export const REQUEST_BULK_SAVING_FILE_START = "REQUEST_BULK_SAVING_FILE_START";
export const REQUEST_BULK_SAVING_FILE_SUCCESS = "REQUEST_BULK_SAVING_FILE_SUCCESS";
export const REQUEST_BULK_SAVING_FILE_FAIL =  "REQUEST_BULK_SAVING_FILE_FAIL";

export const REQUEST_BULK_SAVING_FILE_DETAILS_START = "REQUEST_BULK_SAVING_FILE_DETAILS_START";
export const REQUEST_BULK_SAVING_FILE_DETAILS_SUCCESS = "REQUEST_BULK_SAVING_FILE_DETAILS_SUCCESS";
export const REQUEST_BULK_SAVING_FILE_DETAILS_FAIL =  "REQUEST_BULK_SAVING_FILE_DETAILS_FAIL";


export const UPLOAD_BULK_SAVING_DOCUMENT_START = "UPLOAD_BULK_SAVING_DOCUMENT_START";
export const UPLOAD_BULK_SAVING_DOCUMENT_SUCCESS = "UPLOAD_BULK_SAVING_DOCUMENT_SUCCESS";
export const UPLOAD_BULK_SAVING_DOCUMENT_FAIL =  "UPLOAD_BULK_SAVING_DOCUMENT_FAIL";

export const RECORD_SAVING_START = "RECORD_SAVING_START";
export const RECORD_SAVING_SUCCESS = "RECORD_SAVING_SUCCESS";
export const RECORD_SAVING_FAIL =  "RECORD_SAVING_FAIL";
export const RECORD_SAVING_CLEAR =  "RECORD_SAVING_CLEAR";

export const REQUEST_APPROVEMENT_OR_REJECTION_START = "REQUEST_APPROVEMENT_OR_REJECTION_START";
export const REQUEST_APPROVEMENT_OR_REJECTION_SUCCESS = "REQUEST_APPROVEMENT_OR_REJECTION_SUCCESS";
export const REQUEST_APPROVEMENT_OR_REJECTION_FAIL =  "REQUEST_APPROVEMENT_OR_REJECTION_FAIL";
export const REQUEST_APPROVEMENT_OR_REJECTION_CLEAR =  "REQUEST_APPROVEMENT_OR_REJECTION_CLEAR";