import React from 'react';
import LandingPage from '../../components/landingPage';

const LandingPageContainer = () => {
    return (
        <>
            <LandingPage />
        </>
    );
};

export default LandingPageContainer;
