/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_INMATES_WALLETS_SUCCESS, GET_INMATES_WALLETS_FAIL, GET_INMATES_WALLETS_START } from "../../../constants/actionType/inmates";
import { fetchData } from '../../../helpers';
import { delay } from "../../../constants/time";


export interface ResponseGenerator{}

function* getInmatesWallets() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'prison/inmate-wallets');
        yield call(delay, 3000);
        yield put({type: GET_INMATES_WALLETS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_INMATES_WALLETS_FAIL, error: e.message });
    }
}

export default function* fetchInmatesWallets() {
    yield takeEvery(GET_INMATES_WALLETS_START, getInmatesWallets)
}