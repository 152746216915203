import {
    REQUEST_PHONE_NUMBER_LINK_FAIL, 
    REQUEST_PHONE_NUMBER_LINK_START, 
    REQUEST_PHONE_NUMBER_LINK_SUCCESS,
    REQUEST_PHONE_NUMBER_LINK_CLEAR
    } from "../../../constants/actionType/linkPhoneNumberToInmate";
import { ILinkPhoneNumber, ILinkPhoneNumberList } from "../../../interfaces/linkPhoneNumber";

interface IData {
    data: ILinkPhoneNumber[]
}
    
export interface ILinked {
    data: ILinkPhoneNumberList,
    error: []
}

export const requestPhoneNumberLinkData = (data: IData) =>  ({type: REQUEST_PHONE_NUMBER_LINK_START, data});

export const requestPhoneNumberLinkSuccess = (data: ILinked) => ({
    type: REQUEST_PHONE_NUMBER_LINK_SUCCESS, 
    payload: data
})

export const requestPhoneNumberLinkFail = (error: ILinked) => ({ type: REQUEST_PHONE_NUMBER_LINK_FAIL, payload: error})

export const clearRequestPhoneNumberLinkStore = () => ({
    type: REQUEST_PHONE_NUMBER_LINK_CLEAR
})
