import {
    UPLOAD_DOCUMENT_FAIL, 
    UPLOAD_DOCUMENT_START, 
    UPLOAD_DOCUMENT_SUCCESS 
    } from "../../../constants/actionType/orders";

interface IDocument {
    data: string,
    error: []
}

export const uploadDocumentData = (data: any) =>  ({type: UPLOAD_DOCUMENT_START, data});

export const uploadDocumentSuccess = (data: IDocument) => ({
    type: UPLOAD_DOCUMENT_SUCCESS, 
    payload: data
})

export const uploadDocumentFail = (error: IDocument) => ({ type: UPLOAD_DOCUMENT_FAIL, payload: error})
