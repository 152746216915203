import {
    GET_SUMMARY_REPORT_FAIL, 
    GET_SUMMARY_REPORT_START, 
    GET_SUMMARY_REPORT_SUCCESS 
    } from "../../../constants/actionType/facility";
    
    
export interface ISummaryReportData {
    data: any,
    error: any
}

export const getSummaryReportData = (data:any) =>  ({type: GET_SUMMARY_REPORT_START, data});

export const getSummaryReportsSuccess = (data: ISummaryReportData) => ({
    type: GET_SUMMARY_REPORT_SUCCESS, 
    payload: data
})

export const getSummaryReportsFail = (error: ISummaryReportData) => ({ type: GET_SUMMARY_REPORT_FAIL, payload: error})
