/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_SUMMARY_REPORT_SUCCESS, GET_SUMMARY_REPORT_FAIL, GET_SUMMARY_REPORT_START } from "../../../constants/actionType/facility";
import {  saveData } from '../../../helpers';


export interface ResponseGenerator{}

function* getSupSummaryReport(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'general/summary', data?.data);
        yield put({type: GET_SUMMARY_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_SUMMARY_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchGetSummaryReport() {
    yield takeEvery(GET_SUMMARY_REPORT_START, getSupSummaryReport)
}