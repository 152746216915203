import { Button, Select } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { clearRegisterUserStore, getPublicItemsData, registerUsers } from '../../../redux/actions';
import Input from 'antd/es/input/Input';
import { allGenders } from '../../../constants/gender';
import { countryCode } from '../../../constants/telco';
import { toast } from 'react-toastify';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface ISuperAdminAddDP {
    facilityId: string | number;
    setChangePage: Dispatch<SetStateAction<string>>;
}

interface IForm {
    email: string;
    username: string;
    phoneNumber: string | number;
    firstName: string;
    lastName: string;
    gender: string;
    birthDate: string;
    roleId: string | number;
    prisonId: string;
    password: string;
}

const SuperAdminAddDP: React.FC<ISuperAdminAddDP> = ({ facilityId, setChangePage }) => {
    const dispatch = useDispatch();
    const [userGender, setUserGender] = useState<string>('');
    // const [selectedDate, setSelectedDate] = useState<string>('');
    const [form, setForm] = useState<IForm>({
        email: '',
        username: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        gender: '',
        birthDate: '',
        roleId: '',
        prisonId: '',
        password: '',
    });

    const {
        registerUser: { data, loading },
    } = userSelector((user) => user);

    const onSubmit = () => {
        const userData = {
            email: form.email,
            username: form.username,
            phone_number: `${countryCode}${form.phoneNumber}`,
            first_name: form.firstName,
            last_name: form.lastName,
            gender: userGender,
            birth_date: '',
            role_id: 7,
            is_admin: false,
            is_user: true,
            is_facility: false,
            prison_id: facilityId,
            password: '',
            is_dfc: true,
            is_school: false,
            is_simpo: false,
        };
        dispatch(registerUsers(userData));
    };

    const onHandleChangeUserInfo = (event: any) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const onSearch = (value: string) => {
        return value;
    };

    const onGenderChanges = (value: React.SetStateAction<string>) => {
        setUserGender(value);
    };

    useEffect(() => {
        if (data?.data?.error) {
            toast(data?.data?.error?.description);
            dispatch(clearRegisterUserStore());
        }
    }, [data?.data?.error]);

    useEffect(() => {
        if (data?.data?.description) {
            toast(data?.data?.description);
            dispatch(clearRegisterUserStore());
            setChangePage('default');
        }
    }, [data?.data]);

    useEffect(() => {
        dispatch(getPublicItemsData());
    }, [dispatch]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => setChangePage('default')}>
                            <BiArrowBack size={22} />
                            <span>All facilities</span>
                        </Button>
                        <h2></h2>
                    </div>
                    <div className="column-box">
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>First name</span>
                            <Input
                                size="large"
                                value={form.firstName}
                                placeholder="Enter first name"
                                name="firstName"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Last name</span>
                            <Input
                                size="large"
                                value={form.lastName}
                                placeholder="Enter last name"
                                name="lastName"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Username</span>
                            <Input
                                size="large"
                                value={form.username}
                                placeholder="Enter username"
                                name="username"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>

                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>{`Select gender`}</span>
                            <Select
                                showSearch
                                placeholder="Select gender"
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={(input, option) => {
                                    const val: string = option?.label ?? '';
                                    return val.toLowerCase().includes(input.toLowerCase());
                                }}
                                onChange={onGenderChanges}
                                options={allGenders}
                            />
                        </div>

                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Email</span>
                            <Input
                                size="large"
                                value={form.email}
                                placeholder="Enter phone number"
                                name="email"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div
                            className="form__input"
                            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                        >
                            <span>Phone number</span>
                            <Input
                                addonBefore={countryCode}
                                size="large"
                                value={form.phoneNumber}
                                placeholder="Enter phone number"
                                name="phoneNumber"
                                onChange={onHandleChangeUserInfo}
                            />
                        </div>
                        <div className="form_button">
                            <Button
                                disabled={
                                    form.firstName === '' ||
                                    form.email === '' ||
                                    form.phoneNumber === '' ||
                                    form.lastName === '' ||
                                    form.username === '' ||
                                    userGender === ''
                                }
                                onClick={() => onSubmit()}
                                loading={loading}
                            >
                                Add DCF
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuperAdminAddDP;
