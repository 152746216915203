/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    INMATES_TRANSACTION_REPORT_DETAILS_FAIL, 
    INMATES_TRANSACTION_REPORT_DETAILS_START, 
    INMATES_TRANSACTION_REPORT_DETAILS_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const inmatesTransactionReportDetailsData = (data: any) => ({type:  INMATES_TRANSACTION_REPORT_DETAILS_START, data});
 
 export const inmatesTransactionReportDetailsSuccess = (data:any) => ({
   type: INMATES_TRANSACTION_REPORT_DETAILS_SUCCESS,
   payload: data
 })
 
 export const inmatesTransactionReportDetailsError = (error:any) => ({
   type: INMATES_TRANSACTION_REPORT_DETAILS_FAIL,
   payload: error
 })