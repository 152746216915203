/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_ORDER_FILE_DETAILS_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { getOrderFileDetailsError, getOrderFileDetailsSuccess } from "../../actions/orders/getOrderFileDetails";



export interface ResponseGenerator{
    message: any
    error: any
    data: any
}

function* getCreateOrderFileDetails(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/retrieve-orders', data?.data);
        if (response?.data?.error) {
            yield put(getOrderFileDetailsError(response.data || 'Unknown error'));
        } else {
            yield put(getOrderFileDetailsSuccess(response));
        }
    } catch (e: any) {
        yield put(getOrderFileDetailsError(e.message || 'Unknown error'));
    }
}

export default function* fetchGetCreateOrderFileDetails() {
    yield takeEvery(REQUEST_ORDER_FILE_DETAILS_START, getCreateOrderFileDetails)
}