/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    CREATE_ORDER_FILE_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { createOrderFileFail, createOrderFileSuccess } from "../../actions";


export interface ResponseGenerator{
   data: any
}

function* getCreateOrderFile(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/create-order-file', data?.data);
        
    if (response?.data?.error) {
        yield put(createOrderFileFail(response.data || 'Unknown error'));
    } else {
        yield put(createOrderFileSuccess(response));
    }
    } catch (e: any) {
        yield put(createOrderFileFail(e.message || 'Unknown error'));
    }
}

export default function* fetchGetCreateOrderFile() {
    yield takeEvery(CREATE_ORDER_FILE_START, getCreateOrderFile)
}