/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { REQUEST_SIGN_IN_SUCCESS, REQUEST_SIGN_IN_FAIL, REQUEST_SIGN_IN_START } from "../../constants/actionType/auth";
import { saveData } from '../../helpers';


export interface ResponseGenerator{}

function* signInUser(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'user/login', data?.data);
        yield put({type: REQUEST_SIGN_IN_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_SIGN_IN_FAIL, error: e.message });
    }
}

export default function* getSignInUser() {
    yield takeEvery(REQUEST_SIGN_IN_START, signInUser)
}