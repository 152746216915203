import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REQUEST_START_MEETING_START, 
    REQUEST_START_MEETING_SUCCESS, 
    REQUEST_START_MEETING_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import {  saveData } from "../../../helpers";




export interface ResponseGenerator{
    message: string
}



function* startMeeting(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/auto-start-session', data?.data);
        yield put({type: REQUEST_START_MEETING_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_START_MEETING_FAIL, error: e.message });
    }
}

export default function* fetchStartMeeting() {
    yield takeEvery(REQUEST_START_MEETING_START, startMeeting)
}