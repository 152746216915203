/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    EDIT_ORDER_CLEAR,
    EDIT_ORDER_FAIL,
    EDIT_ORDER_START,
    EDIT_ORDER_SUCCESS,
} from '../../../constants/actionType/orders';

interface IPayload {
    "order_product_id": number,
    "requested_quantity": number,
    "cancel": boolean
}

export const editOrderData = (data: IPayload) => ({ type: EDIT_ORDER_START, data });

export const editOrderSuccess = (data: any) => ({
    type: EDIT_ORDER_SUCCESS,
    payload: data,
});

export const editOrderFail = (error: any) => ({
    type: EDIT_ORDER_FAIL,
    payload: error,
});

export const clearEditOrder = () => ({
    type: EDIT_ORDER_CLEAR
});