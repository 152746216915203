import React from 'react';

const Toast = (api: any, message: string, description: string | undefined) => {
    const placement = 'topRight';
    if (message === 'Success') {
        api.success({
            message: message,
            description: <div>{description}</div>,
            placement,
        });
    }

    if (message === 'Info') {
        api.info({
            message: message,
            description: <div>{description}</div>,
            placement,
        });
    }

    if (message === 'Error') {
        api.error({
            message: message,
            description: <div>{description}</div>,
            placement,
        });
    }
};

type statusType = 'Error' | 'Success' | 'Info';

export const handleToast = (api: any, status: statusType, message: string | undefined) => {
    Toast(api, status, message);
};

export default Toast;
