/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Button, Spin, Table, Modal, Input, Space, Select, Form } from 'antd';
import moment from 'moment';
import { FilePdfOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { useLocation, useNavigate } from 'react-router-dom';
import { IUserSelectorType } from '../../../../interfaces';
import {
    clearDeliveryItemsStore,
    clearPurchaseOrderDetails,
    deliveryItemsData,
    deliveryNoteData,
    purchaseOrderData,
} from '../../../../redux/actions';
import { toast } from 'react-toastify';

interface IOderDetails {
    id: string;
    price: string;
    picture: string;
    article: string;
    quantity: string;
    currency: string;
}
export interface DataType {
    key: number;
    id: string;
    name: string;
    username: number;
    address: string;
    user_profiles: string[];
    order_products: [IOderDetails];
    userRoleId: number;
    rows: any;
    firstName: string;
    lastName: string;
    status: string;
    amount: string;
    send_moneys: any;
    currency: string;
    totalQuantity: string;
    totalAmount: string;
    price: string;
    quantity: string;
    orderedProducts: [IOderDetails];
    inmateWallet: string;
    walletAfterPayment: string;
    article: string;
    delivery_quantity: number;
    unit_price: number;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const ShopPurchaseOrdersDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [editable, setEditable] = useState(false);
    const [tableData, setTableData] = useState<DataType[]>([]);
    const [editableData, setEditableData] = useState<{ [key: number]: DataType }>({});
    const {
        purchaseOrder: { data, loading },
        deliveryNote,
        deliveryItems,
    } = userSelector((user) => user);

    const orderFileId = state && state?.orderFileId;

    const openDeliveryFile = Array.isArray(deliveryNote.data?.details)
        ? deliveryNote.data?.details.filter((item: any) => item.status === 'opened')
        : [];

    const filterDeliveryNote = openDeliveryFile?.map((item: { file_name: string; id: number }, index: number) => ({
        key: index,
        label: item.file_name,
        value: item.id,
    }));

    const dataCol: DataType[] =
        data?.details?.map((item: any, index: number) => ({
            key: index,
            name: item?.item ?? '',
            quantity: item?.quantity ?? 0,
            unit_price: item?.unit_price ?? 0,
            total_price: item?.total_price ?? 0,
            order_day: item?.order_day ?? '',
            delivery_quantity: 0,
        })) || [];

    const baseColumns = [
        {
            title: 'Items',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Delivery quantity',
            dataIndex: 'delivery_quantity',
            key: 'delivery_quantity',
            render: (text: number, record: DataType) =>
                editable ? (
                    <Input
                        type="number"
                        value={editableData[record.key]?.delivery_quantity ?? record.delivery_quantity}
                        onChange={(e) => handleInputChange(e, record.key)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Total amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
    ];

    const daysColumn = { title: 'Days', dataIndex: 'order_day', key: 'order_day' };
    const includeDaysColumn = data?.details?.some((item: any) => item.order_day && item.order_day.trim() !== '');

    const columns = includeDaysColumn ? [daysColumn, ...baseColumns] : baseColumns;

    const handleToggleEditable = () => {
        if (editable) {
            Modal.confirm({
                title: 'Discard changes?',
                content: 'Are you sure you want to discard the changes?',
                onOk: () => {
                    setEditable(false);
                    setEditableData({});
                },
            });
        } else {
            setEditable(true);
            const newEditableData = tableData.reduce((acc, item) => {
                acc[item.key] = { ...item };
                return acc;
            }, {} as { [key: number]: DataType });
            setEditableData(newEditableData);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: number) => {
        const newValue = e.target.value === '' ? 0 : parseFloat(e.target.value);
        setEditableData((prev) => {
            const updatedItem: any = { ...prev[key], delivery_quantity: newValue };
            if (updatedItem.quantity < newValue) {
                toast.info('The entered quantity is greater than the total quantity');
                return prev;
            }
            return { ...prev, [key]: updatedItem };
        });
    };

    const onHandleSaveRecordDelivery = () => {
        const formData = form.getFieldsValue();

        const tableFormData = Object.values(editableData).map((item) => ({
            delivery_quantity: item.delivery_quantity,
            product_id: item.id,
            requested_quantity: item?.quantity,
            total_price: item?.delivery_quantity * item?.unit_price,
        }));

        const payload = {
            delivery_note_file: formData?.delivery_note,
            data: tableFormData,
        };
        dispatch(deliveryItemsData(payload));
    };

    useEffect(() => {
        if (data?.details) {
            const formattedData = data.details.map((item: any, index: number) => ({
                key: index,
                id: item.id,
                name: item?.item ?? '',
                quantity: item?.quantity ?? 0,
                unit_price: item?.unit_price ?? 0,
                total_price: item?.total_price ?? 0,
                order_day: item?.order_day ?? '',
                delivery_quantity: item?.delivery_quantity ?? 0,
            }));
            setTableData(formattedData);
        }
    }, [data]);

    useEffect(() => {
        if (orderFileId) {
            dispatch(
                purchaseOrderData({
                    ordersFileId: orderFileId,
                }),
            );
            dispatch(deliveryNoteData({ ordersFileId: '', check_status: false }));
        }
    }, [orderFileId]);

    useEffect(() => {
        if (!state) {
            navigate('/shop-seller');
        }
        if (deliveryItems?.data === null && deliveryItems?.error === '') {
            toast.success(deliveryItems?.message);
            setTableData(Object.values(editableData));
            setEditable(false);
            setEditableData({});
            dispatch(clearDeliveryItemsStore());
        }
        if (deliveryItems?.error) {
            toast.error(deliveryItems?.message);
        }
    }, [state, deliveryItems?.data, deliveryItems?.error]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <Flex align="center" style={{ padding: '10px' }}>
                <Button
                    danger
                    type="primary"
                    icon={<LeftOutlined />}
                    ghost
                    onClick={() => {
                        navigate('/shop-seller');
                        dispatch(clearPurchaseOrderDetails());
                    }}
                >
                    Back to purchaser orders
                </Button>
            </Flex>

            <Flex align="center" justify="space-between" style={{ padding: '10px', background: '#fff' }}>
                <div className="tbl-items__col">
                    <span style={{ color: '#777677' }}>Order total amount</span>
                    <span style={{ color: '#262D30', marginTop: '10px', fontSize: '20px' }}>
                        {!data ? 0 : `${data?.total_order_amount} ${data?.currency}`}
                    </span>
                </div>
                <Flex gap="middle">
                    <Button ghost onClick={handleToggleEditable} type="primary">
                        Record Delivery
                    </Button>
                    <Form form={form} onFinish={onHandleSaveRecordDelivery}>
                        <Space.Compact>
                            <Form.Item name="delivery_note" rules={[{ required: true }]}>
                                <Select
                                    disabled={!editable}
                                    loading={deliveryNote?.loading}
                                    options={filterDeliveryNote}
                                    placeholder="Select a delivery note"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    loading={deliveryItems?.loading}
                                    htmlType="submit"
                                    type="primary"
                                    disabled={!editable}
                                >
                                    Save Change
                                </Button>
                            </Form.Item>
                        </Space.Compact>
                    </Form>
                </Flex>
            </Flex>
            {loading === undefined ? (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            ) : (
                <Flex vertical>
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={tableData}
                        pagination={tableData.length >= 30 ? undefined : false}
                    />
                </Flex>
            )}
            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>
                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>PURCHASE ORDER</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {data?.data?.facility}
                            </span>
                            <span>
                                <b>Deliver note number: </b>
                                {data?.data?.delivery_file}
                            </span>
                            <span>
                                <b>Delivered on: </b>
                                {moment(data?.data?.date).format('DD-MMM-YYYY')}
                            </span>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataCol}
                            pagination={data?.data?.length >= 30 ? undefined : false}
                        />
                        <div className="print-users">
                            <span>
                                <b>Prepared by: </b>
                                {data?.data?.delivered_by}
                            </span>
                        </div>

                        {data?.data?.reviewers?.map((item: any) => {
                            return (
                                <>
                                    {' '}
                                    <div className="print-users">
                                        {item?.role_id === 5 ? (
                                            <span>
                                                <b>Reviewed by: </b>
                                                {item?.first_name} {item?.last_name}
                                            </span>
                                        ) : null}
                                        {item?.role_id === 3 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Verified for cash availability by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                        {item?.role_id === 2 || item?.role_id == 7 ? (
                                            <div className="print-users">
                                                <span>
                                                    <b>Approved by: </b>
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ShopPurchaseOrdersDetails;
