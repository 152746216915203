/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { FaRegUser } from 'react-icons/fa';
import './index.css';
import { Avatar } from 'antd';

const StaticNavBar: React.FC<any> = ({ userData }) => {
    const languages = [
        { language: 'English', flag: 'united kingdom flag' },
        { language: 'Kinyarwanda', flag: 'rwanda flag' },
        { language: 'Français', flag: 'france flag' },
    ];

    const [selectedLanguage, setSelectedLanguage]: any = useState('');

    const changeLanguage = () => {
        setSelectedLanguage(selectedLanguage);
    };

    const usersData = userData === undefined ? {} : userData[0];

    return (
        <>
            <div className="nav-bar__container">
                <div className="nav-bar_container-row" id="black-content__row">
                    <div className="left-content">
                        <div className="left-content__row">
                            <Link to="/dashboard">
                                <h2 id="static-nav-bar-title" style={{ color: '#333' }}>
                                    {usersData?.name}
                                </h2>
                            </Link>
                        </div>
                    </div>
                    <div className="right-content">
                        <div
                            className="right-content__row"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '300px',
                            }}
                        >
                            <Popup
                                content={
                                    <div className="popup-btn">
                                        {languages.map((item) => {
                                            return (
                                                <>
                                                    <Link to="#" onClick={() => changeLanguage()}>
                                                        <i className={item.flag}></i>
                                                        <span>{item.language}</span>
                                                    </Link>
                                                </>
                                            );
                                        })}
                                    </div>
                                }
                                on="click"
                                positionFixed
                                trigger={
                                    <Link to="#" className="Icon-group">
                                        <Icon name="language" size="big" style={{ color: '#333' }} />
                                        <span style={{ color: '#333' }}>English</span>
                                    </Link>
                                }
                            />
                            <div style={{ height: '40px', width: '0px', borderLeft: '1px solid #868686' }}></div>
                            <Link
                                to="#"
                                className="Icon-group"
                                style={{
                                    width: '300px',
                                }}
                            >
                                <Avatar
                                    size={35}
                                    icon={<FaRegUser size={20} />}
                                    style={{ marginLeft: '-10px', color: '#333' }}
                                />
                                <span style={{ color: '#333', marginTop: '0px', marginLeft: '10px' }}>
                                    {usersData?.username}
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StaticNavBar;
