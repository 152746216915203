import React from 'react';

interface IContainerLayout {
    title?: string | null | undefined;
    children: React.Element | undefined;
    leftTopItem?: React.Element | undefined | null;
    btnBtn?: React.Element | undefined;
}

const ContainerLayout: React.FC<IContainerLayout> = ({
    btnBtn = undefined,
    title = undefined,
    children,
    leftTopItem = undefined,
}) => {
    return (
        <>
            <div className="user-account-tbl">
                <div className="details-btn-group">
                    <div className="tbl-items">
                        <div className="tbl-items-title">{title ? <span>{title}</span> : btnBtn}</div>
                        <div className="left-container-box">{leftTopItem}</div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default ContainerLayout;
