import {
    REQUEST_GLOBAL_SUMMARY_CLEAR,
    REQUEST_GLOBAL_SUMMARY_FAIL, 
    REQUEST_GLOBAL_SUMMARY_START, 
    REQUEST_GLOBAL_SUMMARY_SUCCESS 
 } from "../../../constants/actionType/summary";
import { IGlobalSummaryData } from "../../../interfaces/summary";


export const getGlobalSummaryData = () => ({type:  REQUEST_GLOBAL_SUMMARY_START});
  
export const getGlobalSummarySuccess = (data: IGlobalSummaryData) => ({
    type: REQUEST_GLOBAL_SUMMARY_SUCCESS,
    payload: data
  })
  
export const getGlobalSummaryError = (error:string) => ({
    type: REQUEST_GLOBAL_SUMMARY_FAIL,
    payload: error
  })

export const clearGetGlobalSummaryStore = () => ({
    type:  REQUEST_GLOBAL_SUMMARY_CLEAR,
  });

