import { Button, DatePicker, Modal, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import SenderTransactionReport from './senderTransactionReport';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { IUserSelectorType } from '../../../interfaces';
import { senderTransactionReportData } from '../../../redux/actions';
import { ManageDates } from '..';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import InmatesTransactionReport from './inmatesTransactionReport';
import InmateWithZeroWallet from './inmateWithZeroBalance';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const TransactionsReports = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [changePage, setChangePage] = useState<string>('default');
    const [selectedDate, setSelectedDate]: any = useState();
    const [comprehensiveReport, setComprehensiveReport] = useState(true);
    const [inmateWithZeroBalance, setInmateWithZeroBalance] = useState(false);
    const [inmateComprehensiveReport, setInmateComprehensiveReport] = useState(false);
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);

    const {
        senderTransactionReport: {
            data: { data },
            loading,
        },
    } = userSelector((user) => user);

    const reportData = data !== undefined ? data : [];

    const senderReportData: any = reportData?.details?.map((item: any) => {
        return {
            name: `${item?.sender?.first_name} ${item?.sender?.last_name}`,
            phone_number: item?.sender?.sender_contact,
            amount: item?.total_amount,
            inmate_number: item?.inmate_number,
            frequency: item?.frequency,
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Phone number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: 150,
        },
        {
            title: 'Total amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
        {
            title: 'Number of inmates',
            dataIndex: 'inmate_number',
            key: 'inmate_number',
            width: 150,
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: 150,
        },
    ];

    const columnsPrint: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Phone number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: 150,
        },
        {
            title: 'Total amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
        {
            title: 'Number of inmates',
            dataIndex: 'inmate_number',
            key: 'inmate_number',
            width: 150,
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: 150,
        },
    ];

    const onSelectDate = (dateString: any) => {
        setDates(dateString);

        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const onSearch = () => {
        dispatch(
            senderTransactionReportData({
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            senderTransactionReportData({
                start_date: '',
                end_date: '',
            }),
        );
    }, [dispatch, selectedDate]);

    useEffect(() => {
        dispatch(
            senderTransactionReportData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
            }),
        );
    }, [dispatch]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="dashboard-container__box">
                <div className="tbl-items" style={{ padding: '0px' }}>
                    <div className="tab-box">
                        <div
                            onClick={() => {
                                setComprehensiveReport(true);
                                setInmateComprehensiveReport(false);
                                setInmateWithZeroBalance(false);
                            }}
                            className={comprehensiveReport ? 'active-tab' : 'tab-btn'}
                        >
                            <span>Senders analysis report by frequency</span>
                        </div>
                        <div
                            onClick={() => {
                                setComprehensiveReport(false);
                                setInmateComprehensiveReport(true);
                                setInmateWithZeroBalance(false);
                            }}
                            className={inmateComprehensiveReport ? 'active-tab' : 'tab-btn'}
                        >
                            <span>Inmates analysis report by frequency</span>
                        </div>
                        <div
                            onClick={() => {
                                setComprehensiveReport(false);
                                setInmateComprehensiveReport(false);
                                setInmateWithZeroBalance(true);
                            }}
                            className={inmateWithZeroBalance ? 'active-tab' : 'tab-btn'}
                        >
                            <span>Inmates Per Amount</span>
                        </div>
                    </div>
                </div>
                {changePage === 'default' ? (
                    <>
                        {comprehensiveReport === true ? (
                            <>
                                <div className="tbl-items" style={{ justifyContent: 'flex-end' }}>
                                    <div className="right-btn-group">
                                        <div className="top-right__items" style={{ width: '100%' }}>
                                            <div style={{ marginLeft: '5px', marginTop: '-2px' }}>
                                                <RangePicker
                                                    style={{ padding: '6px' }}
                                                    onChange={onSelectDate}
                                                    format="YYYY-MM-DD"
                                                    defaultValue={[
                                                        dayjs(getDate.defaultStartedDate()),
                                                        dayjs(getDate.defaultEndDate()),
                                                    ]}
                                                />
                                            </div>
                                            <Button
                                                style={{ marginRight: '30px', marginLeft: '10px' }}
                                                loading={loading}
                                                onClick={onSearch}
                                            >
                                                Search
                                            </Button>

                                            <Button style={{ marginRight: '30px' }} onClick={() => setOpen(true)}>
                                                Generate report
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <Table
                                    columns={columns}
                                    dataSource={senderReportData}
                                    pagination={data?.length > 14 ? undefined : false}
                                />
                            </>
                        ) : null}
                    </>
                ) : null}
                {changePage === 'sender-transaction-details-report' ? (
                    <SenderTransactionReport
                        setChangePage={setChangePage}
                        senderPhoneNumber={[]}
                        dispatch={dispatch}
                        componentRef={componentRef}
                        handlePrint={handlePrint}
                    />
                ) : null}
            </div>

            {}

            {inmateComprehensiveReport === true ? <InmatesTransactionReport /> : null}
            {inmateWithZeroBalance === true ? <InmateWithZeroWallet /> : null}

            <Modal
                title=""
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '16px',
                        marginBottom: '55px',
                    }}
                >
                    <div>
                        <Button onClick={handlePrint} size="large" style={{ marginRight: '30px' }}>
                            <FilePdfOutlined /> Export to PDF
                        </Button>
                    </div>

                    <div ref={componentRef} style={{ marginTop: '30px' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                padding: '8px',
                            }}
                        >
                            <span>
                                <b>SENDERS ANALYSIS REPORT BY FREQUENCY</b>
                            </span>
                            <span>
                                <b>Facility: </b>
                                {reportData?.facility}
                            </span>
                            <span>
                                <b>Period: </b>
                                {reportData?.start_date} to {reportData?.start_date}
                            </span>
                        </div>
                        <Table
                            columns={columnsPrint}
                            dataSource={senderReportData}
                            pagination={data?.length >= 10 ? undefined : false}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TransactionsReports;
