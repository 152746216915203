import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import { getGetCallingSettingsData } from '../../../../redux/actions';
import Table, { ColumnsType } from 'antd/es/table';
import { Tag } from 'antd';

interface CallingDay {
    id: number;
    call_count: number;
    calling_day: string;
    calling_period: string;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const CallingDays = () => {
    const dispatch = useDispatch();
    const { retrieveCallingSettings } = userSelector((user) => user);
    const callingSettings = retrieveCallingSettings?.data && retrieveCallingSettings?.data?.data;

    const callingDaysList: CallingDay[] = callingSettings?.calling_days_list && callingSettings?.calling_days_list;

    const columns: ColumnsType<CallingDay> = [
        {
            title: 'Call Count',
            dataIndex: 'call_count',
            key: 'call_count',
        },
        {
            title: 'Calling Day',
            dataIndex: 'calling_day',
            key: 'calling_day',
        },
    ];

    useEffect(() => {
        dispatch(getGetCallingSettingsData());
    }, [dispatch]);

    return (
        <div className="display-column">
            <Tag className="display-row-items-space-bt" style={{ fontSize: '14px', padding: '10px' }}>
                <span className="bold-text">Call duration minutes</span>
                <span className="bold-text">{callingSettings?.call_duration}</span>
            </Tag>
            <Tag color="processing" style={{ fontSize: '14px', padding: '10px' }}>
                {'The above minutes configuration is set for the days in below table'}
            </Tag>
            <Table
                loading={retrieveCallingSettings?.loading}
                dataSource={callingDaysList}
                columns={columns}
                rowKey="id"
            />
        </div>
    );
};

export default CallingDays;
