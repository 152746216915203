import { Button, Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    clearRequestCallingSettingStore,
    getGetCallingSettingsData,
    requestCallingSettingsData,
} from '../../../../redux/actions';
import { IUserSelectorType } from '../../../../interfaces';
import { toast } from 'react-toastify';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const daysList = [
    {
        value: 'Monday',
        label: 'Monday',
    },
    {
        value: 'Tuesday',
        label: 'Tuesday',
    },
    {
        value: 'Wednesday',
        label: 'Wednesday',
    },
    {
        value: 'Thursday',
        label: 'Thursday',
    },
    {
        value: 'Friday',
        label: 'Friday',
    },
    {
        value: 'Saturday',
        label: 'Saturday',
    },
    {
        value: 'Sunday',
        label: 'Sunday',
    },
];

const ConfigureCallingTime = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isActive, setIsActive] = useState(false);
    const [areActive, setAreActive] = useState(false);
    const { requestCallingSettings } = userSelector((user) => user);

    const requestData = requestCallingSettings?.data && requestCallingSettings?.data;

    const onHandleSubmit = () => {
        const formData = form.getFieldsValue();

        const data = {
            ...formData,
            call_duration: parseInt(formData?.call_duration),
            update: false,
            call_count: formData.call_count || '',
        };
        dispatch(requestCallingSettingsData(data));
    };

    const onValueChange = (value: any) => {
        if (value?.set_call_duration === true) {
            setIsActive(false);
            setAreActive(true);
        }
        if (!requestData?.data && value?.set_call_duration === false) {
            setIsActive(true);
            setAreActive(false);
        }
    };

    useEffect(() => {
        if (requestData?.data) {
            form.resetFields();
            toast.success(requestData?.message);
            dispatch(getGetCallingSettingsData());
            dispatch(clearRequestCallingSettingStore());
        }
        if (requestData?.data == null) {
            form.resetFields();
            toast.success(requestData?.message);
            dispatch(getGetCallingSettingsData());
            dispatch(clearRequestCallingSettingStore());
        }

        if (requestData?.data?.error === null) {
            toast.error(requestData?.data.message);
        }
    }, [requestData]);

    return (
        <div className="display-column">
            <Form
                initialValues={{ remember: true }}
                form={form}
                layout="vertical"
                onFinish={onHandleSubmit}
                onValuesChange={onValueChange}
            >
                <Form.Item
                    name="set_call_duration"
                    label="Set call duration"
                    valuePropName="checked"
                    initialValue={isActive}
                >
                    <Switch />
                </Form.Item>
                <Form.Item name="call_duration" label="Call duration times" className="margin-top-min-8">
                    <Input size="large" placeholder="Enter call duration times" disabled={areActive ? false : true} />
                </Form.Item>
                <Form.Item name="calling_day" label="Select calling day" className="margin-top-min-8">
                    <Select
                        size="large"
                        showSearch
                        placeholder="Select a day"
                        options={daysList}
                        disabled={areActive}
                    />
                </Form.Item>

                <Form.Item name="call_count" label="Call count" className="margin-top-min-8">
                    <Input size="large" placeholder="Enter call count" disabled={areActive} />
                </Form.Item>

                <Form.Item>
                    <Button loading={requestCallingSettings.loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ConfigureCallingTime;
