import GlobalLanguages from './globalLanguage';

const chosenLanguage = localStorage.getItem('language');

export const defaultLanguage = 'Kinyarwanda';

const Translate = (text) => {
    if (Object.keys(GlobalLanguages).includes(chosenLanguage || chosenLanguage)) {
        return GlobalLanguages[defaultLanguage][text] || text;
    }
    return text;
};

export default Translate;
