/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    DELIVERY_NOTE_FAIL,
    DELIVERY_NOTE_START,
    DELIVERY_NOTE_SUCCESS,
} from '../../../constants/actionType/orders';

export interface IDeliveryNoteData {
    data: any;
    error: any;
}

export const deliveryNoteData = (data: any) => ({ type: DELIVERY_NOTE_START, data});

export const deliveryNoteSuccess = (data: any) => ({
    type: DELIVERY_NOTE_SUCCESS,
    payload: data,
});

export const deliveryNoteFail = (error: any) => ({
    type: DELIVERY_NOTE_FAIL,
    payload: error,
});
