import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import StaticNavBar from '../common/staticNavBar';
import SubNavBar from '../common/staticNavBar/subNavBar';
import './index.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import UsersAccounts from './users/usersAccount';
import FinanceReport from './managements/financeReport';
import InmateWalletList from './inmates/wallets/inmateWallets';
import ShopDashboard from '../shop/dashboard';
import ProductTable from '../shop/products';
import OrdersList from '../shop/orders';
import OrderOfficer from '../orderOfficer';
import moment from 'moment';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TransactionsReports from './moneyTransfer/transactionsReports';
import ErrorPage from '../common/errorPage';
import Facilities from './users/facilities';
import AppointmentReport from './appointment/appointmentReport';
import InmatesList from './inmates';
import ConfirmVisit from './appointment/confirmVisit';
import AppointmentList from '../Appointment/appointmernList';
import ManageAccounts from '../manageAccounts';
import SuperAdminInmatesList from '../superAdmin/inmates/inmates';
import SuperAdminInmateWalletList from '../superAdmin/inmates/inmatesWallets';
import SuperAdminBookingReport from '../superAdmin/booking/index';
import SuperAdminDashboard from '../superAdmin/superAdminAdashboard';
import SuperAdminTransactionsReport from '../superAdmin/transactions/index';
import BoundPhoneNumber from './inmates/boundPhoneNumber';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Spin } from 'antd';
import InmatePhoneNumber from './inmates/boundPhoneNumber/inmatePhoneNumber';
import InmateCalls from './inmates/boundPhoneNumber/inmateCalls';
import Settings from './Settings';
import RecordSaving from './moneyTransfer/bulkSaving/recordSaving';
import BulkSavingDetails from './moneyTransfer/bulkSaving/bulkSavingDetails';
import BulkSaving from './moneyTransfer/bulkSaving';
import BulkSendingMoney from './moneyTransfer/bulkSendingMoney';
import RecordTransactions from './moneyTransfer/bulkSendingMoney/recordTransaction';
import BulkTransactionDetails from './moneyTransfer/bulkSendingMoney/bulkTransactionDetails';
import PendingApprovalFile from './moneyTransfer/bulkSendingMoney/PendingApprovalFile';
import PendingApprovalSavingFile from './moneyTransfer/bulkSaving/pendingApprovalFile';
import UserProfile from '../manageAccounts/profile';
import CompletedVisit from './appointment/completedVisit';
import Dashboard from './dashboard';
import VideoVisits from './videoVisit';
import { logoutData } from '../../redux/actions/auth/logout';
import ActivityLog from './activityLog';
import CallHistory from './inmates/boundPhoneNumber/callHistory';
import PurchaseOrdersDetails from '../orderOfficer/PurchaseOrdersDetails';
import ShopPurchaseOrdersDetails from '../shop/orders/PurchaseOrdersDetails';
import ShopDeliveryNoteDetails from '../shop/orders/DeliveryNoteDetails';
import DeliveryNoteDetails from '../orderOfficer/DeliveryNoteDetails';
import InvoiceOnPhoneCalls from './inmates/InvoiceOnPhoneCall';

dayjs.extend(customParseFormat);
ChartJS.register(ArcElement, Tooltip, Legend);

export const ManageDates = class {
    currentDate = new Date();
    defaultStartedDate() {
        const currentMoth =
            this.currentDate.getMonth() < 9 ? `0${this.currentDate.getMonth() + 1}` : `${this.currentDate.getMonth()}`;
        const date = `${this.currentDate.getFullYear()}-${currentMoth}-${this.currentDate.getDate()}`;
        const currDate = date;
        const dateParts = currDate.split('-');
        dateParts[0] = `${parseInt(dateParts[0], 10) - 1}`;
        return dateParts.join('-');
    }

    defaultEndDate() {
        return moment(this.currentDate).format('YYYY-MM-DD');
    }

    selectedStartedDate(selectedDate: any) {
        const date = selectedDate !== null ? selectedDate[0] : '';
        return moment(date).format('YYYY-MM-DD');
    }

    selectedEndDate(selectedDate: any) {
        const date = selectedDate !== null ? selectedDate[1] : '';
        return moment(date).format('YYYY-MM-DD');
    }
};

const DashboardPage: React.FC<any> = ({
    logout,
    loadingLogout,
    username,
    email,
    adminName,
    province,
    district,
    alertMessage,
    onHandleChangeDistrict,
    onHandleChangeEmail,
    onHandleChangeName,
    onHandleChangeProvince,
    onHandleChangeUsername,
    onHandleChangeUserRoleId,
    registerAdmin,
    loadingRegisterUser,
    roles,
    getUserData,
    getRegisteredInmates,
    registerInmate,
    registerUserData,
    onHandleChangeInmFirstName,
    onHandleChangeInmLastName,
    onHandleChangeInmFatherNames,
    onHandleChangeInmMotherNames,
    onHandleChangeInmJailedReason,
    onHandleChangeInmInmateCode,
    onHandleChangeInmBirthDate,
    onHandleChangeInmProvince,
    onHandleChangeInmDistrict,
    onHandleChangeInmAddress,
    onHandleChangeInmPrisonEntryDate,
    onHandleChangeInmPrisonId,
    inmFirstName,
    inmLastName,
    inmFatherNames,
    inmMotherNames,
    inmJailedReason,
    inmInmateCode,
    inmBirthDate,
    inmProvince,
    inmDistrict,
    inmAddress,
    inmPrisonEntryDate,
    prisonId,
    setInmPrisonId,
    loadingRegisterInmate,
    getRwandaProvinces,
    onHandleSelectDistrict,
    onHandleSelectProvince,
    districtsList,
    dispatch,
    navigate,
}) => {
    const { pathname } = location;
    const splitLocation: any = pathname.split('/');
    const [visitData] = useState<any>();

    useEffect(() => {
        visitData;
    }, []);
    const usersData = getUserData?.data?.data === undefined ? [] : getUserData?.data?.data[0];

    const managePages = () => {
        if (usersData?.role_id === undefined) {
            return (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            );
        }
        if (splitLocation[1] === 'transactions-report' && [2, 7, 6].includes(usersData?.role_id)) {
            return <TransactionsReports />;
        }
        if (splitLocation[1] === 'orders' && [4, 5, 2, 7, 6, 3].includes(usersData?.role_id)) {
            return <OrderOfficer />;
        }
        if (splitLocation[1] === 'purchase-order-details' && [4, 5, 2, 7, 6, 3].includes(usersData?.role_id)) {
            return <PurchaseOrdersDetails />;
        }

        if (splitLocation[1] === 'shop-purchase-order-details' && [4].includes(usersData?.role_id)) {
            return <ShopPurchaseOrdersDetails />;
        }
        if (splitLocation[1] === 'shop-delivery-note-details' && [4].includes(usersData?.role_id)) {
            return <ShopDeliveryNoteDetails />;
        }
        if (splitLocation[1] === 'delivery-note-details' && [5, 2, 7, 6, 3].includes(usersData?.role_id)) {
            return <DeliveryNoteDetails />;
        }
        if (splitLocation[1] === 'shop' && [4, 5].includes(usersData.role_id)) {
            return <ShopDashboard />;
        }

        // ! TOBE REMOVED 7
        if (splitLocation[1] === 'shop-products' && [4, 5, 7].includes(usersData.role_id)) {
            return <ProductTable />;
        }

        if (splitLocation[1] === 'shop-finance' && [4].includes(usersData.role_id)) {
            return <ProductTable />;
        }

        if (splitLocation[1] === 'shop-seller' && [4, 5].includes(usersData.role_id)) {
            return <OrdersList />;
        }

        if (splitLocation[1] === 'inmates-wallets' && [2, 7, 3, 5].includes(usersData.role_id)) {
            return <InmateWalletList />;
        }

        if (splitLocation[1] === 'out-going-money' && [3, 5, 2, 7].includes(usersData.role_id)) {
            return <BulkSendingMoney />;
        }

        if (splitLocation[1] === 'facilities' && [1].includes(usersData.role_id)) {
            return <Facilities />;
        }

        if (splitLocation[1] === 'appointments-reports' && [2, 7, 6].includes(usersData.role_id)) {
            return <AppointmentReport />;
        }

        if (splitLocation[1] === 'finance-report' && [5, 3, 2, 7].includes(usersData.role_id)) {
            return <FinanceReport />;
        }

        if (splitLocation[1] === 'confirm-visit' && [6].includes(usersData.role_id)) {
            return <ConfirmVisit />;
        }

        if (splitLocation[1] === 'completed-visits' && [6].includes(usersData.role_id)) {
            return <CompletedVisit />;
        }

        if ([5].includes(usersData.role_id)) {
            if (splitLocation[1] === 'record-savings') {
                return <RecordSaving />;
            }

            if (splitLocation[1] === 'record-transaction') {
                return <RecordTransactions />;
            }
        }

        if ([2, 3, 7].includes(usersData.role_id)) {
            if (splitLocation[1] === 'pending-transaction-approval-request') {
                return <PendingApprovalFile />;
            }

            if (splitLocation[1] === 'pending-approval-request') {
                return <PendingApprovalSavingFile />;
            }
        }

        if ([2, 7, 5, 3].includes(usersData.role_id)) {
            if (splitLocation[1] === 'appointment') {
                return <AppointmentList />;
            }

            if (splitLocation[1] === 'bound-phone-number') {
                return <BoundPhoneNumber />;
            }

            if (splitLocation[1] === 'inmate-calls-history') {
                return <CallHistory />;
            }

            if (splitLocation[1] === 'inmates-calls-invoices') {
                return <InvoiceOnPhoneCalls />;
            }

            if (splitLocation[1] === 'inmate-phone-numbers') {
                return <InmatePhoneNumber />;
            }

            if (splitLocation[1] === 'inmate-calls') {
                return <InmateCalls />;
            }

            if (splitLocation[1] === 'bulk-saving-details') {
                return <BulkSavingDetails />;
            }

            if (splitLocation[1] === 'bulk-saving') {
                return <BulkSaving />;
            }

            if (splitLocation[1] === 'bulk-transaction-details') {
                return <BulkTransactionDetails />;
            }
        }

        if (splitLocation[1] === 'facility-inmates-list' && [1].includes(usersData.role_id)) {
            return <SuperAdminInmatesList />;
        }

        if (splitLocation[1] === 'facility-inmates-wallets' && [1].includes(usersData.role_id)) {
            return <SuperAdminInmateWalletList />;
        }

        if (splitLocation[1] === 'user-profile' && [1, 2, 3, 4, 5, 6, 7, 8].includes(usersData.role_id)) {
            return <UserProfile />;
        }

        if (splitLocation[1] === 'facility-appointments' && [1].includes(usersData.role_id)) {
            return <SuperAdminBookingReport />;
        }

        if (splitLocation[1] === 'facility-transactions' && [1].includes(usersData.role_id)) {
            return <SuperAdminTransactionsReport />;
        }

        if (splitLocation[1] === 'general-dashboard' && [1].includes(usersData.role_id)) {
            return <SuperAdminDashboard />;
        }

        if (splitLocation[1] === 'activity-log' && [1].includes(usersData.role_id)) {
            return <ActivityLog />;
        }

        if (splitLocation[1] === 'manage-accounts' && [1, 2, 7].includes(usersData.role_id)) {
            return <ManageAccounts />;
        }

        if (splitLocation[1] === 'video-visits' && [2, 6, 7, 4, 5].includes(usersData.role_id)) {
            return <VideoVisits />;
        }

        if (splitLocation[1] === 'settings' && [1, 2, 7, 5, 4, 6, 3].includes(usersData.role_id)) {
            return <Settings />;
        }

        if (splitLocation[1] === 'dashboard' && [2, 6, 7, 4, 5].includes(usersData.role_id) === true) {
            return <Dashboard />;
        }

        if (splitLocation[1] === 'inmates-list' && [2, 6, 7, 4, 5].includes(usersData.role_id) === true) {
            return (
                <InmatesList
                    getRegisteredInmates={getRegisteredInmates}
                    registerInmate={registerInmate}
                    onHandleChangeInmFirstName={onHandleChangeInmFirstName}
                    onHandleChangeInmLastName={onHandleChangeInmLastName}
                    onHandleChangeInmFatherNames={onHandleChangeInmFatherNames}
                    onHandleChangeInmMotherNames={onHandleChangeInmMotherNames}
                    onHandleChangeInmJailedReason={onHandleChangeInmJailedReason}
                    onHandleChangeInmInmateCode={onHandleChangeInmInmateCode}
                    onHandleChangeInmBirthDate={onHandleChangeInmBirthDate}
                    onHandleChangeInmProvince={onHandleChangeInmProvince}
                    onHandleChangeInmDistrict={onHandleChangeInmDistrict}
                    onHandleChangeInmAddress={onHandleChangeInmAddress}
                    onHandleChangeInmPrisonEntryDate={onHandleChangeInmPrisonEntryDate}
                    onHandleChangeInmPrisonId={onHandleChangeInmPrisonId}
                    inmFirstName={inmFirstName}
                    inmLastName={inmLastName}
                    inmFatherNames={inmFatherNames}
                    inmMotherNames={inmMotherNames}
                    inmJailedReason={inmJailedReason}
                    inmInmateCode={inmInmateCode}
                    inmBirthDate={inmBirthDate}
                    inmProvince={inmProvince}
                    inmDistrict={inmDistrict}
                    inmAddress={inmAddress}
                    inmPrisonEntryDate={inmPrisonEntryDate}
                    prisonId={prisonId}
                    setInmPrisonId={setInmPrisonId}
                    loadingRegisterInmate={loadingRegisterInmate}
                    getRwandaProvinces={getRwandaProvinces}
                    onHandleSelectDistrict={onHandleSelectDistrict}
                    onHandleSelectProvince={onHandleSelectProvince}
                    districtsList={districtsList}
                />
            );
        }

        if (splitLocation[1] === 'users-accounts' && [1, 2, 7].includes(usersData.role_id) === true) {
            return (
                <UsersAccounts
                    username={username}
                    adminName={adminName}
                    email={email}
                    province={province}
                    district={district}
                    alertMessage={alertMessage}
                    onHandleChangeDistrict={onHandleChangeDistrict}
                    onHandleChangeEmail={onHandleChangeEmail}
                    onHandleChangeName={onHandleChangeName}
                    onHandleChangeProvince={onHandleChangeProvince}
                    onHandleChangeUsername={onHandleChangeUsername}
                    onHandleChangeUserRoleId={onHandleChangeUserRoleId}
                    registerAdmin={registerAdmin}
                    loadingRegisterUser={loadingRegisterUser}
                    roles={roles}
                    getUserData={getUserData}
                    registerUserData={registerUserData}
                />
            );
        } else {
            return <ErrorPage />;
        }
    };

    const handleActivity = () => setLastActiveTime(Date.now());

    const [lastActiveTime, setLastActiveTime] = useState(Date.now());
    const [showWarningModal, setShowWarningModal] = useState(false);

    useEffect(() => {
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
        };
    }, []);

    const resetTimeout = () => {
        setLastActiveTime(Date.now());
        setShowWarningModal(false);
    };

    const isInactive = (timeoutDuration: number): boolean => {
        const now = Date.now();
        return now - lastActiveTime > timeoutDuration;
    };

    useEffect(() => {
        //! TO BE CHANGED
        const timeoutDuration: number = 1000 * 60 * 5;
        const alertTimeout: number = 1000 * 60;

        const handleTimeout = () => {
            if (isInactive(timeoutDuration)) {
                if (showWarningModal) {
                    dispatch(logoutData());
                } else {
                    setShowWarningModal(true);
                    setTimeout(() => {
                        if (showWarningModal) {
                            setShowWarningModal(false);
                            dispatch(logoutData());
                        }
                    }, alertTimeout);
                }
            }
        };

        const timeoutId = setTimeout(handleTimeout, timeoutDuration);

        return () => clearTimeout(timeoutId);
    }, [lastActiveTime, showWarningModal]);

    useEffect(() => {
        if (logout?.data?.data) {
            navigate('/admin');
            localStorage.removeItem('token');
            localStorage.removeItem('currentUser');
            setTimeout(function () {
                window.location.reload();
            });
        }
    }, [logout?.data?.data]);

    return (
        <>
            <div className="dashboard-container__row">
                <div className="dashboard-container__left">
                    <div className="nav-bar__left">
                        <SubNavBar userData={getUserData?.data?.data} logout={logout} loadingLogout={loadingLogout} />
                    </div>
                </div>
                <div className="dashboard-container__right">
                    <StaticNavBar userData={getUserData?.data?.data} />
                    <div className="dashboard-container">
                        <div className="dashboard-container__box">{managePages()}</div>
                    </div>
                </div>
            </div>

            <Modal title={'Session Timeout Warning'} open={showWarningModal} closable={false} footer={[]}>
                <Divider />
                <h4>{`You've been inactive for a while. Session will time out in a few minutes.`}</h4>
                <Divider />
                <div className="model-button-group">
                    <Button className="stay-in-btn" type="primary" onClick={resetTimeout}>
                        Stay Logged In
                    </Button>
                    <Button danger onClick={() => dispatch(logoutData())}>
                        Log Out
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default DashboardPage;
