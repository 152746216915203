/* eslint-disable @typescript-eslint/no-explicit-any */
import {  combineReducers } from 'redux';
import signInUser from './reducers/auth/login';
import getUserData from './reducers/auth/getUserData';
import registerUser from './reducers/auth/registerUser';
import getUserRoles from './reducers/roles/roles';
import getUserAccounts from './reducers/users/getUserAccounts';
import registerInmates from './reducers/inmates/registerInmate';
import getPrisonsData from './reducers/users/getPrisons';
import getInmates from './reducers/inmates/getInmates';
import requestAppointment from './reducers/appointments/requestAppointment';
import incomingAppointment from './reducers/appointments/incomingAppointment';
import outgoingAppointment from './reducers/appointments/outgoingAppointment';
import getSummary from "./reducers/summary/getSummary";
import updateDeleteInmates from './reducers/inmates/updateDeleteInmates';
import approveRejectAppointment from './reducers/appointments/approveRejectAppointment';
import sendMoney from './reducers/sendMoney/sendMoney';
import getInmatesWallets from './reducers/inmates/inmatesWallets';
import getFinanceReport from './reducers/finance/getFinanceReport';
import getCorrectionalFacility from './reducers/users/getCorrectionalFacility';
import getShopSummaryReport from './reducers/shop/summaryReport';
import getProduct from './reducers/shop/getProduct';
import saveNewProduct from './reducers/shop/saveNewProduct';
import updateProduct from './reducers/shop/updateProduct';
import deleteProduct from './reducers/shop/deleteProduct';
import getProductSoldList from './reducers/shop/productSoldList';
import getProductSummaryReport from './reducers/shop/productSummaryReport';
import getOrdersDone from './reducers/orders/getOrdersDone';
import confirmOder from './reducers/orders/confirmOder';
import getOrders from './reducers/orders/getOrders';
import makeOder from './reducers/orders/makeOrder';
import updateDeleteUser from './reducers/users/updateDeleteUser';
import getRwandaProvinces from './reducers/rwandaProvinces/getRwandaProvinces';
import recordCashAmount from './reducers/finance/recordCashAmount';
import createOderFile from './reducers/orders/createOrderFile';
import getOrderFile from './reducers/orders/getOrderFile';
import getOrderFileDetails from './reducers/orders/getOrderFileDetails';
import approveOrderFile from './reducers/orders/approveOrderFile';
import getNotifications from './reducers/notifications/getNotifications';
import readNotification from './reducers/notifications/readNotification';
import deliveryNote from './reducers/orders/deliveryNote';
import outGoingMoney from './reducers/sendMoney/outGoingMoney';
import getOutGoingMoney from './reducers/sendMoney/getOutGoingMoney';
import purchaseOrder from './reducers/orders/purchaseOrder';
import getPublicItems from './reducers/publicItems/publicItems';
import registerFacility from './reducers/facility/registeFacility';
import updateFacility from './reducers/facility/updateFacility';
import getFacilityUser from './reducers/facility/getFacilityUsers';
import updateFacilityUser from './reducers/facility/updateFacilityUser';
import getInmatesWalletDetails from './reducers/inmates/getInmateWalletDetails';
import prepareCoupon from './reducers/inmates/prepareCoupon';
import createNewSupplier from './reducers/shop/createNewSupplier';
import getAllSupplier from './reducers/shop/getAllSupplier';
import getComment from './reducers/orders/getComment';
import calculateOrderAmount from './reducers/orders/calculateOrderAmount';
import executeOrderAmount from './reducers/orders/executeOrder';
import deliveryItems from './reducers/orders/deliveryItems';
import outgoingTransactionHistory from './reducers/sendMoney/outgoingTransactionHistory';
import senderTransactionReport from './reducers/sendMoney/senderTransactionReport';
import senderTransactionReportDetails from './reducers/sendMoney/senderTransactionReportDetails';
import inmatesTransactionReport from './reducers/sendMoney/inmateTransactionReport';
import inmatesTransactionReportDetails from './reducers/sendMoney/inmatesTransactionReportDetails';
import getOrderDetails from './reducers/orders/getOdersDetails';
import createDeliveryNoteFile from './reducers/orders/createDeliveryNoteFile';
import getDeliveryNoteItems from './reducers/orders/getDeliveryNoteItems';
import closeDeliveryNoteFile from './reducers/orders/closeDeliveryNoteFile';
import getAppointmentReport from './reducers/appointments/appointmentReport';
import inmatesWithZeroWallet from './reducers/sendMoney/inmateWithZeroWallet';
import getInmateList from './reducers/superAdmin/getInmateList';
import getInmatesWalletsList from './reducers/superAdmin/getInmateWalletList';
import getInmatesWithZeroWallet from './reducers/superAdmin/getInmateWithZeroWallet';
import getBookingReport from './reducers/superAdmin/getBookingReport';
import getSummaryReport from './reducers/superAdmin/getSummaryReport';
import getTransactionsReport from './reducers/superAdmin/getTransactionsReport';
import changePassword from './reducers/auth/changePassword';
import logout from './reducers/auth/logout';
import getCallOfficer from './reducers/users/getCallOfficert';
import requestPhoneNumberLink from './reducers/inmates/linkPhoneNumber';
import getInmatePhoneNumber from './reducers/inmates/inmatePhoneNumber';
import linkOrDeletePhoneNumber from './reducers/inmates/linkOrDeletePhoneNumber';
import inmateCalls from './reducers/inmates/inmateCalls';
import playAudio from './reducers/inmates/playAudio';
import uploadDocument from './reducers/orders/uploadDocument';
import createBulkSaving from './reducers/sendMoney/bulkSaving/createBulkSaving';
import retrieveBulkSavingFiles from './reducers/sendMoney/bulkSaving/retrieveBulkSavingFiles';
import uploadBulkSavingDocument from './reducers/sendMoney/bulkSaving/uploadBulkSavingDocument';
import recordSaving from './reducers/sendMoney/bulkSaving/recordSaving';
import retrieveBulkSavingFilesDetails from './reducers/sendMoney/bulkSaving/bulkSavingDetails';
import requestApprovalOrRejection from './reducers/sendMoney/bulkSaving/requestApprovalOrRejection';
import requestApprovalOrRejectionTransaction from './reducers/sendMoney/bulkTransaction/requestApprovalOrRejectionTransaction';
import retrieveBulkTransactionFilesDetails from './reducers/sendMoney/bulkSaving/bulkSavingDetails';
import createBulkTransaction from './reducers/sendMoney/bulkTransaction/createBulkTransaction';
import recordTransaction from './reducers/sendMoney/bulkTransaction/recordTransaction';
import retrieveBulkTransactionFiles from './reducers/sendMoney/bulkTransaction/retrieveBulkTransactionFiles';
import uploadBulkTransactionDocument from './reducers/sendMoney/bulkTransaction/uploadBulkTransactionDocument';
import deleteUserAccount from './reducers/auth/deleteUserAccount';
import getUserProfile from './reducers/users/getUserProfile';
import updateUserProfile from './reducers/users/updateUserProfile';
import confirmAppointment from './reducers/appointments/confirmAppointment';
import completedVisit from './reducers/appointments/completedVisit';
import getAvailableTime from './reducers/appointments/getAvailableTime';
import getGlobalSummary from './reducers/globalSummary/getGloableSummary';
import checkVisitId from './reducers/videoMeeting/checkVisitId';
import checkWhoJoined from './reducers/videoMeeting/checkWhoJoined';
import videoVisits from './reducers/videoMeeting/videoVisits';
import pendingVisits from './reducers/videoMeeting/pendingVisits';
import visitDone from './reducers/videoMeeting/visitDone';
import saveDeviceSignature from './reducers/videoMeeting/saveDeviceSignature';
import getDevices from './reducers/videoMeeting/getDevice';
import linkDeviceToVisit from './reducers/videoMeeting/linkVisitToDevice';
import autoStartSession from './reducers/videoMeeting/autoStartSession';
import startMeeting from './reducers/videoMeeting/startMeeting';
import fetchActivityLog from './reducers/users/fetchGetActivityLog';
import requestInmateCallHistory from './reducers/inmates/inmateCallHistory';
import requestCallingSettings from './reducers/inmates/requestCallingSettgins';
import getCallingSettings from './reducers/inmates/retrieveCallingSettings';
import previewOrderFile from './reducers/orders/previewOrderFile';
import fetchCells from './reducers/orders/fetchCells';
import requestBindFileAndCell from './reducers/orders/bindFileAndCell';
import retrievePurchaseOrderList from './reducers/orders/purchaseOrderList';
import manageZones from './reducers/orders/manageZones';
import editOrder from './reducers/orders/editOrder';
import uploadBulkAmount from './reducers/sendMoney/bulkTransaction/uploadBulkAmount';
import fetchVisitorItems from './reducers/appointments/fetchVisitorItems';
import fetchVisitorLimit from './reducers/facility/fetchVisitorLimit';
import visitorLimit from './reducers/facility/visitorLimit';
import transferInmate from './reducers/inmates/transferInmate';
import callInvoices from './reducers/inmates/callInvoices';
import sendBulkSMS from './reducers/superAdmin/sendBulkSMS';


const allReducers: any = combineReducers({
    signInUser: signInUser,
    getUserData: getUserData,
    registerUser: registerUser,
    getUserRoles: getUserRoles,
    getUserAccounts: getUserAccounts,
    getRegisteredInmate: registerInmates,
    getPrisons: getPrisonsData,
    getInmates: getInmates,
    requestAppointment: requestAppointment,
    incomingAppointment: incomingAppointment,
    outgoingAppointment: outgoingAppointment,
    getSummary: getSummary,
    updateDeleteInmates: updateDeleteInmates,
    approveRejectAppointment: approveRejectAppointment,
    sendMoney: sendMoney,
    getInmatesWallets: getInmatesWallets,
    getFinanceReport: getFinanceReport,
    getCorrectionalFacility: getCorrectionalFacility,
    getShopSummaryReport:getShopSummaryReport,
    getProduct: getProduct,
    saveNewProduct: saveNewProduct,
    updateProduct: updateProduct,
    deleteProduct: deleteProduct,
    getProductSoldList: getProductSoldList,
    getProductSummaryReport: getProductSummaryReport,
    getOrdersDone: getOrdersDone,
    getOrders: getOrders,
    confirmOder: confirmOder,
    makeOder: makeOder,
    updateDeleteUser: updateDeleteUser,
    getRwandaProvinces: getRwandaProvinces,
    recordCashAmount: recordCashAmount,
    createOrderFile:  createOderFile,
    getOrderFile: getOrderFile,
    getOrderFileDetails: getOrderFileDetails,
    approveOrderFile: approveOrderFile,
    getNotifications: getNotifications,
    readNotification: readNotification,
    deliveryNote: deliveryNote,
    outGoingMoney: outGoingMoney,
    getOutGoingMoney: getOutGoingMoney,
    purchaseOrder: purchaseOrder,
    getPublicItem: getPublicItems,
    registerFacility: registerFacility,
    updateFacility: updateFacility,
    getFacilityUser: getFacilityUser,
    updateFacilityUser: updateFacilityUser,
    getInmatesWalletDetails: getInmatesWalletDetails,
    prepareCoupon: prepareCoupon,
    createNewSupplier: createNewSupplier,
    getAllSupplier: getAllSupplier,
    getComment: getComment,
    calculateOrderAmount: calculateOrderAmount,
    executeOrderAmount: executeOrderAmount,
    deliveryItems: deliveryItems,
    outgoingTransactionHistory: outgoingTransactionHistory,
    senderTransactionReport: senderTransactionReport,
    senderTransactionReportDetails: senderTransactionReportDetails,
    inmatesTransactionReport: inmatesTransactionReport,
    inmatesTransactionReportDetails: inmatesTransactionReportDetails,
    getOrderDetails: getOrderDetails,
    createDeliveryNoteFile: createDeliveryNoteFile,
    getDeliveryNoteItems: getDeliveryNoteItems,
    closeDeliveryNoteFile: closeDeliveryNoteFile,
    getAppointmentReport: getAppointmentReport,
    inmatesWithZeroWallet: inmatesWithZeroWallet,
    getInmateList: getInmateList,
    getInmatesWalletsList: getInmatesWalletsList,
    getInmatesWithZeroWallet: getInmatesWithZeroWallet,
    getBookingReport: getBookingReport,
    getSummaryReport: getSummaryReport,
    getTransactionsReport: getTransactionsReport,
    changePassword: changePassword,
    logout: logout,
    getCallOfficer: getCallOfficer,
    requestPhoneNumberLink: requestPhoneNumberLink,
    getInmatePhoneNumber: getInmatePhoneNumber,
    linkOrDeletePhoneNumber: linkOrDeletePhoneNumber,
    inmateCalls: inmateCalls,
    playAudio: playAudio,
    uploadDocument: uploadDocument,
    createBulkSaving: createBulkSaving,
    retrieveBulkSavingFiles: retrieveBulkSavingFiles,
    uploadBulkSavingDocument: uploadBulkSavingDocument,
    recordSaving: recordSaving,
    retrieveBulkSavingFilesDetails: retrieveBulkSavingFilesDetails,
    requestApprovalOrRejection: requestApprovalOrRejection,
    createBulkTransaction: createBulkTransaction,
    retrieveBulkTransactionFiles: retrieveBulkTransactionFiles,
    uploadBulkTransactionDocument: uploadBulkTransactionDocument,
    recordTransaction: recordTransaction,
    retrieveBulkTransactionFilesDetails: retrieveBulkTransactionFilesDetails,
    requestApprovalOrRejectionTransaction: requestApprovalOrRejectionTransaction,
    deleteUserAccount: deleteUserAccount,
    getUserProfile: getUserProfile,
    updateUserProfile: updateUserProfile,
    confirmAppointment: confirmAppointment,
    completedVisit: completedVisit,
    getAvailableTime: getAvailableTime,
    getGlobalSummary: getGlobalSummary,
    checkVisitId: checkVisitId,
    checkWhoJoined: checkWhoJoined,
    videoVisits: videoVisits,
    pendingVisits: pendingVisits,
    visitDone: visitDone,
    saveDeviceSignature: saveDeviceSignature,
    getDevice: getDevices,
    linkDeviceToVisit: linkDeviceToVisit,
    autoStartSession: autoStartSession,
    startMeeting: startMeeting,
    fetchActivityLog: fetchActivityLog,
    requestInmateCallHistory: requestInmateCallHistory,
    requestCallingSettings: requestCallingSettings,
    retrieveCallingSettings: getCallingSettings,
    previewOrderFile: previewOrderFile,
    fetchCells: fetchCells,
    requestBindFileAndCell: requestBindFileAndCell,
    retrievePurchaseOrderList: retrievePurchaseOrderList,
    manageZones: manageZones,
    editOrder: editOrder,
    uploadBulkAmount: uploadBulkAmount,
    fetchVisitorItems: fetchVisitorItems,
    fetchVisitorLimit: fetchVisitorLimit,
    visitorLimit: visitorLimit,
    transferInmate: transferInmate,
    callInvoices: callInvoices,
    sendBulkSMS: sendBulkSMS
});


export type RootState = ReturnType<typeof allReducers>;
export default allReducers;