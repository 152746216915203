import {
   CHECK_WHO_JOINED_CLEAR,
   CHECK_WHO_JOINED_FAIL,
   CHECK_WHO_JOINED_START,
   CHECK_WHO_JOINED_SUCCESS,
} from '../../../constants/actionType/videoMeeting';
import { IResponseData } from './checkVisitId';


interface IPayload {
    visit_id: string;
    time: string;
    today: string;
}

export const checkWhoJoinedData = (data:IPayload) => ({ type:CHECK_WHO_JOINED_START, data});

export const checkWhoJoinedSuccess = (data: IResponseData) => ({
    type:CHECK_WHO_JOINED_SUCCESS,
    payload: data,
});

export const checkWhoJoinedFail = (error: IResponseData) => ({
    type:CHECK_WHO_JOINED_FAIL,
    payload: error,
});


export const clearCheckWhoJoined = () =>  ({
    type:CHECK_WHO_JOINED_CLEAR,
});