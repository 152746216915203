/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_BULK_SAVING_FILE_SUCCESS,
    REQUEST_BULK_SAVING_FILE_FAIL,
    REQUEST_BULK_SAVING_FILE_START 
} from "../../../../constants/actionType/bulkSaving";
import { fetchData } from '../../../../helpers';




export interface ResponseGenerator{
    message: string
}

function* retrieveBulkSavingFile() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'retrieve-bulk-saving-file');
        yield put({type:REQUEST_BULK_SAVING_FILE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_BULK_SAVING_FILE_FAIL, error: e.message });
    }
}

export default function* fetchRetrieveBulkSavingFile() {
    yield takeEvery(REQUEST_BULK_SAVING_FILE_START, retrieveBulkSavingFile)
}