import {
    AUTO_START_SESSION_CLEAR,
    AUTO_START_SESSION_FAIL,
    AUTO_START_SESSION_START,
    AUTO_START_SESSION_SUCCESS,
} from '../../../constants/actionType/videoMeeting';
import { IResponseData } from './checkVisitId';


interface IPayload {
    browser_signature: string | number;
    today_date: string | number;
}

export const autoStartSessionData = (data:IPayload) => ({ type: AUTO_START_SESSION_START, data});

export const autoStartSessionSuccess = (data: IResponseData) => ({
    type: AUTO_START_SESSION_SUCCESS,
    payload: data,
});

export const autoStartSessionFail = (error: IResponseData) => ({
    type: AUTO_START_SESSION_FAIL,
    payload: error,
});


export const clearAutoStartSession = () =>  ({
    type: AUTO_START_SESSION_CLEAR,
});