import { call, put, takeEvery } from "redux-saga/effects";
import { 
    UPDATE_DELETE_INMATES_SUCCESS, 
    UPDATE_DELETE_INMATES_FAIL, 
    UPDATE_DELETE_INMATES_START 
} from "../../../constants/actionType/inmates";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: string
}

interface IData {
    id: string | number;
    status: string | boolean;
}

interface IPayload {
    type: typeof UPDATE_DELETE_INMATES_START,
    data: IData
}


function* updateDeleteInmates(data: IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/update-inmates', data?.data);
        yield put({type: UPDATE_DELETE_INMATES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: UPDATE_DELETE_INMATES_FAIL, error: e.message });
    }
}

export default function* getUpdateDeleteInmates() {
    yield takeEvery(UPDATE_DELETE_INMATES_START, updateDeleteInmates)
}