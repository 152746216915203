import { call, put, takeEvery } from "redux-saga/effects";
import {
    CONFIRM_VISIT_SUCCESS,
    CONFIRM_VISIT_FAIL,
    CONFIRM_VISIT_START 
} from "../../../constants/actionType/appointment";
import { saveData} from '../../../helpers';


export interface ResponseGenerator{
    message: string
}

interface IData {
    visit_code: string | number,
    visitor_identity: string | number,
    confirm_visit: boolean,
    get_visitor: boolean
}

interface IPayload {
    type: typeof CONFIRM_VISIT_START,
    data: IData
}

function* confirmAppointment(data:IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/get-visitor-confirm-visit', data?.data);
        yield put({type:CONFIRM_VISIT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:CONFIRM_VISIT_FAIL, error: e.message });
    }
}

export default function* fetchConfirmAppointment() {
    yield takeEvery(CONFIRM_VISIT_START, confirmAppointment)
}