/* eslint-disable @typescript-eslint/no-explicit-any */
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const exportToXLSX = (data: any[], fileName: string, headerMapping: any) => {
    const formattedData = data.map((item) => {
        const formattedItem: { [key: string]: any } = {};
        Object.keys(item).forEach((key) => {
            const newKey = headerMapping[key] || key;
            formattedItem[newKey] = item[key];
        });
        return formattedItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    const headers = Object.values(headerMapping);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    saveAs(dataBlob, fileName);
};

export default exportToXLSX;
