import { call, put, takeEvery } from "redux-saga/effects";
import { 
    UPDATE_FACILITY_USERS_SUCCESS, 
    UPDATE_FACILITY_USERS_FAIL, 
    UPDATE_FACILITY_USERS_START } from "../../../constants/actionType/facility";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: string
}

interface IData {
    user_id: string | number,
    blocked: string | boolean,
    unblocked: string | boolean,
}

interface IPayload {
    type: typeof UPDATE_FACILITY_USERS_START,
    data: IData
}

function* updateFacilityUsers(data: IPayload) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'user/update-users', data?.data);
        yield put({type: UPDATE_FACILITY_USERS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: UPDATE_FACILITY_USERS_FAIL, error: e.message });
    }
}

export default function* getUpdateFacilityUser() {
    yield takeEvery(UPDATE_FACILITY_USERS_START, updateFacilityUsers)
}