/* eslint-disable @typescript-eslint/no-explicit-any */

import { call, put, takeEvery } from "redux-saga/effects";
import {
    DELIVERY_NOTE_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { deliveryNoteFail, deliveryNoteSuccess } from '../../actions/orders/deliveryNote';


interface ResponseGenerator {
    data: any;
}

function* getDeliveryNote(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/retrieve-delivery-order-file', data?.data);
        if (response.data?.error) {
            yield put(deliveryNoteFail(response.data || 'Unknown error'));
        } else {
            yield put(deliveryNoteSuccess(response));
        }
    } catch (e: any) {
        yield put(deliveryNoteFail(e.message || 'Unknown error'));
    }
}

export default function* fetchDeliveryNote() {
    yield takeEvery(DELIVERY_NOTE_START, getDeliveryNote)
}