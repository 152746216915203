/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    AVAILABLE_TIME_START 
} from "../../../constants/actionType/appointment";
import { saveData} from '../../../helpers';
import { IGetAvailableTimePayload } from "../../../interfaces/appointment";
import { getAvailableTimeError, getAvailableTimeSuccess } from "../../actions";


export interface ResponseGenerator{
    data: any;
}



interface IPayload {
    type: typeof AVAILABLE_TIME_START,
    data: IGetAvailableTimePayload
    
}

function* getAvailableTime(data:IPayload) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'prison/appointment-available-time', data?.data);

        if (response?.data.error) {

            yield put(getAvailableTimeError(response.data || 'Unknown error'));
        } else {
            yield put(getAvailableTimeSuccess(response));
        }
    } catch (e: any) {
        yield put(getAvailableTimeError(e.message || 'Unknown error'));
    }
}

export default function* fetchGetAvailableTime() {
    yield takeEvery(AVAILABLE_TIME_START, getAvailableTime)
}