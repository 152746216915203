/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery } from "redux-saga/effects";
import { REQUEST_CALLING_SETTINGS_SUCCESS, REQUEST_CALLING_SETTINGS_FAIL, REQUEST_CALLING_SETTINGS_START } from "../../../constants/actionType/inmates";
import { saveData } from '../../../helpers';


export interface ResponseGenerator{
    message: any
}

function* requestCallingSettings(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/audio/configuration-call-timer', data?.data);
        yield put({type: REQUEST_CALLING_SETTINGS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_CALLING_SETTINGS_FAIL, error: e.message });
    }
}

export default function* getRequestCallingSettings() {
    yield takeEvery(REQUEST_CALLING_SETTINGS_START, requestCallingSettings)
}