import { Button, DatePicker, Modal, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ManageDates } from '..';
import moment from 'moment';
import Table, { ColumnsType } from 'antd/es/table';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import { IUserSelectorType } from '../../../interfaces';
import { inmatesTransactionReportData } from '../../../redux/actions';
import InmatesTransactionReportDetails from './inmatesTransactionReportDetails';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const InmatesTransactionReport: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate]: any = useState();
    const getDate = new ManageDates();
    const [dates, setDates] = useState<RangeValue>(null);
    const [open, setOpen] = useState(false);
    const [changesPage, setChangePage] = useState<string>('inmate-report');
    const [inmateCode, setInmateCode] = useState<any>('');

    const {
        inmatesTransactionReport: { data, loading },
    } = userSelector((user) => user);

    const reportData = data?.data !== undefined ? data?.data : [];

    const inmatesReportData: any = reportData?.details?.map((item: any) => {
        return {
            name: `${item?.inmate?.first_name} ${item?.inmate?.last_name}`,
            inmate_code: item?.inmate?.inmate_code,
            amount: item?.total_amount,
            senders_number: item?.sender_number,
            frequency: item?.frequency,
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
            width: 150,
        },
        {
            title: 'Total amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
        {
            title: 'Number of senders',
            dataIndex: 'senders_number',
            key: 'senders_number',
            width: 150,
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: 150,
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (data) => {
                return (
                    <Space size="middle">
                        <a
                            onClick={() => {
                                setInmateCode(data?.inmate_code);
                                setChangePage('inmate-report-details');
                            }}
                        >
                            Details
                        </a>
                    </Space>
                );
            },
        },
    ];

    const columns1: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
            width: 150,
        },
        {
            title: 'Total amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
        {
            title: 'Number of senders',
            dataIndex: 'senders_number',
            key: 'senders_number',
            width: 150,
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: 150,
        },
    ];

    const onSelectDate = (dateString: any) => {
        setDates(dateString);

        setSelectedDate({
            start_date: !dates?.[0] ? getDate.defaultStartedDate() : moment(`${dates?.[0]}`).format('YYYY-MM-DD'),
            end_date: !dates?.[1] ? getDate.defaultEndDate() : moment(`${dates?.[1]}`).format('YYYY-MM-DD'),
        });
    };

    const onSearch = () => {
        dispatch(
            inmatesTransactionReportData({
                start_date:
                    selectedDate?.start_date === undefined ? getDate.defaultStartedDate() : selectedDate?.start_date,
                end_date: selectedDate?.end_date === undefined ? getDate.defaultEndDate() : selectedDate?.end_date,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            inmatesTransactionReportData({
                start_date: getDate.defaultStartedDate(),
                end_date: getDate.defaultEndDate(),
            }),
        );
    }, [dispatch, selectedDate]);

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {changesPage === 'inmate-report' ? (
                <>
                    <div className="dashboard-container__box">
                        <div className="user-account-tbl">
                            <div className="tbl-items">
                                <div></div>
                                <div className="top-right__items" style={{ width: '80%' }}>
                                    <div style={{ marginLeft: '5px' }}>
                                        <RangePicker
                                            style={{ padding: '6px' }}
                                            onChange={onSelectDate}
                                            format="YYYY-MM-DD"
                                            defaultValue={[
                                                dayjs(getDate.defaultStartedDate()),
                                                dayjs(getDate.defaultEndDate()),
                                            ]}
                                        />
                                    </div>
                                    <Button
                                        style={{ marginRight: '30px', marginLeft: '10px' }}
                                        loading={loading}
                                        onClick={onSearch}
                                    >
                                        Search
                                    </Button>

                                    <Button type="primary" onClick={() => setOpen(true)}>
                                        Generate report
                                    </Button>
                                </div>
                            </div>

                            <Table
                                columns={columns}
                                dataSource={inmatesReportData}
                                pagination={data?.length >= 10 ? undefined : false}
                            />
                        </div>
                    </div>
                    <Modal
                        title=""
                        centered
                        open={open}
                        onOk={() => setOpen(false)}
                        onCancel={() => setOpen(false)}
                        width={1000}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: '16px',
                            }}
                        >
                            <div>
                                <Button onClick={handlePrint} style={{ marginRight: '30px' }}>
                                    <FilePdfOutlined /> Export to PDF
                                </Button>
                            </div>
                            <div ref={componentRef} style={{ marginTop: '30px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                        padding: '8px',
                                    }}
                                >
                                    <span>
                                        <b>INMATES ANALYSIS REPORT BY FREQUENCY</b>
                                    </span>
                                    <span>
                                        <b>Facility: </b>
                                        {reportData?.facility}
                                    </span>
                                    <span>
                                        <b>Period: </b>
                                        {reportData?.start_date} to {reportData?.start_date}
                                    </span>
                                </div>
                                <Table
                                    columns={columns1}
                                    dataSource={inmatesReportData}
                                    pagination={data?.length >= 10 ? undefined : false}
                                />
                            </div>
                        </div>
                    </Modal>
                </>
            ) : null}

            {changesPage === 'inmate-report-details' ? (
                <InmatesTransactionReportDetails
                    setChangePage={setChangePage}
                    inmateCode={inmateCode}
                    dispatch={dispatch}
                    componentRef={componentRef}
                    handlePrint={handlePrint}
                />
            ) : null}
        </>
    );
};

export default InmatesTransactionReport;
