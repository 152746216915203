import { Button, Divider, Flex, Form, Input, Modal } from 'antd';
import { IUserSelectorType } from 'interfaces';
import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearOutGoingMoney, outGoingMoneyData } from 'redux/actions';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const inputStyle = { margin: '6px' };

const SendOutMoneyModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { outGoingMoney } = userSelector((user) => user);

    const onSubmit = () => {
        const formData = form.getFieldsValue();

        const userData = {
            receiver_phone_number: formData.receiver_phone_number || '',
            to_other_inmate: formData.to_other_inmate || '', // To others
            tariffAmount: formData.tariffAmount || '',
            inmate_code: formData.inmate_code || '',
            receiver_first_name: formData.receiver_first_name || '',
            receiver_last_name: formData.receiver_last_name || '',
            telco: 'MTN',
        };

        dispatch(outGoingMoneyData(userData));
    };

    const onCloseModal = () => {
        onClose();
        dispatch(clearOutGoingMoney());
    };

    useEffect(() => {
        if (outGoingMoney.data) {
            toast.success(outGoingMoney.message);
            onCloseModal();
        }

        if (outGoingMoney.error) {
            toast.error(outGoingMoney.message);
        }
    }, [outGoingMoney]);

    return (
        <Modal title="Send Money" open={open} onCancel={onCloseModal} footer={[]}>
            <Form onFinish={onSubmit} form={form} layout="vertical">
                <Form.Item
                    name="inmate_code"
                    label="Sender Inmate RSC case number"
                    rules={[{ required: true }]}
                    style={inputStyle}
                >
                    <Input size="large" placeholder="Enter inmate RSC case number" />
                </Form.Item>
                <Form.Item
                    name="to_other_inmate"
                    label="Receiver RSC Case Number"
                    rules={[{ required: true }]}
                    style={inputStyle}
                >
                    <Input size="large" placeholder="Receiver RSC Case Number" />
                </Form.Item>
                <Form.Item name="receiver_first_name" label="Enter Receiver First Name" style={inputStyle}>
                    <Input size="large" placeholder="first name" />
                </Form.Item>
                <Form.Item name="receiver_last_name" label="Enter Receiver Last Name" style={inputStyle}>
                    <Input size="large" placeholder="last name" />
                </Form.Item>
                <Form.Item name="tariffAmount" label="Amount" rules={[{ required: true }]} style={inputStyle}>
                    <Input size="large" placeholder="Amount" />
                </Form.Item>
                <Divider />
                <Form.Item style={inputStyle}>
                    <Flex justify="space-between" style={{ width: '100%' }}>
                        <Button danger ghost type="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button loading={outGoingMoney.loading} htmlType="submit" type="primary" ghost>
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SendOutMoneyModal;
