/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    PRODUCT_SOLD_LIST_SUCCESS,
    PRODUCT_SOLD_LIST_FAIL,
    PRODUCT_SOLD_LIST_START 
} from "../../../constants/actionType/shop";
import { fetchData} from '../../../helpers';


export interface ResponseGenerator{}

function* getProductSoldList() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'v2/prison/shop/product-sold');
        yield put({type:PRODUCT_SOLD_LIST_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:PRODUCT_SOLD_LIST_FAIL, error: e.message });
    }
}

export default function* fetchGetProductSoldList() {
    yield takeEvery(PRODUCT_SOLD_LIST_START, getProductSoldList)
}