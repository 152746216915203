import {
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL, 
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START, 
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS ,
    REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_CLEAR
} from "../../../../constants/actionType/bulkTransaction";
import { IResponse, IError } from "../../../../interfaces/bulkSaving";
import { ITransactionRequestApprovalOrRejectionPayload } from "../../../../interfaces/bulkTransaction";


 export const requestApprovalOrRejectionTransactionData = (data: ITransactionRequestApprovalOrRejectionPayload) => 
 ({type:  REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_START, data});
 
 export const requestApprovalOrRejectionTransactionSuccess = (data: IResponse) => ({
   type: REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_SUCCESS,
   payload: data
 })
 
 export const requestApprovalOrRejectionTransactionError = (error:IError ) => ({
   type: REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_FAIL,
   payload: error
 })

 export const clearRequestApprovalOrRejectionTransactionStore = () => ({type: REQUEST_APPROVEMENT_OR_REJECTION_TRANSACTION_FILE_CLEAR})