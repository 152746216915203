/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Divider, Table, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { DataType } from '..';

const OrderFileSummary: React.FC<{ data: any }> = ({ data }) => {
    const columns: ColumnsType<DataType> = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Price',
            dataIndex: 'unit_price',
            key: 'unit_price',
        },
        {
            title: 'Quantity',
            dataIndex: 'requested_quantity',
            key: 'requested_quantity',
        },
        {
            title: 'Total price',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },
    ];
    return (
        <Flex gap="middle" vertical>
            <Flex align="center" justify="space-between">
                <span>Order Total Amount</span>
                <span className="bold-text">{data?.total_amount ?? 0}</span>
            </Flex>
            <Divider style={{ margin: '4px' }} />
            <Flex align="center" justify="space-between">
                <span>Order Total Quantity</span>
                <span className="bold-text">{data?.total_quantity ?? 0}</span>
            </Flex>

            {!data.loading ? (
                <Table size="small" columns={columns} dataSource={data?.details ?? []} pagination={false} />
            ) : (
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            )}
        </Flex>
    );
};

export default OrderFileSummary;
