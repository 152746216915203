import { Button, Empty, Flex, Table } from 'antd';
import React, { useEffect } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import EmptyTable from '../../../common/emptyTable';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import {
    clearRequestApprovalOrRejectionStore,
    requestApprovalOrRejectionData,
    retrieveBulkSavingFileDetailsData,
} from '../../../../redux/actions';
import { IFileDetailsData } from '../../../../interfaces/bulkSaving';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const BulkSavingDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        state: { fileId, fileCurrentUser },
    } = location;

    const { retrieveBulkSavingFilesDetails, requestApprovalOrRejection, getUserData } = userSelector((state) => state);

    const fileDetails = retrieveBulkSavingFilesDetails.data?.data ? retrieveBulkSavingFilesDetails?.data?.data : [];
    const currentUser = getUserData?.data?.data ? getUserData?.data?.data : {};

    const columns1: ColumnsType<IFileDetailsData> = [
        {
            title: 'Added on',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'First name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const dataCol1: IFileDetailsData[] = fileDetails.map((inmate: IFileDetailsData) => {
        return {
            first_name: inmate?.first_name,
            last_name: inmate?.last_name,
            amount: inmate?.amount,
            inmate_code: inmate?.inmate_code,
            created_at: moment(inmate.created_at).format('DD-MMM-YYYY'),
        };
    });

    const requestForApproval = () => {
        dispatch(
            requestApprovalOrRejectionData({
                file_id: fileId,
                rejected: false,
                approved: true,
            }),
        );
    };

    const approvalFile = () => {
        dispatch(
            requestApprovalOrRejectionData({
                file_id: fileId,
                rejected: false,
                approved: true,
            }),
        );
    };

    const rejectFile = () => {
        dispatch(
            requestApprovalOrRejectionData({
                file_id: fileId,
                rejected: true,
                approved: false,
            }),
        );
    };

    useEffect(() => {
        if (requestApprovalOrRejection.data) {
            dispatch(clearRequestApprovalOrRejectionStore());
            navigate('/bulk-saving');
        }
    }, [dispatch, requestApprovalOrRejection.data]);

    useEffect(() => {
        dispatch(retrieveBulkSavingFileDetailsData({ file_id: fileId }));
    }, []);
    console.log(fileId, 'YYYY');
    return (
        <>
            {' '}
            <div className="user-account-tbl">
                <div className="details-btn-group">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => navigate('/bulk-saving')}>
                            <BiArrowBack size={18} />
                            <span style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px' }}>
                                10% income files
                            </span>
                        </Button>
                        <div>
                            {currentUser[0]?.role_id === parseInt(fileCurrentUser) ? (
                                <Button
                                    loading={requestApprovalOrRejection.loading}
                                    onClick={() => requestForApproval()}
                                >
                                    <span>Request for approval</span>
                                </Button>
                            ) : null}

                            {[2, 7, 3].includes(currentUser[0]?.role_id) &&
                            parseInt(currentUser[0]?.role_id) === parseInt(fileCurrentUser) ? (
                                <>
                                    <Button loading={requestApprovalOrRejection.loading} onClick={() => approvalFile()}>
                                        <span>Approval</span>
                                    </Button>
                                    <Button loading={requestApprovalOrRejection.loading} onClick={() => rejectFile()}>
                                        <span>Reject</span>
                                    </Button>
                                </>
                            ) : null}

                            <Button
                                disabled={
                                    [2, 7].includes(parseInt(fileCurrentUser)) && currentUser[0]?.role_id == 5
                                        ? false
                                        : true
                                }
                            >
                                <span>Confirm saving</span>
                            </Button>
                        </div>
                    </div>
                </div>

                {fileDetails.length !== 0 ? (
                    <>
                        <Table
                            columns={columns1}
                            dataSource={dataCol1}
                            pagination={fileDetails.length >= 12 ? undefined : false}
                        />
                    </>
                ) : (
                    <EmptyTable
                        title={'No record found'}
                        subTitle={`Try to add inmate amount by`}
                        Icon={<Empty />}
                        button={
                            <Flex align="center" justify="center" style={{ width: '100%', padding: '10px' }}>
                                <Button type="primary" ghost onClick={() => navigate('/record-savings')}>
                                    clicking here
                                </Button>
                            </Flex>
                        }
                    />
                )}
            </div>
        </>
    );
};

export default BulkSavingDetails;
