import { 
    REQUEST_VISITS_DONE_START, 
    REQUEST_VISITS_DONE_SUCCESS, 
    REQUEST_VISITS_DONE_FAIL, 
    REQUEST_VISITS_DONE_CLEAR
} from '../../../constants/actionType/videoMeeting';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const visitDone = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_VISITS_DONE_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_VISITS_DONE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_VISITS_DONE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case REQUEST_VISITS_DONE_CLEAR: {
            return {
                ...state,
                loading: false,
                data: null,
                error: null,
            };
        }

        default: {
            return state;
          }
    }
}

export default visitDone;