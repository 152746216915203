/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    REQUEST_APPROVE_ORDER_FILE_SUCCESS,
    REQUEST_APPROVE_ORDER_FILE_FAIL,
    REQUEST_APPROVE_ORDER_FILE_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { toast } from "react-toastify";


export interface ResponseGenerator{
    message: any
}

function* getApproveOrderFile(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'orders/approve-reject-order-file', data?.data);
        toast(`${userData?.message}`)
        yield put({type:REQUEST_APPROVE_ORDER_FILE_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_APPROVE_ORDER_FILE_FAIL, error: e.message });
    }
}

export default function* fetchApproveOrderFile() {
    yield takeEvery(REQUEST_APPROVE_ORDER_FILE_START, getApproveOrderFile)
}