/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_SUMMARY_SUCCESS,
    GET_SUMMARY_FAIL,
    GET_SUMMARY_START 
} from "../../../constants/actionType/summary";
import { saveData } from "../../../helpers/index";



export interface ResponseGenerator{}

function* getSummary(data: any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'v2/prison/collection-report', data?.data);
        yield put({type:GET_SUMMARY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:GET_SUMMARY_FAIL, error: e.message });
    }
}

export default function* fetchSummary() {
    yield takeEvery(GET_SUMMARY_START, getSummary)
}