/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './loginPage.css';
import { Button, Input } from 'antd';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { generateRandomCode } from '../../utils/randomCode';
import { appName } from '../../constants/defaultLabel';

const LoginPage: React.FC<any> = ({ onSubmit, loading, form, onHandleInputChanges, showOtp }: any) => {
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState('password');

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };

    const navigator: any = () => {
        const dataToSend = { changePassword: true, email: form.email };
        localStorage.setItem('isUpdateCredentials', generateRandomCode(10));
        navigate('/change-password', { state: dataToSend });
    };

    return (
        <div className="container">
            <div id="container-box" className="container-box">
                <div className="login-container-box__items">
                    <div className="item__contents">
                        <span className="login-title">Login to your {appName} account</span>
                        <div className="sized-box"></div>

                        <div className="login-form__input">
                            <span>Email/Phone number</span>
                            <Input
                                type="email"
                                value={form.email}
                                size="large"
                                name="email"
                                onChange={onHandleInputChanges}
                                placeholder="Enter email or phone number"
                            />
                        </div>

                        <div className="login-form__input">
                            <span>Password</span>
                            <div className="input-group">
                                <Input
                                    type={passwordType}
                                    value={form.password}
                                    size="large"
                                    name="password"
                                    onChange={onHandleInputChanges}
                                    placeholder="Enter password"
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            onSubmit();
                                        }
                                    }}
                                />
                                <Button
                                    type="primary"
                                    icon={
                                        passwordType === 'password' ? (
                                            <BsEyeSlash size={25} style={{ marginTop: '-13px', marginLeft: '5px' }} />
                                        ) : (
                                            <BsEye size={25} style={{ marginTop: '-13px', marginLeft: '5px' }} />
                                        )
                                    }
                                    onClick={togglePassword}
                                    size="large"
                                    style={{ marginLeft: '10px', background: '#000' }}
                                />
                            </div>
                        </div>

                        {showOtp && (
                            <div className="login-form__input">
                                <span>Provide OTP</span>
                                <Input
                                    type="text"
                                    value={form.otp}
                                    size="large"
                                    name="otp"
                                    onChange={onHandleInputChanges}
                                    placeholder="Enter OTP"
                                />
                            </div>
                        )}

                        <div
                            className="login-form__input"
                            style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                        >
                            <button
                                onClick={navigator}
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    marginTop: '10px',
                                    background: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Forgot password ?
                            </button>
                        </div>

                        <div className="login-btn">
                            <Button
                                style={{ background: '#000', color: '#fff', fontWeight: '600' }}
                                size="large"
                                type="primary"
                                loading={loading}
                                onClick={() => onSubmit()}
                                block
                            >
                                Login
                            </Button>
                        </div>

                        <div className="login-form__input">
                            <Link to="/" className="go-to-web__btn">
                                Visit our client site
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
