import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TbPrison } from 'react-icons/tb';
import { LoadingOutlined } from '@ant-design/icons';
import { GiPrisoner, GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../interfaces';
import { getSummaryReportData } from '../../redux/actions';
import Table, { ColumnsType } from 'antd/es/table';
import { Spin } from 'antd';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IForm {
    facility_name: string;
    incoming_amount: string;
    out_going_amount: string;
    total_amount: string;
    currency: string;
    facility: string;
    inmate_number: string;
}

const SuperAdminDashboard = () => {
    const dispatch = useDispatch();

    const {
        getSummaryReport: {
            data: { data },
        },
    } = userSelector((user) => user);

    const facilityData: IForm[] = data?.facility_amount?.map((item: IForm) => {
        return {
            facility_name: `${item?.facility_name}`,
            incoming_amount: `${item?.incoming_amount} ${item?.currency}`,
            out_going_amount: `${item?.out_going_amount} ${item?.currency}`,
            total_amount: `${item?.total_amount} ${item?.currency}`,
        };
    });

    const facilitiesInmateData: IForm[] = data?.facility_inmates?.map((item: IForm) => {
        return {
            facility_name: item?.facility,
            inmate_number: item?.inmate_number,
        };
    });

    const column1: ColumnsType<IForm> = [
        {
            title: 'Correctional facility',
            dataIndex: 'facility_name',
            key: 'facility_name',
        },
        {
            title: 'Number of inmates',
            dataIndex: 'inmate_number',
            key: 'inmate_number',
        },
    ];

    const columns: ColumnsType<IForm> = [
        {
            title: 'Correctional facility',
            dataIndex: 'facility_name',
            key: 'facility_name',
            fixed: 'left',
        },
        {
            title: 'Incoming amount',
            dataIndex: 'incoming_amount',
            key: 'incoming_amount',
        },
        {
            title: 'Outgoing amount',
            dataIndex: 'out_going_amount',
            key: 'out_going_amount',
        },
        {
            title: 'Balance',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },
    ];

    useEffect(() => {
        dispatch(getSummaryReportData({}));
    }, [dispatch]);

    return (
        <div className="dashboard-container__box">
            {data !== undefined ? (
                <div className="user-account-tbl">
                    <div className="top-items">
                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: '#abf1ff' }}>
                                            <i>
                                                <TbPrison size={30} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Correctional facilities</span>
                                            <a></a>
                                        </div>
                                        <h2>{data?.all_facilities}</h2>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: '#99ffd3' }}>
                                            <i>
                                                {' '}
                                                <GiPrisoner size={30} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Inmates in country</span>
                                        </div>

                                        <h2>{data?.all_inmates}</h2>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: '#ffadbe' }}>
                                            <i>
                                                <GiReceiveMoney size={25} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Incoming amount</span>
                                        </div>

                                        <h2>
                                            {data?.incoming_amount} {data?.currency}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link to="#">
                            <div className="card-container">
                                <div className="card-container__box">
                                    <div className="card-container_right-item">
                                        <div className="icons" style={{ background: 'rgb(255, 215, 173)' }}>
                                            <i>
                                                <GiTakeMyMoney size={25} />
                                            </i>
                                        </div>
                                    </div>
                                    <div className="card-container_left-item">
                                        <div className="title-box">
                                            <span>Outgoing amount</span>
                                        </div>

                                        <h2>
                                            {data?.out_going_amount} {data?.currency}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="facility_summary_tbl">
                        <div className="facility_summary_tbl-left">
                            <div className="title">
                                <span>Finance Report</span>
                            </div>
                            <Table
                                columns={columns}
                                dataSource={facilityData}
                                pagination={data?.length >= 10 ? undefined : false}
                            />
                        </div>
                        <div className="facility_summary_tbl-left">
                            <div className="title">
                                <span>Inmate per facility</span>
                            </div>
                            <Table
                                columns={column1}
                                dataSource={facilitiesInmateData}
                                pagination={data?.length >= 10 ? undefined : false}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div style={{ margin: '20% auto', height: '800px' }}>
                        <Spin
                            style={{ color: ' #282936' }}
                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default SuperAdminDashboard;
