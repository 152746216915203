/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GET_ORDERS_DETAILS_FAIL,
    GET_ORDERS_DETAILS_START,
    GET_ORDERS_DETAILS_SUCCESS,
} from '../../../constants/actionType/orders';

export interface ISGetOrdersDetailsDetailsData {
    data: any;
    error: any;
}

export const getOrdersDetailsData = (data:any) => ({ type: GET_ORDERS_DETAILS_START, data });

export const getOrdersDetailsSuccess = (data: any) => ({
    type: GET_ORDERS_DETAILS_SUCCESS,
    payload: data,
});

export const getOrdersDetailsFail = (error: any) => ({
    type: GET_ORDERS_DETAILS_FAIL,
    payload: error,
});
