import {
    GET_ORDERS_FAIL,
    GET_ORDERS_START,
    GET_ORDERS_SUCCESS,
} from '../../../constants/actionType/orders';

export interface ISGetOrdersData {
    data: any;
    error: any;
}

export const getOrdersData = () => ({ type: GET_ORDERS_START });

export const getOrdersSuccess = (data: ISGetOrdersData) => ({
    type: GET_ORDERS_SUCCESS,
    payload: data,
});

export const getOrdersFail = (error: ISGetOrdersData) => ({
    type: GET_ORDERS_FAIL,
    payload: error,
});
