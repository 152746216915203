import {
    Button,
    DrawerProps,
    Flex,
    Input,
    List,
    Popconfirm,
    Popover,
    RadioChangeEvent,
    Spin,
    Steps,
    Table,
    Tabs,
    TabsProps,
} from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FilePdfOutlined, LeftOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

import { getOrderFileDetailsData } from '../../../redux/actions/orders/getOrderFileDetails';
import { IUserSelectorType } from '../../../interfaces';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined } from '@ant-design/icons';
import CustomDrawer from '../../common/drawer';
import { approveOrderFileData, calculateOrderAmountData, getOrdersDetailsData } from '../../../redux/actions';
import DeliveryNote from '../deliveryNote';
import PurchaseOrder from '../purchaseOrder';

import FormModal from '../../common/modal';
import OrderFileSummary from './OrderFileSummary';
import DetailsOnOrder from './DetailsOnOrder';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IOderDetails {
    id: string;
    price: string;
    picture: string;
    article: string;
    quantity: string;
    currency: string;
}

interface ISteps {
    title: string;
    description: string;
}

interface ItemType {
    first_name: string;
    last_name: string;
    id: number;
    item: string;
    inmate_code: string;
    requested_quantity: number;
    total_amount: number;
    currency?: string;
    inmateWallet: number;
    walletAfterPayment: number;
}

export interface DataType {
    id: string;
    name: string;
    username: number;
    address: string;
    user_profiles: string[];
    order_products: [IOderDetails];
    userRoleId: number;
    rows: any;
    firstName: string;
    lastName: string;
    status: string;
    amount: string;
    send_moneys: any;
    currency: string;
    totalQuantity: string;
    totalAmount: string;
    price: string;
    quantity: string;
    orderedProducts: [IOderDetails];
    inmateWallet: string;
    walletAfterPayment: string;
    article: string;
    inmate_code: string;
}

const OrderDetails: React.FC<any> = ({ setChangePage, orderFileId, stepper, actionType, currentUser }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement]: any = useState<DrawerProps['placement']>('right');
    const [showNextPage, setShowNextPage] = useState('details');
    const [inmateDetails, setInmateDetails]: any = useState();

    const [comment, setComment] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const {
        getOrderDetails,
        getOrderFileDetails: { data, loading },
        getUserData,
        approveOrderFile,
        calculateOrderAmount,
    } = userSelector((user) => user);

    const inmatesOrdersSummary: any = Array.isArray(data?.details)
        ? data?.details.map((item: ItemType) => {
              const currency = item.currency ?? 'Rwf';
              return {
                  firstName: item.first_name,
                  lastName: item.last_name,
                  inmateId: item.id,
                  item: item.item,
                  inmate_code: item.inmate_code,
                  totalQuantity: item.requested_quantity,
                  totalAmount: `${item.total_amount} ${currency}`,
                  inmateWallet: `${item.inmateWallet} ${currency}`,
                  walletAfterPayment: `${item.walletAfterPayment} ${currency}`,
              };
          })
        : Array.isArray(data)
        ? data?.map((item: ItemType) => {
              const currency = item.currency ?? 'Rwf';
              return {
                  firstName: item.first_name,
                  lastName: item.last_name,
                  inmateId: item.id,
                  item: item.item,
                  inmate_code: item.inmate_code,
                  totalQuantity: item.requested_quantity,
                  totalAmount: `${item.total_amount} ${currency}`,
                  inmateWallet: `${item.inmateWallet} ${currency}`,
                  walletAfterPayment: `${item.walletAfterPayment} ${currency}`,
              };
          })
        : [];

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onHandleChangeComment = (e: any) => setComment(e.target.value);

    const columns: ColumnsType<DataType> =
        [3, 2, 7].includes(getUserData?.data?.data[0]?.role_id) === true
            ? [
                  {
                      title: 'First name',
                      dataIndex: 'firstName',
                      key: 'firstName',
                  },
                  {
                      title: 'Last name',
                      dataIndex: 'lastName',
                      key: 'lastName',
                  },
                  {
                      title: 'RSC case number',
                      dataIndex: 'inmate_code',
                      key: 'inmate_code',
                  },
                  {
                      title: 'Ordered Item Count',
                      dataIndex: 'totalQuantity',
                      key: 'totalQuantity',
                  },
                  {
                      title: 'Action',
                      key: 'action',
                      render: (_, data: any) => {
                          return (
                              <>
                                  <a
                                      onClick={() => {
                                          setInmateDetails(data);
                                          setOpen(true);
                                          dispatch(
                                              getOrdersDetailsData({
                                                  inmate_id: data?.inmateId,
                                                  ordersFileId: orderFileId,
                                              }),
                                          );
                                      }}
                                  >
                                      Details
                                  </a>
                              </>
                          );
                      },
                  },
              ]
            : [
                  {
                      title: 'First name',
                      dataIndex: 'firstName',
                      key: 'firstName',
                  },
                  {
                      title: 'Last name',
                      dataIndex: 'lastName',
                      key: 'lastName',
                  },
                  {
                      title: 'RSC case number',
                      dataIndex: 'inmate_code',
                      key: 'inmate_code',
                  },
                  {
                      title: 'Ordered Item Count',
                      dataIndex: 'totalQuantity',
                      key: 'totalQuantity',
                  },
                  {
                      title: 'Action',
                      key: 'action',
                      render: (_, data: any) => {
                          return (
                              <a
                                  onClick={() => {
                                      setInmateDetails(data);
                                      setOpen(true);
                                      dispatch(
                                          getOrdersDetailsData({
                                              inmate_id: data?.inmateId,
                                              ordersFileId: orderFileId,
                                          }),
                                      );
                                  }}
                              >
                                  Details
                              </a>
                          );
                      },
                  },
              ];

    const calculateAmount = () => {
        dispatch(
            calculateOrderAmountData({
                ordersFileId: orderFileId,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            getOrderFileDetailsData({
                ordersFileId: orderFileId,
            }),
        );
    }, [dispatch, actionType, stepper]);

    const approveOrder = () => {
        dispatch(
            approveOrderFileData({
                order_file_id: orderFileId,
                comment: '',
                rejected: false,
                approved: true,
                ready_file: true,
            }),
        );
        setChangePage('default');
    };

    const requestApproval = () => {
        dispatch(
            approveOrderFileData({
                order_file_id: orderFileId,
                comment: '',
                rejected: false,
                approved: true,
                ready_file: true,
            }),
        );
        setChangePage('default');
    };

    const rejectOrderFile = () => {
        dispatch(
            approveOrderFileData({
                order_file_id: orderFileId,
                comment: comment,
                rejected: true,
                approved: false,
            }),
        );
        setChangePage('default');
    };

    const step: string | number = parseInt(stepper);

    const items: ISteps[] = [
        {
            title: step === 1 ? 'Started' : 'Done',
            description: 'Order officer',
        },
        {
            title: step === 2 ? 'In progress' : step > 2 ? 'Done' : 'Waiting',
            description: 'Head of social',
        },
        {
            title: step === 3 ? 'In progress' : step > 3 ? 'Done' : 'Waiting',
            description: 'Accountant',
        },
        {
            title: step === 4 ? 'In progress' : step > 4 ? 'Done' : 'Waiting',
            description: 'DCF or DDCF',
        },
        {
            title: step === 5 ? 'Done' : 'Waiting',
            description: 'Shop',
        },
    ];

    const calculatedData = calculateOrderAmount?.data;

    useEffect(() => {
        if (calculatedData?.length !== 0) {
            setOpenModal(true);
        } else {
            setOpenModal(false);
        }
    }, [calculatedData]);

    const hideModal = () => {
        setOpenModal(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onChange = (e: RadioChangeEvent) => {
        setPlacement(e.target.value);
    };

    const onClose: any = () => {
        setOpen(false);
    };

    const calculateAmountData = [
        {
            title: 'Facility inmate wallet',
            description: `${calculatedData?.data?.amount} ${calculatedData?.data?.currency}`,
        },
        {
            title: 'Current order amount',
            description: `${calculatedData?.data?.products_amount} ${calculatedData?.data?.currency}`,
        },
        {
            title: 'Total balance in facility',
            description: `${calculatedData?.data?.needed_amount} ${calculatedData?.data?.currency}`,
        },
    ];

    const tabItems: TabsProps['items'] = [
        {
            label: 'Summary',
            key: '1',
            children: <OrderFileSummary data={getOrderDetails?.data} />,
        },
        {
            label: 'Details',
            key: '2',
            children: (
                <DetailsOnOrder
                    data={getOrderDetails?.data}
                    orderFileId={orderFileId}
                    loading={getOrderDetails?.loading}
                    inmate={inmateDetails?.inmateId}
                />
            ),
        },
    ];

    return (
        <>
            {showNextPage === 'details' ? (
                <>
                    <Flex align="start" justify="space-between" style={{ padding: '10px', width: '100%' }}>
                        <Button
                            danger
                            type="primary"
                            icon={<LeftOutlined />}
                            ghost
                            onClick={() => setChangePage('default')}
                        >
                            Back to order files
                        </Button>

                        <Steps
                            current={step}
                            style={{ width: '60%' }}
                            labelPlacement="horizontal"
                            direction="horizontal"
                            items={items}
                        />
                    </Flex>

                    {getUserData?.data?.data !== undefined ? (
                        <Flex align="center" justify="space-between" style={{ padding: '10px', background: '#fff' }}>
                            <Flex align="center" gap="middle" justify="flex-start">
                                <span></span>
                                {getUserData?.data?.data[0]?.role_id === 6 ? (
                                    <>
                                        {getUserData?.data?.data[0]?.role_id === 6 &&
                                        ['approved', 'pending', 'rejected'].includes(actionType) === true &&
                                        parseInt(currentUser) === getUserData?.data?.data[0]?.role_id ? (
                                            <>
                                                <Popconfirm
                                                    placement="bottom"
                                                    title={
                                                        'Are you sure you want to sent approval request for this order file?'
                                                    }
                                                    onConfirm={requestApproval}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button ghost type="primary" loading={approveOrderFile?.loading}>
                                                        <span>Request approval</span>
                                                    </Button>
                                                </Popconfirm>

                                                <Button
                                                    ghost
                                                    type="primary"
                                                    onClick={() => setChangePage('add-orders')}
                                                >
                                                    <span>Add orders</span>
                                                </Button>
                                                <Button ghost type="primary" disabled={true}>
                                                    <span>Upload attached file</span>
                                                </Button>
                                            </>
                                        ) : null}
                                    </>
                                ) : null}

                                {parseInt(currentUser) === 3 &&
                                getUserData?.data?.data[0]?.role_id === 3 &&
                                ['approved', 'rejected'].includes(actionType) === true ? (
                                    <>
                                        <Button
                                            ghost
                                            type="primary"
                                            loading={calculateOrderAmount.loading}
                                            onClick={() => calculateAmount()}
                                        >
                                            <span>Calculate amount</span>
                                        </Button>
                                    </>
                                ) : null}

                                {[2, 3, 5, 4, 7].includes(getUserData?.data?.data[0]?.role_id) === true &&
                                ['approved', 'pending', 'rejected'].includes(actionType) === true &&
                                parseInt(currentUser) === getUserData?.data?.data[0]?.role_id ? (
                                    <>
                                        <Popconfirm
                                            placement="bottom"
                                            title={'Are you sure you want to approve this order file?'}
                                            onConfirm={approveOrder}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button ghost type="primary" loading={approveOrderFile?.loading}>
                                                <span>Approve order</span>
                                            </Button>
                                        </Popconfirm>
                                        <Popover
                                            placement="bottom"
                                            title={'Are you sure you want to reject this order file?'}
                                            content={
                                                <div className="reject-form">
                                                    <Input
                                                        placeholder="Leave a comment!"
                                                        value={comment}
                                                        onChange={onHandleChangeComment}
                                                    />
                                                    <div className="btn-group">
                                                        <Button type="primary" danger>
                                                            Cancel
                                                        </Button>
                                                        <Button type="primary" onClick={() => rejectOrderFile()}>
                                                            Send
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <Button ghost type="primary" loading={approveOrderFile?.loading}>
                                                <span>Reject order</span>
                                            </Button>
                                        </Popover>
                                    </>
                                ) : null}
                            </Flex>
                            <Button onClick={handlePrint} type="primary" icon={<FilePdfOutlined />}>
                                Export to PDF
                            </Button>
                        </Flex>
                    ) : null}

                    <Flex style={{ width: '100%' }} vertical>
                        {[2, 3, 6, 5, 7].includes(getUserData?.data?.data[0]?.role_id) === true ? (
                            <>
                                {loading ? (
                                    <div style={{ margin: '20% auto', height: '800px' }}>
                                        <Spin
                                            style={{ color: ' #282936' }}
                                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                                        />
                                    </div>
                                ) : (
                                    <div ref={componentRef}>
                                        <Table
                                            bordered
                                            size="small"
                                            columns={columns}
                                            dataSource={inmatesOrdersSummary}
                                            defaultExpandAllRows={true}
                                            loading={loading}
                                            pagination={data?.inmateOrdersDetails?.length >= 30 ? undefined : false}
                                        />
                                    </div>
                                )}
                            </>
                        ) : null}
                    </Flex>

                    <CustomDrawer
                        placement={placement}
                        onChange={onChange}
                        showDrawer={showDrawer}
                        onClose={onClose}
                        open={open}
                        title={`${inmateDetails?.firstName} ${inmateDetails?.lastName} orders`}
                        category={'details'}
                        onSubmit={undefined}
                        width={600}
                    >
                        <Tabs defaultActiveKey="1" items={tabItems} />
                    </CustomDrawer>
                </>
            ) : null}

            {showNextPage === 'delivery-note' ? (
                <DeliveryNote setShowNextPage={setShowNextPage} orderFileId={orderFileId} />
            ) : null}

            {showNextPage === 'purchase-order' ? (
                <PurchaseOrder setShowNextPage={setShowNextPage} orderFileId={orderFileId} />
            ) : null}

            <FormModal
                title={'Calculation result'}
                open={openModal}
                hideModal={hideModal}
                loading={undefined}
                register={undefined}
                calculate={true}
                btn="Print"
            >
                <List
                    dataSource={calculateAmountData}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta key={index} title={item.title} description={item.description} />
                        </List.Item>
                    )}
                />
            </FormModal>
        </>
    );
};

export default OrderDetails;
