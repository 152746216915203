import { call, put,  takeEvery } from "redux-saga/effects";
import { REQUEST_GLOBAL_SUMMARY_SUCCESS, REQUEST_GLOBAL_SUMMARY_FAIL, REQUEST_GLOBAL_SUMMARY_START } from "../../../constants/actionType/summary";
import { fetchData } from '../../../helpers/index';
import { IGlobalSummaryData } from "../../../interfaces/summary";


function* getGlobalSummary() {
    try {
        const userData: IGlobalSummaryData = yield call( fetchData, 'global-summary');
        yield put({type: REQUEST_GLOBAL_SUMMARY_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_GLOBAL_SUMMARY_FAIL, error: e.message });
    }
}

export default function* fetchGlobalSummary() {
    yield takeEvery(REQUEST_GLOBAL_SUMMARY_START, getGlobalSummary)
}