/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {  
    REQUEST_PHONE_NUMBER_LINK_SUCCESS,  
    REQUEST_PHONE_NUMBER_LINK_FAIL,  
    REQUEST_PHONE_NUMBER_LINK_START } 
    from "../../../constants/actionType/linkPhoneNumberToInmate";
import {  saveData } from '../../../helpers';




export interface ResponseGenerator{
    message: string
}

function* requestPhoneNumberLink(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'prison/audio/bind-phone-number', data?.data);
        yield put({type:REQUEST_PHONE_NUMBER_LINK_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type:REQUEST_PHONE_NUMBER_LINK_FAIL, error: e.message });
    }
}

export default function* fetchRequestPhoneNumberLink() {
    yield takeEvery(REQUEST_PHONE_NUMBER_LINK_START, requestPhoneNumberLink)
}