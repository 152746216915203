import { call, put, takeEvery } from "redux-saga/effects";
import { 
    GET_DEVICES_START, 
    GET_DEVICES_SUCCESS, 
    GET_DEVICES_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import {  saveData } from "../../../helpers";




export interface ResponseGenerator{
    message: string
}



function* getDevices(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/fetch-devices', data?.data);
        yield put({type: GET_DEVICES_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_DEVICES_FAIL, error: e.message });
    }
}

export default function* fetchGetDevices() {
    yield takeEvery(GET_DEVICES_START, getDevices)
}