/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    FETCH_MANAGED_ZONES_CLEAR,
    FETCH_MANAGED_ZONES_FAIL, 
    FETCH_MANAGED_ZONES_START, 
    FETCH_MANAGED_ZONES_SUCCESS 
} from "../../../constants/actionType/orders";


export const fetchCellsData = (data: any) => ({type:  FETCH_MANAGED_ZONES_START, data});
  
export const fetchCellsSuccess = (data: any) => ({
    type: FETCH_MANAGED_ZONES_SUCCESS,
    payload: data
  })
  
export const fetchCellsError = (error:string) => ({
    type: FETCH_MANAGED_ZONES_FAIL,
    payload: error
  })

export const clearFetchCellsStore = () => ({
    type:  FETCH_MANAGED_ZONES_CLEAR,
  });
