/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    OUT_GOING_MONEY_FAIL, 
    OUT_GOING_MONEY_START, 
    OUT_GOING_MONEY_SUCCESS,
    CLEAR_OUT_GOING_MONEY
} from "../../../constants/actionType/sendMoney";

 export const outGoingMoneyData = (data: any) => ({type:  OUT_GOING_MONEY_START, data});
 
 export const outGoingMoneySuccess = (data:any) => ({
   type: OUT_GOING_MONEY_SUCCESS,
   payload: data
 })
 
 export const outGoingMoneyError = (error:any) => ({
   type: OUT_GOING_MONEY_FAIL,
   payload: error
 })

 export const clearOutGoingMoney = () => ({
  type: CLEAR_OUT_GOING_MONEY,
})