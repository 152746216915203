import { 
    FETCH_ACTIVITY_LOG_FAIL, 
    FETCH_ACTIVITY_LOG_START, 
    FETCH_ACTIVITY_LOG_SUCCESS,
    FETCH_ACTIVITY_LOG_CLEAR 
} from '../../../constants/actionType/users';
import { IFetchActivityLog } from '../../actions';



const INITIAL_STATE: IFetchActivityLog = {
    fetchActivityLog: null,
    fetchActivityLogError: null,
    loadGetActivityLog: false
}

const fetchActivityLog = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_ACTIVITY_LOG_START: {
            return {
                ...state,
                loadGetActivityLog: true,
                fetchActivityLogError: "",
            };
        }

        case FETCH_ACTIVITY_LOG_SUCCESS: {
            return {
                ...state,
                loadGetActivityLog: false,
                fetchActivityLog: action.data,
            };
        }

        case FETCH_ACTIVITY_LOG_FAIL: {
            return {
                ...state,
                loadGetActivityLog: false,
                fetchActivityLogError: action.error,
            };
        }
        case FETCH_ACTIVITY_LOG_CLEAR: {
            return {
                ...state,
                loading: false,
                fetchActivityLogError: null,
                fetchActivityLog: null
            };
        }
        default: {
            return state;
        }
    }
}

export default fetchActivityLog;