/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_CALLING_SETTINGS_SUCCESS, GET_CALLING_SETTINGS_FAIL, GET_CALLING_SETTINGS_START } from "../../../constants/actionType/inmates";
import { fetchData } from '../../../helpers';
import { delay } from "../../../constants/time";


export interface ResponseGenerator{}

function* getCallingSettings() {
    try {
        const userData: ResponseGenerator = yield call(fetchData, 'prison/audio/retrieve-default-configuration');
        yield call(delay, 3000);
        yield put({type: GET_CALLING_SETTINGS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_CALLING_SETTINGS_FAIL, error: e.message });
    }
}

export default function* retrieveCallingSettings() {
    yield takeEvery(GET_CALLING_SETTINGS_START, getCallingSettings)
}