/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex } from 'antd';
import React from 'react';
import { FileImageOutlined, CloudUploadOutlined } from '@ant-design/icons';

interface Data {
    id: number;
    document: string;
    action_status: string;
    created_at: string;
    steps: any;
    actions: any;
    current_user_role: any;
}

interface RenderActionIconsProps {
    data: Data;
    userRoleId: number;
    onModelDisplayImg: () => void;
    setDocument: any;
    setOrderFileId: any;
    setChangePage: any;
    setStepper: any;
    setActionType: any;
    setCurrentUser: any;
    onModalUploadedDocument: any;
}

const RenderActionIcons: React.FC<RenderActionIconsProps> = ({
    data,
    userRoleId,
    onModelDisplayImg,
    setDocument,
    setOrderFileId,
    setChangePage,
    setStepper,
    setActionType,
    setCurrentUser,
    onModalUploadedDocument,
}) => {
    return (
        <Flex align="center" gap="small">
            {[2, 3, 5, 6, 7].includes(userRoleId) && (
                <Button
                    onClick={() => {
                        onModalUploadedDocument();
                        setDocument({
                            name: data.document,
                            file: data.id,
                        });
                    }}
                    type="primary"
                    ghost
                    icon={<FileImageOutlined />}
                >
                    Preview
                </Button>
            )}
            {userRoleId === 6 && (
                <Button
                    onClick={() => {
                        onModelDisplayImg();
                        setOrderFileId(data.id);
                    }}
                    icon={<CloudUploadOutlined />}
                    type="primary"
                    ghost
                >
                    Upload
                </Button>
            )}
            <Button
                onClick={() => {
                    setOrderFileId({
                        ordersFileId: data.id,
                    });
                    setChangePage('order-file-details');
                    setOrderFileId(data.id);
                    setStepper(data.steps);
                    setActionType(data.action_status);
                    setCurrentUser(data.current_user_role);
                }}
                type="primary"
                ghost
            >
                Details
            </Button>
        </Flex>
    );
};

export default RenderActionIcons;
