import { Spin, Table } from 'antd';

import React from 'react';

import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined } from '@ant-design/icons';

const InmateComprehensiveReportDetails: React.FC<any> = ({ inmateReportDetails }) => {
    const inmatesComprehensiveReportData: any = inmateReportDetails?.map((item: any) => {
        return {
            name: item?.visitor_name,
            visitor_contact: item?.visitor_contact,
            frequency: item?.visit_number,
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Contacts',
            dataIndex: 'visitor_contact',
            key: 'visitor_contact',
        },
        {
            title: 'Visit frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },
    ];

    return (
        <>
            <div className="tbl-items"></div>
            {inmateReportDetails !== undefined ? (
                <>
                    {' '}
                    <Table
                        columns={columns}
                        dataSource={inmatesComprehensiveReportData}
                        pagination={inmateReportDetails?.length >= 10 ? undefined : false}
                    />
                </>
            ) : (
                <>
                    {' '}
                    <div style={{ margin: '20% auto', height: '800px' }}>
                        <Spin
                            style={{ background: ' #282936' }}
                            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default InmateComprehensiveReportDetails;
