/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REGISTER_USER_FAIL, 
    REGISTER_USER_START, 
    REGISTER_USER_SUCCESS 
} from "../../constants/actionType/auth";
import { saveData } from '../../helpers/index';

interface ResponseGenerator{
    data: any,
    message: string
}

function* registerUser(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, `user/signup`, data?.data);
        yield put({type: REGISTER_USER_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REGISTER_USER_FAIL, error: e.message });
    }
}

export default function* fetchRegisteredUser() {
    yield takeEvery(REGISTER_USER_START, registerUser)
}