/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    REQUEST_USER_ACCOUNT_FAIL,
    REQUEST_USER_ACCOUNT_START,
    REQUEST_USER_ACCOUNT_SUCCESS,
} from '../../../constants/actionType/users';

export const getUserProfileData = () => ({ type: REQUEST_USER_ACCOUNT_START });

export const getUserProfileSuccess = (data: any) => ({
    type: REQUEST_USER_ACCOUNT_SUCCESS,
    payload: data,
});

export const getUserProfileError = (error: any) => ({
    type: REQUEST_USER_ACCOUNT_FAIL,
    payload: error,
});
