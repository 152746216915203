import { call, put, takeEvery } from "redux-saga/effects";
import { 
    AUTO_START_SESSION_START, 
    AUTO_START_SESSION_SUCCESS, 
    AUTO_START_SESSION_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import {  saveData } from "../../../helpers";




export interface ResponseGenerator{
    message: string
}



function* autoStartSession(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/auto-start-session', data?.data);
        yield put({type: AUTO_START_SESSION_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: AUTO_START_SESSION_FAIL, error: e.message });
    }
}

export default function* fetchAutoStartSession() {
    yield takeEvery(AUTO_START_SESSION_START, autoStartSession)
}