/* eslint-disable @typescript-eslint/no-explicit-any */
import { 
    FETCH_VISITOR_LIMIT_START, 
    FETCH_VISITOR_LIMIT_SUCCESS, 
    FETCH_VISITOR_LIMIT_FAIL, 
    FETCH_VISITOR_LIMIT_CLEAR
} from '../../../constants/actionType/facility';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: [],
    error: {},
    loading: false,
}

const fetchVisitorLimit = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_VISITOR_LIMIT_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case FETCH_VISITOR_LIMIT_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }

        case FETCH_VISITOR_LIMIT_FAIL: {
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        }


        case FETCH_VISITOR_LIMIT_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }
        default: {
            return state;
          }
    }
}

export default fetchVisitorLimit;