import { 
    REQUEST_GLOBAL_SUMMARY_START, 
    REQUEST_GLOBAL_SUMMARY_SUCCESS, 
    REQUEST_GLOBAL_SUMMARY_FAIL,
    REQUEST_GLOBAL_SUMMARY_CLEAR
} from '../../../constants/actionType/summary';

interface InitialState {
    data: any;
    error: any;
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    data: {},
    error: {},
    loading: false,
}

const getGlobalSummary = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case REQUEST_GLOBAL_SUMMARY_START: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }

        case REQUEST_GLOBAL_SUMMARY_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        }

        case REQUEST_GLOBAL_SUMMARY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case REQUEST_GLOBAL_SUMMARY_CLEAR: {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            };
        }

        default: {
            return state;
          }
    }
}

export default getGlobalSummary;