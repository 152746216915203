/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    INMATES_TRANSACTION_REPORT_FAIL, 
    INMATES_TRANSACTION_REPORT_START, 
    INMATES_TRANSACTION_REPORT_SUCCESS 
} from "../../../constants/actionType/sendMoney";

 export const inmatesTransactionReportData = (data: any) => ({type:  INMATES_TRANSACTION_REPORT_START, data});
 
 export const inmatesTransactionReportSuccess = (data:any) => ({
   type: INMATES_TRANSACTION_REPORT_SUCCESS,
   payload: data
 })
 
 export const inmatesTransactionReportError = (error:any) => ({
   type: INMATES_TRANSACTION_REPORT_FAIL,
   payload: error
 })