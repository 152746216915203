import { Button, Divider, Flex, Form, Input, Modal } from 'antd';
import { IUserSelectorType } from 'interfaces';
import React, { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearVisitorLimit, fetchVisitorLimitData, visitorLimitData } from 'redux/actions';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SetVisitorLimitModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { visitorLimit, fetchVisitorLimit } = userSelector((state) => state);

    const onSetVisitorLimit = () => {
        const formData = form.getFieldsValue();
        dispatch(visitorLimitData({ visitor_limit: parseInt(formData?.visitor_limit) }));
    };

    const onCloseModal = () => {
        onClose();
        dispatch(clearVisitorLimit());
    };

    useEffect(() => {
        if (visitorLimit.data?.success) {
            toast.success(visitorLimit.message);
            dispatch(fetchVisitorLimitData());
            onCloseModal();
        }

        if (visitorLimit.error) {
            toast.error(visitorLimit.message);
        }
    }, [visitorLimit, visitorLimit.error]);

    useEffect(() => {
        dispatch(fetchVisitorLimitData());
    }, []);

    return (
        <Modal title="Set visitor limit" open={open} onCancel={onClose} footer={[]}>
            <Divider />
            <Flex justify="space-between">
                <span>Visitor Limit</span>
                <span className="bold-text">{fetchVisitorLimit?.data?.desk_capacity ?? 0}</span>
            </Flex>
            <Divider />
            <Form form={form} onFinish={onSetVisitorLimit} layout="vertical">
                <Form.Item name="visitor_limit" label="Visitor Limit" rules={[{ required: true }]}>
                    <Input placeholder="Visitor limit" />
                </Form.Item>
                <Form.Item>
                    <Flex justify="space-between" style={{ width: '100%' }}>
                        <Button danger ghost type="primary" onClick={onCloseModal}>
                            Cancel
                        </Button>
                        <Button loading={visitorLimit.loading} ghost type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SetVisitorLimitModal;
