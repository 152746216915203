import { Button, Empty, Table } from 'antd';
import React, { useEffect } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import EmptyTable from '../../../common/emptyTable';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import {
    clearRequestApprovalOrRejectionTransactionStore,
    requestApprovalOrRejectionTransactionData,
    retrieveBulkTransactionFileDetailsData,
} from '../../../../redux/actions';
import { IFileDetailsData } from '../../../../interfaces/bulkSaving';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { ITransactionFileDetailsData } from '../../../../interfaces/bulkTransaction';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const BulkTransactionDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        state: { fileId, fileCurrentUser },
    } = location;

    const { retrieveBulkTransactionFilesDetails, requestApprovalOrRejectionTransaction, getUserData } = userSelector(
        (state) => state,
    );

    const fileDetails = retrieveBulkTransactionFilesDetails.data?.data
        ? retrieveBulkTransactionFilesDetails?.data?.data
        : [];
    const currentUser = getUserData?.data?.data ? getUserData?.data?.data : {};

    const columns1: ColumnsType<IFileDetailsData> = [
        {
            title: 'Added on',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'Receiver name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Receiver contact',
            dataIndex: 'receiver_contact',
            key: 'receiver_contact',
        },
        {
            title: 'Receiver RSC number',
            dataIndex: 'inmate_receiver_code',
            key: 'inmate_receiver_code',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const dataCol1: IFileDetailsData[] = fileDetails.map((inmate: ITransactionFileDetailsData) => {
        return {
            first_name: inmate?.first_name,
            last_name: inmate?.last_name,
            amount: `${inmate?.amount} RWF`,
            inmate_code: inmate?.inmate_code,
            inmate_receiver_code: inmate?.inmate_receiver_code || 'Nothing to mention',
            receiver_contact: inmate?.receiver_contact || 'Nothing to mention',
            name: `${inmate?.receiver_first_name} ${inmate?.receiver_last_name}`,
            created_at: moment(inmate.created_at).format('DD-MMM-YYYY'),
        };
    });

    const requestForApproval = () => {
        dispatch(
            requestApprovalOrRejectionTransactionData({
                file_id: fileId,
                rejected: false,
                approved: true,
            }),
        );
    };

    const approvalFile = () => {
        dispatch(
            requestApprovalOrRejectionTransactionData({
                file_id: fileId,
                rejected: false,
                approved: true,
            }),
        );
    };

    const rejectFile = () => {
        dispatch(
            requestApprovalOrRejectionTransactionData({
                file_id: fileId,
                rejected: true,
                approved: false,
            }),
        );
    };

    useEffect(() => {
        if (requestApprovalOrRejectionTransaction.data) {
            dispatch(clearRequestApprovalOrRejectionTransactionStore());
            navigate('/out-going-money');
        }
    }, [dispatch, requestApprovalOrRejectionTransaction.data]);

    useEffect(() => {
        dispatch(retrieveBulkTransactionFileDetailsData({ file_id: fileId }));
    }, []);

    return (
        <>
            <div className="user-account-tbl">
                <div className="details-btn-group">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => navigate('/out-going-money')}>
                            <BiArrowBack size={18} />
                            <span style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px' }}>
                                Inmate transfers
                            </span>
                        </Button>
                        <div>
                            {currentUser[0]?.role_id === 5 ? (
                                <Button
                                    loading={requestApprovalOrRejectionTransaction.loading}
                                    onClick={() => requestForApproval()}
                                >
                                    <span>Request for approval</span>
                                </Button>
                            ) : null}

                            {[2, 7, 3].includes(currentUser[0]?.role_id) &&
                            parseInt(currentUser[0]?.role_id) === parseInt(fileCurrentUser) ? (
                                <>
                                    <Button
                                        loading={requestApprovalOrRejectionTransaction.loading}
                                        onClick={() => approvalFile()}
                                    >
                                        <span>Approval</span>
                                    </Button>
                                    <Button
                                        loading={requestApprovalOrRejectionTransaction.loading}
                                        onClick={() => rejectFile()}
                                    >
                                        <span>Reject</span>
                                    </Button>
                                </>
                            ) : null}

                            <Button
                                ghost
                                type={'primary'}
                                disabled={
                                    [2, 7].includes(parseInt(fileCurrentUser)) && currentUser[0]?.role_id == 7
                                        ? false
                                        : true
                                }
                            >
                                Confirm transaction
                            </Button>
                        </div>
                    </div>
                </div>

                {fileDetails.length !== 0 ? (
                    <>
                        <Table
                            columns={columns1}
                            dataSource={dataCol1}
                            pagination={fileDetails.length >= 12 ? undefined : false}
                        />
                    </>
                ) : (
                    <EmptyTable
                        title={'No record found'}
                        subTitle={`Try to add inmate amount by`}
                        Icon={<Empty />}
                        button={
                            <Button ghost type="primary" onClick={() => navigate('/record-transaction')}>
                                clicking here
                            </Button>
                        }
                    />
                )}
            </div>
        </>
    );
};

export default BulkTransactionDetails;
