import { Button, Empty, Input, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../../../../interfaces';
import {
    clearRecordSavingStore,
    getInmateData,
    recordSavingData,
    retrieveBulkSavingFileData,
} from '../../../../redux/actions';
import { IInmateDetails } from '../../../../interfaces/inmate';
import { ColumnsType } from 'antd/es/table';
import EmptyTable from '../../../common/emptyTable';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { IFileData } from '../../../../interfaces/bulkSaving';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IInmateInfo {
    value: string | number;
    label: string;
}

interface IForm {
    bulk_saving_file_id: string | number;
    amount: string | number;
    inmate_code: string;
    first_name: string;
    last_name: string;
    inmate_id: number | string;
}

const RecordSaving = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itemSelected, setItemSelected] = useState<IForm[]>([]);
    const [inmateId, setInmateId] = useState<string | number>();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [savedFiles, setSavedFiles] = useState<IInmateInfo[]>([]);
    const [form, setForm] = useState<IForm>({
        bulk_saving_file_id: '',
        amount: '',
        inmate_code: '',
        first_name: '',
        last_name: '',
        inmate_id: '',
    });

    const {
        getInmates: {
            data: { data },
        },
        recordSaving,
        retrieveBulkSavingFiles,
    } = userSelector((state) => state);

    const inmateInfo: IInmateInfo[] = data?.details?.map((item: IInmateDetails) => {
        return {
            value: item.id,
            label: item?.inmate_code,
        };
    });

    const onChangeInmateCode = (value: string | number) => {
        setInmateId(value);
    };

    const onChangeSaveFile = (value: string | number) => {
        setSelectedFile(value);
    };

    const onSubmit = () => {
        const userData = itemSelected;

        dispatch(
            recordSavingData({
                data: userData,
            }),
        );
    };

    useEffect(() => {
        if (inmateId !== undefined) {
            data?.details?.map((item: IInmateDetails) => {
                if (item?.id === inmateId) {
                    setForm({
                        bulk_saving_file_id: '',
                        inmate_id: item.id,
                        first_name: item.first_name,
                        last_name: item.last_name,
                        inmate_code: item.inmate_code,
                        amount: '',
                    });
                }
            });
        }
    }, [inmateId, data]);

    useEffect(() => {
        dispatch(
            getInmateData({
                inmateWithWallet: 'NO',
            }),
        );
    }, [dispatch]);

    const onChangeInputValue = (event: { target: { name: string; value: string | number } }) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const columns1: ColumnsType<IForm> = [
        {
            title: 'Case number',
            dataIndex: 'inmate_code',
            key: 'inmate_code',
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, _data, index: number) => {
                return <a onClick={() => removeInputFields(index)}>Remove</a>;
            },
        },
    ];

    const dataCol1: IForm[] = itemSelected.map((inmate: IForm) => {
        return {
            inmate_id: inmate?.inmate_id,
            first_name: inmate?.first_name,
            last_name: inmate?.last_name,
            amount: inmate?.amount,
            inmate_code: inmate?.inmate_code,
            bulk_saving_file_id: inmate.bulk_saving_file_id,
        };
    });

    useEffect(() => {
        const newList: IInmateInfo[] = [];
        retrieveBulkSavingFiles.data?.data?.flatMap((item: IFileData) => {
            if (item?.actions_status === 'pending') {
                newList.push({
                    value: item.id,
                    label: item?.file_name,
                });
            }
        });
        setSavedFiles(newList);
    }, []);

    const addInputField = () => {
        setItemSelected([
            ...itemSelected,
            {
                inmate_id: form.inmate_id,
                first_name: form.first_name,
                last_name: form.last_name,
                inmate_code: form.inmate_code,
                amount: parseFloat(`${form.amount}`),
                bulk_saving_file_id: selectedFile,
            },
        ]);
    };

    const removeInputFields = (index: number) => {
        const rows = [...itemSelected];
        rows.splice(index, 1);
        setItemSelected(rows);
    };

    useEffect(() => {
        if (recordSaving.data) {
            navigate('/bulk-saving');
            dispatch(clearRecordSavingStore());
        }
    }, [dispatch, recordSaving?.data]);

    useEffect(() => {
        dispatch(retrieveBulkSavingFileData());
    }, []);

    return (
        <>
            <div className="user-account-tbl">
                <div className="details-btn-group">
                    <div className="tbl-items">
                        <Button className="back-btn" onClick={() => navigate('/bulk-saving')}>
                            <BiArrowBack size={18} />
                            <span style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '-10px' }}>
                                10% income files
                            </span>
                        </Button>
                        <div></div>
                    </div>
                </div>
                <div className="column-box record-money-form">
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div className="send-form__left">
                            <div className="form__input">
                                <span>Select bulk saving file</span>
                                <Select
                                    showSearch
                                    style={{ width: '100%', textAlign: 'left' }}
                                    placeholder="Select bulk saving file"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '')
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    loading={retrieveBulkSavingFiles.loading}
                                    options={savedFiles}
                                    onChange={onChangeSaveFile}
                                />
                            </div>
                            <div className="form__input">
                                <span>Select inmate by case number</span>
                                <Select
                                    showSearch
                                    style={{ width: '100%', textAlign: 'left' }}
                                    placeholder="Search inmate by code"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '')
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={inmateInfo}
                                    onChange={onChangeInmateCode}
                                />
                            </div>

                            <div className="form__input">
                                <span>First name</span>
                                <Input
                                    size="middle"
                                    value={form.first_name}
                                    placeholder="Enter first name"
                                    name="first_name"
                                    onChange={(event) => onChangeInputValue(event)}
                                    disabled={true}
                                    style={{ color: '#333' }}
                                />
                            </div>
                            <div className="form__input">
                                <span>{`Last name`}</span>
                                <Input
                                    size="middle"
                                    value={form.last_name}
                                    name="last_name"
                                    onChange={(event) => onChangeInputValue(event)}
                                    placeholder="Last name"
                                    disabled={true}
                                    style={{ color: '#333' }}
                                />
                            </div>
                            <div className="form__input">
                                <span>{`Inmate code`}</span>
                                <Input
                                    size="middle"
                                    type="text"
                                    value={form.inmate_code}
                                    name="inmate_code"
                                    onChange={(event) => onChangeInputValue(event)}
                                    placeholder="Enter inmate code"
                                    disabled={true}
                                    style={{ color: '#333' }}
                                />
                            </div>

                            <div className="form__input">
                                <span>{`Amount`}</span>
                                <Input
                                    size="middle"
                                    placeholder="Enter amount"
                                    value={form.amount}
                                    name="amount"
                                    onChange={(event) => onChangeInputValue(event)}
                                />
                            </div>

                            <Button
                                disabled={
                                    form?.amount === '' ||
                                    form?.amount === 0 ||
                                    form?.inmate_code === '' ||
                                    form?.first_name === '' ||
                                    form?.last_name === '' ||
                                    selectedFile === undefined ||
                                    selectedFile === ''
                                        ? true
                                        : false
                                }
                                size="large"
                                className="add-btn-list"
                                onClick={() => addInputField()}
                            >
                                Add
                            </Button>
                        </div>
                        <div className="display-inmate-recorded">
                            <div style={{ height: 600 }}>
                                {itemSelected.length !== 0 ? (
                                    <>
                                        {' '}
                                        <Table
                                            columns={columns1}
                                            dataSource={dataCol1}
                                            pagination={itemSelected.length >= 8 ? undefined : false}
                                        />
                                        <Button
                                            loading={recordSaving.loading}
                                            disabled={itemSelected.length === 0 ? true : false}
                                            className="save-btn"
                                            onClick={() => onSubmit()}
                                        >
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <EmptyTable
                                        title={'No record found'}
                                        subTitle={`Try to add inmate with amount by selecting inmate by their case number.`}
                                        Icon={<Empty />}
                                        button={undefined}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecordSaving;
