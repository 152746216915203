/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    GET_FACILITY_USERS_FAIL, 
    GET_FACILITY_USERS_START, 
    GET_FACILITY_USERS_SUCCESS 
} from "../../../constants/actionType/facility";

 export const getFacilityUserData = (data: any) => ({type:  GET_FACILITY_USERS_START, data});
 
 export const getFacilityUserSuccess = (data:any) => ({
   type: GET_FACILITY_USERS_SUCCESS,
   payload: data
 })
 
 export const getFacilityUserError = (error:any) => ({
   type: GET_FACILITY_USERS_FAIL,
   payload: error
 })