/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
     PREVIEW_UPLOADED_FILE_START 
} from "../../../constants/actionType/orders";
import { saveData} from '../../../helpers';
import { previewOrderFileError, previewOrderFileSuccess } from "../../actions";


interface ResponseGenerator {
    data: any;
}

function* previewOrderFile(action: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/preview-uploaded-file', action.data);
        if (response?.data?.error) {
            yield put(previewOrderFileError(response.data || 'Unknown error'));
        } else {
            yield put(previewOrderFileSuccess(response));
        }
    } catch (e: any) {
        yield put(previewOrderFileError(e.message || 'Unknown error'));
    }
}

export default function* fetchPreviewOrderFile() {
    yield takeEvery(PREVIEW_UPLOADED_FILE_START, previewOrderFile);
}