/* eslint-disable @typescript-eslint/no-explicit-any */

import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_ORDERS_DETAILS_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers/index';
import { getOrdersDetailsFail, getOrdersDetailsSuccess } from "../../actions";


export interface ResponseGenerator {
    data: any
}

function* getOrdersDetails(data:any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/retrieve-orders-details', data?.data);
        if (response?.data?.error) {
            yield put(getOrdersDetailsFail(response.data || 'Unknown error'));
        } else {
            yield put(getOrdersDetailsSuccess(response));
        }
    } catch (e: any) {
        yield put(getOrdersDetailsFail(e.message || 'Unknown error'));
    }
}

export default function* fetchGetOrdersDetails() {
    yield takeEvery(GET_ORDERS_DETAILS_START, getOrdersDetails)
}

