import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Table, { ColumnsType } from 'antd/es/table';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IUserSelectorType } from '../../../interfaces';
import { getPublicItemsData, getTransactionReportData } from '../../../redux/actions';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SenderTransactionsHistory: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [prisonName, setPrisonName] = useState('');

    const {
        getTransactionsReport: { data, loading },
        getPublicItem,
    } = userSelector((user) => user);
    const publicItems = getPublicItem?.data?.data;

    const reportData = data?.data !== undefined ? data?.data : [];

    const senderReportData: any = reportData?.details?.map((item: any) => {
        return {
            name: `${item?.sender?.first_name} ${item?.sender?.last_name}`,
            sender_reference: item?.sender?.sender_contact,
            amount: item?.total_amount,
            inmate_number: item?.inmate_number,
            frequency: item?.frequency,
            inmateCode: item?.inmate_code,
            date: moment(`${item?.created_at}`).format('YYYY-MM-DD'),
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Contact',
            dataIndex: 'sender_reference',
            key: 'sender_reference',
            width: 150,
        },
        {
            title: 'Total amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
        {
            title: 'Number of inmates',
            dataIndex: 'inmate_number',
            key: 'inmate_number',
            width: 150,
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: 150,
        },
    ];

    const onSearch = (value: string) => {
        return value;
    };
    const onHandleChangeCorrectionalService = (value: string) => setPrisonName(value);

    useEffect(() => {
        dispatch(getPublicItemsData());
    }, [dispatch]);

    return (
        <>
            <div className="dashboard-container__box">
                <div className="user-account-tbl">
                    <div className="tbl-items">
                        <h2></h2>
                        <div className="details-btn-group">
                            <div className="right-btn-group">
                                <div className="top-right__items" style={{ width: '100%' }}>
                                    <Select
                                        showSearch
                                        placeholder="Select a correctional facility"
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, option) => {
                                            const val: any = option?.label ?? '';
                                            return val.toLowerCase().includes(input.toLowerCase());
                                        }}
                                        onChange={onHandleChangeCorrectionalService}
                                        options={publicItems?.facilities}
                                    />
                                    <Button
                                        style={{ marginLeft: '30px' }}
                                        loading={loading}
                                        onClick={() => {
                                            dispatch(
                                                getTransactionReportData({
                                                    prison_name: prisonName,
                                                }),
                                            );
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={senderReportData}
                        pagination={data?.length > 14 ? undefined : false}
                    />
                </div>
            </div>
        </>
    );
};

export default SenderTransactionsHistory;
