/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    MAKE_ORDER_START 
} from "../../../constants/actionType/orders";
import { saveData } from '../../../helpers';
import { makeOrderError, makeOrderSuccess } from "../../actions";


export interface ResponseGenerator{
   data: any
}

function* getMakeOder(data: any) {
    try {
        const response: ResponseGenerator = yield call(saveData, 'orders/make-order', data?.data);
        if (response?.data?.error) {
            yield put(makeOrderError(response.data || 'Unknown error'));
        } else {
            yield put(makeOrderSuccess(response));
        }
        } catch (e: any) {
        yield put(makeOrderError(e.message || 'Unknown error'));
        }
}



export default function* fetchGetMakeOder() {
    yield takeEvery(MAKE_ORDER_START, getMakeOder)
}