import {
    REQUEST_FINANCE_REPORT_FAIL, 
    REQUEST_FINANCE_REPORT_START, 
    REQUEST_FINANCE_REPORT_SUCCESS 
    } from "../../../constants/actionType/finance";
    
    
export interface IFinanceReportData {
    data: any,
    error: any
}

export const getFinanceReportData = (data:any) =>  ({type: REQUEST_FINANCE_REPORT_START, data});

export const getFinanceReportSuccess = (data: IFinanceReportData) => ({
    type: REQUEST_FINANCE_REPORT_SUCCESS, 
    payload: data
})

export const getFinanceReportFail = (error: IFinanceReportData) => ({ type: REQUEST_FINANCE_REPORT_FAIL, payload: error})