import { call, put, takeEvery } from "redux-saga/effects";
import { 
    REQUEST_VIDEO_VISITS_START, 
    REQUEST_VIDEO_VISITS_SUCCESS, 
    REQUEST_VIDEO_VISITS_FAIL, 
} from '../../../constants/actionType/videoMeeting';
import {  saveData } from "../../../helpers";




export interface ResponseGenerator{
    message: string
}



function* videoVisits(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'video-call/today-meeting', data?.data);
        yield put({type: REQUEST_VIDEO_VISITS_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: REQUEST_VIDEO_VISITS_FAIL, error: e.message });
    }
}

export default function* fetchVideoVisits() {
    yield takeEvery(REQUEST_VIDEO_VISITS_START, videoVisits)
}