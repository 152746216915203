import {
    GET_NOTIFICATION_FAIL,
    GET_NOTIFICATION_START,
    GET_NOTIFICATION_SUCCESS,
} from '../../../constants/actionType/notifications';

export interface ISGetNotificationData {
    data: any;
    error: any;
}

export const getNotificationData = () => ({ type: GET_NOTIFICATION_START });

export const getNotificationSuccess = (data: ISGetNotificationData) => ({
    type: GET_NOTIFICATION_SUCCESS,
    payload: data,
});

export const getNotificationFail = (error: ISGetNotificationData) => ({
    type: GET_NOTIFICATION_FAIL,
    payload: error,
});
