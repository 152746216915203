/* eslint-disable @typescript-eslint/no-explicit-any */
import {
   APPROVE_REJECT_REQUEST_FAIL, 
   APPROVE_REJECT_REQUEST_START, 
   APPROVE_REJECT_REQUEST_SUCCESS,
   CLEAR_APPROVE_REJECT_REQUEST_STORE
} from "../../../constants/actionType/appointment";

 export const approveRejectAppointmentData = (data: any) => ({type: APPROVE_REJECT_REQUEST_START, data});
 
 export const approveRejectAppointmentSuccess = (data:any) => ({
   type:APPROVE_REJECT_REQUEST_SUCCESS,
   payload: data
 })
 
 export const approveRejectAppointmentError = (error:any) => ({
   type:APPROVE_REJECT_REQUEST_FAIL,
   payload: error
 })

 export const clearApproveRejectAppointment = () => ({
  type:CLEAR_APPROVE_REJECT_REQUEST_STORE,
})