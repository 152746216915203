/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_BOOKING_REPORT_SUCCESS, GET_BOOKING_REPORT_FAIL, GET_BOOKING_REPORT_START } from "../../../constants/actionType/appointment";
import {  saveData } from '../../../helpers';


export interface ResponseGenerator{}

function* getBookingReport(data:any) {
    try {
        const userData: ResponseGenerator = yield call(saveData, 'general/booking-report', data?.data);
        yield put({type: GET_BOOKING_REPORT_SUCCESS, data: userData});

    } catch (e: any) {
        yield put({ type: GET_BOOKING_REPORT_FAIL, error: e.message });
    }
}

export default function* fetchBookingReport() {
    yield takeEvery(GET_BOOKING_REPORT_START, getBookingReport)
}