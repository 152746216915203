/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { logoutData } from '../redux/actions';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IUserSelectorType } from '../interfaces';

export const token: any = localStorage.getItem('token');
export const userInfo: any = {};

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const AuthMiddleware = ({ isAuthProtected }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const splitLocation: any = pathname.split('/');
    const { logout } = userSelector((user) => user);

    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (!userToken) {
            navigate('/admin');
        }
    }, [navigate, splitLocation]);

    const storageKey = 'appOpenTime';

    const logouts = () => dispatch(logoutData());

    useEffect(() => {
        const initialTime = Date.now();
        localStorage.setItem(storageKey, initialTime.toString());

        const onStorageChange = (event: StorageEvent) => {
            if (event.key === storageKey && event.oldValue !== initialTime.toString()) {
                logouts();
            }
        };

        window.addEventListener('storage', onStorageChange);

        return () => window.removeEventListener('storage', onStorageChange);
    }, []);

    useEffect(() => {
        if (logout?.data?.data) {
            navigate('/admin');
            localStorage.removeItem('token');
            localStorage.removeItem('currentUser');
            setTimeout(function () {
                window.location.reload();
            });
        }
    }, [logout?.data?.data]);

    return localStorage.getItem('token') && isAuthProtected === true ? <Outlet /> : <Navigate to={'/admin'} />;
};

AuthMiddleware.prototypes = {
    isAuthProtected: PropTypes.bool,
};

export default AuthMiddleware;
