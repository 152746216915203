
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CREATE_SUPPLIER_FAIL, 
    CREATE_SUPPLIER_START, 
    CREATE_SUPPLIER_SUCCESS,
    CLEAR_CREATE_SUPPLIER
} from "../../../constants/actionType/shop"

 export const saveNewSupplierData = (data: any) => ({type:  CREATE_SUPPLIER_START, data});
 
 export const saveNewSupplierSuccess = (data:any) => ({
   type: CREATE_SUPPLIER_SUCCESS,
   payload: data
 })
 
 export const saveNewSupplierError = (error:any) => ({
   type: CREATE_SUPPLIER_FAIL,
   payload: error
 })

 export const clearSaveNewSupplier = () => ({
  type:  CLEAR_CREATE_SUPPLIER,
  payload: null
})

