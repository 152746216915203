/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

export interface TableDataType {
    [x: string]: any;
    id: number;
    file_name: string;
    status: string;
    actions_status: string;
    created_at: moment.MomentInput;
    current_user_role: number;
    steps: number;
    document: string;
    order_file_type: string;
}

interface OrderFilesProps {
    loading: boolean;
    columns: ColumnsType<any>;
    data: any[];
}

const OrderFiles: React.FC<OrderFilesProps> = ({ loading, columns, data }) => {
    return (
        <>
            {!loading ? (
                <Table
                    columns={columns}
                    dataSource={data}
                    size="small"
                    pagination={data?.length > 14 ? undefined : false}
                />
            ) : (
                <div style={{ margin: '20% auto', height: '800px' }}>
                    <Spin style={{ color: ' #282936' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                </div>
            )}
        </>
    );
};

export default OrderFiles;
